import { useState } from "react"

type UseUndoRedo<T> = {
	present: T
	canUndo: boolean
	canRedo: boolean
	set: (newState: T) => void
	undo: () => void
	redo: () => void
	reset: (newState: T) => void
}

/**
 * A general-purpose undo/redo hook that works for any data type T (arrays, objects, strings, etc.)
 */
export function useUndoRedo<T>(initialState: T): UseUndoRedo<T> {
	const [past, setPast] = useState<T[]>([])
	const [present, setPresent] = useState<T>(initialState)
	const [future, setFuture] = useState<T[]>([])

	function set(newState: T) {
		setPast((prevPast) => [...prevPast, present])
		setPresent(newState)
		setFuture([])
	}

	function undo() {
		if (!past.length) return
		const previous = past[past.length - 1]
		const newPast = past.slice(0, -1)
		setPast(newPast)
		setFuture((prevFuture) => [present, ...prevFuture])
		setPresent(previous)
	}

	function redo() {
		if (!future.length) return
		const next = future[0]
		const newFuture = future.slice(1)
		setPast((prevPast) => [...prevPast, present])
		setFuture(newFuture)
		setPresent(next)
	}

	function reset(newState: T) {
		setPast([])
		setPresent(newState)
		setFuture([])
	}

	return {
		present,
		canUndo: past.length > 0,
		canRedo: future.length > 0,
		set,
		undo,
		redo,
		reset,
	}
}
