import type { OfficeAction } from "@/types"
import { memo, useState } from "react"
import { ClaimsSectionHeader } from "./components/ClaimsSectionHeader"
import { OfficeActionSectionContainer } from "./components/OfficeActionSectionContainer"
interface ClaimRowsProps {
	document: OfficeAction
}

export const ClaimRows = memo(({ document }: ClaimRowsProps) => {
	const [sortByRejected, setSortByRejected] = useState(false)
	const [isExpanded, setIsExpanded] = useState(false)
	const claimDetails = document.claimDetails

	if (!claimDetails || claimDetails.length === 0) return null

	// Sorting claims
	const sortedClaims = [...claimDetails].sort((a, b) => {
		if (!sortByRejected) return 0
		// const aIsRejected = a.status === ClaimStatus.REJECTED
		// const bIsRejected = b.status === ClaimStatus.REJECTED
		// if (aIsRejected && !bIsRejected) return -1
		// if (!aIsRejected && bIsRejected) return 1
		return 0
	})

	return (
		<OfficeActionSectionContainer>
			<ClaimsSectionHeader
				sortByRejected={sortByRejected}
				onSortChange={() => setSortByRejected(!sortByRejected)}
				isExpanded={isExpanded}
				onExpandToggle={() => setIsExpanded(!isExpanded)}
			/>
			<div className="mt-3 space-y-2">
				{/* {sortedClaims.map((claim) => (
          <ClaimRow
            key={claim.claimNumber}
            claim={claim}
            isGlobalExpanded={isExpanded} // Pass this to ClaimRow
          />
        ))} */}
			</div>
		</OfficeActionSectionContainer>
	)
})
