/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { CheckIcon, CopyIcon } from "@radix-ui/react-icons"
import clipboardCopy from "clipboard-copy"
import type React from "react"
import { useState } from "react"

interface CopyToClipboardButtonProps {
	index: number
	text: string
	className?: string
	style?: React.CSSProperties
}

/**
 * @description Copy to clipboard button
 */
const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
	index,
	text,
	className,
	style,
}) => {
	const [copiedMessageIndex, setCopiedMessageIndex] = useState<number | null>(null)

	const handleCopyToClipboard = (textToCopy: string, index: number) => {
		clipboardCopy(textToCopy)
			.then(() => {
				setCopiedMessageIndex(index)
				setTimeout(() => setCopiedMessageIndex(null), 2500)
			})
			.catch((err) => {
				console.error("Error copying text:", err)
			})
	}

	const isCopied = copiedMessageIndex === index
	const ariaLabel = isCopied ? "Copied to clipboard" : "Copy to clipboard"

	return (
		<Button
			variant="ghost"
			size="icon"
			className={`h-6 w-6 p-0 ${className}`}
			onClick={() => handleCopyToClipboard(text, index)}
			aria-label={ariaLabel}
			style={style}
		>
			{isCopied ? <CheckIcon className="h-4 w-4" /> : <CopyIcon className="h-4 w-4" />}
		</Button>
	)
}

export default CopyToClipboardButton
