import { format, isValid } from "date-fns"

/**
 * @param {string | Date | undefined} date - The date to format
 * @returns {string} - The formatted date
 */
export const formatDateTime = (date: string | Date | undefined) => {
	if (!date) return "N/A"
	const parsedDate = new Date(date)
	return isValid(parsedDate) ? format(parsedDate, "MMM d, yyyy HH:mm") : "Invalid Date"
}
