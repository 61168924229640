// services/frontend/src/features/create/CreateProjectContext.tsx

import useUnprocessedDocuments from "@/hooks/useUnprocessedDocuments"
import type React from "react"
import { createContext, useContext } from "react"
import useProjectCreation from "./hooks/useProjectCreation"
import useStepNavigation from "./hooks/useStepNavigation"

const CreateProjectContext = createContext<any>(undefined)

export const CreateProjectProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	// Use the Document Management hook
	const documentManagement = useUnprocessedDocuments()

	// Destructure necessary variables from documentManagement
	const {
		unprocessedDocuments,
		unprocessedSubjectDocuments,
		unprocessedOfficeActionDocuments,
	} = documentManagement

	// Pass in the documents to the project creation hook
	const projectCreation = useProjectCreation({
		unprocessedDocuments,
		unprocessedSubjectDocuments,
		unprocessedOfficeActionDocuments,
	})

	// Destructure project details from projectCreation
	const { projectName, projectType } = projectCreation

	// Use the Step Navigation hook, passing in necessary state
	const stepNavigation = useStepNavigation(
		projectName,
		projectType,
		unprocessedSubjectDocuments,
	)

	// Combine all the states into a single context value
	const contextValue = {
		...documentManagement,
		...projectCreation,
		...stepNavigation,
	}

	return (
		<CreateProjectContext.Provider value={contextValue}>
			{children}
		</CreateProjectContext.Provider>
	)
}

export const useCreateProjectContext = () => {
	const context = useContext(CreateProjectContext)
	if (!context) {
		throw new Error("useCreateProjectContext must be used within a CreateProjectProvider")
	}
	return context
}
