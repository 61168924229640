/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import PatentNumberInput from "@/components/documents/patents/PatentNumberInput"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { useNavigationState, useProjectAndPortfolioIds } from "@/hooks"
import useProcessDocuments from "@/hooks/useProcessDocuments"
import { docRoleToProcessType } from "@/hooks/useProcessDocuments"
import useUnprocessedDocuments from "@/hooks/useUnprocessedDocuments"
import {
	DocumentRole,
	type PatentNumberOptions,
	UnprocessedDocumentType,
} from "@/types"
import type React from "react"
import { useState } from "react"
import ProcessSettings from "./ProcessSettings"
import UnprocessedDocumentsList from "./UnprocessedDocumentsList"

interface AddPatentsModalProps {
	open: boolean
	handleClose: () => void
	showProcessSettings?: boolean
	documentRole?: DocumentRole
}
/**
 * @description Modal for adding references by number
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 * @param {boolean} isPortfolio - Whether the modal is for a portfolio
 */
const AddPatentsModal: React.FC<AddPatentsModalProps> = ({
	open,
	handleClose,
	showProcessSettings = true,
	documentRole = DocumentRole.PRIOR_ART,
}) => {
	const { processDocuments } = useProcessDocuments()
	const { isPortfolioPage } = useNavigationState()
	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const { isDocumentsPage } = useNavigationState()

	// Use the custom hook for handling unprocessed documents
	const {
		unprocessedDocuments,
		updateSelectedKindCode,
		removeUnprocessedDocument,
		addUnprocessedDocuments,
		resetAll,
	} = useUnprocessedDocuments()

	// Modal state
	const [useClaimsCheckboxChecked, setUseClaimsCheckboxChecked] = useState<boolean>(true)

	// Reset the modal state on close
	const handleResetModal = () => {
		setUseClaimsCheckboxChecked(true)
		resetAll()
		handleClose()
	}

	const handleAddClick = async () => {
		handleClose()
		if (unprocessedDocuments.length > 0) {
			await processDocuments(
				docRoleToProcessType[documentRole],
				portfolioId,
				isPortfolioPage ? null : projectId,
				unprocessedDocuments,
				!isDocumentsPage, // Don't chart if in user documents
				useClaimsCheckboxChecked,
			)
		}
		setUseClaimsCheckboxChecked(true)
	}

	const handleAddDocuments = (patentDetails: PatentNumberOptions[]) => {
		addUnprocessedDocuments(documentRole, UnprocessedDocumentType.PUBLISHED_PATENT, {
			patentDetails,
		})
	}

	return (
		<Dialog open={open} onOpenChange={handleResetModal}>
			<DialogContent className="w-auto min-w-2xl">
				<DialogHeader>
					<DialogTitle>
						{documentRole === DocumentRole.PRIOR_ART ? "Add Patents" : "Add Subjects"}
					</DialogTitle>
				</DialogHeader>

				{unprocessedDocuments.length === 0 ? (
					<PatentNumberInput onDetails={handleAddDocuments} onCancel={handleResetModal} />
				) : (
					<>
						<UnprocessedDocumentsList
							documents={unprocessedDocuments}
							onRemove={removeUnprocessedDocument}
							updateSelectedKindCode={updateSelectedKindCode}
						/>
						<div className="flex flex-row justify-between gap-2">
							{showProcessSettings && (
								<ProcessSettings
									useClaimsCheckboxChecked={useClaimsCheckboxChecked}
									setUseClaimsCheckboxChecked={setUseClaimsCheckboxChecked}
								/>
							)}

							<div className="flex justify-end gap-2">
								<Button variant="outline" onClick={handleResetModal}>
									Cancel
								</Button>
								<Button onClick={handleAddClick} disabled={unprocessedDocuments.length === 0}>
									Add Patents
								</Button>
							</div>
						</div>
					</>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default AddPatentsModal
