import { api } from "@/api"
import { useApi } from "@/hooks"
import { useAppStateStore } from "@/store"
import type { Organization } from "@/types"
import { invalidateOrgRelatedQueries } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

const useSuperAdminOrganizations = () => {
	const { handleError } = useApi()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()
	const queryClient = useQueryClient()

	// Queries
	const superAdminOrganizations = useQuery({
		queryKey: QUERY_KEYS.superAdmin.organizations,
		queryFn: () => api.getSuperAdminOrganizations(),
		...DEFAULT_QUERY_OPTIONS,
	})

	// Organization Mutations
	const createOrganizationMutation = useMutation({
		mutationKey: MUTATION_KEYS.organization.create(),
		mutationFn: (org: Organization) => api.createOrganization(org),
		onSuccess: () => {
			addSuccessMessage("Organization created successfully")
			invalidateOrgRelatedQueries(queryClient)
		},
		onError: (error) => {
			addErrorMessage("Error creating organization")
			handleError(error, "Error creating organization")
		},
	})

	const updateOrganizationMutation = useMutation({
		mutationKey: MUTATION_KEYS.organization.update(),
		mutationFn: ({
			organizationId,
			newOrg,
		}: {
			organizationId: string
			newOrg: Organization
		}) => api.updateOrganization(organizationId, newOrg),
		onSuccess: () => {
			addSuccessMessage("Organization updated successfully")
			invalidateOrgRelatedQueries(queryClient)
		},
		onError: (error, variables) => {
			addErrorMessage("Error updating organization")
			handleError(error, `Error updating organization: ${variables.organizationId}`)
		},
	})

	// Organization Mutations
	const deleteOrganizationMutation = useMutation({
		mutationKey: MUTATION_KEYS.organization.delete(),
		mutationFn: (organizationId: string) => api.deleteOrganization(organizationId, ""),
		onSuccess: () => {
			invalidateOrgRelatedQueries(queryClient)
		},
		onError: (error) => handleError(error, "Error deleting organization"),
	})

	return {
		// Query data
		organizations: superAdminOrganizations.data,
		isLoading: superAdminOrganizations.isLoading,
		isFetching: superAdminOrganizations.isFetching,

		// Organization mutations
		createOrganization: createOrganizationMutation.mutate,
		updateOrganization: updateOrganizationMutation.mutate,
		deleteOrganization: deleteOrganizationMutation.mutate,

		isDeletePending: deleteOrganizationMutation.isPending,
		isUpdatePending: updateOrganizationMutation.isPending,
		isCreatePending: createOrganizationMutation.isPending,
	}
}

export { useSuperAdminOrganizations }
