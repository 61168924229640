/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { TagIcon, X } from "lucide-react"
import type React from "react"
import { useEffect, useRef, useState } from "react"

interface TableTagsProps {
	tagOptions: string[]
	selectedTags: string[]
	setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>
	onClose: () => void
	onApply: (selectedTags: string[]) => void
	children?: React.ReactNode
}

/**
 * TableTags component for selecting tags from a dropdown menu.
 * @param {TableTagsProps} props - The properties for the TableTags component.
 * @param {string[]} props.tagOptions - The list of available tag options.
 * @param {React.Dispatch<React.SetStateAction<string[]>>} props.setTagOptions - The function to set the available tag options.
 * @param {string[]} props.selectedTags - The list of currently selected tags.
 * @param {React.Dispatch<React.SetStateAction<string[]>>} props.setSelectedTags - The function to set the currently selected tags.
 * @param {() => void} props.onClose - The function to call when the dropdown menu is closed.
 * @param {(selectedTags: string[]) => void} props.onApply - The function to call when the selected tags are applied.
 */
export const TableTags: React.FC<TableTagsProps> = ({
	tagOptions,
	selectedTags,
	setSelectedTags,
	onClose,
	onApply,
	children,
}) => {
	const [searchTerm, setSearchTerm] = useState("")
	const [open, setOpen] = useState(false)
	const inputRef = useRef<HTMLInputElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const [showCreateTag, setShowCreateTag] = useState(false)
	const [localSelectedTags, setLocalSelectedTags] = useState<string[]>([])

	useEffect(() => {
		if (open) {
			inputRef.current?.focus()
		}
	}, [open])

	useEffect(() => {
		setLocalSelectedTags(selectedTags)
	}, [selectedTags])

	useEffect(() => {
		const lowerSearch = searchTerm.toLowerCase()
		const filtered = tagOptions.filter((tag) => tag.toLowerCase().includes(lowerSearch))
		setShowCreateTag(searchTerm.trim() !== "" && !filtered.includes(searchTerm.trim()))
	}, [searchTerm, tagOptions])

	const addNewTag = (tag: string) => {
		if (!localSelectedTags.includes(tag)) {
			setLocalSelectedTags((prev) => [...prev, tag])
		}
		setSearchTerm("")
		setShowCreateTag(false)
		inputRef.current?.focus()
	}

	const toggleTag = (tag: string) => {
		setLocalSelectedTags((prev) =>
			prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag],
		)
		inputRef.current?.focus()
	}

	const removeTag = (tag: string) => {
		setLocalSelectedTags((prev) => prev.filter((t) => t !== tag))
	}

	const handleClickOutside = (e: MouseEvent) => {
		if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
			if (children) {
				// if individual cell
				handleApply()
			} else {
				handleClear()
				onClose()
			}
		}
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [])

	const handleClear = () => {
		setSearchTerm("")
		setSelectedTags([])
		setLocalSelectedTags([])
	}

	const handleApply = () => {
		onApply(localSelectedTags)
		setOpen(false)
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>) => {
		if (e.key === "Enter") {
			e.preventDefault()
			e.stopPropagation()
			if (searchTerm.trim()) {
				addNewTag(searchTerm.trim())
			}
		}
	}

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<Tooltip>
				<TooltipTrigger>
					<PopoverTrigger asChild>
						{children ? ( // individual cell
							children
						) : (
							// table action bar
							<div className="h-9 w-9 flex items-center justify-center hover:bg-blue-500 hover:text-white transition-colors cursor-pointer border-l border-r border-input">
								<TagIcon className="h-4 w-4" />
							</div>
						)}
					</PopoverTrigger>
				</TooltipTrigger>
				<TooltipContent>Add tags</TooltipContent>
			</Tooltip>

			<PopoverContent className="p-0">
				<div>
					<div className="mb-1">
						<div className="flex flex-wrap items-start border border-input bg-accent rounded min-h-[40px] max-h-40 overflow-y-auto overflow-x-hidden">
							{localSelectedTags.map((tag) => (
								<Button
									key={tag}
									variant="outline"
									size="sm"
									className=" items-center mt-1 mx-1 px-2 h-7 text-xs bg-background truncate"
								>
									<span className="text-xs truncate">{tag}</span>
									<X className="h-4 w-4 ml-1 cursor-pointer" onClick={() => removeTag(tag)} />
								</Button>
							))}
							<input
								ref={inputRef} // Ensure input stays focused
								type="text"
								placeholder={selectedTags.length > 0 ? "" : "Search for an option..."}
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								onKeyDown={(e) => handleKeyDown(e)}
								className="flex-grow border-none outline-none focus:outline-none p-2 h-9 bg-transparent text-xs placeholder:text-muted-foreground"
							/>
						</div>
					</div>
					<div className="max-h-40 overflow-y-auto p-2 pt-1">
						<p className="text-xs text-muted-foreground mb-1">
							Select an option or create one
						</p>
						{tagOptions.map((tag) => (
							<Button
								key={tag}
								variant="ghost"
								size="sm"
								className="w-full mb-1 justify-start text-left bg-background hover:bg-secondary border-none outline-none truncate"
								onClick={() => toggleTag(tag)}
							>
								<span className="text-xs truncate">{tag}</span>
							</Button>
						))}
						{showCreateTag && (
							<Button
								variant="ghost"
								size="sm"
								className="w-full justify-start text-left"
								onClick={() => addNewTag(searchTerm.trim())}
							>
								{searchTerm}
							</Button>
						)}
					</div>

					<div className="flex justify-end p-2">
						<Button variant="outline" className="w-full h-9" onClick={handleApply}>
							Apply
						</Button>
					</div>
				</div>
			</PopoverContent>
		</Popover>
	)
}
