import { api } from "@/api"
import type { StripeInvoice } from "@/types"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

interface SuperAdminBillingReturn {
	invoices: StripeInvoice[] | undefined
	isLoading: boolean
	isFetching: boolean
}

const useSuperAdminBilling = (): SuperAdminBillingReturn => {
	const superAdminInvoices = useQuery({
		queryKey: QUERY_KEYS.superAdmin.billing,
		queryFn: () => api.getSuperAdminBilling(),
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		invoices: superAdminInvoices.data,
		isLoading: superAdminInvoices.isLoading,
		isFetching: superAdminInvoices.isFetching,
	}
}

export { useSuperAdminBilling }
