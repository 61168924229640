/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import { Button } from "@/components/ui/button"
import { ChevronRightIcon } from "@radix-ui/react-icons"
import { useNavigate } from "react-router-dom"
import UserDocumentsNavigation from "./UserDocumentsNavigation"

/**
 * @description User document page header containing title and navigation tabs
 */
function UserDocumentPageHeader({ name }: { name: string }) {
	const navigate = useNavigate()
	return (
		<div className="flex items-center justify-between px-4 py-2 border-b">
			<div className="flex items-center space-x-2">
				<div className="flex flex-col mr-4">
					<div className="flex items-center space-x-2">
						<div className="flex items-center">
							<Button
								variant="link"
								className="text-muted-foreground p-0 h-auto"
								onClick={() => {
									navigate("/documents")
								}}
							>
								Documents
							</Button>
							<ChevronRightIcon className="mx-2 h-4 w-4 text-muted-foreground" />
							<span className={"text-base font-medium whitespace-nowrap"}>
								{name || "Untitled"}
							</span>
						</div>
					</div>
				</div>
				<UserDocumentsNavigation />
			</div>
		</div>
	)
}

export default UserDocumentPageHeader
