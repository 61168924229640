import { searchApi } from "@/api/searchApi"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi, useProject } from "@/hooks"
import { useUnprocessedDocuments } from "@/hooks"
import {
	type ApiResponse,
	DocumentRole,
	type PatentNumberOptions,
	UnprocessedDocumentType,
} from "@/types"
import { useState } from "react"

interface SearchFilters {
	fromDate: Date | null
	toDate: Date | null
	cpcCodes: string[]
	keywords: string[]
	countryCodes: string[]
	assignees: string[]
	types: string[]
	statuses: string[]
	maxResults: number
	dedupeFamilyId: boolean
	claimNumbers?: string[]
	featureNumbers?: string[]
}

export const useSearchFilters = (initialFilters?: Partial<SearchFilters>) => {
	const { project } = useProject()

	// Get initial toDate from priority/filing dates
	const getInitialToDate = () => {
		if (project?.priorityDate) {
			const priorityDate = project.priorityDate

			const parsedDate = new Date(priorityDate)
			if (!Number.isNaN(parsedDate.getTime())) {
				return parsedDate
			}
		}

		if (project?.priorityDate) {
			return new Date(project.priorityDate)
		}

		return null
	}

	const getDefaultFilters = (): SearchFilters => ({
		fromDate: null,
		toDate: getInitialToDate(),
		cpcCodes: [],
		keywords: [],
		countryCodes: [],
		assignees: [],
		types: [],
		statuses: [],
		maxResults: 25,
		dedupeFamilyId: true,
		claimNumbers: [],
		featureNumbers: [],
	})

	const [filters, setFilters] = useState<SearchFilters>({
		...getDefaultFilters(),
		...initialFilters,
	})

	const updateFilter = <K extends keyof SearchFilters>(
		key: K,
		value: SearchFilters[K],
	) => {
		setFilters((prev) => ({ ...prev, [key]: value }))
	}

	const resetFilters = () => {
		setFilters(getDefaultFilters())
	}

	return {
		filters,
		updateFilter,
		setFilters,
		resetFilters,
	}
}

const usePriorArtSearch = () => {
	const { handleError } = useApi()
	const [_enabled, _setEnabled] = useState(false)
	const { addUnprocessedDocuments } = useUnprocessedDocuments()

	const _processSearchResults = (results: any[]) => {
		if (results && results.length > 0) {
			addUnprocessedDocuments(
				DocumentRole.PRIOR_ART,
				UnprocessedDocumentType.PUBLISHED_PATENT,
				{
					patentDetails: results,
				},
			)
		}
	}

	// TODO: Add get_patents_for_assignee to the search
	const searchPriorArt = async (
		project_id: string,
		keywords: any[],
		options: { [key: string]: any } = {},
	): Promise<ApiResponse<PatentNumberOptions[]>> => {
		try {
			const priorArt: any = await searchApi.search(project_id, keywords, options)
			// Move below to BE
			const parsedPriorArt = priorArt.map((patent: any) => {
				const prefix = patent.number.match(/^[A-Z]+/)?.[0] || ""
				return { ...patent, number: patent.number.replace(/-/g, ""), prefix }
			})

			return { success: true, data: parsedPriorArt }
		} catch (error) {
			console.error("Error searching for prior art:", error)

			return handleError(error, "Error searching for prior art")
		}
	}

	return {
		searchPriorArt,
	}
}

export default usePriorArtSearch
