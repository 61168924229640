import { useMemo } from "react"
import { useFeedbackChartDataStore } from "./useFeedbackChartDataStore"

/**
 * @description Hook for handling feedback data
 * @returns {object} - The feedback data
 */
export const useFeedbackData = (
	projectId: string,
	documentId: string,
	claimLimitationId: string,
) => {
	const feedbackChartData = useFeedbackChartDataStore((state) => state.feedbackChartData)

	return useMemo(() => {
		const citationData =
			feedbackChartData[projectId]?.[documentId]?.[claimLimitationId] || []
		return {
			positiveFeedbackData: citationData.filter((c) => c.feedback === "positive"),
			negativeFeedbackData: citationData.filter((c) => c.feedback === "negative"),
		}
	}, [feedbackChartData, projectId, documentId, claimLimitationId])
}

export const useClearFeedbackDataByID = () => {
	const clearFeedbackChartData = useFeedbackChartDataStore(
		(state) => state.clearFeedbackChartDataByID,
	)
	return clearFeedbackChartData
}

export default useFeedbackData
