import { api } from "@/api"
import { useApi } from "@/hooks"
import { useAppStateStore } from "@/store"
import type { User } from "@/types"
import { invalidateUserRelatedQueries } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

const useAdminUsers = (orgId: string) => {
	const { handleError } = useApi()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()
	const queryClient = useQueryClient()

	// Queries
	const adminUsers = useQuery({
		queryKey: QUERY_KEYS.admin.users(orgId),
		queryFn: () => api.getUsers(orgId),
		enabled: !!orgId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Mutations
	const createUserMutation = useMutation({
		mutationKey: MUTATION_KEYS.admin.user.create(),
		mutationFn: (userData: { user: User; orgId: string }) =>
			api.createUser(
				userData.user.email,
				userData.user.firstName,
				userData.user.lastName,
				userData.user.role,
				userData.orgId,
			),
		onSuccess: (_result, variables) => {
			addSuccessMessage(`User ${variables.user.email} added successfully`)
			invalidateUserRelatedQueries(queryClient, orgId)
		},
		onError: (error) => {
			addErrorMessage("Error creating user")
			handleError(error, "Error creating user")
		},
		onMutate: async (newUserData) => {
			await queryClient.cancelQueries({
				queryKey: QUERY_KEYS.admin.users(orgId),
			})
			const previousUsers = queryClient.getQueryData<User[]>(QUERY_KEYS.admin.users(orgId))

			queryClient.setQueryData<User[]>(QUERY_KEYS.admin.users(orgId), (old = []) => [
				...old,
				newUserData.user,
			])

			return { previousUsers }
		},
	})

	const updateUserRoleMutation = useMutation({
		mutationKey: MUTATION_KEYS.admin.user.update(),
		mutationFn: ({
			userId,
			role,
			orgId,
		}: {
			userId: string
			role: string
			orgId: string
		}) => api.updateUserRole(userId, role, orgId),
		onSuccess: (_data, variables) => {
			addSuccessMessage(`User role updated to ${variables.role}`)
			invalidateUserRelatedQueries(queryClient, orgId)
		},
		onError: (error) => {
			addErrorMessage("Error updating user role")
			handleError(error, "Error updating user role")
		},
	})

	const deleteUserMutation = useMutation({
		mutationKey: MUTATION_KEYS.admin.user.delete(),
		mutationFn: ({ userId, orgId }: { userId: string; orgId: string }) =>
			api.deleteUser(userId, orgId),
		onSuccess: (_data, variables) => {
			addSuccessMessage(`User with ID ${variables.userId} deleted`)
			invalidateUserRelatedQueries(queryClient, orgId)
		},
		onError: (error) => {
			addErrorMessage("Error deleting user")
			handleError(error, "Error deleting user")
		},
	})

	const toggleUserStatusMutation = useMutation({
		mutationKey: MUTATION_KEYS.admin.user.toggleStatus(),
		mutationFn: ({ userId, enable }: { userId: string; enable: boolean }) =>
			enable ? api.enableUser(userId) : api.disableUser(userId),
		onSuccess: (_data, variables) => {
			addSuccessMessage(
				variables.enable
					? `User with ID ${variables.userId} enabled`
					: `User with ID ${variables.userId} disabled`,
			)
			invalidateUserRelatedQueries(queryClient, orgId)
		},
		onError: (error, variables) => {
			addErrorMessage(
				variables.enable
					? `Error enabling user ${variables.userId}`
					: `Error disabling user ${variables.userId}`,
			)
			handleError(error, `Error ${variables.enable ? "enabling" : "disabling"} user`)
		},
	})

	return {
		// Query data
		adminUsers: adminUsers.data,
		isLoading: adminUsers.isLoading,

		// Flattened mutations
		createUser: createUserMutation.mutate,
		updateUserRole: updateUserRoleMutation.mutate,
		deleteUser: deleteUserMutation.mutate,
		enableUser: (userId: string, options?: any) =>
			toggleUserStatusMutation.mutate({ userId, enable: true }, options),
		disableUser: (userId: string, options?: any) =>
			toggleUserStatusMutation.mutate({ userId, enable: false }, options),

		isCreatePending: createUserMutation.isPending,
		isUpdatePending: updateUserRoleMutation.isPending,
		isDeletePending: deleteUserMutation.isPending,
		isEnablePending: toggleUserStatusMutation.isPending,
		isDisablePending: toggleUserStatusMutation.isPending,
	}
}

export { useAdminUsers }
