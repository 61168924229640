import { api } from "@/api"
import type { ClaimEdit } from "@/types"
import { toSnakeCase } from "@/utils/dataUtils"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useProjectAndPortfolioIds } from "./useProjectAndPortfolioIds"

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useClaims = () => {
	const queryClient = useQueryClient()
	const { projectId } = useProjectAndPortfolioIds()

	const projectClaims = useQuery({
		queryKey: QUERY_KEYS.project.claims(projectId),
		queryFn: ({ queryKey, signal }) => {
			const [, , projectId, collapsed] = queryKey
			return api.getProjectClaims(projectId as string, collapsed as boolean)
		},
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const udpateClaimsMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.claims.update(),
		mutationFn: (claims: ClaimEdit[]) =>
			api.updateClaims({ project_id: projectId, claims: toSnakeCase(claims) }),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: QUERY_KEYS.project.claims(projectId) })
		},
	})

	return {
		projectClaims: projectClaims.data,
		limitations: projectClaims.data?.flatMap((claim) => claim.limitations) ?? [],

		// Loading states
		isLoading: {
			projectClaims: projectClaims.isLoading,
			updateClaims: udpateClaimsMutation.isPending,
		},

		// Error states
		isError: {
			projectClaims: projectClaims.isError,
			updateClaims: udpateClaimsMutation.isError,
		},

		// Mutation functions
		updateClaims: udpateClaimsMutation.mutate,

		// Refetch functions
		refetch: {
			projectClaims: projectClaims.refetch,
		},
	}
}

export default useClaims
