/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import type { ProjectMetadata } from "@/types"
import { useState } from "react"

interface DeletePortfolioProjectModalProps {
	open: boolean
	onClose: () => void
	removeProjectsFromPortfolio: (projects: ProjectMetadata[]) => Promise<void>
	projects: ProjectMetadata[]
}

/**
 * @description Remove projects from portfolio modal
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} onClose - Function to close the modal
 * @param {ProjectMetadata[]} projects - The projects to remove
 * @param {(projects: ProjectMetadata[]) => Promise<void>} removeProjectsFromPortfolio - Function to remove the projects from the portfolio
 */
function DeletePortfolioProjectModal({
	open,
	onClose,
	projects,
	removeProjectsFromPortfolio,
}: DeletePortfolioProjectModalProps) {
	const [isLoading, setIsLoading] = useState(false)

	const handleRemovePatentFromPortfolio = async () => {
		setIsLoading(true)
		await removeProjectsFromPortfolio(projects)
		setIsLoading(false)
		onClose()
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>
						Remove {projects.length > 1 ? "projects" : "project"} from portfolio?
					</DialogTitle>
					<DialogDescription>
						Remove {projects.length > 1 ? "these projects" : "the project"}{" "}
						<strong>{projects.map((project) => project.name).join(", ")}</strong> from this
						portfolio? All data will be deleted. This action cannot be undone.
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button
						variant="outline"
						onClick={onClose}
						aria-label="Cancel deletion"
						disabled={isLoading}
					>
						Cancel
					</Button>
					<LoadingButton
						variant="destructive"
						loading={isLoading}
						onClick={handleRemovePatentFromPortfolio}
						aria-label="Delete patent"
						disabled={isLoading}
					>
						{isLoading ? "Removing..." : "Remove"}
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default DeletePortfolioProjectModal
