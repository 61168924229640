/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ErrorMessage, InfoPopover, Loader, SidebarPage } from "@/components"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { H4 } from "@/components/ui/typography"
import { CreateProjectStep } from "@/types"
import { PageName } from "@/types/pages"
import { ArrowRightIcon, RotateCcwIcon } from "lucide-react"
import type React from "react"
import { CreateProjectProvider, useCreateProjectContext } from "./CreateProjectContext"
import { CreateStepBreadcrumb } from "./components/CreateStepBreadcrumb"
import DetailsStep from "./components/DetailsStep"
import DocumentsStep from "./components/DocumentsStep"
import ReviewStep from "./components/ReviewStep"
/**
import DetailsStep from "./components/DetailsStep";
 * @description Renders the create project page
 */
const CreateProjectPage: React.FC = () => {
	const {
		handleStepClick,
		createProject,
		projectError,
		spinnerText,
		resetCreateProjectState,
		unprocessedPriorArtDocuments,
		unprocessedContextDocuments,
		unprocessedOfficeActionDocuments,
		currentStep,
		steps,
		isStepComplete,
		canProceedToNext,
		handleNextStep,
		isProjectCreationInProgress,
	} = useCreateProjectContext()

	const getNextStepLabel = (): string => {
		switch (currentStep) {
			case CreateProjectStep.DETAILS:
			case CreateProjectStep.SUBJECT:
				return "Next Step"
			case CreateProjectStep.OFFICE_ACTION:
				return unprocessedOfficeActionDocuments.length > 0 ? "Next Step" : "Skip"
			case CreateProjectStep.PRIOR_ART:
				return unprocessedPriorArtDocuments.length > 0 ? "Next Step" : "Skip"
			case CreateProjectStep.CONTEXT:
				return unprocessedContextDocuments.length > 0 ? "Next Step" : "Skip"
			default:
				return "Next Step"
		}
	}

	return (
		<SidebarPage page={PageName.ProjectCreate}>
			<div className="w-full ">
				{isProjectCreationInProgress ? (
					<div className="flex justify-center items-center h-full">
						<Loader message={spinnerText} aria-label="Loading" />
					</div>
				) : (
					<div>
						{projectError ? (
							<ErrorMessage
								showButton={true}
								buttonLabel="Try Again"
								handleButtonClick={resetCreateProjectState}
							/>
						) : (
							<div className="flex flex-col w-full py-6">
								<div className="px-6 relative">
									{/* Fixed Start Over button */}
									<div className="absolute right-6 top-0">
										<Button size="sm" variant="outline" onClick={resetCreateProjectState}>
											<RotateCcwIcon className="w-4 h-4 mr-2" />
											Start Over
										</Button>
									</div>

									{/* Centered Breadcrumbs */}
									<div className="flex justify-center">
										<CreateStepBreadcrumb
											steps={steps}
											currentStep={currentStep}
											onStepClick={handleStepClick}
											isStepComplete={isStepComplete}
										/>
									</div>
								</div>

								<div className="py-6 flex flex-col items-center w-full">
									<div className="w-full max-w-[1000px] flex flex-col">
										{/* Step Header */}
										<div className="flex flex-col mb-4">
											<div className="flex items-center">
												<H4 className="mb-1 mt-6 mr-1">
													{steps.find((s) => s.key === currentStep)?.label}
												</H4>
												{steps.find((s) => s.key === currentStep)?.infoPopoverContent && (
													<InfoPopover
														content={steps.find((s) => s.key === currentStep)?.infoPopoverContent}
														className="ml-1"
													/>
												)}
											</div>
											{steps.find((s) => s.key === currentStep)?.description && (
												<p className="text-sm text-gray-500">
													{steps.find((s) => s.key === currentStep)?.description}
												</p>
											)}
										</div>

										<ScrollArea className="h-[calc(100vh-300px)]">
											{/* Render Step Component */}
											{currentStep === CreateProjectStep.DETAILS && <DetailsStep />}
											{(currentStep === CreateProjectStep.SUBJECT ||
												currentStep === CreateProjectStep.PRIOR_ART ||
												currentStep === CreateProjectStep.CONTEXT ||
												currentStep === CreateProjectStep.OFFICE_ACTION) && <DocumentsStep />}
											{currentStep === CreateProjectStep.REVIEW && <ReviewStep />}
											<div className="flex justify-end mt-4">
												{currentStep === CreateProjectStep.REVIEW ? (
													<Button onClick={createProject}>Create Project</Button>
												) : (
													<Button onClick={handleNextStep} disabled={!canProceedToNext}>
														{getNextStepLabel()}
														<ArrowRightIcon className="w-4 h-4 ml-2" />
													</Button>
												)}
											</div>
										</ScrollArea>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</SidebarPage>
	)
}

export default function CreateProjectPageWithProvider() {
	return (
		<CreateProjectProvider>
			<CreateProjectPage />
		</CreateProjectProvider>
	)
}
