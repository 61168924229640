import type React from "react"
import { useRef, useState } from "react"
import { Page } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu"
import type { Bbox, DocumentChunk } from "@/types"
import { formatDocumentChunkLocation } from "@/utils/projectUtils"
import { CopyToClipboardButton } from ".."

interface PdfPageWithBoundingBoxesProps {
	pageNumber: number
	scale: number
	documentChunks: DocumentChunk[]
	onChunkSelect: (id: string) => void
	selectedChunkId: string | null
	copyToClipboard: (text: string) => void
}

const PdfPageWithBoundingBoxes: React.FC<PdfPageWithBoundingBoxesProps> = ({
	pageNumber,
	scale,
	documentChunks,
	onChunkSelect,
	selectedChunkId,
	copyToClipboard,
}) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const [dimensions, setDimensions] = useState<{
		width: number
		height: number
		left: number
		top: number
	} | null>(null)

	const onRenderSuccess = () => {
		if (containerRef.current) {
			// Find the rendered canvas element inside the container
			const canvas = containerRef.current.querySelector("canvas")
			if (canvas) {
				const canvasRect = canvas.getBoundingClientRect()
				const containerRect = containerRef.current.getBoundingClientRect()

				const width = canvasRect.width
				const height = canvasRect.height
				const left = canvasRect.left - containerRect.left
				const top = canvasRect.top - containerRect.top

				setDimensions({ width, height, left, top })
			}
		}
	}

	return (
		<div style={{ position: "relative" }} ref={containerRef}>
			<Page
				pageNumber={pageNumber}
				renderTextLayer={false}
				renderAnnotationLayer={false}
				scale={scale}
				onRenderSuccess={onRenderSuccess}
			/>

			{/* Render bounding boxes for the current page */}
			{dimensions &&
				documentChunks?.map((chunk) => {
					const bboxes =
						typeof chunk.bboxes === "string"
							? JSON.parse(chunk.bboxes).map((bbox: string) => JSON.parse(bbox))
							: chunk.bboxes
					const bboxArray = Array.isArray(bboxes) ? bboxes : [bboxes]
					// Create an array of bounding box elements
					const boundingBoxElements = bboxArray.map((bbox: Bbox, index: number) => {
						const top = dimensions.top + bbox.top * dimensions.height
						const left = dimensions.left + bbox.left * dimensions.width
						const width = bbox.width * dimensions.width
						const height = bbox.height * dimensions.height

						const boxCenter = left + width / 2 - dimensions.left
						const isRightHalf = boxCenter > dimensions.width / 2

						return (
							<div
								key={`${chunk.id}-box-${index}`}
								data-chunk-id={chunk.id}
								onClick={() => onChunkSelect(chunk.id)}
								style={{
									position: "absolute",
									top: `${top - 4}px`,
									left: `${left - 4}px`,
									width: `${width + 8}px`,
									height: `${height + 8}px`,
									border: chunk.id === selectedChunkId ? "1px solid blue" : "",
									backgroundColor:
										chunk.id === selectedChunkId ? "rgba(59, 130, 246, 0.1)" : "transparent",
									boxSizing: "border-box",
								}}
								className="hover:cursor-pointer hover:border hover:border-blue-400"
							>
								{chunk.id === selectedChunkId && (
									<CopyToClipboardButton
										index={1}
										text={`${chunk.text}\n\n${formatDocumentChunkLocation(chunk.location)}`}
										className="absolute flex items-center justify-center w-8 h-8 -translate-y-1/2 opacity-80 hover:opacity-100"
										style={{
											top: "50%",
											[isRightHalf ? "right" : "left"]: "-36px",
										}}
									/>
								)}
							</div>
						)
					})

					// Return a single ContextMenu component containing all bounding boxes for this chunk
					return (
						<ContextMenu key={chunk.id}>
							<ContextMenuTrigger>{boundingBoxElements}</ContextMenuTrigger>
							<ContextMenuContent>
								<ContextMenuItem onClick={() => copyToClipboard(chunk.text)}>
									Copy
								</ContextMenuItem>
								<ContextMenuItem
									onClick={() =>
										copyToClipboard(
											`${chunk.text}\n\n${formatDocumentChunkLocation(chunk.location)}`,
										)
									}
								>
									Copy with Citation
								</ContextMenuItem>
							</ContextMenuContent>
						</ContextMenu>
					)
				})}
		</div>
	)
}

export default PdfPageWithBoundingBoxes
