import { create } from "zustand"

interface UserState {
	organizationId: string
	organizationName: string
	role: string
	userHash: string // Used for Intercom
}

interface UserActions {
	updateOrganizationId: (organizationId: string) => void
	updateOrganizationName: (organizationName: string) => void
	updateRole: (role: string) => void
	updateUserHash: (userHash: string) => void
}

const initialState: UserState = {
	organizationId: "",
	organizationName: "",
	role: "User",
	userHash: "",
}

const useUserStore = create<UserState & UserActions>((set) => ({
	...initialState,

	updateOrganizationId: (organizationId: string) => {
		localStorage.setItem("organizationId", organizationId)
		set({ organizationId })
	},

	updateOrganizationName: (organizationName: string) => {
		localStorage.setItem("organizationName", organizationName)
		set({ organizationName })
	},

	updateRole: (role: string) => {
		localStorage.setItem("role", role)
		set({ role })
	},

	updateUserHash: (userHash: string) => {
		localStorage.setItem("userHash", userHash)
		set({ userHash })
	},
}))

export default useUserStore
