import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import type { ChunkToLimitation } from "@/types"
import { formatDateTime, formatDocumentChunkLocation, pluralString } from "@/utils"
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@radix-ui/react-collapsible"
import type React from "react"
import { useState } from "react"
import InvalidityCitationActions from "./InvalidityCitationActions"
interface InvalidityTableCellCollapsibleCitationsProps {
	positiveFeedbackData: ChunkToLimitation[]
	negativeFeedbackData: ChunkToLimitation[]
	removedCitations: ChunkToLimitation[]
	verifiedCitations: ChunkToLimitation[]
}

// New subcomponents
const CollapsibleButton: React.FC<{
	count: number
	label: string
	isOpen: boolean
	onClick: () => void
}> = ({ count, label, isOpen, onClick }) => (
	<CollapsibleTrigger asChild>
		<Button
			variant="ghost"
			size="sm"
			className={cn(
				"justify-between",
				"border border-gray-200 hover:border-gray-300",
				"transition-all duration-10",
				isOpen && "bg-accent",
			)}
			onClick={onClick}
		>
			<h4 className="text-sm font-semibold">
				{label} ({count})
			</h4>
		</Button>
	</CollapsibleTrigger>
)

const CitationCard: React.FC<{
	citation: ChunkToLimitation
	showTimestamp?: boolean
	showVerify?: boolean
	showRemove?: boolean
	showFeedback?: boolean
}> = ({ citation, showTimestamp, showVerify, showRemove, showFeedback }) => (
	<div className="rounded-md border px-4 py-2 text-sm flex justify-between items-start w-full gap-2">
		<div className="flex-grow">
			<div className="flex justify-between items-center mb-1">
				<p className="font-bold">
					{formatDocumentChunkLocation(citation.documentChunk.location)}
				</p>
				{showTimestamp && (
					<p className="text-xs text-gray-500 ml-2 italic">
						{citation.updatedAt ? `Removed at ${formatDateTime(citation.updatedAt)}` : ""}
						{citation.updatedById ? ` by ${citation.updatedById}` : ""}
					</p>
				)}
			</div>
			<p className="mb-1">{citation.documentChunk.text}</p>
		</div>
		<div className="flex-none">
			<InvalidityCitationActions
				citation={citation}
				showVerify={showVerify}
				showRemove={showRemove}
				showFeedback={showFeedback}
			/>
		</div>
	</div>
)

const InvalidityTableCellCollapsibleCitations: React.FC<
	InvalidityTableCellCollapsibleCitationsProps
> = ({
	positiveFeedbackData,
	negativeFeedbackData,
	removedCitations,
	verifiedCitations,
}) => {
	const [showPositiveCitations, setShowPositiveCitations] = useState(false)
	const [showNegativeCitations, setShowNegativeCitations] = useState(false)
	const [showRemovedCitations, setShowRemovedCitations] = useState(false)
	const [showVerifiedCitations, setShowVerifiedCitations] = useState(false)

	return (
		<div className="flex pb-2">
			<Collapsible
				open={
					showNegativeCitations ||
					showRemovedCitations ||
					showPositiveCitations ||
					showVerifiedCitations
				}
				onOpenChange={(open) => {
					if (!open) {
						setShowPositiveCitations(false)
						setShowNegativeCitations(false)
						setShowRemovedCitations(false)
						setShowVerifiedCitations(false)
					}
				}}
				className="space-y-2"
			>
				<div className="flex w-[250px] space-x-2">
					{positiveFeedbackData.length > 0 && (
						<CollapsibleButton
							count={positiveFeedbackData.length}
							label={pluralString(positiveFeedbackData.length, "Positive citation")}
							isOpen={showPositiveCitations}
							onClick={() => setShowPositiveCitations(!showPositiveCitations)}
						/>
					)}
					{negativeFeedbackData.length > 0 && (
						<CollapsibleButton
							count={negativeFeedbackData.length}
							label={pluralString(negativeFeedbackData.length, "Negative citation")}
							isOpen={showNegativeCitations}
							onClick={() => setShowNegativeCitations(!showNegativeCitations)}
						/>
					)}
					{removedCitations.length > 0 && (
						<CollapsibleButton
							count={removedCitations.length}
							label="Removed citations"
							isOpen={showRemovedCitations}
							onClick={() => setShowRemovedCitations(!showRemovedCitations)}
						/>
					)}
					{verifiedCitations.length > 0 && (
						<CollapsibleButton
							count={verifiedCitations.length}
							label="Verified citations"
							isOpen={showVerifiedCitations}
							onClick={() => setShowVerifiedCitations(!showVerifiedCitations)}
						/>
					)}
				</div>
				<CollapsibleContent className="space-y-2 pb-2">
					{showPositiveCitations &&
						positiveFeedbackData.map((item, index) => (
							<CitationCard
								key={`positive-${index}`}
								citation={item}
								showTimestamp={true}
								showVerify={false}
								showRemove={false}
								showFeedback={false}
							/>
						))}
					{showNegativeCitations &&
						negativeFeedbackData.map((item, index) => (
							<CitationCard
								key={`negative-${index}`}
								citation={item}
								showTimestamp={true}
								showVerify={false}
								showRemove={false}
								showFeedback={false}
							/>
						))}
					{showRemovedCitations &&
						removedCitations.map((item, index) => (
							<CitationCard
								key={`removed-${index}`}
								citation={item}
								showTimestamp={true}
								showVerify={false}
								showRemove={true}
								showFeedback={false}
							/>
						))}
					{showVerifiedCitations &&
						verifiedCitations.map((item, index) => (
							<CitationCard
								key={`verified-${index}`}
								citation={item}
								showTimestamp={true}
								showVerify={false}
								showRemove={false}
								showFeedback={false}
							/>
						))}
				</CollapsibleContent>
			</Collapsible>
		</div>
	)
}

export default InvalidityTableCellCollapsibleCitations
