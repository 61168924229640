import { ScrollArea } from "@/components/ui/scroll-area"
import useProjectStore from "@/store/projectStore"
import type { OfficeAction } from "@/types"
import { memo } from "react"
import { ClaimRows } from "./ClaimRows"
import { ClaimRow } from "./components/ClaimRow"
import { OfficeActionSummaryRow } from "./components/OfficeActionSummaryRow"

interface OfficeActionDocumentProps {
	document: OfficeAction
}

const OfficeActionDocument: React.FC<OfficeActionDocumentProps> = memo(
	({ document }) => {
		const { draftingClaimID, updateDraftingClaimID } = useProjectStore()
		const claimDetails = Array.isArray(document?.claimDetails)
			? document.claimDetails
			: []

		const getClaimByID = (claimID: string) => {
			return claimDetails?.find((claim) => claim.claimNumber === claimID)
		}

		return (
			<ScrollArea className="h-[calc(100vh-120px)]">
				<div className="space-y-3">
					{draftingClaimID ? (
						// Render only the editing claim row
						<ClaimRow
							claim={getClaimByID(draftingClaimID)}
							isGlobalExpanded={true}
							onEdit={() => updateDraftingClaimID(null)} // Allow toggling back to full view
						/>
					) : (
						// Render the full document view
						<>
							<OfficeActionSummaryRow document={document} />
							<ClaimRows document={document} />
						</>
					)}
				</div>
			</ScrollArea>
		)
	},
)

OfficeActionDocument.displayName = "OfficeActionDocument"

export default OfficeActionDocument
