import type { BaseReferencesHook } from "@/hooks/references/references"
import useProjectAndPortfolioIds from "@/hooks/useProjectAndPortfolioIds"

import { useProjectDocuments } from "./useProjectDocuments"

export function useReferences(): BaseReferencesHook {
	const { projectId } = useProjectAndPortfolioIds()

	const projectRefs = useProjectDocuments(projectId) as unknown as BaseReferencesHook

	return projectRefs
}

export default useReferences
