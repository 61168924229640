import { chartsApi } from "@/api/chartsApi"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi, useInvaliditySettings, useProjectAndPortfolioIds } from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { useAppStateStore, useProjectStore } from "@/store"
import type { ChunkToLimitation } from "@/types"
import { invalidateInvalidityRelatedQueries } from "@/utils"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { useMutation, useQueryClient } from "@tanstack/react-query"
interface ChartDataPage {
	data: Array<{
		documentChunkId: string
		// other fields...
		color?: string
		verified?: boolean
		removed?: boolean
		score?: number
		// ...
	}>
	// Feel free to add pagination/other properties as needed
}

export const useInvalidityChart = () => {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { selectedReferences, selectedColors, selectedLimitations } = useProjectStore()
	const { invalidateSettings } = useInvaliditySettings()
	const { projectId } = useProjectAndPortfolioIds()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()
	const { getDocumentName } = useDocumentNaming()

	// Define the query key as a tuple type
	const chartQueryKey = [
		"invalidityChartData",
		projectId,
		selectedReferences,
		selectedLimitations,
		selectedColors,
	] as const // Make it readonly

	const invalidateChartData = () => {
		invalidateInvalidityRelatedQueries(
			queryClient,
			projectId,
			selectedReferences,
			selectedLimitations,
			selectedColors,
		)
	}

	/**
	 * @description Updates the header and footer boilerplate for a chunk to a limitation
	 */
	const updateChunkToLimitationBoilerplateMutation = useMutation({
		mutationFn: async ({
			patentId,
			documentId,
			header,
			footer,
			claimLimitationIds,
			updateAllElements,
		}: {
			patentId: string
			documentId: string
			header: string
			footer: string
			claimLimitationIds?: string[]
			updateAllElements?: boolean
		}) => {
			return chartsApi.updateChunkToLimitationBoilerplate(
				patentId,
				documentId,
				header,
				footer,
				claimLimitationIds,
				updateAllElements,
			)
		},
		onError: (error) => {
			handleError(error, "Error updating chunk to limitation boilerplate")
		},
		onSuccess: (_, variables) => {
			invalidateChartData()
			if (variables.updateAllElements) {
				invalidateSettings()
			}
		},
	})

	/**
	 * @description Updates the language of a reference citation
	 * @param {string} citationId - The id of the citation to update the language for
	 * @param {object} options - Additional options to pass to the request
	 */
	const updateChunkToLimitationMutation = useMutation({
		mutationFn: async ({
			documentId,
			claimId,
			documentChunkId,
			claimLimitationId,
			options,
		}: {
			documentId: string
			claimId: string
			documentChunkId: string
			claimLimitationId: string
			options: {
				verified?: boolean
				removed?: boolean
				score?: number
				color?: string
			}
		}) => {
			return chartsApi.updateChunkToLimitation(documentChunkId, claimLimitationId, options)
		},
		onMutate: async (variables) => {
			return handleOptimisticUpdate({
				queryClient,
				queryKeys: [chartQueryKey],
				updateFn: (
					oldData:
						| Record<string, Record<string, Record<string, ChunkToLimitation[]>>>
						| undefined,
				) => {
					if (!oldData) return oldData

					const { claimId, claimLimitationId, documentId, documentChunkId } = variables

					return {
						...oldData,
						[claimId]: {
							...oldData[claimId],
							[claimLimitationId]: {
								...oldData[claimId]?.[claimLimitationId],
								[documentId]:
									oldData[claimId]?.[claimLimitationId]?.[documentId]?.map((chunk) => {
										if (chunk.documentChunkId === documentChunkId) {
											return {
												...chunk,
												...variables.options,
											}
										}
										return chunk
									}) || [],
							},
						},
					}
				},
			})
		},
		onError: (error, variables, context) => {
			handleError(error, "Error updating document chunk to claim limitation")
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
		},
		onSuccess: () => {
			invalidateInvalidityRelatedQueries(
				queryClient,
				projectId,
				selectedReferences,
				selectedLimitations,
				selectedColors,
			)
		},
	})

	/**
	 * @description Adds a reference citation to the project
	 * @param {string} invalidityId - The id of the invalidity to add the citation to
	 * @param {string} referenceId - The id of the reference to add the citation to
	 * @param {string} color - The color of the citation to add
	 * @param {string} text - The text of the citation to add
	 * @param {ChunkLocation} location - The location details of the citation
	 * @param {string} projectId - The id of the project
	 * @param {boolean} isFeature - Whether this is a feature citation
	 * @param {string[]} figureUrls - The URLs of the figures to add
	 * @param {string[]} figureRefs - The references of the figures to add
	 * @param {string} claimNumber - The claim number for the citation
	 */
	const addChunkToLimitationMutation = useMutation({
		mutationFn: async ({
			documentChunkId,
			claimLimitationId,
			color,
		}: {
			documentChunkId: string
			claimLimitationId: string
			color: string
		}) => {
			const response = await chartsApi.createChunkToLimitation(
				documentChunkId,
				claimLimitationId,
				color,
				projectId,
			)
			return response
		},
		onError: (error) => {
			handleError(error, "Error adding reference citation")
		},
		onSuccess: () => invalidateChartData(),
	})

	/**
	 * @description Deletes a reference citation from a project
	 * @param {string} invalidityCitationId - The id of the invalidity citation to delete
	 */
	const toggleRemoveAllDocumentChunkCitations = useMutation({
		mutationFn: async (documentChunkId: string) => {
			return chartsApi.toggleRemoveAllDocumentChunkCitations(documentChunkId)
		},
		onError: (error) => {
			handleError(error, "Error deleting reference citation")
		},
		onSuccess: () => invalidateChartData(),
	})

	/**
	 * @description Mass toggle verified on all citations in a reference
	 */
	const toggleVerifyAllCitations = useMutation({
		mutationFn: async ({
			documentId,
			verified,
		}: {
			documentId: string
			verified: boolean
		}) => {
			return chartsApi.toggleVerifyAllDocumentCitations(documentId, verified)
		},
		onError: (error, variables) => {
			addErrorMessage(
				`Error updating all chart citations for ${getDocumentName(variables.documentId)}`,
			)
		},
		onSuccess: (_, variables) => {
			invalidateChartData()
		},
	})

	/**
	 * @description Find more citations for a limitation using AI
	 */
	const findMoreCitationsForLimitationMutation = useMutation({
		mutationFn: async ({
			claimLimitationId,
			documentIds,
			positiveExamples,
			negativeExamples,
			header,
			footer,
		}: {
			claimLimitationId: string
			documentIds: string[]
			positiveExamples: string[]
			negativeExamples: string[]
			header?: string
			footer?: string
		}) => {
			return chartsApi.findMoreCitationsForLimitation(
				claimLimitationId,
				documentIds,
				positiveExamples,
				negativeExamples,
				header,
				footer,
			)
		},
		onError: (error) => {
			handleError(error, "Error finding more citations for limitation")
		},
		onSuccess: () => {
			invalidateChartData()
		},
	})

	/**
	 * @description Copies invalidities from one element to another
	 */
	const copyInvalidityCitations = useMutation({
		mutationFn: async ({
			targetClaimNumber,
			sourceClaimNumber,
			referenceId,
			deleteExisting,
			applyToAllReferences,
			isFeature,
		}: {
			targetClaimNumber: string
			sourceClaimNumber: string
			referenceId: string
			deleteExisting: boolean
			applyToAllReferences: boolean
			isFeature: boolean
		}) => {
			return chartsApi.copyInvalidityCitations(
				projectId,
				targetClaimNumber,
				sourceClaimNumber,
				referenceId,
				deleteExisting,
				applyToAllReferences,
				isFeature,
			)
		},
		onError: (_, variables) => {
			addErrorMessage(
				`Error copying invalidity citations for element ${variables.targetClaimNumber}`,
			)
		},
		onSuccess: () => {
			invalidateChartData()
		},
	})

	/**
	 * @description Prune invalidities by color
	 */
	const pruneChunkToLimitationByColor = useMutation({
		mutationFn: async ({
			documentIds,
			colors,
		}: {
			documentIds: string[]
			colors: string[]
		}) => {
			return chartsApi.pruneChunkToLimitationByColor(documentIds, colors)
		},
		onError: (error) => {
			handleError(error, "Error pruning invalidities by color")
		},
		onSuccess: () => {
			invalidateChartData()
		},
	})

	/**
	 * @description Prune invalidities by a minimum matching "score"
	 */
	const pruneChunkToLimitationByScore = useMutation({
		mutationFn: async ({
			documentIds,
			score,
		}: {
			documentIds: string[]
			score: number
		}) => {
			return chartsApi.pruneChunkToLimitationByScore(documentIds, score)
		},
		onError: (error) => {
			handleError(error, "Error pruning invalidities by score")
		},
		onSuccess: () => {
			invalidateChartData()
		},
	})

	/**
	 * @description Copies invalidities from one element to another
	 */
	const copyChunkToLimitations = useMutation({
		mutationFn: async ({
			documentId,
			sourceLimitationId,
			targetLimitationId,
			replace,
		}: {
			documentId: string
			sourceLimitationId: string
			targetLimitationId: string
			replace: boolean
		}) => {
			return chartsApi.copyChunkToLimitations(
				documentId,
				sourceLimitationId,
				targetLimitationId,
				replace,
			)
		},
		onError: (error) => {
			handleError(error, "Error copying invalidities from one element to another")
		},
		onSuccess: () => {
			invalidateChartData()
		},
	})

	return {
		findMoreCitationsForLimitation: findMoreCitationsForLimitationMutation.mutateAsync,
		isFindingMoreCitations: findMoreCitationsForLimitationMutation.isPending,
		addCitation: addChunkToLimitationMutation.mutateAsync,
		isAddingCitation: addChunkToLimitationMutation.isPending,

		copyInvalidityCitations: copyInvalidityCitations.mutateAsync,
		isCopyingInvalidityCitations: copyInvalidityCitations.isPending,

		toggleVerifyAllCitations: toggleVerifyAllCitations.mutateAsync,
		isVerifyingAllCitations: toggleVerifyAllCitations.isPending,

		toggleRemoveAllDocumentChunkCitations:
			toggleRemoveAllDocumentChunkCitations.mutateAsync,
		isDeletingAllDocumentChunkCitations: toggleRemoveAllDocumentChunkCitations.isPending,

		pruneChunkToLimitationByColor: pruneChunkToLimitationByColor.mutateAsync,
		isPruningChunkToLimitationByColor: pruneChunkToLimitationByColor.isPending,

		pruneChunkToLimitationByScore: pruneChunkToLimitationByScore.mutateAsync,
		isPruningChunkToLimitationByScore: pruneChunkToLimitationByScore.isPending,

		// Use for individual citation updates to removed, verified, or color
		updateCitation: updateChunkToLimitationMutation.mutateAsync,
		isUpdatingCitation: updateChunkToLimitationMutation.isPending,

		copyChunkToLimitations: copyChunkToLimitations.mutateAsync,
		isCopyingChunkToLimitations: copyChunkToLimitations.isPending,

		updateChunkToLimitationBoilerplate:
			updateChunkToLimitationBoilerplateMutation.mutateAsync,
		isUpdatingChunkToLimitationBoilerplate:
			updateChunkToLimitationBoilerplateMutation.isPending,
	}
}

export default useInvalidityChart
