import { streamChatResponse } from "@/api/chatApi"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useAtBottom } from "@/hooks"
import type { Message, ParentDocumentMetadata } from "@/types"
import { ArrowDownIcon } from "@radix-ui/react-icons"
import type React from "react"
import { useCallback, useLayoutEffect, useRef, useState } from "react"
import ChatActions from "./ChatActions"
import ChatMessage from "./ChatMessage"

interface ChatComponentProps {
	projectId: string
	documents: ParentDocumentMetadata[]
	disabledBool: boolean
}

/**
 * @description Base chat component used in project and user document chat
 */
const ChatComponent: React.FC<ChatComponentProps> = ({
	projectId,
	documents,
	disabledBool,
}) => {
	// Local state
	const listRef = useRef<HTMLDivElement>(null)
	const isAtBottom = useAtBottom(listRef, 20) // 20 is the offset and can be adjusted
	const [messages, setMessages] = useState<Message[]>([])
	const [isAIResponding, setIsAIResponding] = useState(false)
	const [sessionId, setSessionId] = useState<string | null>(null)
	const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([])
	const abortControllerRef = useRef<AbortController | null>(null)

	/**
	 * We remove the setSessionId(null) here so that subsequent requests
	 * correctly retain the sessionId, preventing creation of new sessions.
	 */
	const updateSelectedDocumentIds = (selectedIds: string[]) => {
		setMessages([])
		setSelectedDocumentIds(selectedIds)
	}

	// Handle stop streaming
	const handleStopStreaming = () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort()
			setIsAIResponding(false)
			setMessages((prevMessages) => {
				const lastMessage = prevMessages[prevMessages.length - 1]
				if (lastMessage?.sender === "&AI" && lastMessage.loading) {
					return [
						...prevMessages.slice(0, -1),
						{
							...lastMessage,
							loading: false,
							text: `${lastMessage.text} [Stopped]`,
						},
					]
				}
				return prevMessages
			})
		}
	}

	const messagesEndRef = useRef<HTMLDivElement>(null)

	const scrollToBottom = useCallback(() => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
	}, [])

	useLayoutEffect(() => {
		// Use layout effect to scroll immediately when messages change
		// This prevents visual jank by scrolling before browser paint
		if (messages.length > 0) {
			const lastMessage = messages[messages.length - 1]
			// Only auto-scroll for new messages or when AI is still typing
			if (lastMessage.loading || messages.length === 1) {
				messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
			}
		}
	}, [messages])

	// Handle send message
	const handleSendMessage = async (newMessage: string) => {
		abortControllerRef.current = new AbortController()
		const signal = abortControllerRef.current.signal

		if (newMessage.trim() !== "") {
			// Only add the user message
			setMessages((prevMessages) => [
				...prevMessages,
				{
					text: newMessage,
					sender: "user",
					loading: false,
				},
			])

			setIsAIResponding(true)
			await fetchResponse(newMessage, signal)
		}
	}

	const fetchResponse = async (message: string, signal: AbortSignal) => {
		try {
			const payload = {
				query: message,
				project_id: projectId,
				document_ids: selectedDocumentIds,
				...(sessionId ? { session_id: sessionId } : {}),
			}

			// Add initial AI message
			setMessages((prevMessages) => [
				...prevMessages,
				{
					sender: "&AI",
					text: "",
					loading: true,
				},
			])

			await streamChatResponse(
				payload,
				signal,
				(newSessionId) => setSessionId(newSessionId),
				(text) => {
					setMessages((prevMessages) => {
						const newMessages = [...prevMessages]
						const lastMessage = newMessages[newMessages.length - 1]
						if (lastMessage.sender === "&AI") {
							lastMessage.text = text
						}
						return newMessages
					})
				},
				() => {
					setMessages((prevMessages) => {
						const newMessages = [...prevMessages]
						const lastMessage = newMessages[newMessages.length - 1]
						if (lastMessage.sender === "&AI") {
							lastMessage.loading = false
						}
						return newMessages
					})
				},
			)
		} catch (error: any) {
			if (error.name !== "AbortError") {
				console.error("Error fetching response:", error)
			}
		} finally {
			setIsAIResponding(false)
		}
	}

	const [footerHeight, setFooterHeight] = useState(175)

	// Dynamically update the footer's height
	const updateFooterHeight = useCallback((height: number) => {
		setFooterHeight(height)
	}, [])

	const getScrollAreaHeight = () => {
		const headerHeight = 64 // Adjust this value based on your actual header height
		return `calc(100vh - ${headerHeight}px - ${footerHeight}px)`
	}

	return (
		<div className="flex flex-col h-[calc(100vh-64px)]">
			<div
				className="flex justify-center w-full"
				style={{ height: getScrollAreaHeight() }}
			>
				<ScrollArea className="flex-1 px-3 w-full" ref={listRef} scrollHideDelay={0}>
					<div className="max-w-[1000px] mx-auto px-3 mb-2 break-words">
						{messages.map((message, index) => (
							<ChatMessage
								key={`${message.sender}-${message.text.slice(0, 20)}-${index}`}
								message={message}
								index={index}
								isAIResponding={isAIResponding}
								isMostRecent={index === messages.length - 1}
							/>
						))}
						<div ref={messagesEndRef} />
					</div>
				</ScrollArea>
			</div>

			{!isAtBottom && (
				<div className="absolute bottom-[160px] right-[60px] z-[1]">
					<Button size="icon" variant="ghost" onClick={scrollToBottom}>
						<ArrowDownIcon />
					</Button>
				</div>
			)}
			<ChatActions
				handleSendMessage={handleSendMessage}
				disabledBool={disabledBool}
				documents={documents}
				setSelectedDocumentIds={updateSelectedDocumentIds}
				id={projectId}
				sessionId={sessionId || ""}
				isAIResponding={isAIResponding}
				handleStopStreaming={handleStopStreaming}
				updateFooterHeight={updateFooterHeight}
			/>
		</div>
	)
}

export default ChatComponent
