/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import {
	AdminPageLayout,
	NoAdminAccessMessage,
	UsersTable,
} from "@/features/admin/components"
import { useUserStore } from "@/store"
import { RoleType } from "@/types"
import { PageName } from "@/types/pages"
import { useMemo } from "react"
import { useAdminUsers } from "../hooks/useAdminUsers"

/**
 * @description Admin page component
 */
function AdminUsersPage() {
	const { organizationId, organizationName, role } = useUserStore()
	const { adminUsers, isLoading } = useAdminUsers(organizationId)

	const isAdmin = useMemo(
		() => role === RoleType.ADMIN || role === RoleType.OWNER,
		[role],
	)

	return (
		<AdminPageLayout isSuperAdmin={false} page={PageName.AdminUsers}>
			{isAdmin ? (
				<div className="mt-2">
					{isLoading ? (
						<Loader />
					) : !adminUsers ? (
						<p className="text-destructive">Error fetching users.</p>
					) : (
						<UsersTable
							orgName={organizationName}
							orgId={organizationId}
							users={adminUsers}
						/>
					)}
				</div>
			) : (
				<NoAdminAccessMessage />
			)}
		</AdminPageLayout>
	)
}

export default AdminUsersPage
