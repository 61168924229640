import { toCamelCase } from "@/utils/dataUtils"
import axios, {
	type AxiosError,
	type AxiosInstance,
	type AxiosRequestConfig,
} from "axios"
import qs from "qs"

// Custom error class for API errors
export class ApiError extends Error {
	constructor(
		message: string,
		public status?: number,
		public code?: string,
		public details?: unknown,
	) {
		super(message)
		this.name = "ApiError"
	}
}

// Types for API responses
export interface ApiSuccessResponse<T> {
	data: T
	message?: string
	status: number
}

export type ApiConfig = AxiosRequestConfig & {
	skipAuthHeader?: boolean
	timeout?: number
}

class ApiClient {
	private readonly baseURL: string
	private readonly instance: AxiosInstance
	private authToken?: string
	private unauthorizedHandler?: () => Promise<void>

	constructor() {
		this.baseURL = process.env.REACT_APP_API_BASE_URL ?? ""
		this.instance = axios.create({
			baseURL: this.baseURL,
			withCredentials: true,
			timeout: 300000,
			headers: {
				"Content-Type": "application/json",
			},
		})

		this.setupInterceptors()
	}

	private setupInterceptors(): void {
		// Request interceptor
		this.instance.interceptors.request.use(
			(config) => {
				// Don't add auth header if specifically requested to skip
				if (!config.headers?.skipAuthHeader && this.authToken) {
					config.headers.Authorization = `Bearer ${this.authToken}`
				}
				return config
			},
			(error) => Promise.reject(error),
		)

		// Response interceptor
		this.instance.interceptors.response.use(
			(response) => response,
			(error: AxiosError) => {
				return Promise.reject(this.handleRequestError(error))
			},
		)
	}

	private handleRequestError(error: AxiosError): ApiError {
		if (process.env.NODE_ENV === "development") {
			console.error("API Error:", error)
		}

		// Handle network errors
		if (!error.response) {
			return new ApiError(
				"Unable to reach the server. Please check your internet connection.",
				0,
				"NETWORK_ERROR",
			)
		}

		const status = error.response.status
		const data = error.response.data as any

		// Handle different types of errors
		switch (status) {
			case 401:
				// Call the unauthorized handler if it exists
				if (this.unauthorizedHandler) {
					this.unauthorizedHandler().catch(console.error)
				}
				return new ApiError(
					"Your session has expired. Please log in again.",
					status,
					"UNAUTHORIZED",
				)
			case 403:
				return new ApiError(
					"You do not have permission to perform this action.",
					status,
					"FORBIDDEN",
				)
			case 404:
				return new ApiError("The requested resource was not found.", status, "NOT_FOUND")
			case 422:
				return new ApiError(
					"Invalid data provided.",
					status,
					"VALIDATION_ERROR",
					data.errors,
				)
			case 429:
				return new ApiError(
					"Too many requests. Please try again later.",
					status,
					"RATE_LIMIT",
				)
			case 500:
				return new ApiError(
					"An unexpected error occurred. Please try again later.",
					status,
					"SERVER_ERROR",
				)
			default:
				return new ApiError(
					data?.message || "An unexpected error occurred.",
					status,
					"UNKNOWN_ERROR",
					data,
				)
		}
	}

	setAuthToken(token: string): void {
		this.authToken = token
	}

	clearAuthToken(): void {
		this.authToken = undefined
	}

	private async ensureAuthToken(): Promise<string> {
		if (!this.authToken) {
			throw new ApiError("No authentication token available", 401, "MISSING_AUTH_TOKEN")
		}
		return this.authToken
	}

	async get<T>(url: string, params: object = {}, config: ApiConfig = {}): Promise<T> {
		await this.ensureAuthToken()
		const finalConfig: ApiConfig = {
			...config,
			params,
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" })
			},
		}
		const response = await this.instance.get<ApiSuccessResponse<T>>(url, finalConfig)
		return toCamelCase(response.data) as T
	}

	async post<T>(
		url: string,
		body: object = {},
		params: object = {},
		config: ApiConfig = {},
	): Promise<T> {
		await this.ensureAuthToken()
		const response = await this.instance.post<ApiSuccessResponse<T>>(url, body, {
			...config,
			params,
		})
		return toCamelCase(response.data) as T
	}

	async postFile<T>(
		url: string,
		formData: FormData,
		params: object = {},
		config: RequestInit = {},
		timeout = 300000,
	): Promise<T> {
		await this.ensureAuthToken()
		const fullUrl = this.joinUrl(this.baseURL, url)
		const queryString = this.buildQueryString(params)
		const finalUrl = queryString ? `${fullUrl}?${queryString}` : fullUrl

		const response = await fetch(finalUrl, {
			method: "POST",
			body: formData,
			credentials: "include",
			headers: {
				Authorization: `Bearer ${this.authToken}`,
				...config.headers,
			},
			signal: AbortSignal.timeout(timeout),
			...config,
		})

		if (!response.ok) {
			throw new ApiError("File upload failed", response.status, "UPLOAD_ERROR")
		}

		const data = await response.json()
		return toCamelCase(data) as T
	}

	private joinUrl(baseUrl: string, urlPath: string): string {
		return `${baseUrl.replace(/\/+$/, "")}/${urlPath.replace(/^\/+/, "")}`
	}

	private buildQueryString(params: object): string {
		return new URLSearchParams(
			Object.entries(params).filter(([_, value]) => value != null) as [string, string][],
		).toString()
	}

	setUnauthorizedHandler(handler: () => Promise<void>): void {
		this.unauthorizedHandler = handler
	}

	async getBinary(
		url: string,
		params: object = {},
		config: ApiConfig = {},
	): Promise<{ data: Blob; status: number }> {
		await this.ensureAuthToken()
		const finalConfig: ApiConfig = {
			...config,
			headers: {
				...config.headers,
				Accept: "application/pdf",
			},
			params,
			responseType: "blob",
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" })
			},
		}

		const response = await this.instance.get(url, finalConfig)
		return { data: response.data, status: response.status }
	}

	/**
	 * Streams a POST request and reads chunked responses,
	 * invoking onChunk for each piece of data read from the server.
	 */
	async stream<T>(
		url: string,
		body: object = {},
		params: object = {},
		config: RequestInit = {},
		onChunk?: (chunk: T) => void,
	): Promise<void> {
		await this.ensureAuthToken()
		const fullUrl = this.joinUrl(this.baseURL, url)
		const queryString = this.buildQueryString(params)
		const finalUrl = queryString ? `${fullUrl}?${queryString}` : fullUrl

		const response = await fetch(finalUrl, {
			method: "POST",
			body: JSON.stringify(body),
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${this.authToken}`,
				...config.headers,
			},
			...config,
		})

		if (!response.ok) {
			throw new ApiError("Stream request failed", response.status, "STREAM_ERROR")
		}

		// -- Actual streaming logic:
		const reader = response.body?.getReader()
		if (!reader) {
			throw new ApiError("No readable stream in response", response.status, "NO_STREAM")
		}

		const decoder = new TextDecoder()
		let chunkCount = 0
		const startTime = Date.now()

		try {
			while (true) {
				const { done, value } = await reader.read()
				if (done) {
					break
				}
				chunkCount++
				const chunkString = decoder.decode(value, { stream: true })
				onChunk?.(chunkString as T)
			}
		} finally {
			reader.releaseLock()
		}
	}
}

export const apiClient = new ApiClient()
