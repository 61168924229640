import { api } from "@/api"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useQuery } from "@tanstack/react-query"

import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"

const useAdminManagement = (orgId?: string) => {
	// Queries

	const adminOverview = useQuery({
		queryKey: QUERY_KEYS.admin.overview(orgId),
		queryFn: () => api.getAdminOverviewForOrg(orgId),
		enabled: !!orgId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const adminProjects = useQuery({
		queryKey: QUERY_KEYS.admin.projects(orgId),
		queryFn: () => api.getAdminProjectsForOrg(orgId),
		enabled: !!orgId,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		// Query data

		adminOverview: adminOverview.data,
		adminProjects: adminProjects.data,

		// Loading states
		isLoading: {
			adminOverview: adminOverview.isLoading,
			adminProjects: adminProjects.isLoading,
		},

		isFetching: {
			adminOverview: adminOverview.isFetching,
			adminProjects: adminProjects.isFetching,
		},
	}
}

export { useAdminManagement }
