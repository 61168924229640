import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useState } from "react"

interface GenerateContextDropdownProps {
	isEditing: boolean
	isGeneratingContext: boolean
	isGenerateContextDisabled: boolean
	onGenerateContext: (type: "subject-patent" | "document" | "input") => Promise<void>
}

export const GenerateContextDropdown: React.FC<GenerateContextDropdownProps> = ({
	isEditing,
	isGeneratingContext,
	isGenerateContextDisabled,
	onGenerateContext,
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const handleContextGeneration = async (
		type: "subject-patent" | "document" | "input",
	) => {
		try {
			await onGenerateContext(type)
			setDropdownOpen(false)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className="gap-2 flex flex-row">
			<DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
				<DropdownMenuTrigger asChild>
					<Button
						variant={isEditing || isGeneratingContext ? "outline" : "default"}
						disabled={isGeneratingContext || isEditing || isGenerateContextDisabled}
						className="h-9"
						onClick={() => setDropdownOpen(true)}
					>
						Generate Context
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					<DropdownMenuItem onClick={() => handleContextGeneration("subject-patent")}>
						From subject
					</DropdownMenuItem>
					<DropdownMenuItem onClick={() => handleContextGeneration("document")}>
						From document
					</DropdownMenuItem>
					<DropdownMenuItem onClick={() => handleContextGeneration("input")}>
						Input context
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	)
}
