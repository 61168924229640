/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, ProjectPage } from "@/components"
import DocumentDetailLayout from "@/components/documents/DocumentDetailLayout"
import { useDocument } from "@/hooks"
import { PageName } from "@/types/pages"
import type React from "react"
import { useParams } from "react-router-dom"

/**
 * @description User document page, displays selected document details
 */
const ProjectDocumentPage: React.FC = () => {
	const { documentId } = useParams()
	const { document, isLoading, isError } = useDocument(documentId)
	const pageName = document?.document.title || "Untitled"

	return (
		<ProjectPage pageName={PageName.ProjectReferences}>
			<div className="w-full h-full overflow-hidden flex flex-col">
				{isLoading ? (
					<Loader />
				) : isError ? (
					<div>Error loading document</div>
				) : (
					<DocumentDetailLayout document={document} />
				)}
			</div>
		</ProjectPage>
	)
}

export default ProjectDocumentPage
