// services/frontend/src/hooks/useDocumentHandler.ts
import { usePatentDetails } from "@/hooks"
import {
	DocumentRole,
	type PatentNumberOptions,
	type UnprocessedDocument,
	UnprocessedDocumentType,
	type UnpublishedPatentEntry,
} from "@/types"
import { nanoid } from "nanoid"
import { useCallback, useMemo, useState } from "react"

const useUnprocessedDocuments = () => {
	const [unprocessedDocuments, setUnprocessedDocuments] = useState<
		UnprocessedDocument[]
	>([])

	// Patent details handling
	const [isLoadingPatentDetails, setIsLoadingPatentDetails] = useState(false)
	const { getPatentDetails } = usePatentDetails()

	const addUnprocessedDocumentToList = (document: UnprocessedDocument) => {
		setUnprocessedDocuments((prev) => {
			const newDocs = [...prev, document]
			return newDocs
		})
	}

	const removeUnprocessedDocument = (documentId: string) => {
		setUnprocessedDocuments((prev) => prev.filter((doc) => doc.id !== documentId))
	}

	// Function to handle adding documents based on type
	const addUnprocessedDocuments = useCallback(
		async (
			documentRole: DocumentRole,
			documentType: UnprocessedDocumentType,
			options: {
				files?: File[]
				patentDetails?: PatentNumberOptions[]
				unpublishedPatent?: UnpublishedPatentEntry
				patentNumbers?: string[]
			} = {},
		) => {
			const { files, patentDetails, unpublishedPatent, patentNumbers } = options
			try {
				const createDocument = (
					type: UnprocessedDocumentType,
					content: any,
				): UnprocessedDocument => ({
					id: nanoid(),
					type,
					role: documentRole,
					...(type === UnprocessedDocumentType.PUBLISHED_PATENT
						? { patentDetails: content }
						: type === UnprocessedDocumentType.UNPUBLISHED_PATENT
							? { unpublishedPatent: content }
							: { file: content }),
				})

				switch (documentType) {
					case UnprocessedDocumentType.PUBLISHED_PATENT:
						if (patentNumbers) {
							setIsLoadingPatentDetails(true)
							const response = await getPatentDetails(patentNumbers, false)

							if (response.error) {
								console.error("Error fetching patent details:", response.error)
							} else {
								response.data.forEach((detail) =>
									addUnprocessedDocumentToList(
										createDocument(documentType!, {
											...detail,
											inventors: Array.isArray(detail.inventors)
												? detail.inventors
												: [detail.inventors],
										}),
									),
								)
							}
							setIsLoadingPatentDetails(false)
						} else {
							patentDetails?.forEach((detail) =>
								addUnprocessedDocumentToList(createDocument(documentType!, detail)),
							)
						}
						break
					case UnprocessedDocumentType.UNPUBLISHED_PATENT:
						addUnprocessedDocumentToList(createDocument(documentType!, unpublishedPatent))
						break
					case UnprocessedDocumentType.REFERENCE:
						files?.forEach((file) =>
							addUnprocessedDocumentToList(createDocument(documentType!, file)),
						)
						break
					case UnprocessedDocumentType.OFFICE_ACTION:
						files?.forEach((file) =>
							addUnprocessedDocumentToList(createDocument(documentType!, file)),
						)
						break
					default:
						files?.forEach((file) =>
							addUnprocessedDocumentToList(createDocument(documentType!, file)),
						)
				}
			} catch (error) {
				console.error("Error adding document:", error)
			}
		},
		[addUnprocessedDocumentToList],
	)

	// Reset all state
	const resetAll = () => {
		setUnprocessedDocuments([])
		setIsLoadingPatentDetails(false)
	}

	const unprocessedPriorArtDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.PRIOR_ART)
	}, [unprocessedDocuments])

	const unprocessedSubjectDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.SUBJECT)
	}, [unprocessedDocuments, unprocessedDocuments.length])

	const unprocessedContextDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.CONTEXT)
	}, [unprocessedDocuments])

	const unprocessedOfficeActionDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.OFFICE_ACTION)
	}, [unprocessedDocuments])

	// Update selectedKindCode on UnprocessedDocument
	const updateSelectedKindCode = (documentId: string, kindCode: string) => {
		setUnprocessedDocuments((prevDocs) => {
			const newDocs = prevDocs.map((doc) => {
				const updated = doc.id === documentId ? { ...doc, selectedKindCode: kindCode } : doc
				return updated
			})
			return newDocs
		})
	}
	return {
		unprocessedDocuments,
		addUnprocessedDocuments,
		removeUnprocessedDocument,
		isLoadingPatentDetails,
		resetAll,
		unprocessedPriorArtDocuments,
		unprocessedSubjectDocuments,
		unprocessedContextDocuments,
		unprocessedOfficeActionDocuments,
		// Update details on unprocessed document (kind codes, dates)
		updateSelectedKindCode,
	}
}

export default useUnprocessedDocuments
