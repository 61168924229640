/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import {
	AdminPageLayout,
	InvoicesTable,
	NoAdminAccessMessage,
} from "@/features/admin/components"
import { useUserStore } from "@/store"
import { RoleType } from "@/types"
import { PageName } from "@/types/pages"
import type { FC } from "react"
import { useSuperAdminBilling } from "../hooks/useSuperAdminBilling"

/**
 * @description Super admin billing page component for managing organization invoices
 */
const SuperAdminBillingPage: FC = () => {
	const { invoices, isLoading } = useSuperAdminBilling()
	const { role } = useUserStore()

	if (role !== RoleType.OWNER) {
		return (
			<AdminPageLayout isSuperAdmin page={PageName.SuperAdminBilling}>
				<NoAdminAccessMessage />
			</AdminPageLayout>
		)
	}

	return (
		<AdminPageLayout isSuperAdmin page={PageName.SuperAdminBilling}>
			<div className="space-y-8 mt-2">
				{isLoading ? <Loader /> : <InvoicesTable invoices={invoices ?? []} />}
			</div>
		</AdminPageLayout>
	)
}

export default SuperAdminBillingPage
