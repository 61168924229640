import CustomInput from "@/components/input/CustomInput"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { LoadingButton } from "@/components/ui/loading-button"
import type React from "react"
import { useCallback, useState } from "react"

import { useDocumentNaming, useInvaliditySettings, useProject } from "@/hooks"
import { convertToUtcDateString } from "@/utils/dateUtils"
import { useInvalidityChart } from "../../hooks"

interface Variable {
	id: string
	name: string
	current: string
}

interface EditHeaderFooterModalProps {
	isOpen: boolean
	onClose: () => void
	defaultUseFormatForAllElements?: boolean
	currentElementNumber?: string
	currentElementLanguage?: string
	claimLimitationId?: string
	documentId?: string
	cellHeader?: string
	cellFooter?: string
}

const EditHeaderFooterModal: React.FC<EditHeaderFooterModalProps> = ({
	isOpen,
	onClose,
	defaultUseFormatForAllElements = false,
	currentElementNumber = "1",
	currentElementLanguage = "Example element language",
	claimLimitationId,
	documentId,
	cellHeader,
	cellFooter,
}) => {
	const [isSaving, setIsSaving] = useState(false)
	const { projectSubject, projectId } = useProject()
	const { updateChunkToLimitationBoilerplate } = useInvalidityChart()
	const { settings } = useInvaliditySettings()
	const { getDocumentName } = useDocumentNaming()

	const [header, setHeader] = useState<string>(
		() => cellHeader ?? settings.headerFormat ?? "",
	)
	const [footer, setFooter] = useState<string>(
		() => cellFooter ?? settings.footerFormat ?? "",
	)
	const [useFormatForAllElements, setUseFormatForAllElements] = useState<boolean>(
		() => defaultUseFormatForAllElements,
	)

	const variables: Variable[] = [
		{
			id: "reference_name",
			name: "Reference Name",
			current: getDocumentName(documentId) || "",
		},
		{
			id: "element_number",
			name: "Element Number",
			current: currentElementNumber,
		},
		{
			id: "element_language",
			name: "Element Language",
			current: currentElementLanguage,
		},
		{
			id: "subject_nickname",
			name: "Subject Nickname",
			current: getDocumentName(projectSubject?.documentId) || "",
		},
		{
			id: "subject_priority_date",
			name: "Subject Priority Date",
			current:
				convertToUtcDateString(projectSubject?.document?.patent?.priorityDate) || "",
		},
		{
			id: "subject_number",
			name: "Subject Number",
			current: projectSubject?.document?.patent?.number || "",
		},
	]

	const handleSave = useCallback(async () => {
		setIsSaving(true)

		try {
			// Pass the raw header/footer with variables and let backend handle substitution
			await updateChunkToLimitationBoilerplate({
				patentId: projectSubject?.documentId,
				documentId: documentId,
				header: header,
				footer: footer,
				claimLimitationIds: [claimLimitationId],
				updateAllElements: false,
			})
			onClose()
		} catch (error) {
			console.error("Error updating invalidity boilerplate:", error)
		} finally {
			setIsSaving(false)
		}
	}, [header, footer, projectId, variables, useFormatForAllElements, onClose])

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[800px]">
				<DialogHeader>
					<DialogTitle>Edit Header and Footer</DialogTitle>
					<DialogDescription>
						The header and footer will be applied to all invalidities for this claim
						limitation.
					</DialogDescription>
				</DialogHeader>
				<div className="space-y-4 pb-4">
					<div>
						<div className="flex items-center gap-4">
							<Label htmlFor="header" className="mb-0">
								Header
							</Label>
						</div>
						<CustomInput
							id="header"
							label=""
							value={header}
							onChange={setHeader}
							variables={variables}
						/>
					</div>
					<div className="space-y-2">
						<div className="flex items-center gap-4">
							<Label htmlFor="footer" className="mb-0">
								Footer
							</Label>
						</div>
						<CustomInput
							id="footer"
							label=""
							value={footer}
							onChange={setFooter}
							variables={variables}
						/>
					</div>
				</div>
				<DialogFooter>
					{/* TODO: Removing checkbox for useFormatForAllElements */}
					{/* <CheckboxWithText
						label="Use format for all invalidities"
						checked={useFormatForAllElements}
						onCheckedChange={(checked) => setUseFormatForAllElements(checked as boolean)}
					/> */}
					<Button variant="outline" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} type="button" onClick={handleSave}>
						Save changes
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
export default EditHeaderFooterModal
