import { Label } from "@/components/ui/label"
import { ArrowUpDown, ChevronDown, ChevronUp } from "lucide-react"
import { memo } from "react"

interface ClaimsSectionHeaderProps {
	sortByRejected: boolean
	onSortChange: () => void
	isExpanded: boolean
	onExpandToggle: () => void
}

export const ClaimsSectionHeader = memo(
	({
		sortByRejected,
		onSortChange,
		isExpanded,
		onExpandToggle,
	}: ClaimsSectionHeaderProps) => {
		return (
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-2">
					<Label className="mb-0 pl-2">Claims</Label>
				</div>
				<div className="flex items-center gap-2">
					<button
						onClick={onExpandToggle}
						className="flex items-center gap-1.5 px-2 py-1 text-sm hover:bg-gray-50 rounded-md"
					>
						{isExpanded ? (
							<>
								<ChevronUp className="h-3.5 w-3.5" />
								Collapse all
							</>
						) : (
							<>
								<ChevronDown className="h-3.5 w-3.5" />
								Expand all
							</>
						)}
					</button>
					<button
						onClick={onSortChange}
						className="flex items-center gap-1.5 px-2 py-1 text-sm hover:bg-gray-50 rounded-md"
					>
						<ArrowUpDown className="h-3.5 w-3.5" />
						{sortByRejected ? "Sort by number" : "Sort by status"}
					</button>
				</div>
			</div>
		)
	},
)

ClaimsSectionHeader.displayName = "ClaimsSectionHeader"
