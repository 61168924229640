/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
	NavigationMenu,
	NavigationMenuItem,
	NavigationMenuList,
} from "@/components/ui/navigation-menu"
import type React from "react"
import { memo } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
/**
 * @description Component for navigation tabs in user documents
 */
const UserDocumentsNavigation: React.FC = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { documentId } = useParams()

	const getTabValue = (pathname: string): string => {
		if (pathname.includes("/search")) {
			return "search"
		}
		if (pathname.includes("/chat")) {
			return "chat"
		}
		return "details"
	}

	const currentTabValue = getTabValue(location.pathname)

	// Handle nav item selection
	const handleTabChange = (_event: React.SyntheticEvent, newValue: string): void => {
		const path = ""
		switch (newValue) {
			case "details":
				navigate(`${path}/documents/${documentId}`)
				break
			case "search":
				navigate(`${path}/documents/${documentId}/search`)
				break
			case "chat":
				navigate(`${path}/documents/${documentId}/chat`)
				break
			default:
				break
		}
	}

	// Render tab label
	const renderNavItem = (text: string, value: string) => (
		<NavigationMenuItem
			className={`px-3 py-1 text-sm hover:cursor-pointer font-medium ${
				currentTabValue === value
					? "bg-accent text-accent-foreground rounded-full"
					: "text-muted-foreground"
			}`}
			onClick={(event) => {
				handleTabChange(event, value)
			}}
		>
			{text}
		</NavigationMenuItem>
	)

	return (
		<NavigationMenu>
			<NavigationMenuList>
				{renderNavItem("Details", "details")}
				{renderNavItem("Search", "search")}
				{renderNavItem("Chat", "chat")}
			</NavigationMenuList>
		</NavigationMenu>
	)
}

export default memo(UserDocumentsNavigation)
