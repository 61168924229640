/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DocumentViewer } from "@/components"
import { Button } from "@/components/ui/button"
import {
	DataTableHead,
	DataTableRowOutline,
	Table,
	TableBody,
	TableHeader,
} from "@/components/ui/table"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useInvalidityChart } from "@/features/charts/hooks"
import { useClaims, useDocumentNaming, useProject, useReferences } from "@/hooks"
import { cn } from "@/lib/utils"
import type { InvalidityChartData, InvalidityClaimRowData } from "@/types"
import { isLikelyPatentNumber } from "@/utils/dataUtils"
import { formatPatentNumber } from "@/utils/projectUtils"
import {
	ExternalLinkIcon,
	EyeOpenIcon,
	HandIcon,
	InfoCircledIcon,
} from "@radix-ui/react-icons"
import { AlignVerticalSpaceAround, BadgeCheckIcon } from "lucide-react"
import type React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import InvalidityTableClaimRows from "./InvalidityTableClaimRows"
import HeaderFooterEditor from "./modals/EditHeaderFooterModal"
import InvalidityTableHelpModal from "./modals/InvalidityTableHelpModal"

interface InvalidityTableProps {
	onFillStart: () => void
	onFillEnd: () => void
	rowVisibility: string
	chartData: InvalidityChartData
	isLoading: boolean
}

/**
 * @description Data table
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 */
const InvalidityTable: React.FC<InvalidityTableProps> = ({
	onFillStart,
	onFillEnd,
	rowVisibility,
	chartData,
	isLoading,
}) => {
	const { projectSubject } = useProject()
	const { projectClaims } = useClaims()
	const { references } = useReferences()
	const { toggleVerifyAllCitations } = useInvalidityChart()
	const { getDocumentName } = useDocumentNaming()

	const [showDocumentViewer, setShowDocumentViewer] = useState<boolean>(false)
	const [documentViewerDocument, setDocumentViewerDocument] = useState<any>(null)
	const [isBoilerplateOpen, setIsBoilerplateOpen] = useState<boolean>(false)
	const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false)
	const [columnVerificationStatus, setColumnVerificationStatus] = useState<
		Record<string, boolean>
	>({})

	const documentIdList = useMemo(() => {
		if (!chartData || typeof chartData !== "object") {
			return []
		}

		const documentIds = new Set<string>()
		for (const limitationsMap of Object.values(chartData)) {
			for (const documentsMap of Object.values(limitationsMap)) {
				for (const docId of Object.keys(documentsMap)) {
					documentIds.add(docId)
				}
			}
		}
		return Array.from(documentIds)
	}, [chartData])

	useEffect(() => {
		const newVerificationStatus = documentIdList.reduce(
			(acc, refId) => {
				// Check if chartData and its properties exist
				acc[refId] = Object.values(chartData || {}).every((claimData) => {
					const limitationsMap = claimData as InvalidityClaimRowData
					return Object.values(limitationsMap).every((limitationData) => {
						const docData = limitationData[refId]
						if (!docData || !docData) return true
						const activeCitations = docData.filter((citation) => !citation.removed)
						return (
							activeCitations.length === 0 ||
							activeCitations.every((citation) => citation.verified)
						)
					})
				})
				return acc
			},
			{} as Record<string, boolean>,
		)

		setColumnVerificationStatus(newVerificationStatus)
	}, [chartData, documentIdList])

	const handleToggleVerifyAllCitations = useCallback(
		async (refId: string) => {
			try {
				await toggleVerifyAllCitations({
					documentId: refId,
					verified: !columnVerificationStatus[refId],
				})
			} catch (error) {
				console.error("Failed to toggle verification status:", error)
			}
		},
		[columnVerificationStatus, toggleVerifyAllCitations],
	)

	const handleShowPatentViewer = useCallback((_refId: string) => {
		setShowDocumentViewer(true)
	}, [])

	// function levensteinDistance(a: string, b: string): number {
	// 	const matrix = []

	// 	for (let i = 0; i <= b.length; i++) {
	// 		matrix[i] = [i]
	// 	}

	// 	for (let j = 0; j <= a.length; j++) {
	// 		matrix[0][j] = j
	// 	}

	// 	for (let i = 1; i <= b.length; i++) {
	// 		for (let j = 1; j <= a.length; j++) {
	// 			if (b.charAt(i - 1) === a.charAt(j - 1)) {
	// 				matrix[i][j] = matrix[i - 1][j - 1]
	// 			} else {
	// 				matrix[i][j] = Math.min(
	// 					matrix[i - 1][j - 1] + 1,
	// 					matrix[i][j - 1] + 1,
	// 					matrix[i - 1][j] + 1,
	// 				)
	// 			}
	// 		}
	// 	}
	// 	const similarity = 1 - matrix[b.length][a.length] / Math.max(b.length, a.length)

	// 	return similarity
	// }
	// const handleFill = useCallback(
	//   async (refId: string) => {
	//     try {
	//       onFillStart()
	//       const paragraphsResponse = await getRemainingParagraphs(projectId, refId)
	//       if (paragraphsResponse.success) {
	//         const { ipr_number, ...paragraphData } = paragraphsResponse.data
	//         const remainingParagraphs: Map<string, string[]> = new Map(
	//           Object.entries(paragraphData),
	//         )
	//         remainingParagraphs.forEach(async (paragraphNumbers, claimLanguage) => {
	//           // find the corresponding invalidity
	//           let bestInvalidityMatch = null
	//           let bestScore = 0

	//           chartData.forEach((invalidity) => {
	//             if (
	//               invalidity.claim_text
	//                 .toLowerCase()
	//                 .includes(claimLanguage.toLowerCase()) ||
	//               claimLanguage
	//                 .toLowerCase()
	//                 .includes(invalidity.claim_text.toLowerCase())
	//             ) {
	//               bestInvalidityMatch = invalidity
	//               return // This effectively breaks out of the forEach loop
	//             }
	//             const score = levensteinDistance(
	//               invalidity.claim_text.toLowerCase(),
	//               claimLanguage.toLowerCase(),
	//             )
	//             if (score > bestScore && score > 0.95) {
	//               bestScore = score
	//               bestInvalidityMatch = invalidity
	//             }
	//           })

	//           if (bestInvalidityMatch) {
	//             const invalidityId = bestInvalidityMatch[refId].invalidityId
	//             const _invaliditiesResponse = await processParagraphsAsInvalidities(
	//               projectId,
	//               invalidityId,
	//               refId,
	//               ipr_number[0],
	//               paragraphNumbers,
	//             )
	//           }
	//         })
	//       } else {
	//         console.error("Failed to fetch remaining paragraphs")
	//       }
	//     } catch (error) {
	//       console.error("Error fetching remaining paragraphs:", error)
	//       addErrorMessage("Failed to fetch remaining paragraphs")
	//     } finally {
	//       onFillEnd()
	//     }
	//   },
	//   [projectId, getRemainingParagraphs, addErrorMessage, onFillStart, onFillEnd],
	// )

	const handleClosePreview = useCallback(() => {
		setDocumentViewerDocument(null)
		setShowDocumentViewer(false)
	}, [])

	if (isLoading) {
		return <div>Loading...</div>
	}

	if (!chartData) {
		return <div>No chart data available</div>
	}

	return (
		<div>
			{showDocumentViewer && (
				<DocumentViewer
					open={showDocumentViewer}
					handleClose={handleClosePreview}
					documentId={documentViewerDocument?.id}
					startInChartMode={true}
				/>
			)}
			<Table>
				<TableHeader>
					<DataTableRowOutline>
						<DataTableHead className="font-bold whitespace-nowrap">No.</DataTableHead>
						<DataTableHead className="font-bold whitespace-nowrap min-w-[250px]">
							{projectSubject?.nickname ??
								formatPatentNumber(projectSubject?.document?.patent?.number ?? "")}{" "}
							Language
						</DataTableHead>
						{documentIdList.map((refId) => (
							<DataTableHead key={`${refId}-header`} className="whitespace-nowrap">
								<div className="flex justify-between items-center">
									<span className="font-bold">{getDocumentName(refId)}</span>
									<div className="flex">
										{references &&
											isLikelyPatentNumber(
												references.find((r) => r.documentId === refId)?.document?.patent?.number,
											) && (
												<Tooltip>
													<TooltipTrigger asChild>
														<Button
															variant="ghost"
															size="icon"
															asChild
															className="text-black hover:bg-primary-foreground/10 flex-shrink-0"
														>
															<a
																href={`https://patents.google.com/patent/${
																	references.find((r) => r.documentId === refId)?.document?.patent
																		?.number
																}`}
																target="_blank"
																rel="noopener noreferrer"
															>
																<ExternalLinkIcon className="h-4 w-4" />
															</a>
														</Button>
													</TooltipTrigger>
													<TooltipContent>
														<p>Go to Google Patents</p>
													</TooltipContent>
												</Tooltip>
											)}
										<Tooltip>
											<TooltipTrigger asChild>
												<Button
													variant="ghost"
													size="icon"
													onClick={() => setIsBoilerplateOpen(true)}
												>
													<AlignVerticalSpaceAround className="h-4 w-4" />
												</Button>
											</TooltipTrigger>
											<TooltipContent>
												<p>Edit header/footer</p>
											</TooltipContent>
										</Tooltip>

										<Tooltip>
											<TooltipTrigger asChild>
												<Button
													variant="ghost"
													size="icon"
													onClick={() => handleShowPatentViewer(refId)}
												>
													<EyeOpenIcon className="h-4 w-4" />
												</Button>
											</TooltipTrigger>
											<TooltipContent>
												<p>View full document</p>
											</TooltipContent>
										</Tooltip>

										<Tooltip>
											<TooltipTrigger asChild>
												<Button
													variant="ghost"
													size="icon"
													// onClick={() => handleFill(refId)}
												>
													<HandIcon className="h-4 w-4" />
												</Button>
											</TooltipTrigger>
											<TooltipContent>
												<p>Pull IPR Proceedings</p>
											</TooltipContent>
										</Tooltip>
										<Tooltip>
											<TooltipTrigger asChild>
												<Button
													variant="ghost"
													size="icon"
													onClick={() => handleToggleVerifyAllCitations(refId)}
												>
													<BadgeCheckIcon
														className={cn(
															"h-4 w-4",
															columnVerificationStatus[refId] ? "text-blue-500" : "text-gray-500",
														)}
													/>
												</Button>
											</TooltipTrigger>
											<TooltipContent>
												<p>
													{columnVerificationStatus[refId]
														? "Unverify all citations"
														: "Verify all citations"}
												</p>
											</TooltipContent>
										</Tooltip>
										<Tooltip>
											<TooltipTrigger asChild>
												<Button
													variant="ghost"
													size="icon"
													onClick={() => setIsHelpModalOpen(true)}
												>
													<InfoCircledIcon className="h-4 w-4" />
												</Button>
											</TooltipTrigger>
											<TooltipContent>
												<p>Help</p>
											</TooltipContent>
										</Tooltip>
									</div>
								</div>
							</DataTableHead>
						))}
					</DataTableRowOutline>
				</TableHeader>
				<TableBody>
					{Object.keys(chartData || {}).map((claimId) => {
						return (
							<InvalidityTableClaimRows
								key={claimId}
								row={chartData[claimId] as InvalidityClaimRowData}
								claim={projectClaims?.find((c) => c.id === claimId)}
								rowVisibility={rowVisibility}
							/>
						)
					})}
				</TableBody>
			</Table>
			<HeaderFooterEditor
				isOpen={isBoilerplateOpen}
				onClose={() => setIsBoilerplateOpen(false)}
				defaultUseFormatForAllElements={true}
			/>
			<InvalidityTableHelpModal
				isOpen={isHelpModalOpen}
				onClose={() => setIsHelpModalOpen(false)}
			/>
		</div>
	)
}

export default InvalidityTable
