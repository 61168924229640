/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useAppStateStore } from "@/store"
import type { ProcessType } from "@/types"
import { AlertCircle, AlertTriangle, CheckCircle, Info, X } from "lucide-react"
import type React from "react"
import { useEffect, useRef, useState } from "react"
import { Spinner } from "../ui/spinner"
import LoadingGroupAlert from "./LoadingGroupAlert"
const getTimestamp = () => new Date().getTime()

/**
 * @description Status/loading messages component
 */
import { DndContext, useDraggable } from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"

const DraggableAlerts = ({ position }) => {
	const {
		statusMessages,
		removeStatusMessage,
		loadingGroups,
		loadingMessages,
		removeLoadingGroup,
	} = useAppStateStore()

	const timerRef = useRef<Record<string, NodeJS.Timeout>>({})

	useEffect(() => {
		// Clean up old timers for messages that no longer exist
		Object.keys(timerRef.current).forEach((key) => {
			if (!statusMessages.find((msg) => msg.key === key)) {
				clearTimeout(timerRef.current[key])
				delete timerRef.current[key]
			}
		})

		// Set up new timers only for messages that don't have one
		statusMessages.forEach((message) => {
			if (!timerRef.current[message.key]) {
				timerRef.current[message.key] = setTimeout(() => {
					removeStatusMessage(message.key)
					delete timerRef.current[message.key]
				}, 5000)
			}
		})

		return () => {
			// Clean up all timers on unmount
			Object.values(timerRef.current).forEach(clearTimeout)
		}
	}, [statusMessages, removeStatusMessage])

	const getIcon = (severity: string) => {
		switch (severity) {
			case "error":
				return <AlertCircle className="h-4 w-4 text-red-500" />
			case "info":
				return <Info className="h-4 w-4 text-blue-500" />
			case "success":
				return <CheckCircle className="h-4 w-4 text-blue-500" />
			case "warning":
				return <AlertTriangle className="h-4 w-4 text-yellow-500" />
			default:
				return null
		}
	}

	// Dismiss button
	const DismissButton = ({ messageKey }: { messageKey: string }) => (
		<Tooltip>
			<TooltipTrigger asChild>
				<Button
					variant="ghost"
					size="icon"
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						removeStatusMessage(messageKey)
					}}
					onPointerDown={(e) => {
						e.preventDefault()
						e.stopPropagation()
					}}
					className="ml-2"
				>
					<X className="h-4 w-4" />
				</Button>
			</TooltipTrigger>
			<TooltipContent>Dismiss</TooltipContent>
		</Tooltip>
	)

	// Alert wrapper component
	const AlertWrapper = ({
		message,
		children,
		variant = "default",
	}: {
		message: { key: string; severity?: string }
		children: React.ReactNode
		variant?: "default" | "destructive"
	}) => (
		<Alert key={message.key} variant={variant} className="relative pr-8">
			<div
				className="flex items-center justify-between w-full"
				onClick={(e) => e.stopPropagation()}
				onPointerDown={(e) => e.stopPropagation()}
			>
				<div className="flex items-center gap-2">{children}</div>
				<DismissButton messageKey={message.key} />
			</div>
		</Alert>
	)

	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: "alerts-draggable",
	})

	const style = transform
		? {
				transform: CSS.Translate.toString(transform),
				position: "fixed",
				bottom: position.y,
				right: position.x,
				zIndex: 50,
				maxHeight: `calc(100vh - ${position.y}px)`,
				maxWidth: `calc(100vw - ${position.x}px)`,
			}
		: {
				position: "fixed",
				bottom: position.y,
				right: position.x,
				zIndex: 50,
				maxHeight: `calc(100vh - ${position.y}px)`,
				maxWidth: `calc(100vw - ${position.x}px)`,
			}

	return (
		<div
			ref={setNodeRef}
			style={style as React.CSSProperties}
			{...listeners}
			{...attributes}
		>
			<div className="max-w-[420px] min-w-[320px] cursor-move">
				<div
					className="space-y-2 overflow-auto p-2"
					style={{ maxHeight: "calc(100vh - 40px)" }}
				>
					{loadingMessages.map((message) => (
						<AlertWrapper key={message.key} message={message}>
							<Spinner className="mr-2 flex-shrink-0 h-4 w-4" />
							<AlertTitle className="mb-0 ml-2 break-words">{message.message}</AlertTitle>
						</AlertWrapper>
					))}

					{loadingGroups.map((loadingGroup) => (
						<LoadingGroupAlert
							key={loadingGroup.key + getTimestamp()}
							loadingGroup={loadingGroup}
							removeLoadingGroup={removeLoadingGroup}
							processType={loadingGroup.processType as ProcessType}
						/>
					))}

					{[...statusMessages.filter((m) => m.severity)].map((message) => (
						<AlertWrapper
							key={message.key}
							message={message}
							variant={message.severity === "error" ? "destructive" : "default"}
						>
							<div className="flex-shrink-0 flex items-center mr-3">
								{getIcon(message.severity)}
							</div>
							<div className="flex flex-col overflow-hidden">
								<AlertTitle className="mb-0 break-words">
									{message.severity.charAt(0).toUpperCase() + message.severity.slice(1)}
								</AlertTitle>
								<AlertDescription className="break-words">{message.message}</AlertDescription>
							</div>
						</AlertWrapper>
					))}
				</div>
			</div>
		</div>
	)
}

const AlertMessages: React.FC = () => {
	const [position, setPosition] = useState({
		x: 20,
		y: 20,
	})

	return (
		<DndContext
			onDragEnd={({ delta }) => {
				setPosition((prev) => {
					// Get window dimensions
					const windowWidth = window.innerWidth
					const windowHeight = window.innerHeight

					// Calculate temporary new position
					const tempX = prev.x - delta.x
					const tempY = prev.y - delta.y

					// Define corner positions
					const corners = {
						bottomRight: { x: 20, y: 20 },
						bottomLeft: { x: windowWidth - 420 - 20, y: 20 }, // 420 is alert width
						topRight: { x: 20, y: windowHeight - 20 },
						topLeft: { x: windowWidth - 420 - 20, y: windowHeight - 20 },
					}

					// Find the nearest corner
					const distances = Object.entries(corners).map(([corner, pos]) => ({
						corner,
						distance: Math.sqrt((tempX - pos.x) ** 2 + (tempY - pos.y) ** 2),
					}))

					const nearestCorner = distances.reduce((prev, current) =>
						prev.distance < current.distance ? prev : current,
					)

					// Return the position of the nearest corner
					return corners[nearestCorner.corner]
				})
			}}
		>
			<DraggableAlerts position={position} />
		</DndContext>
	)
}

export default AlertMessages
