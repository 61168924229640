/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { useInvalidityChartData } from "@/features/charts/hooks"
import { useProjectAndPortfolioIds, useSearchDocuments } from "@/hooks"
import useClaims from "@/hooks/useClaims"
import type { ChunkToLimitation, ClaimLimitation, DocumentChunk } from "@/types"
import { formatDocumentChunkLocation } from "@/utils/projectUtils"
import { Loader2, Search } from "lucide-react"
import type React from "react"
import { useRef, useState } from "react"
import { DocumentBody } from ".."
import { CondensedParagraph } from "../ui/typography"

/**
 * Props for the ChartViewerLayout component.
 */
interface ChartViewerLayoutProps {
	documentChunks: DocumentChunk[]
	documentId: string
	selectedDocumentChunkId?: string
	selectedLimitationId?: string
}

const ChartViewerLayout: React.FC<ChartViewerLayoutProps> = ({
	documentChunks,
	documentId,
	selectedDocumentChunkId,
	selectedLimitationId: initialSelectedLimitationId,
}) => {
	const [leftPanelSize, setLeftPanelSize] = useState(50)
	const { projectId } = useProjectAndPortfolioIds()
	const { invalidityChartData } = useInvalidityChartData()
	const { limitations } = useClaims()
	const [selectedLimitationId, setSelectedLimitationId] = useState<string | null>(
		initialSelectedLimitationId || null,
	)

	const selectedElementRef = useRef<HTMLDivElement>(null)
	const paragraphRefs = useRef<{ [chunkId: string]: HTMLDivElement | null }>({})
	const claimRefs = useRef<{ [limitationId: string]: HTMLDivElement | null }>({})
	const [searchQuery, setSearchQuery] = useState("")
	const [searchResults, setSearchResults] = useState<DocumentChunk[]>([])
	const { semanticSearchDocuments } = useSearchDocuments()
	const [isSearching, setIsSearching] = useState(false)
	const [showNoResults, setShowNoResults] = useState<string | null>(null)

	/**
	 * Perform the search on both semantic and keyword engines.
	 */
	const handleSearch = async () => {
		if (!searchQuery.trim() || !documentId) return
		setIsSearching(true)

		try {
			const [semanticResult, keywordResult] = await Promise.all([
				semanticSearchDocuments(projectId, searchQuery, [documentId], "semantic"),
				semanticSearchDocuments(projectId, searchQuery, [documentId], "keyword"),
			])

			const combinedResults = [
				...(semanticResult.success ? semanticResult.data.sources[0] || [] : []),
				...(keywordResult.success ? keywordResult.data.sources[0] || [] : []),
			]

			const finalResults = Array.from(new Set(combinedResults))
			setSearchResults(finalResults)

			if (finalResults.length === 0) {
				setShowNoResults(searchQuery)
				setTimeout(() => setShowNoResults(null), 5000)
			} else {
				setShowNoResults(null)
			}
		} catch (error) {
			console.error("Search failed:", error)
		} finally {
			setIsSearching(false)
		}
	}

	/**
	 * Scroll to the paragraph with matching documentChunkId.
	 */
	const scrollToParagraph = (documentChunkId: string) => {
		const paragraphElement = paragraphRefs.current[documentChunkId]
		if (paragraphElement) {
			setTimeout(() => {
				try {
					const container = paragraphElement.closest(".overflow-y-auto")
					if (container) {
						const containerRect = container.getBoundingClientRect()
						const elementRect = paragraphElement.getBoundingClientRect()
						const relativeTop = elementRect.top - containerRect.top

						const scrollPosition =
							container.scrollTop +
							relativeTop -
							containerRect.height / 2 +
							elementRect.height / 2

						container.scrollTo({
							top: scrollPosition,
							behavior: "smooth",
						})

						paragraphElement.classList.add("bg-blue-100")
						setTimeout(() => {
							paragraphElement.classList.remove("bg-blue-100")
						}, 1000)
					}
				} catch (error) {
					console.error("Error during scroll:", error)
				}
			}, 100)
		}
	}

	/**
	 * Scroll to a claim card by its limitationId.
	 */
	const scrollToClaim = (limitationId: string) => {
		const claimElement = claimRefs.current[limitationId]
		if (claimElement) {
			setTimeout(() => {
				try {
					const container = claimElement.closest(".overflow-y-auto")
					if (container) {
						const containerRect = container.getBoundingClientRect()
						const elementRect = claimElement.getBoundingClientRect()
						const relativeTop = elementRect.top - containerRect.top

						const scrollPosition =
							container.scrollTop +
							relativeTop -
							containerRect.height / 2 +
							elementRect.height / 2

						container.scrollTo({
							top: scrollPosition,
							behavior: "smooth",
						})

						claimElement.classList.add("bg-blue-100")
						setTimeout(() => {
							claimElement.classList.remove("bg-blue-100")
						}, 1000)
					}
				} catch (error) {
					console.error("Error during scroll:", error)
				}
			}, 100)
		}
	}

	/**
	 * Set the selected limitation and cause re-render with new selection.
	 */
	const handleSetSelectedElements = async (limitationId: string) => {
		setSelectedLimitationId(limitationId)
		scrollToClaim(limitationId)
	}

	/**
	 * Retrieve the citations for a given limitationId.
	 */
	const getCitationsForLimitation = (limitationId: string) => {
		if (!documentId || !invalidityChartData) return []
		const citations: ChunkToLimitation[] = []
		Object.values(invalidityChartData).forEach((limitationSet) => {
			if (limitationSet[limitationId]?.[documentId]) {
				citations.push(...limitationSet[limitationId][documentId])
			}
		})
		return citations.filter((citation) => !citation.removed)
	}

	/**
	 * Render a single claim/limitation card, including any citation badges.
	 */
	const renderClaimCard = (limitation: ClaimLimitation) => {
		const isSelected = selectedLimitationId === limitation.id
		const citations = getCitationsForLimitation(limitation.id)

		return (
			<div
				ref={(el) => {
					if (isSelected) {
						selectedElementRef.current = el
					}
					// Store by limitation.id so we can retrieve by ID consistently
					claimRefs.current[limitation.id] = el
				}}
				className={`w-full px-4 py-3 mb-2 border rounded-lg cursor-pointer hover:bg-gray-50 ${
					isSelected ? "border-blue-500 bg-blue-50" : ""
				}`}
				onClick={() => handleSetSelectedElements(limitation.id)}
			>
				<div className="w-16 flex-shrink-0 text-muted-foreground mb-1">
					<CondensedParagraph>{limitation.label}</CondensedParagraph>
				</div>
				<CondensedParagraph>{limitation.text}</CondensedParagraph>

				{citations.length > 0 && (
					<div className="flex flex-wrap gap-2 mt-2">
						{citations.map((citation, index) => {
							const formattedCitation = formatDocumentChunkLocation(
								citation.documentChunk.location,
							)
							return (
								<span
									key={`${citation.documentChunk.location.paragraphs}-${index}`}
									className="px-2 py-1 text-xs rounded-full text-black cursor-pointer"
									style={{ backgroundColor: citation.color }}
									onClick={(e) => {
										e.stopPropagation()
										handleSetSelectedElements(limitation.id)
										const matchingSection = documentChunks.find(
											(section) => section.id === citation.documentChunk.id,
										)
										if (matchingSection) {
											setTimeout(() => {
												scrollToParagraph(matchingSection.id)
											}, 50)
										}
									}}
								>
									{formattedCitation || citation.documentChunk.location.paragraphs}
								</span>
							)
						})}
					</div>
				)}
			</div>
		)
	}

	/**
	 * Render the main document body in chart mode.
	 */
	const renderBody = () => {
		return (
			<DocumentBody
				body={documentChunks}
				figureUrls={[]}
				chartMode={true}
				documentId={documentId}
				invalidityData={invalidityChartData}
				limitations={limitations}
				selectedLimitationId={selectedLimitationId}
				selectedChunkId={selectedDocumentChunkId}
			/>
		)
	}

	/**
	 * Clear the existing search query and results.
	 */
	const clearSearch = () => {
		setSearchQuery("")
		setSearchResults([])
	}

	/**
	 * JSX for the search input area.
	 */
	const searchInput = (
		<div className="transition-all duration-200 h-9 overflow-hidden mb-1">
			<div className="flex gap-2">
				<div className="relative flex-1">
					<Input
						type="text"
						placeholder="Search document..."
						value={searchQuery}
						className="h-9 pr-16"
						onChange={(e) => setSearchQuery(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleSearch()
							}
						}}
					/>
					<Button
						variant="ghost"
						size="sm"
						className="absolute right-2 top-1/2 -translate-y-1/2 h-6 w-6 p-0 bg-background"
						onClick={handleSearch}
					>
						<Search className="h-4 w-4" />
					</Button>
				</div>
				{searchResults.length > 0 && (
					<Button variant="outline" size="sm" className="h-9 px-3" onClick={clearSearch}>
						Clear
					</Button>
				)}
			</div>
		</div>
	)

	// /**
	//  * On mount: scroll to any pre-selected documentChunkId or limitationId.
	//  */
	// useEffect(() => {
	// 	// Only attempt scrolling once limitations are loaded/rendered
	// 	if (!limitations || limitations.length === 0) return

	// 	// If a chunk is already selected on mount, scroll to it
	// 	if (selectedDocumentChunkId) {
	// 		setTimeout(() => {
	// 			scrollToParagraph(selectedDocumentChunkId)
	// 		}, 100)
	// 	}

	// 	// If a limitation is already selected on mount, set & scroll to it
	// 	if (initialSelectedLimitationId) {
	// 		setSelectedLimitationId(initialSelectedLimitationId)
	// 		setTimeout(() => {
	// 			scrollToClaim(initialSelectedLimitationId)
	// 		}, 100)
	// 	}
	// }, [limitations, selectedDocumentChunkId, initialSelectedLimitationId])

	return (
		<div className="flex h-full">
			<ResizablePanelGroup direction="horizontal" className="w-full p-0">
				<ResizablePanel
					defaultSize={leftPanelSize}
					onResize={setLeftPanelSize}
					className="p-0"
				>
					<div className="h-[calc(100vh-120px)] flex flex-col">
						<div className="flex-none px-3 py-2">{searchInput}</div>
						<div className="flex-1 overflow-y-auto custom-scrollbar px-3 pt-3 pb-3">
							{isSearching ? (
								<div className="flex items-center justify-center h-full">
									<Loader2 className="h-8 w-8 animate-spin text-blue-500" />
								</div>
							) : (
								<div className="w-full mb-3">{renderBody()}</div>
							)}
						</div>
					</div>
				</ResizablePanel>

				<ResizableHandle withHandle={true} />

				<ResizablePanel defaultSize={100 - leftPanelSize}>
					<div className="h-[calc(100vh-120px)] flex flex-col px-3 py-2">
						<div className="flex-none flex items-center justify-between pb-2 border-b">
							<div className="flex items-center gap-2">
								<Select value={selectedLimitationId} onValueChange={handleSetSelectedElements}>
									<SelectTrigger className="w-[150px] h-9">
										<SelectValue placeholder="Select element" />
									</SelectTrigger>
									<SelectContent>
										{limitations.map((limitation) => (
											<SelectItem key={limitation.id} value={limitation.id}>
												{limitation.label}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
						</div>
						<div className="flex-1 overflow-y-auto custom-scrollbar pt-2 pb-3 mb-3">
							{limitations.map((limitation) => renderClaimCard(limitation))}
						</div>
					</div>
				</ResizablePanel>
			</ResizablePanelGroup>
		</div>
	)
}

export default ChartViewerLayout
