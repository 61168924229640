/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ErrorMessage } from "@/components"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Spinner } from "@/components/ui/spinner"
import { Switch } from "@/components/ui/switch"
import { H4 } from "@/components/ui/typography"
import { useNavigationState } from "@/hooks"
import useDocument from "@/hooks/useDocument"
import { useProjectStore } from "@/store"
import { VisuallyHidden } from "@radix-ui/react-visually-hidden"
import type React from "react"
import { useEffect, useRef, useState } from "react"
import ChartViewerLayout from "./ChartViewerLayout"
import DocumentDetailLayout from "./DocumentDetailLayout"

interface DocumentViewerProps {
	open: boolean
	handleClose: () => void
	documentId: string
	selectedDocumentChunkId?: string
	selectedLimitationId?: string
	startInChartMode?: boolean
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({
	open,
	handleClose,
	documentId,
	selectedDocumentChunkId,
	selectedLimitationId,
	startInChartMode,
}) => {
	if (!open) {
		return null
	}

	const { document, isLoading, isError } = useDocument(documentId)
	const containerRef = useRef<HTMLDivElement | null>(null)
	const highlightedRef = useRef<HTMLDivElement | HTMLTableRowElement | null>(null)
	const [viewerMode, setViewerMode] = useState<"viewer" | "editor">(
		startInChartMode ? "editor" : "viewer",
	)
	const { isProjectPage } = useNavigationState()
	const { updateSelectedReferences } = useProjectStore()

	useEffect(() => {
		setViewerMode(startInChartMode ? "editor" : "viewer")
	}, [startInChartMode])

	// Scroll effect - moved before conditional returns
	useEffect(() => {
		if (open && document) {
			if (containerRef.current) {
				containerRef.current.scrollTop = 0
			}

			// Delay the scroll to ensure the content is rendered
			setTimeout(() => {
				if (highlightedRef.current && containerRef.current) {
					const container = containerRef.current
					const highlightedElement = highlightedRef.current

					// Set the scroll position without animation
					highlightedElement.scrollIntoView({ block: "center" })

					// Adjust the scroll position
					container.scrollTop = container.scrollTop - 100
				}
			}, 0)
		}
	}, [open, document])

	useEffect(() => {
		updateSelectedReferences([documentId])
	}, [])

	if (!document || isLoading) {
		return (
			<Dialog open={open} onOpenChange={handleClose}>
				<DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col p-0 overflow-hidden">
					<div className="flex items-center justify-center h-full m-12">
						<Spinner />
					</div>
				</DialogContent>
			</Dialog>
		)
	}

	if (isError) {
		return (
			<Dialog open={open} onOpenChange={handleClose}>
				<DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col p-0 overflow-hidden">
					<div className="flex items-center justify-center h-full m-12">
						<ErrorMessage />
					</div>
				</DialogContent>
			</Dialog>
		)
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col p-0 overflow-hidden">
				<VisuallyHidden>
					<DialogTitle>
						<H4>{document.nickname}</H4>
					</DialogTitle>
				</VisuallyHidden>
				<VisuallyHidden asChild>
					<DialogDescription>{document.document.title} details</DialogDescription>
				</VisuallyHidden>
				<div className="flex flex-col h-full">
					<DialogHeader className="sticky top-0 z-10 bg-background py-2 px-3 border-b bg">
						<div className="flex justify-between items-center gap-2">
							<div className="flex items-center gap-2">
								<H4>{document.nickname}</H4>
							</div>

							<div className="flex items-center gap-4">
								{isProjectPage && (
									<div className="flex items-center gap-2">
										<Switch
											checked={viewerMode === "editor"}
											onCheckedChange={(checked) => setViewerMode(checked ? "editor" : "viewer")}
										/>
										<span className="text-sm text-muted-foreground">Chart Mode</span>
									</div>
								)}
							</div>
						</div>
					</DialogHeader>

					{viewerMode === "viewer" ? (
						<DocumentDetailLayout
							document={document}
							selectedDocumentChunkId={selectedDocumentChunkId}
							highlightedRef={highlightedRef}
						/>
					) : (
						<ChartViewerLayout
							documentId={documentId}
							documentChunks={document.document.body}
							selectedDocumentChunkId={selectedDocumentChunkId}
							selectedLimitationId={selectedLimitationId}
						/>
					)}
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default DocumentViewer
