import { api } from "@/api"
import { useApi, useProjectAndPortfolioIds } from "@/hooks"
import type { ProjectContext } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

const useProjectContext = () => {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { projectId } = useProjectAndPortfolioIds()

	// Queries
	const context = useQuery({
		queryKey: QUERY_KEYS.project.context(projectId),
		queryFn: () => api.getProjectContext(projectId!),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Mutations
	const generateFromIdMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.context.generateFromId(),
		mutationFn: ({
			projectId,
			documentId,
		}: {
			projectId: string
			documentId: string
		}) => api.generatePatentContextFromId(projectId, documentId),
		onSuccess: (response: ProjectContext) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.context(projectId),
			})
			return { success: true, data: response }
		},
		onError: (error) => handleError(error, "Error generating patent context"),
	})

	const generateFromTextMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.context.generateFromText(),
		mutationFn: ({ projectId, text }: { projectId: string; text?: string }) =>
			api.generatePatentContextFromText(projectId, text),
		onSuccess: (response: ProjectContext) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.context(projectId),
			})
			return { success: true, data: response }
		},
		onError: (error) => handleError(error, "Error generating patent context"),
	})

	const updateContextMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.context.update(),
		mutationFn: ({
			projectId,
			context,
		}: {
			projectId: string
			context: ProjectContext
		}) => api.updateProjectContext(projectId, context),
		onMutate: async (variables) => {
			// Set up optimistic update
			const queryKey = QUERY_KEYS.project.context(variables.projectId)
			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys: [queryKey],
				updateFn: () => variables.context,
			})
			return context
		},
		onSuccess: (_, variables) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.context(variables.projectId),
			})
			return { success: true }
		},
		onError: (error, variables, context) => {
			// Revert optimistic update on error
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			handleError(error, "Error saving patent context")
		},
	})

	// TODO: invalidate reference queries

	return {
		// Query data
		context: context.data,

		// Loading states
		isLoading: {
			patentContext: context.isLoading,
		},

		// Error states
		isError: {
			patentContext: context.isError,
		},

		// Fetching states
		isFetching: {
			patentContext: context.isFetching,
		},

		// Pending states for mutations
		isPending: {
			generateFromId: generateFromIdMutation.isPending,
			generateFromText: generateFromTextMutation.isPending,
			updateProjectContext: updateContextMutation.isPending,
		},

		// Mutation functions with type safety
		generatePatentContextFromId: (projectId: string, documentId: string) =>
			generateFromIdMutation.mutate({ projectId, documentId }),
		generatePatentContextFromText: (projectId: string, text?: string) =>
			generateFromTextMutation.mutate({ projectId, text }),
		updateProjectContext: (projectId: string, context: ProjectContext) =>
			updateContextMutation.mutate({ projectId, context }),
	}
}

export default useProjectContext
