import type {
	CreateProjectRequest,
	GetOfficeActionApplicationNumberRequest,
	ProcessDocumentsRequest,
	ReprocessDocumentsRequest,
} from "@/types"
import type { ProjectType } from "@/types"
import type { DocumentToProcess, ProcessSettings } from "@/types/documents"
import { apiClient } from "./client"

export const processApi = {
	async processDocuments(
		portfolioId: string | null,
		projectIds: string[] | null,
		documents: DocumentToProcess[],
		settings: ProcessSettings,
	) {
		const payload: ProcessDocumentsRequest = {
			portfolio_id: portfolioId,
			project_ids: projectIds,
			documents,
			settings,
		}
		return await apiClient.post("process", payload)
	},

	async reprocessDocuments(
		portfolioId: string | null,
		projectIds: string[] | null,
		documentIds: string[],
		settings: ProcessSettings,
	) {
		const payload: ReprocessDocumentsRequest = {
			portfolio_id: portfolioId,
			project_ids: projectIds,
			document_ids: documentIds,
			settings: {
				chart: settings.chart ?? true,
				reparse: settings.reparse ?? false,
				citation_policy: settings.citation_policy,
				claims_in_charting: settings.claims_in_charting ?? false,
			},
		}
		return await apiClient.post("reprocess", payload)
	},

	async createProject(
		name: string,
		type: ProjectType,
		client_number: string,
		documents: DocumentToProcess[],
		evaluation_project: boolean,
	) {
		const payload: CreateProjectRequest = {
			name: name,
			type: type,
			client_number: client_number,
			documents: documents,
			evaluation_project: evaluation_project,
		}
		return await apiClient.post("post_create_project", payload)
	},

	async getOfficeActionApplicationNumber(s3_key: string): Promise<string> {
		const params: GetOfficeActionApplicationNumberRequest = {
			s3_key: s3_key,
		}
		return await apiClient.get("get_office_action_application_number", params)
	},
}
