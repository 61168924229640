/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import blueLogoImage from "@/assets/logomark_lightblue.png"
import navyLogoImage from "@/assets/logomark_navy.png"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { useNavigationState } from "@/hooks"
import { useIntercom } from "@/hooks/integrations/useIntercom"
import { useUserStore } from "@/store"
import { useTheme } from "@/styled/ThemeProvider"
import { RoleType } from "@/types"
import {
	FileIcon,
	HomeIcon,
	LockClosedIcon,
	MoonIcon,
	PersonIcon,
	PlusIcon,
	QuestionMarkCircledIcon,
	SunIcon,
} from "@radix-ui/react-icons"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import ProfileMenu from "./ProfileMenu"
import SidebarMenuItem from "./SidebarMenuItem"

/**
 * @description Sidebar component
 */
const Sidebar: React.FC = () => {
	const navigate = useNavigate()
	const { showLauncher, hideLauncher } = useIntercom()
	const {
		isHomePage,
		isDocumentsPage,
		isAdminPage,
		isSuperAdminPage,
		isCreateProjectPage,
	} = useNavigationState()

	// Manage intercom
	useEffect(() => {
		hideLauncher() // Hide the default launcher
		return () => showLauncher() // Show the default launcher when component unmounts
	}, [])

	const handleIntercomClick = () => {
		const w = window as any
		if (typeof w.Intercom === "function") {
			w.Intercom("show")
		}
	}

	// Global state from store
	const { theme, setTheme } = useTheme()
	const { role } = useUserStore()

	// Handle clicks
	const handleNavigationClick = (path: string) => {
		navigate(path)
	}

	const handleDarkMode = () => {
		setTheme(theme === "light" ? "dark" : "light")
	}

	// Menu items array
	const menuItems = [
		{
			icon: HomeIcon,
			onClick: () => handleNavigationClick("/home"),
			isActive: isHomePage,
			label: "Home",
			show: true,
		},
		{
			icon: FileIcon,
			onClick: () => handleNavigationClick("/documents"),
			isActive: isDocumentsPage,
			label: "My documents",
			show: true,
		},
		{
			icon: PersonIcon,
			onClick: () => handleNavigationClick("/admin"),
			isActive: isAdminPage,
			label: "Admin",
			show: role === RoleType.ADMIN || role === RoleType.OWNER,
		},
		{
			icon: LockClosedIcon,
			onClick: () => handleNavigationClick("/superadmin"),
			isActive: isSuperAdminPage,
			label: "Super Admin",
			show: role === RoleType.OWNER,
		},
		{
			icon: PlusIcon,
			onClick: () => handleNavigationClick("/create-project"),
			isActive: isCreateProjectPage,
			label: "New Project",
			show: true,
		},
	]

	return (
		<div className="flex flex-col h-screen bg-background border-r w-20">
			<div
				className={"px-2 py-4 flex items-center justify-center cursor-pointer"}
				onClick={() => handleNavigationClick("/home")}
			>
				<img
					src={theme === "light" ? navyLogoImage : blueLogoImage}
					alt="AndAI Logo"
					className="h-10 w-10"
				/>
			</div>

			<div className="flex-grow space-y-2 p-2">
				<div className="flex flex-col items-center gap-2">
					{menuItems
						.filter((item) => item.show)
						.map((item, index) => (
							<React.Fragment key={item.label}>
								{index === 4 && <Separator orientation="horizontal" />}
								<SidebarMenuItem
									icon={item.icon}
									onClick={item.onClick}
									isActive={item.isActive}
									label={item.label}
								/>
							</React.Fragment>
						))}
				</div>
			</div>

			<div className="flex justify-center p-4">
				<div className="flex flex-col items-center gap-2">
					<Button variant="ghost" size="icon" onClick={handleDarkMode}>
						{theme === "light" ? (
							<MoonIcon className="h-5 w-5" />
						) : (
							<SunIcon className="h-5 w-5" />
						)}
					</Button>

					<Button variant="ghost" size="icon" onClick={handleIntercomClick}>
						<QuestionMarkCircledIcon className="h-5 w-5" />
					</Button>
				</div>
			</div>

			<div className="m-auto px-2 py-4">
				<ProfileMenu />
			</div>
		</div>
	)
}

export default Sidebar
