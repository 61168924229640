import type {
	DeleteProjectRequest,
	GetProjectClaimsResponse,
	GetProjectListRequest,
	InvaliditySettings,
	PortfolioMetadata,
	ProjectDocumentMetadata,
	ProjectMetadata,
	ProjectToDocument,
	UpdateProjectDetailsRequest,
	UpdateSettingsRequest,
} from "@/types"
import { toSnakeCase } from "@/utils/dataUtils"
import { apiClient } from "./client"

export const projectApi = {
	async getProjectMetadata(projectId: string): Promise<ProjectMetadata> {
		const response = await apiClient.get("get_project_metadata", {
			project_id: projectId,
		})
		return response as ProjectMetadata
	},

	async getProjectPriorArtDocuments(
		projectId: string,
	): Promise<ProjectDocumentMetadata[]> {
		return await apiClient.get("get_project_prior_art_rows", {
			project_id: projectId,
		})
	},

	async deleteProject(projectId: string) {
		const payload: DeleteProjectRequest = {
			project_id: projectId,
		}
		return await apiClient.post("post_delete_project", payload)
	},

	async updateProjectDetails(projectId: string, options: any) {
		const payload: UpdateProjectDetailsRequest = {
			project_id: projectId,
			...options,
		}
		return await apiClient.post("post_update_project_details", payload)
	},

	async updateProjectSettings(
		projectId: string,
		portfolioId: string | null,
		settings: InvaliditySettings,
	) {
		const payload: UpdateSettingsRequest = {
			...toSnakeCase(settings),
			project_id: projectId,
			portfolio_id: portfolioId,
		}
		return await apiClient.post("post_update_project_settings", payload)
	},

	async getInvaliditySettings(
		projectId: string | null,
		portfolioId: string | null,
	): Promise<InvaliditySettings> {
		const params: Record<string, string> = {}
		if (projectId) params.project_id = projectId
		if (portfolioId) params.portfolio_id = portfolioId

		return await apiClient.get("get_project_settings", params)
	},

	async getProjectSubject(
		projectId: string,
		subjectId: string,
	): Promise<ProjectToDocument> {
		const response = await apiClient.get("get_project_subject", {
			project_id: projectId,
			subject_id: subjectId,
		})
		return response as ProjectToDocument
	},

	async getProjectClaims(
		projectId: string,
		collapsed = false,
	): Promise<GetProjectClaimsResponse> {
		return await apiClient.get("get_project_claims", {
			project_id: projectId,
			collapsed,
		})
	},

	async getProjectList(
		isArchived: boolean,
	): Promise<ProjectMetadata | PortfolioMetadata[]> {
		const params: GetProjectListRequest = {
			include_archived: isArchived,
			include_active: !isArchived, // TODO: update later
		}
		return await apiClient.get("get_project_list", params)
	},

	async getUserPersonalProjectId(): Promise<string> {
		return await apiClient.get("get_personal_user_project_id")
	},
}
