import { Button } from "@/components/ui/button"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Label } from "@/components/ui/label"
import { useInvalidityChart } from "@/features/charts/hooks/mutations/useInvalidityChart"
import { useDocumentChunk } from "@/hooks"
import {
	ChartColor,
	type ClaimLimitation,
	type DocumentChunk,
	DocumentChunkMode,
	type InvalidityChartData,
} from "@/types"
import { normalizeTextToMatch } from "@/utils/normalizeTextToMatch"
import Fuse from "fuse.js"
import { Plus } from "lucide-react"
import type React from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import { DocumentChunkComponent } from "./DocumentChunk"
import { EditDocumentChunkDialog } from "./EditDocumentChunkDialog"

interface DocumentBodyProps {
	body: DocumentChunk[]
	figureUrls: string[]
	citationText?: string
	highlightedRef?: React.RefObject<HTMLDivElement>
	selectedChunkId?: string | null
	onChunkSelect?: (id: string) => void
	editMode?: boolean
	chartMode?: boolean
	documentId: string

	invalidityData?: InvalidityChartData
	limitations?: ClaimLimitation[]
	selectedLimitationId?: string
}

const DocumentBody: React.FC<DocumentBodyProps> = ({
	body,
	figureUrls,
	citationText,
	highlightedRef,
	selectedChunkId,
	onChunkSelect,
	editMode,
	chartMode,
	documentId,
	invalidityData,
	limitations,
	selectedLimitationId,
}) => {
	const bodyRef = useRef<HTMLDivElement>(null)
	const { addDocumentChunk, deleteDocumentChunk } = useDocumentChunk()
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
	const { addCitation, updateCitation } = useInvalidityChart()

	if (!document?.body) {
		return null
	}

	const selectedClaimId = useMemo(() => {
		return limitations?.find((limitation) => limitation.id === selectedLimitationId)
			?.claimId
	}, [limitations, selectedLimitationId])

	const getColorForChunk = useMemo(
		() => (chunkId: string) => {
			if (
				!selectedClaimId ||
				!selectedLimitationId ||
				!invalidityData?.[selectedClaimId]?.[selectedLimitationId]?.[documentId]
			) {
				return null
			}
			const citation = invalidityData[selectedClaimId][selectedLimitationId][
				documentId
			].find((item) => item.documentChunkId === chunkId)
			return citation?.removed ? null : (citation?.color ?? null)
		},
		[selectedClaimId, selectedLimitationId, documentId, invalidityData],
	)

	const handleAddChunkAsCitation = async (chunk: DocumentChunk) => {
		await addCitation({
			documentChunkId: chunk.id,
			claimLimitationId: selectedLimitationId,
			color: ChartColor.GREEN,
		})
	}

	const handleRemoveChunkAsCitation = async (chunk: DocumentChunk) => {
		await updateCitation({
			documentId,
			claimId: selectedClaimId,
			documentChunkId: chunk.id,
			claimLimitationId: selectedLimitationId,
			options: {
				removed: true,
			},
		})
	}

	const handleUpdateColorChunkAsCitation = async (
		chunk: DocumentChunk,
		color: ChartColor,
	) => {
		await updateCitation({
			documentId,
			claimId: selectedClaimId,
			documentChunkId: chunk.id,
			claimLimitationId: selectedLimitationId,
			options: {
				color: color,
			},
		})
	}

	const isHighlighted = (plainText?: string, searchText?: string): boolean => {
		if (!plainText || !searchText) return false

		// Split the plain text into sentences for more granular matching
		const sentences = plainText.split(/[.!?]+/).map((s) => s.trim())

		const normalizedSearchText = normalizeTextToMatch(searchText)

		// Check each sentence for a match
		for (const sentence of sentences) {
			const normalizedSentence = normalizeTextToMatch(sentence)
			if (normalizedSentence.includes(normalizedSearchText)) {
				return true
			}
		}

		// If no direct match found, try fuzzy search on each sentence
		const fuse = new Fuse(sentences.map(normalizeTextToMatch), {
			includeScore: true,
			threshold: 0.2, // More strict threshold
			distance: 1000,
			minMatchCharLength: 10,
		})

		const result = fuse.search(normalizedSearchText)
		return result.length > 0 && (result[0].score || 1) < 0.3
	}

	useEffect(() => {
		// Handle scrolling to citation text
		if (citationText && bodyRef.current) {
			setTimeout(() => {
				const highlightedSections = Array.from(
					bodyRef.current?.querySelectorAll(".bg-blue-100"),
				)

				if (highlightedSections.length > 0) {
					const firstHighlighted = highlightedSections[0] as HTMLElement
					firstHighlighted.scrollIntoView({
						block: "center",
						behavior: "auto",
					})
				}
			}, 100)
		}

		// Handle scrolling to selected chunk
		if (selectedChunkId && bodyRef.current) {
			setTimeout(() => {
				const selectedChunk = bodyRef.current?.querySelector(
					`[data-chunk-id="${selectedChunkId}"]`,
				)

				if (selectedChunk) {
					selectedChunk.scrollIntoView({
						block: "center",
						behavior: "smooth",
					})
				}
			}, 100)
		}
	}, [citationText, selectedChunkId])

	const handleAddChunk = () => {
		setIsAddDialogOpen(true)
	}

	const handleSaveNewChunk = (chunk: DocumentChunk) => {
		addDocumentChunk({
			documentId,
			text: chunk.text,
			location: chunk.location,
			figureUrls: chunk.figureUrls || [],
			// type: BlockType.TEXT
		})
		setIsAddDialogOpen(false)
	}

	const handleDeleteChunk = (id: string) => {
		deleteDocumentChunk({ documentId, documentChunkId: id })
	}

	const renderBody = () => {
		return body
			.map((chunk: DocumentChunk | null, index: number) => {
				if (!chunk || index === 0 || index === 1) {
					return null
				}

				const isTextHighlighted = isHighlighted(chunk.text, citationText)
				const highlightColor = getColorForChunk(chunk.id) ?? null

				return (
					<DocumentChunkComponent
						key={index}
						chunk={chunk}
						citationText={citationText}
						highlightedRef={highlightedRef}
						isTextHighlighted={isTextHighlighted}
						selectedChunkId={selectedChunkId}
						onChunkSelect={onChunkSelect}
						figureUrls={figureUrls}
						editMode={editMode}
						onDeleteChunk={handleDeleteChunk}
						onAddCitation={handleAddChunkAsCitation}
						onRemoveCitation={handleRemoveChunkAsCitation}
						onUpdateCitationColor={handleUpdateColorChunkAsCitation}
						chartColor={highlightColor}
						mode={chartMode ? DocumentChunkMode.CHART : DocumentChunkMode.DETAIL}
					/>
				)
			})
			.filter(Boolean)
			.reduce((acc, curr, index) => {
				return acc.concat(curr, <div key={`spacer-${index}`} className="h-4" />)
			}, [] as React.ReactNode[])
	}

	return (
		<div className="mb-4" ref={bodyRef}>
			{!chartMode && (
				<div className="flex items-center gap-2 mb-4">
					<Label>Body</Label>
					{editMode && (
						<Button variant="ghost" size="icon" onClick={handleAddChunk}>
							<Plus className="h-4 w-4" />
						</Button>
					)}
				</div>
			)}
			<div className="px-2">{body?.length > 0 ? renderBody() : null}</div>

			<EditDocumentChunkDialog
				chunk={
					{
						id: "",
						text: "",
						location: {
							pages: [null, null],
							paragraphs: [null, null],
							columns: [null, null],
							lines: [null, null],
							section: null,
						},
						figureUrls: [],
						// type: "text"
					} as DocumentChunk
				}
				open={isAddDialogOpen}
				onOpenChange={setIsAddDialogOpen}
				onSave={handleSaveNewChunk}
				figureUrls={figureUrls}
			/>
		</div>
	)
}

export default DocumentBody
