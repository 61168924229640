export enum PageName {
	// Project pages
	ProjectSubject = "PROJECT_SUBJECT",
	ProjectReferences = "PROJECT_REFERENCES",
	ProjectContext = "PROJECT_CONTEXT",
	ProjectCover = "PROJECT_COVER",
	ProjectCharts = "PROJECT_CHARTS",
	ProjectApplicationDocuments = "PROJECT_APPLICATION_DOCUMENTS",

	// Portfolio pages
	PortfolioOverview = "PORTFOLIO_OVERVIEW",
	PortfolioProjects = "PORTFOLIO_PROJECTS",
	PortfolioReferences = "PORTFOLIO_REFERENCES",
	PortfolioCover = "PORTFOLIO_COVER",

	AdminOverview = "ADMIN_OVERVIEW",
	AdminBilling = "ADMIN_BILLING",
	AdminUsers = "ADMIN_USERS",

	SuperAdminOverview = "SUPER_ADMIN_OVERVIEW",
	SuperAdminBilling = "SUPER_ADMIN_BILLING",
	SuperAdminUsers = "SUPER_ADMIN_USERS",

	Chat = "CHAT",
	Search = "SEARCH",

	Home = "HOME",
	ProjectCreate = "PROJECT_CREATE",
	Documents = "DOCUMENTS",
	Unauthorized = "UNAUTHORIZED",
	NotFound = "NOT_FOUND",
}

// Display names mapping
export const PAGE_DISPLAY_NAMES: Record<PageName, string> = {
	[PageName.ProjectSubject]: "Subject",
	[PageName.ProjectReferences]: "References",
	[PageName.ProjectContext]: "Context",
	[PageName.ProjectCover]: "Cover",
	[PageName.ProjectCharts]: "Charts",
	[PageName.ProjectApplicationDocuments]: "Application Documents",
	[PageName.PortfolioOverview]: "Overview",
	[PageName.PortfolioProjects]: "Projects",
	[PageName.PortfolioReferences]: "References",
	[PageName.PortfolioCover]: "Cover",

	[PageName.Chat]: "Chat",
	[PageName.Search]: "Search",

	[PageName.AdminOverview]: "Admin - Overview",
	[PageName.AdminBilling]: "Admin - Billing",
	[PageName.AdminUsers]: "Admin - Users",
	[PageName.SuperAdminOverview]: "Super Admin - Overview",
	[PageName.SuperAdminBilling]: "Super Admin - Billing",
	[PageName.SuperAdminUsers]: "Super Admin - Users",

	[PageName.Home]: "Home",
	[PageName.ProjectCreate]: "New Project",
	[PageName.Documents]: "Documents",
	[PageName.Unauthorized]: "Unauthorized",
	[PageName.NotFound]: "Not Found",
} as const

// Type helpers
export type Pages =
	| PageName.ProjectSubject
	| PageName.ProjectReferences
	| PageName.ProjectContext
	| PageName.ProjectCover
	| PageName.ProjectCharts
	| PageName.ProjectApplicationDocuments
	| PageName.Chat
	| PageName.Search
	| PageName.PortfolioOverview
	| PageName.PortfolioProjects
	| PageName.PortfolioReferences
	| PageName.PortfolioCover
	| PageName.AdminOverview
	| PageName.AdminBilling
	| PageName.AdminUsers
	| PageName.SuperAdminOverview
	| PageName.SuperAdminBilling
	| PageName.SuperAdminUsers
	| PageName.Home
	| PageName.ProjectCreate
	| PageName.Documents
	| PageName.Unauthorized
	| PageName.NotFound
