/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { SidebarPage } from "@/components"
import { H4 } from "@/components/ui/typography"
import { PageContentBox } from "@/styled"
import { PageName } from "@/types/pages"
import type React from "react"

/**
 * @description Renders the unauthorized page
 */
const UnauthorizedPage: React.FC = () => {
	return (
		<SidebarPage page={PageName.Unauthorized}>
			<PageContentBox>
				<div className="flex flex-1 flex-col justify-center items-center mt-2">
					<H4>You do not have access to this project.</H4>
				</div>
			</PageContentBox>
		</SidebarPage>
	)
}

export default UnauthorizedPage
