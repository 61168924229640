import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Label } from "@/components/ui/label"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { Variable } from "lucide-react"

// Add citation variables
const CITATION_VARIABLES = [
	{ id: "inventor", name: "Inventor Name" },
	{ id: "paragraph", name: "Paragraph" },
	{ id: "page", name: "Page" },
	{ id: "column", name: "Column" },
	{ id: "lines", name: "Lines" },
	{ id: "nickname", name: "Document Nickname" },
]

const FIGURE_VARIABLES = [
	{ id: "figure_number", name: "Figure Number" },
	{ id: "index", name: "Index" },
]

const HEADER_FOOTER_VARIABLES = [
	{ id: "reference_name", name: "Document Name" },
	{ id: "element_number", name: "Element Number" },
	{ id: "element_language", name: "Element Language" },
	{ id: "subject_nickname", name: "Subject Nickname" },
	{ id: "subject_number", name: "Subject Number" },
	{ id: "subject_priority_date", name: "Subject Priority Date" },
]

const TITLE_VARIABLES = [
	{ id: "subject_number", name: "Subject Number" },
	{ id: "nickname", name: "Subject Nickname" },
	{ id: "reference_number", name: "Reference Number" },
	{ id: "reference_nickname", name: "Reference Nickname" },
	{ id: "reference_publication_date", name: "Reference Publication Date" },
]

interface VariableInputProps {
	label: string
	value: string
	onChange: (value: string) => void
	type: "citation" | "figure" | "header_footer" | "title" | "introduction"
	placeholder?: string
}

const VariableInput: React.FC<VariableInputProps> = ({
	label,
	value,
	onChange,
	type,
	placeholder,
}) => {
	const variables =
		type === "citation"
			? CITATION_VARIABLES
			: type === "figure"
				? FIGURE_VARIABLES
				: type === "header_footer"
					? HEADER_FOOTER_VARIABLES
					: TITLE_VARIABLES
	return (
		<div className="space-y-1">
			<Label className="text-sm">{label}</Label>
			<div className="relative">
				<AutosizeTextarea
					value={value}
					onChange={(e) => onChange(e.target.value)}
					className="pr-12"
					placeholder={placeholder}
				/>
				<DropdownMenu>
					<Tooltip>
						<TooltipTrigger asChild>
							<DropdownMenuTrigger asChild>
								<Button
									type="button"
									size="icon"
									variant="ghost"
									className="h-8 w-8 absolute right-2 top-1"
								>
									<Variable className="h-5 w-5" />
								</Button>
							</DropdownMenuTrigger>
						</TooltipTrigger>
						<TooltipContent>Insert variable</TooltipContent>
					</Tooltip>
					<DropdownMenuContent>
						{variables.map((variable) => (
							<DropdownMenuItem
								key={variable.id}
								onClick={() => {
									onChange(`${value}{{${variable.id}}}`)
								}}
							>
								{variable.name}
							</DropdownMenuItem>
						))}
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
		</div>
	)
}

export default VariableInput
