import { api } from "@/api"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useAppStateStore } from "@/store"
import type { PortfolioMetadata } from "@/types"
import { MUTATION_KEYS, invalidateProjectNameRelatedQueries } from "@/utils"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useAuthInfo } from "@propelauth/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useApi, useProjectAndPortfolioIds } from "./"

/**
 * @description Hook for handling portfolio operations
 */
const usePortfolio = () => {
	const { handleError } = useApi()
	const queryClient = useQueryClient()
	const { portfolioId } = useProjectAndPortfolioIds()
	const { user } = useAuthInfo()

	// Original hook logic only runs when in portfolio context
	const { addSuccessMessage } = useAppStateStore()

	const portfolioMetadataQuery = useQuery({
		queryKey: ["portfolio", "metadata", portfolioId],
		queryFn: () => api.getPortfolioMetadata(portfolioId, true),
		enabled: !!portfolioId,
		...DEFAULT_QUERY_OPTIONS,
	})

	/**
	 * @description Updates portfolio details
	 */
	const updatePortfolioMutation = useMutation({
		mutationKey: MUTATION_KEYS.portfolio.update(),
		mutationFn: ({ portfolioId, options }: { portfolioId: string; options: any }) => {
			const payload: { [key: string]: any } = {
				portfolio_id: portfolioId,
			}

			// Append additional options to payload if they exist
			for (const key of Object.keys(options)) {
				if (options[key]) {
					payload[key] = options[key]
				}
			}

			return api.updatePortfolioDetails(portfolioId, payload)
		},
		onError: (error) => handleError(error, "Error updating portfolio metadata"),
		onSuccess: () => {
			addSuccessMessage("Portfolio updated successfully")
			queryClient.invalidateQueries({ queryKey: ["portfolio"] })
		},
	})

	/**
	 * @description Removes projects from portfolio
	 */
	const removeProjectsMutation = useMutation({
		mutationKey: MUTATION_KEYS.portfolio.removeProjects(),
		mutationFn: async ({
			portfolioId,
			projectIds,
		}: {
			portfolioId: string
			projectIds: string[]
		}) => {
			return api.removeProjectsFromPortfolio(portfolioId, projectIds)
		},
		onError: (error) => handleError(error, "Error removing patents from portfolio"),
		onSuccess: () => {
			addSuccessMessage("Projects removed successfully")
			queryClient.invalidateQueries({ queryKey: ["portfolio"] })
		},
	})

	/**
	 * @description Gets portfolio matrix
	 */
	const portfolioMatrixQuery = useQuery({
		queryKey: ["portfolio", "matrix", portfolioId],
		queryFn: () => api.getPortfolioMatrix(portfolioId, true),
		enabled: !!portfolioId,
		...DEFAULT_QUERY_OPTIONS,
	})

	/**
	 * @description Gets portfolio references
	 */
	const getPortfolioDocumentsQuery = useQuery({
		queryKey: ["portfolio", "documents", portfolioId],
		queryFn: async () => {
			if (!portfolioId) {
				throw new Error("Portfolio ID is required")
			}
			return api.getPortfolioPriorArtRows(portfolioId)
		},
		enabled: !!portfolioId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const renamePortfolioMutation = useMutation({
		mutationKey: MUTATION_KEYS.portfolio.rename(),
		mutationFn: ({ name }: { name: string }) => {
			const payload = {
				portfolio_id: portfolioId,
				name: name,
			}
			return api.updatePortfolioDetails(portfolioId, payload)
		},
		onMutate: async ({ name }) => {
			// Cancel any outgoing refetches
			await queryClient.cancelQueries({
				queryKey: ["portfolio", "metadata", portfolioId],
			})

			// Snapshot the previous value
			const previousMetadata = queryClient.getQueryData([
				"portfolio",
				"metadata",
				portfolioId,
			])

			// Optimistically update the metadata
			queryClient.setQueryData(
				["portfolio", "metadata", portfolioId],
				(old: PortfolioMetadata) => (old ? { ...old, name } : old),
			)

			// Return context with the snapshotted value
			return { previousMetadata }
		},
		onError: (error, variables, context) => {
			// Revert to the previous value on error
			if (context?.previousMetadata) {
				queryClient.setQueryData(
					["portfolio", "metadata", portfolioId],
					context.previousMetadata,
				)
			}
			handleError(error, "Error updating portfolio name")
		},
		onSuccess: () => {
			invalidateProjectNameRelatedQueries(queryClient, portfolioId)
		},
	})

	const subjectIds = portfolioMetadataQuery.data?.projects?.map(
		(project) => project.subjectId,
	)
	const portfolioReferenceDocuments = getPortfolioDocumentsQuery.data?.filter(
		(document) => !subjectIds?.includes(document.document.id),
	)

	const isPortfolioOwner = portfolioMetadataQuery.data?.createdBy === user?.userId

	return {
		// Query data
		portfolio: portfolioMetadataQuery.data,
		portfolioMatrix: portfolioMatrixQuery.data,
		portfolioDocuments: getPortfolioDocumentsQuery.data,

		// Loading states
		isLoading: {
			portfolio: portfolioMetadataQuery.isLoading,
			portfolioMatrix: portfolioMatrixQuery.isLoading,
			portfolioDocuments: getPortfolioDocumentsQuery.isLoading,
		},

		// Pending states for mutations
		isPending: {
			updatePortfolio: updatePortfolioMutation.isPending,
			removeProjects: removeProjectsMutation.isPending,
			renamePortfolio: renamePortfolioMutation.isPending,
		},

		// Fetching states
		isFetching: {
			portfolio: portfolioMetadataQuery.isFetching,
			portfolioMatrix: portfolioMatrixQuery.isFetching,
			portfolioDocuments: getPortfolioDocumentsQuery.isFetching,
		},

		// Mutations
		updatePortfolioDetails: updatePortfolioMutation.mutateAsync,
		removeProjectsFromPortfolio: removeProjectsMutation.mutateAsync,
		getPortfolioMatrix: portfolioMatrixQuery.refetch,
		getPortfolioDocuments: getPortfolioDocumentsQuery.refetch,
		getPortfolioMetadata: portfolioMetadataQuery.refetch,

		renamePortfolio: renamePortfolioMutation.mutateAsync,
		portfolioId,
		portfolioName: portfolioMetadataQuery.data?.name,
		portfolioType: portfolioMetadataQuery.data?.type,
		portfolioReferenceDocuments,
		projects: portfolioMetadataQuery.data?.projects,
		isPortfolioOwner,
	}
}

export default usePortfolio
