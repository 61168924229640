/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { OpenInNewWindowIcon } from "@radix-ui/react-icons"
import type React from "react"
import { Button } from "../../ui/button"
/**
 * @description Source number button component
 * @param {string} sourceNumber - The source number
 */
interface OpenInGooglePatentButtonProps {
	sourceNumber: string
	prefix?: string
	link?: string
}

const OpenInGooglePatentButton: React.FC<OpenInGooglePatentButtonProps> = ({
	sourceNumber,
	link = "https://patents.google.com/patent/",
	prefix = "",
}) => {
	// Return null if sourceNumber is not provided or exceeds 20 characters
	if (!sourceNumber || sourceNumber.length > 20) {
		return null
	}

	// Check if sourceNumber already includes the prefix
	let finalString = sourceNumber

	if (!sourceNumber.startsWith(link)) {
		if (!sourceNumber.startsWith(prefix)) {
			finalString = link + prefix + sourceNumber
		} else {
			finalString = link + sourceNumber
		}
	}

	return (
		<Button
			variant="ghost"
			size="icon"
			onClick={() => window.open(finalString, "_blank")}
			className="h-4 w-4"
		>
			<OpenInNewWindowIcon />
		</Button>
	)
}

export default OpenInGooglePatentButton
