import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbPage,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import type React from "react"
import { memo } from "react"

interface DocumentsBreadcrumbProps {
	onBackToDocuments: () => void
	documentType: string
}

const DocumentsBreadcrumb: React.FC<DocumentsBreadcrumbProps> = memo(
	({ onBackToDocuments, documentType }) => {
		return (
			<div className="flex items-center justify-between pt-1 pb-2 px-1 w-full">
				<Breadcrumb className="py-1.5">
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbLink onClick={onBackToDocuments} className="text-sm font-medium">
								Documents
							</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbSeparator />
						<BreadcrumbItem>
							<BreadcrumbPage className="text-sm font-medium">{documentType}</BreadcrumbPage>
						</BreadcrumbItem>
					</BreadcrumbList>
				</Breadcrumb>
			</div>
		)
	},
)

DocumentsBreadcrumb.displayName = "DocumentsBreadcrumb"

export default DocumentsBreadcrumb
