import { Card, CardContent, CardHeader } from "@/components/ui/card"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { H4 } from "@/components/ui/typography"
import type { PortfolioSummary, ProjectSummary } from "@/types"
import { ChevronDown, ChevronRight } from "lucide-react"
import React, { useState } from "react"

function UsageLogTable({ projects }: { projects: ProjectSummary[] }) {
	const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set())

	const toggleRow = (id: string) => {
		setExpandedRows((prev) => {
			const next = new Set(prev)
			if (next.has(id)) {
				next.delete(id)
			} else {
				next.add(id)
			}
			return next
		})
	}

	const renderRow = (project: ProjectSummary | PortfolioSummary, level = 0) => {
		const isExpanded = expandedRows.has(project.id)
		const hasChildren = "documentIds" in project && project.documentIds.length > 0

		return (
			<React.Fragment key={project.id}>
				<TableRow>
					<TableCellCondensed style={{ paddingLeft: `${level * 20}px` }}>
						{hasChildren && (
							<button onClick={() => toggleRow(project.id)}>
								{isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
							</button>
						)}
					</TableCellCondensed>
					{/* <TableCellCondensed>
            <Badge variant="outline">
              {project.type === "INV"
                ? "Invalidity"
                : project.type === "APP"
                  ? "Application"
                  : project.type}
            </Badge>
          </TableCellCondensed>
          <TableCellCondensed>
            <Badge variant="outline">{project.type}</Badge>
          </TableCellCondensed> */}
					{/* <TableCellCondensed>{project.created_by}</TableCellCondensed> */}
					<TableCellCondensed>
						{new Date(project.createdAt).toLocaleString(undefined, {
							year: "numeric",
							month: "numeric",
							day: "numeric",
							hour: "2-digit",
							minute: "2-digit",
						})}
					</TableCellCondensed>
					{/* <TableCellCondensed>
            {new Date(project.updated_at).toLocaleString(undefined, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </TableCellCondensed> */}
					{/* <TableCellCondensed>{project.created_by}</TableCellCondensed> */}
					<TableCellCondensed>{project.numDocsAdded}</TableCellCondensed>
					{/* <TableCellCondensed>{project.documentIds.length}</TableCellCondensed> */}
				</TableRow>
				{/* {isExpanded &&
          hasChildren &&
          project.children!.map((child) => renderRow(child, level + 1))} */}
			</React.Fragment>
		)
	}

	return (
		<Card>
			<CardHeader>
				<H4>Usage</H4>
			</CardHeader>
			<CardContent>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead />
							<TableHead>Type</TableHead>
							<TableHead>Status</TableHead>
							<TableHead>Created By</TableHead>
							<TableHead>Created At</TableHead>
							<TableHead>Updated At</TableHead>
							<TableHead>Users</TableHead>
							<TableHead>Documents Processed</TableHead>
							<TableHead>Documents Exported</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{Array.isArray(projects)
							? projects.map((project) => renderRow(project))
							: renderRow(projects)}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	)
}

export default UsageLogTable
