/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"
import type React from "react"

interface SidebarMenuItemProps {
	icon: React.ElementType
	onClick: () => void
	isActive: boolean
	label: string
}

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
	icon: Icon,
	onClick,
	isActive,
	label,
}) => {
	return (
		<Tooltip>
			<TooltipTrigger asChild>
				<Button
					variant="ghost"
					size="icon"
					onClick={onClick}
					className={cn(
						"justify-center",

						isActive && "bg-accent",
					)}
				>
					<Icon className={cn("h-5 w-5")} />
				</Button>
			</TooltipTrigger>
			<TooltipContent side="right" sideOffset={5}>
				{label}
			</TooltipContent>
		</Tooltip>
	)
}

export default SidebarMenuItem
