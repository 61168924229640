import { api } from "@/api"
import { useProjectStore } from "@/store"
import type React from "react"
import { useEffect, useState } from "react"
import { Navigate, Outlet, useParams } from "react-router-dom"

export const PortfolioRoute: React.FC = () => {
	const { portfolioId } = useParams<{
		portfolioId: string
	}>()
	const { resetProjectStore } = useProjectStore()
	const [isLoading, setIsLoading] = useState(true)
	const [hasAccess, setHasAccess] = useState(false)

	useEffect(() => {
		let isMounted = true

		async function checkAccess() {
			try {
				if (!portfolioId) {
					setIsLoading(false)
					return
				}
				const hasPermission = await api.getUserPortfolioAccessCheck(portfolioId)
				if (isMounted) {
					setHasAccess(hasPermission)
					setIsLoading(false)
				}
			} catch (error) {
				if (process.env.NODE_ENV !== "production") {
					console.error("Error checking access:", error)
				}
				if (isMounted) {
					setHasAccess(false)
					setIsLoading(false)
				}
			}
		}

		checkAccess()
		return () => {
			isMounted = false
		}
	}, [portfolioId])

	if (isLoading) {
		return null
	}

	if (!hasAccess) {
		return <Navigate to={`/portfolio/${portfolioId}/unauthorized`} replace />
	}

	return <Outlet />
}
