/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { AddDocumentsModal, AddPatentsModal, DocumentMetadataTable } from "@/components"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { H4 } from "@/components/ui/typography"
import {
	useDocument,
	usePortfolio,
	usePortfolioDocuments,
	useProjectAndPortfolioIds,
	useProjectDocuments,
} from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import useProcessDocuments from "@/hooks/useProcessDocuments"
import { useAppStateStore, useAreProcessesPending } from "@/store"
import {
	type CitationPolicy,
	DocumentStatus,
	ProjectType,
	conflictingReferenceProcesses,
} from "@/types"
import type { ParentDocumentMetadata } from "@/types"
import { PlusIcon } from "lucide-react"
import type React from "react"
import { useCallback, useMemo, useState } from "react"
import {
	DismissDocumentStatusModal,
	PatchReferencesModal,
	ProcessReferencesModal,
	PruneReferencesModal,
	ReferencesTableActions,
	SearchReferencesModal,
} from "./"

interface ReferencesTableProps {
	inUserDocuments?: boolean
	documents: ParentDocumentMetadata[]
	title?: string
	onRowClick?: (document: ParentDocumentMetadata) => void
}

/**
 * @description Reference details page for invalidity project
 */
const ReferencesTable: React.FC<ReferencesTableProps> = ({
	inUserDocuments = false,
	title,
	onRowClick,
	documents,
}) => {
	const { updateDocumentMetadata, updateDocuments } = useDocument()
	const { reprocessDocuments } = useProcessDocuments()
	const { getDocumentName } = useDocumentNaming()

	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const { deleteDocuments: deleteProjectDocuments } = useProjectDocuments(projectId)
	const { deleteDocuments: deletePortfolioDocuments } =
		usePortfolioDocuments(portfolioId)
	usePortfolioDocuments(portfolioId)
	const { portfolioType } = usePortfolio()
	const { addErrorMessage } = useAppStateStore()

	// Block adding documents if recharting or downloading chart is in progress
	const isReferenceDisabled = useAreProcessesPending(
		conflictingReferenceProcesses,
		projectId || null,
		portfolioId || null,
	)

	// Local state setup
	const [showAddModal, setShowAddModal] = useState(false)
	const [showAddClaimsModal, setShowAddClaimsModal] = useState(false)
	const [showEditClaimsModal, setShowEditClaimsModal] = useState(false)
	const [showProcessReferencesModal, setShowProcessReferencesModal] = useState(false)
	const [showUploadModal, setShowUploadModal] = useState(false)
	const [showSearchModal, setShowSearchModal] = useState(false)
	const [showPatchReferencesModal, setShowPatchReferencesModal] = useState(false)
	const [showPruneModal, setShowPruneModal] = useState(false)
	const [showDismissStatusesModal, setShowDismissStatusesModal] = useState(false)
	const [selectedReferencesToDismiss, setSelectedReferencesToDismiss] = useState<
		ParentDocumentMetadata[]
	>([])
	const [selectedReferences, setSelectedReferences] = useState<ParentDocumentMetadata[]>(
		[],
	)
	const [selectedReferencesToReprocess, setSelectedReferencesToReprocess] = useState<
		ParentDocumentMetadata[]
	>([])
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [isDeleteSelectedLoading, setIsDeleteSelectedLoading] = useState(false)
	const [isReprocess, setIsReprocess] = useState<boolean>(false)

	// Handle deletions
	const handleDeleteSelected = useCallback(async () => {
		setIsDeleteSelectedLoading(true)
		const referenceIds = selectedReferences.map((document) => document.documentId)

		try {
			await (projectId ? deleteProjectDocuments : deletePortfolioDocuments)(referenceIds)
		} catch (_error) {
			addErrorMessage(
				"An unexpected error occurred while deleting documents. Please try again.",
			)
		} finally {
			setIsDeleteSelectedLoading(false)
		}
	}, [
		projectId,
		portfolioId,
		selectedReferences,
		deleteProjectDocuments,
		deletePortfolioDocuments,
	])

	const handleRemovePriorArtFromProject = useCallback(
		async (document: ParentDocumentMetadata) => {
			const refId = document.documentId
			if (refId) {
				await (projectId ? deleteProjectDocuments : deletePortfolioDocuments)([refId])
			}
		},
		[projectId, portfolioId, documents],
	)

	const handleSearchReferencesClick = useCallback(() => {
		setShowSearchModal(true)
	}, [])

	const handlePatchReferencesClick = useCallback(() => {
		setShowPatchReferencesModal((prev) => !prev)
	}, [])

	const handlePruneReferencesClick = useCallback(() => {
		setShowPruneModal((prev) => !prev)
	}, [])

	const handleAddPublishedPatentsClick = useCallback(() => {
		setShowAddModal((prev) => !prev)
	}, [])

	const handleAddOtherDocumentsClick = useCallback(() => {
		setShowUploadModal((prev) => !prev)
	}, [])

	const handleDismissReferenceStatuses = useCallback(async () => {
		try {
			const selectedIds = selectedReferencesToDismiss.map(
				(document) => document.documentId,
			)
			await updateDocuments({
				documentIds: selectedIds,
				status: DocumentStatus.PROCESSED,
			})
			setSelectedReferencesToReprocess([])
			setSelectedReferences([])
			setShowDismissStatusesModal(false)
		} catch (_error) {
			addErrorMessage("Failed to update document statuses")
		}
	}, [selectedReferencesToDismiss, updateDocuments, addErrorMessage])

	const handleReprocessReferences = useCallback(
		async (mode: CitationPolicy) => {
			setShowProcessReferencesModal(false)
			await reprocessDocuments(selectedReferencesToReprocess, isReprocess, mode)
			setSelectedReferencesToReprocess([])
			setSelectedReferences([])
		},
		[selectedReferencesToReprocess, reprocessDocuments, isReprocess],
	)

	const handleTagUpdate = useCallback(
		async (
			selectedRows: ParentDocumentMetadata[],
			addTags: string[] | null,
			setTags: string[] | null,
		) => {
			try {
				if (addTags || setTags) {
					const referenceIds = selectedRows.map((row) => row.documentId)
					await updateDocumentMetadata({
						documentIds: referenceIds,
						addTags,
						setTags,
					})
				}
			} catch (_error) {
				addErrorMessage("Failed to update tags")
			}
		},
		[updateDocumentMetadata, addErrorMessage],
	)

	const handleSaveName = useCallback(
		async (document: ParentDocumentMetadata, newName: string, isNickname = true) => {
			try {
				if (isNickname) {
					await updateDocumentMetadata({
						documentIds: [document.documentId],
						nickname: newName,
					})
				} else {
					await updateDocuments({
						documentIds: [document.documentId],
						title: newName,
					})
				}
			} catch (error) {
				addErrorMessage(
					typeof error === "string"
						? error
						: `Failed to update ${isNickname ? "name" : "title"}`,
				)
			}
		},
		[updateDocumentMetadata, addErrorMessage],
	)

	const handleSaveNote = useCallback(
		async (document: ParentDocumentMetadata, newNote: string) => {
			try {
				await updateDocumentMetadata({
					documentIds: [document.documentId],
					notes: newNote,
				})
			} catch (_error) {
				addErrorMessage("Failed to save note")
			}
		},
		[updateDocumentMetadata, addErrorMessage],
	)

	const handleReprocessClick = useCallback(
		(selectedRows: ParentDocumentMetadata[], isReprocess = true) => {
			setSelectedReferencesToReprocess(selectedRows)
			setIsReprocess(isReprocess)
			setShowProcessReferencesModal(true)
		},
		[],
	)

	const handleDismissSelected = () => {
		setSelectedReferencesToDismiss(
			selectedReferences.filter(
				(ref) =>
					ref.document?.status === DocumentStatus.RECHART ||
					ref.document?.status === DocumentStatus.REPROCESS,
			),
		)
		setShowDismissStatusesModal(true)
	}

	const handleRowSelection = useCallback((selectedRows: ParentDocumentMetadata[]) => {
		setSelectedReferences(selectedRows)
	}, [])

	const documentNames = useMemo(
		() => selectedReferencesToReprocess?.map((ref) => getDocumentName(ref.documentId)),
		[selectedReferencesToReprocess],
	)

	const isStandardsPortfolio = portfolioType === ProjectType.SEP

	return (
		<>
			{documents?.length > 0 ? (
				<DocumentMetadataTable
					documents={documents}
					onDeleteRow={handleRemovePriorArtFromProject}
					height="calc(100vh - 100px)"
					onRowSelection={handleRowSelection}
					onTagUpdate={handleTagUpdate}
					onDeleteSelected={handleDeleteSelected}
					onSaveName={handleSaveName}
					onSaveNote={handleSaveNote}
					onReprocessSelected={handleReprocessClick}
					onDismissSelected={handleDismissSelected}
					enableRowDelete={true}
					isSearch={false}
					isReference={true}
					isDeleteSelectedLoading={isDeleteSelectedLoading}
					title={title}
					inUserDocuments={inUserDocuments}
					onRowClick={onRowClick}
				>
					{!isStandardsPortfolio && !inUserDocuments && (
						<ReferencesTableActions
							isStandardsPortfolio={isStandardsPortfolio}
							isReferenceDisabled={isReferenceDisabled}
							references={documents}
							onPatch={handlePatchReferencesClick}
							onPrune={handlePruneReferencesClick}
							onSearch={handleSearchReferencesClick}
						/>
					)}
					<DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
						<DropdownMenuTrigger asChild>
							<Button
								className="h-9"
								disabled={isReferenceDisabled}
								onClick={() => setDropdownOpen(true)}
							>
								<span className="hidden lg:inline">Add</span>
								<PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							<DropdownMenuItem
								onClick={() => {
									handleAddPublishedPatentsClick()
									setDropdownOpen(false)
								}}
							>
								Add published patents/applications
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={() => {
									handleAddOtherDocumentsClick()
									setDropdownOpen(false)
								}}
							>
								Upload other documents
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</DocumentMetadataTable>
			) : (
				<div className="flex justify-center items-center flex-col mt-12">
					<H4 className="text-center">This project has no references.</H4>
					<div className="flex gap-2 mt-3">
						<Button
							variant="outline"
							onClick={handleSearchReferencesClick}
							disabled={isReferenceDisabled}
							className="h-9"
						>
							Search
						</Button>
						<Button
							variant="outline"
							onClick={handleAddPublishedPatentsClick}
							disabled={isReferenceDisabled}
							className="h-9"
						>
							Add by Number
						</Button>
						<Button
							variant="outline"
							onClick={handleAddOtherDocumentsClick}
							disabled={isReferenceDisabled}
							className="h-9"
						>
							Upload Files
						</Button>
					</div>
				</div>
			)}

			<AddPatentsModal open={showAddModal} handleClose={handleAddPublishedPatentsClick} />
			<SearchReferencesModal
				open={showSearchModal}
				handleClose={() => setShowSearchModal(false)}
			/>
			<AddDocumentsModal
				open={showUploadModal}
				handleClose={() => setShowUploadModal(false)}
			/>
			<PatchReferencesModal
				open={showPatchReferencesModal}
				handleClose={handlePatchReferencesClick}
			/>
			<PruneReferencesModal open={showPruneModal} onOpenChange={setShowPruneModal} />

			<Dialog open={showAddClaimsModal} onOpenChange={setShowAddClaimsModal}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Add claims to search</DialogTitle>
						<DialogDescription>
							You must add claims to search for prior art.
						</DialogDescription>
					</DialogHeader>
					<DialogFooter>
						<Button variant="outline" onClick={() => setShowAddClaimsModal(false)}>
							Cancel
						</Button>
						<Button
							variant="default"
							onClick={() => {
								setShowAddClaimsModal(false)
								setShowEditClaimsModal(true)
							}}
						>
							Add Claims
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			{/* <UpdateClaimsModal
        open={showEditClaimsModal}
        onClose={() => setShowEditClaimsModal(false)}
      /> */}
			<ProcessReferencesModal
				isOpen={showProcessReferencesModal}
				onOpenChange={setShowProcessReferencesModal}
				onConfirm={handleReprocessReferences}
				documentNames={documentNames}
				isReprocess={isReprocess}
			/>
			<DismissDocumentStatusModal
				isOpen={showDismissStatusesModal}
				onOpenChange={setShowDismissStatusesModal}
				onConfirm={handleDismissReferenceStatuses}
				documentNames={selectedReferencesToDismiss
					.filter(
						(document) =>
							document.document?.status === DocumentStatus.RECHART ||
							document.document?.status === DocumentStatus.REPROCESS,
					)
					.map((document) => getDocumentName(document.documentId))}
			/>
		</>
	)
}

export default ReferencesTable
