import { CheckIcon } from "@radix-ui/react-icons"

import { Button } from "@/components/ui/button"
import { DateTimePicker } from "@/components/ui/datetime-picker"
import { useProject } from "@/hooks"
import { useProjectAndPortfolioIds } from "@/hooks"
import { convertToUtcDateTimeString, getUtcDateString } from "@/utils/dateUtils"
import { Pencil1Icon } from "@radix-ui/react-icons"
import { DateTime } from "luxon"
import { useState } from "react"

interface DocumentDatesProps {
	documentType: string
	publicationDate: string
	priorityDate: string
	enableEditPriorityDate?: boolean
}

const DocumentDates: React.FC<DocumentDatesProps> = ({
	publicationDate,
	priorityDate,
	enableEditPriorityDate = false,
}) => {
	const { updateProjectDetails } = useProject()
	const { projectId } = useProjectAndPortfolioIds()
	const [isEditingPriorityDate, setIsEditingPriorityDate] = useState(false)

	const [priorityDateEdited, setPriorityDateEdited] = useState<DateTime>(() => {
		if (priorityDate) {
			return DateTime.isDateTime(priorityDate)
				? priorityDate
				: DateTime.fromISO(priorityDate, { zone: "utc" })
		}
	})

	const handleSavePriorityDate = () => {
		try {
			const utcPriorityDate = DateTime.utc(
				priorityDateEdited.year,
				priorityDateEdited.month,
				priorityDateEdited.day,
			)

			updateProjectDetails({
				projectId: projectId,
				options: {
					priority_date: utcPriorityDate.toISO(),
				},
			})
		} catch (error) {
			console.error(error)
		}
		setIsEditingPriorityDate(false)
	}

	const handleEditPriorityDate = () => {
		setIsEditingPriorityDate(true)
	}

	return (
		<div className="flex gap-4">
			<div className="flex items-center gap-2">
				<span className="text-gray-500 text-sm">Publication Date</span>
				<span className="text-gray-900 text-sm">
					{publicationDate ? getUtcDateString(publicationDate) : "—"}
				</span>
				<span className="text-gray-400">•</span>
			</div>

			<div className="flex items-center gap-2">
				<span className="text-gray-500 text-sm">Priority Date</span>
				{isEditingPriorityDate ? (
					<DateTimePicker
						value={
							priorityDate ? DateTime.fromISO(priorityDate, { zone: "utc" }).toJSDate() : null
						}
						granularity="day"
						onChange={(date) => {
							if (date) {
								const utcDate = convertToUtcDateTimeString(date)
								setPriorityDateEdited(DateTime.fromISO(utcDate))
							} else {
								setPriorityDateEdited(null)
							}
						}}
					/>
				) : (
					<span className="text-gray-900 text-sm">
						{priorityDate ? getUtcDateString(priorityDate) : "—"}
					</span>
				)}

				{enableEditPriorityDate && (
					<Button
						size="icon"
						variant="ghost"
						onClick={isEditingPriorityDate ? handleSavePriorityDate : handleEditPriorityDate}
					>
						{isEditingPriorityDate ? <CheckIcon /> : <Pencil1Icon />}
					</Button>
				)}
			</div>
		</div>
	)
}

export default DocumentDates
