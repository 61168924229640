import { chartsApi } from "@/api/chartsApi"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useQuery } from "@tanstack/react-query"

interface SummaryChartSelection {
	data: any[] // each of these objects has shape { claim_number, color1, color2, ... }
	summaryChartHeaders: string[]
	summaryChartRowHeightCollapsed: number
}

/**
 * Use this hook to fetch and prepare summary-chart data
 */
export const useInvaliditySummaryData = (
	projectId: string,
	assertedClaimsOnly?: boolean,
) => {
	return useQuery({
		queryKey: QUERY_KEYS.invalidity.summaryData(projectId),
		queryFn: async () => {
			if (!projectId) {
				throw new Error("Project ID is required")
			}
			const response = await chartsApi.getSummaryChartData(projectId, assertedClaimsOnly)
			return response
		},
		enabled: Boolean(projectId),

		select: (response: any[]): SummaryChartSelection => {
			if (!response || response.length === 0) {
				return {
					data: [],
					summaryChartHeaders: [],
					summaryChartRowHeightCollapsed: 0,
				}
			}

			// For simplicity, deduce the column headers from the first row
			const uniqueColumnHeaders = Object.keys(response[0].documentColors)

			// Simple formula for a default row-height
			const totalContainerHeight = 250 - 60
			const numRows = response.length
			const rowHeightCollapsed = numRows > 0 ? totalContainerHeight / numRows : 0

			return {
				data: response,
				summaryChartHeaders: uniqueColumnHeaders,
				summaryChartRowHeightCollapsed: rowHeightCollapsed,
			}
		},
		...DEFAULT_QUERY_OPTIONS,
	})
}

export default useInvaliditySummaryData
