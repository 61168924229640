/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { InfoPopover } from "@/components"
import VariableInput from "@/components/input/VariableInput"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { LoadingButton } from "@/components/ui/loading-button"
import { RadioGroup, RadioGroupItemWithText } from "@/components/ui/radio-group"
import { useInvaliditySettings } from "@/hooks"
import { ClaimNumberStyle, FigurePlacement, type InvaliditySettings } from "@/types"
import { Loader2 } from "lucide-react"
import type React from "react"
import { useEffect, useState } from "react"
import SettingsSection from "../SettingsSection"

interface InvaliditySettingsModalProps {
	open: boolean
	handleClose: () => void
}

/**
 * @description Share project modal
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {string} projectTitle - The title of the project.
 */
const InvaliditySettingsModal: React.FC<InvaliditySettingsModalProps> = ({
	open,
	handleClose,
}) => {
	const { settings, updateSettings } = useInvaliditySettings()

	// Initialize state from settings
	const [selectedColors, setSelectedColors] = useState<string[]>(
		settings?.citationColors || [],
	)
	const [introductionLanguage, setIntroductionLanguage] = useState(
		settings?.wordIntroductionLanguage || "",
	)
	const [isCitationBefore, setIsCitationBefore] = useState(
		settings?.isCitationBefore ?? true,
	)
	const [patentCitationFormat, setPatentCitationFormat] = useState(
		settings?.usPatentCitationFormat || "",
	)
	const [applicationCitationFormat, setApplicationCitationFormat] = useState(
		settings?.usApplicationCitationFormat || "",
	)
	const [documentCitationFormat, setDocumentCitationFormat] = useState(
		settings?.uploadedDocumentCitationFormat || "",
	)
	const [foreignDocumentCitationFormat, setForeignDocumentCitationFormat] = useState(
		settings?.nonUsPatentCitationFormat || "",
	)
	const [figurePlacement, setFigurePlacement] = useState<FigurePlacement>(
		settings?.figurePlacement || FigurePlacement.GROUPED,
	)
	const [claimNumberStyle, setClaimNumberStyle] = useState<ClaimNumberStyle>(
		settings?.claimNumberStyle || ClaimNumberStyle.DECIMAL,
	)
	const [header, setHeader] = useState(settings?.headerFormat || "")
	const [footer, setFooter] = useState(settings?.footerFormat || "")
	const [defaultToAssertedClaims, setDefaultToAssertedClaims] = useState(
		settings?.defaultToAssertedClaims || false,
	)
	const [titleFormat, setTitleFormat] = useState(
		settings?.wordClaimChartTitleFormat || "",
	)
	const [isLoading, _setIsLoading] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [collapsedClaimDepth, setCollapsedClaimDepth] = useState(
		settings?.collapsedClaimDepth || 0,
	)
	const [_originalClaimDepth, setOriginalClaimDepth] = useState(
		settings?.collapsedClaimDepth || 0,
	)

	// Use useEffect to update state when settings change
	useEffect(() => {
		setSelectedColors(settings?.citationColors || [])
		setIntroductionLanguage(settings?.wordIntroductionLanguage || "")
		setIsCitationBefore(settings?.isCitationBefore ?? true)
		setPatentCitationFormat(settings?.usPatentCitationFormat || "")
		setApplicationCitationFormat(settings?.usApplicationCitationFormat || "")
		setDocumentCitationFormat(settings?.uploadedDocumentCitationFormat || "")
		setForeignDocumentCitationFormat(settings?.nonUsPatentCitationFormat || "")
		setFigurePlacement(settings?.figurePlacement || FigurePlacement.GROUPED)
		setClaimNumberStyle(settings?.claimNumberStyle || ClaimNumberStyle.DECIMAL)
		setHeader(settings?.headerFormat || "")
		setFooter(settings?.footerFormat || "")
		setDefaultToAssertedClaims(settings?.defaultToAssertedClaims || false)
		setTitleFormat(settings?.wordClaimChartTitleFormat || "")
		setCollapsedClaimDepth(settings?.collapsedClaimDepth || 0)
		setOriginalClaimDepth(settings?.collapsedClaimDepth || 0)
	}, [settings])

	const handleModalClose = () => {
		// Reset all state values to match settings
		setSelectedColors(settings?.citationColors || [])
		setIntroductionLanguage(settings?.wordIntroductionLanguage || "")
		setIsCitationBefore(settings?.isCitationBefore ?? true)
		setPatentCitationFormat(settings?.usPatentCitationFormat || "")
		setApplicationCitationFormat(settings?.usApplicationCitationFormat || "")
		setDocumentCitationFormat(settings?.uploadedDocumentCitationFormat || "")
		setForeignDocumentCitationFormat(settings?.nonUsPatentCitationFormat || "")
		setFigurePlacement(settings?.figurePlacement || FigurePlacement.GROUPED)
		setClaimNumberStyle(settings?.claimNumberStyle || ClaimNumberStyle.DECIMAL)
		setHeader(settings?.headerFormat || "")
		setFooter(settings?.footerFormat || "")
		setDefaultToAssertedClaims(settings?.defaultToAssertedClaims || false)
		setTitleFormat(settings?.wordClaimChartTitleFormat || "")
		setCollapsedClaimDepth(settings?.collapsedClaimDepth || 0)
		setOriginalClaimDepth(settings?.collapsedClaimDepth || 0)

		handleClose()
	}

	const handleSave = async () => {
		setIsSaving(true)
		const newSettings: Partial<InvaliditySettings> = Object.fromEntries(
			Object.entries({
				headerFormat: header,
				footerFormat: footer,
				usPatentCitationFormat: patentCitationFormat,
				nonUsPatentCitationFormat: foreignDocumentCitationFormat,
				usApplicationCitationFormat: applicationCitationFormat,
				uploadedDocumentCitationFormat: documentCitationFormat,
				defaultToAssertedClaims: defaultToAssertedClaims,
				isCitationBefore: isCitationBefore,
				figurePlacement: figurePlacement,
				wordIntroductionLanguage: introductionLanguage,
				citationColors: selectedColors,
				wordClaimChartTitleFormat: titleFormat,
				collapsedClaimDepth: collapsedClaimDepth,
				claimNumberStyle: claimNumberStyle,
			}).filter(([_, value]) => value !== null && value !== ""),
		)

		try {
			await updateSettings(newSettings as InvaliditySettings)
			handleModalClose()
		} finally {
			setIsSaving(false)
		}
	}

	const collapseClaimDepthContent = (
		<div>
			<p className="text-sm">
				The depth of claims to combine in the invalidity cells. For example, if the subject
				has claims ["1", "1.1", "1.1.1", "1.2", "2", "3"], and depth is 1, then all
				sub-claims will be combined into the 1st level, so the claims will be displayed as
				["1", "2", "3"]. If the depth is 2, the claims will be displayed as ["1", "1.1",
				"1.2", "2", "3"]. If no depth is specified, all claims will be displayed
				individually.
			</p>
		</div>
	)

	return (
		<Dialog open={open} onOpenChange={handleModalClose}>
			<DialogContent className="sm:max-w-[1200px] flex flex-col max-h-[90vh]">
				<DialogHeader>
					<DialogTitle>Project settings</DialogTitle>
				</DialogHeader>

				{isLoading ? (
					<div className="flex-1 flex items-center justify-center">
						<Loader2 className="h-8 w-8 animate-spin" />
					</div>
				) : (
					<div className="grid gap-4 py-2 overflow-y-scroll flex-1 always-show-scrollbar !pr-2">
						{/* Claims Section */}
						<SettingsSection
							label="Claim settings"
							description="Default claims and collapsed claim depth displayed in charts and exports"
						>
							<div className="grid grid-cols-3 gap-4">
								<div>
									<Label className="text-sm">Default claims</Label>
									<RadioGroup
										value={defaultToAssertedClaims ? "asserted" : "all"}
										onValueChange={(value) => setDefaultToAssertedClaims(value === "asserted")}
										className="flex gap-4"
									>
										<div className="flex items-center space-x-2">
											<RadioGroupItemWithText value="asserted" id="asserted" label="Asserted" />
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItemWithText value="all" id="all" label="All" />
										</div>
									</RadioGroup>
								</div>
								<div>
									<Label className="text-sm">Claim number style</Label>
									<RadioGroup
										value={claimNumberStyle}
										onValueChange={(value) => setClaimNumberStyle(value as ClaimNumberStyle)}
										className="flex gap-4"
									>
										<div className="flex items-center space-x-2">
											<RadioGroupItemWithText
												value={ClaimNumberStyle.DECIMAL}
												id={ClaimNumberStyle.DECIMAL}
												label="Decimal (e.g., 1, 1.1, 1.1.1, 2)"
											/>
										</div>
										<div className="flex items-center space-x-2">
											<RadioGroupItemWithText
												value={ClaimNumberStyle.LEGAL}
												id={ClaimNumberStyle.LEGAL}
												label="Legal (e.g., 1, 1(a), 1(a)(i), 2)"
											/>
										</div>
									</RadioGroup>
								</div>
								<div>
									<div className="flex flex-row gap-2">
										<Label className="text-sm">Maximum claim depth</Label>
										<InfoPopover content={collapseClaimDepthContent} />
									</div>
									<div className="flex gap-2">
										<Input
											type="number"
											min={0}
											value={collapsedClaimDepth}
											onChange={(e) => {
												const value = e.target.value
												// Only update if it's a valid non-negative integer
												if (/^\d*$/.test(value)) {
													setCollapsedClaimDepth(Number.parseInt(value))
												}
											}}
											className="w-[100px] h-9"
										/>
										{collapsedClaimDepth > 0 && (
											<Button
												variant="outline"
												onClick={() => setCollapsedClaimDepth(0)}
												className="h-9"
											>
												Clear
											</Button>
										)}
									</div>
								</div>
							</div>
						</SettingsSection>

						{/* Invalidity Cell Boilerplate Section */}
						<SettingsSection
							label="Invalidity cell boilerplate"
							description="Language displayed as the header and footer of each invalidity cell in app and exports"
						>
							<div className="grid grid-cols-2 gap-4">
								<VariableInput
									label="Header"
									value={header}
									onChange={setHeader}
									type="header_footer"
									placeholder="e.g., {{element_number}}"
								/>
								<VariableInput
									label="Footer"
									value={footer}
									onChange={setFooter}
									type="header_footer"
									placeholder="e.g., {{element_number}}"
								/>
							</div>
						</SettingsSection>

						<SettingsSection
							label="Word invalidity claim chart title"
							description="Format for the title displayed at the top of the invalidity claim chart documents"
						>
							<div className="space-y-2">
								<VariableInput
									label=""
									value={titleFormat}
									onChange={setTitleFormat}
									type="title"
									placeholder='e.g., Invalidity of {{subject_number}} ("{{nickname}}")\nin view of {{reference_number}}'
								/>
							</div>
						</SettingsSection>

						{/* Introduction Language Section */}
						<SettingsSection
							label="Word export introduction"
							description="Language displayed at the beginning of the Word export above the claim charts"
						>
							<VariableInput
								label=""
								value={introductionLanguage}
								onChange={setIntroductionLanguage}
								type="introduction"
							/>
						</SettingsSection>

						{/* Citation Settings Section */}
						<SettingsSection
							label="Citation export settings"
							description="Citation position and format in the exported charts"
						>
							<div className="space-y-4">
								<div>
									<Label className="text-sm">Position</Label>
									<RadioGroup
										value={isCitationBefore ? "start" : "end"}
										onValueChange={(value) => setIsCitationBefore(value === "start")}
										className="flex gap-4 mt-2"
									>
										<RadioGroupItemWithText value="start" id="start" label="Before language" />
										<RadioGroupItemWithText value="end" id="end" label="After language" />
									</RadioGroup>
								</div>

								<div className="grid grid-cols-2 gap-4">
									{/* Citation format inputs */}
									<VariableInput
										label="US Patent Format"
										value={patentCitationFormat}
										onChange={setPatentCitationFormat}
										type="citation"
										placeholder="e.g., {{nickname}} at {{column}}:{{lines}}"
									/>

									<VariableInput
										label="US Patent Application Format"
										value={applicationCitationFormat}
										onChange={setApplicationCitationFormat}
										type="citation"
										placeholder="e.g., {{nickname}} at [{{paragraph}}]"
									/>

									<VariableInput
										label="Uploaded Document Format"
										value={documentCitationFormat}
										onChange={setDocumentCitationFormat}
										type="citation"
										placeholder="e.g., {{nickname}} at p. {{pages}}"
									/>

									<VariableInput
										label="Non-US Patent Format"
										value={foreignDocumentCitationFormat}
										onChange={setForeignDocumentCitationFormat}
										type="citation"
										placeholder="e.g., {{nickname}} at [{{paragraph}}]"
									/>
								</div>
							</div>
						</SettingsSection>

						{/* Figure Settings Section */}
						<SettingsSection
							label="Figure export settings"
							description="Figure position in the exported charts"
						>
							<div>
								<Label className="text-sm">Position</Label>
								<RadioGroup
									value={figurePlacement}
									onValueChange={(value) => setFigurePlacement(value as FigurePlacement)}
									className="flex gap-4"
								>
									{/* <RadioGroupItemWithText
                    value={FigurePlacement.EMBEDDED}
                    id={FigurePlacement.EMBEDDED}
                    label="Embedded with each citation"
                  /> */}
									<RadioGroupItemWithText
										value={FigurePlacement.GROUPED}
										id={FigurePlacement.GROUPED}
										label="Grouped for all element citations"
									/>
									<RadioGroupItemWithText
										value={FigurePlacement.OMITTED}
										id={FigurePlacement.OMITTED}
										label="Omitted from export"
									/>
								</RadioGroup>
							</div>
						</SettingsSection>
					</div>
				)}

				<DialogFooter className="mt-2 pt-2">
					<div className="flex flex-row justify-between gap-3">
						<div className="flex items-center gap-2">
							<Button variant="outline" onClick={handleModalClose}>
								Cancel
							</Button>
							<LoadingButton onClick={handleSave} loading={isSaving}>
								{isSaving ? "Saving..." : "Save Changes"}
							</LoadingButton>
						</div>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default InvaliditySettingsModal
