import {} from "@/components/ui/context-menu"
import { Table } from "@/components/ui/table"
import { usePortfolio } from "@/hooks"
import type { PortfolioMatrixColumn } from "@/types/project"
import {} from "@radix-ui/react-icons"
import type React from "react"
import { useEffect, useMemo, useState } from "react"
import PortfolioMatrixBody from "./components/PortfolioMatrixBody"
import PortfolioMatrixHeader from "./components/PortfolioMatrixHeader"

interface PortfolioMatrixTableProps {
	portfolioMatrix?: PortfolioMatrixColumn[]
	hideEmptyRows: boolean
	setHideEmptyRows: (hideEmptyRows: boolean) => void
}

export const PortfolioMatrixTable: React.FC<PortfolioMatrixTableProps> = ({
	hideEmptyRows,
	setHideEmptyRows,
}) => {
	const { portfolioMatrix } = usePortfolio()
	const [columnWidths, setColumnWidths] = useState<Record<string, number>>({})
	const patentIds = useMemo(
		() =>
			Array.from(
				new Set(portfolioMatrix.flatMap((col) => col.priorArts.map((art) => art.id))),
			),
		[portfolioMatrix],
	)

	if (!portfolioMatrix) return null

	useEffect(() => {
		const updateColumnWidths = () => {
			const newWidths: Record<string, number> = {}
			for (const patentId of patentIds) {
				const el = document.querySelector(`[data-patent-id="${patentId}"]`)
				if (el) {
					newWidths[patentId] = el.getBoundingClientRect().width
				}
			}
			setColumnWidths(newWidths)
		}

		updateColumnWidths()
		window.addEventListener("resize", updateColumnWidths)
		return () => window.removeEventListener("resize", updateColumnWidths)
	}, [patentIds])

	const handleDeleteDocument = async (documentId: string) => {
		// TODO: implement
	}

	return (
		<div className="w-full">
			<Table>
				<PortfolioMatrixHeader
					hideEmptyRows={hideEmptyRows}
					setHideEmptyRows={setHideEmptyRows}
					columnWidths={columnWidths}
					handleDeleteDocument={handleDeleteDocument}
				/>
				<PortfolioMatrixBody patentIds={patentIds} hideEmptyRows={hideEmptyRows} />
			</Table>
		</div>
	)
}
