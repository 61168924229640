import { cn } from "@/lib/utils"
import * as React from "react"

const Box = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => (
		<div ref={ref} className={cn("box", className)} {...props} />
	),
)
Box.displayName = "Box"

export { Box }

export function PageContainer({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"flex relative transition-[margin-left] duration-300 ease-in-out h-screen overflow-hidden",
				className,
			)}
			{...props}
		/>
	)
}

export function UnauthenticatedPageContainer({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return <Box className={cn("flex flex-col h-screen", className)} {...props} />
}

export function MainContentBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"flex-1 transition-[margin-left] duration-300 ease-in-out h-full overflow-hidden",
				className,
			)}
			{...props}
		/>
	)
}

export function PageContentBox({
	className,
	marginLeft,
	overflowy,
	overflowX,
	...props
}: React.HTMLAttributes<HTMLDivElement> & {
	marginLeft?: string
	overflowy?: string
	overflowX?: string
}) {
	return (
		<Box
			className={cn(
				"transition-[margin-left] duration-300 ease h-full w-full",
				{
					[`ml-[${marginLeft}]`]: marginLeft,
					[`overflow-y-${overflowy || "auto"}`]: true,
					[`overflow-x-${overflowX || "hidden"}`]: true,
				},
				className,
			)}
			{...props}
		/>
	)
}

export function PasswordResetContainer({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn("max-w-[600px] flex flex-col items-start gap-4", className)}
			{...props}
		/>
	)
}

export function ModalBox({
	className,
	size,
	overflowy,
	paddingTop,
	...props
}: React.HTMLAttributes<HTMLDivElement> & {
	size?: "lg" | "md" | "sm"
	overflowy?: string
	paddingTop?: string
}) {
	return (
		<Box
			className={cn(
				"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-auto shadow-2xl p-6 rounded-lg",
				{
					"min-w-[80vw]": size === "lg",
					"min-w-[50vw]": size === "md",
					"min-w-[20vw]": size === "sm",
					[`pt-[${paddingTop || "25px"}]`]: true,
					"max-w-[95vw]": true,
					[`overflow-y-${overflowy || "auto"}`]: true,
				},
				className,
			)}
			{...props}
		/>
	)
}

export function CenteredBox({
	className,
	height,
	padding,
	marginTop,
	marginBottom,
	marginLeft,
	marginRight,
	...props
}: React.HTMLAttributes<HTMLDivElement> & {
	height?: string
	padding?: string
	marginTop?: string
	marginBottom?: string
	marginLeft?: string
	marginRight?: string
}) {
	return (
		<Box
			className={cn(
				"flex items-center justify-center",
				{
					[`h-[${height || "auto"}]`]: true,
					[`p-[${padding || "0"}]`]: true,
					[`mt-[${marginTop || "0"}]`]: true,
					[`mb-[${marginBottom || "0"}]`]: true,
					[`ml-[${marginLeft || "0"}]`]: true,
					[`mr-[${marginRight || "0"}]`]: true,
				},
				className,
			)}
			{...props}
		/>
	)
}

export function ProjectHeaderBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"sticky top-0 z-[1000] border-b border-gray-300 overflow-x-hidden w-full flex items-center pl-6 justify-between",
				className,
			)}
			{...props}
		/>
	)
}

export function ExploreHeaderBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"sticky top-0 z-[1000] border-b border-gray-300 overflow-x-hidden w-full flex items-center justify-between",
				className,
			)}
			{...props}
		/>
	)
}

export function ProjectRenameBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"hover:cursor-pointer hover:outline hover:outline-1 hover:outline-black/87 hover:rounded",
				className,
			)}
			{...props}
		/>
	)
}

export function PlaceholderBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"flex-1 flex flex-col justify-center items-center mt-[100px]",
				className,
			)}
			{...props}
		/>
	)
}

export function ImageViewerBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[75vw] shadow-2xl p-6 rounded-lg",
				className,
			)}
			{...props}
		/>
	)
}

export function ImageBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"border border-gray-300 hover:border-gray-500 p-1.5 rounded mr-2.5 mb-2.5 inline-flex items-start",
				className,
			)}
			{...props}
		/>
	)
}

export function ProfileMenuBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"flex-shrink-0 flex-grow-0 absolute bottom-0 p-5 border-t border-gray-300 z-[1100] flex items-center w-full h-[100px]",
				className,
			)}
			{...props}
		/>
	)
}

export function SidebarBox({
	className,
	width,
	...props
}: React.HTMLAttributes<HTMLDivElement> & { width?: string }) {
	return (
		<Box
			className={cn(
				"flex flex-col transition-[width] duration-300 ease flex-shrink-0 flex-grow-0 h-screen overflow-hidden border-r border-gray-300",
				{
					[`w-[${width || "200px"}]`]: true,
				},
				className,
			)}
			{...props}
		/>
	)
}

export function AppTitleBox({
	className,
	paddingLeft,
	justifyContent,
	...props
}: React.HTMLAttributes<HTMLDivElement> & {
	paddingLeft?: string
	justifyContent?: string
}) {
	return (
		<Box
			className={cn(
				"z-[1000] sticky top-0 left-0 pt-6 flex hover:cursor-pointer",
				{
					[`pl-[${paddingLeft || "40px"}]`]: true,
					[`justify-${justifyContent || "flex-start"}`]: true,
				},
				className,
			)}
			{...props}
		/>
	)
}

export function SidebarTopSectionBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"z-[1000] sticky top-[65px] p-5 flex-shrink-0 flex-grow-0 overflow-hidden",
				className,
			)}
			{...props}
		/>
	)
}

export function SidebarBottomSectionBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn("p-5 overflow-hidden flex-1 mt-[50px] relative h-full", className)}
			{...props}
		/>
	)
}

export function SidebarNewProjectSectionBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"flex items-center justify-center w-full flex-shrink-0 flex-grow-0 overflow-hidden mt-1.5",
				className,
			)}
			{...props}
		/>
	)
}

export function SidebarCollapseButtonBox({
	className,
	justifyContent,
	...props
}: React.HTMLAttributes<HTMLDivElement> & { justifyContent?: string }) {
	return (
		<Box
			className={cn(
				"absolute bottom-[100px] right-0 w-full flex items-center overflow-hidden p-5 rounded-lg cursor-pointer",
				{
					[`justify-${justifyContent || "flex-start"}`]: true,
				},
				className,
			)}
			{...props}
		/>
	)
}

export function LoadingContainer({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn("flex flex-col items-center justify-center", className)}
			{...props}
		/>
	)
}

export function UploadedFileBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box
			className={cn(
				"inline-flex items-center border border-gray-400 rounded-lg gap-1 px-2 py-1 mb-1",
				className,
			)}
			{...props}
		/>
	)
}

export function ModalTableBox({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<Box className={cn("max-h-[calc(80vh-100px)] overflow-auto", className)} {...props} />
	)
}

export function ChatPageContentBox({
	className,
	marginLeft,
	overflowy,
	overflowX,
	...props
}: React.HTMLAttributes<HTMLDivElement> & {
	marginLeft?: string
	overflowy?: string
	overflowX?: string
}) {
	return (
		<Box
			className={cn(
				"p-5 transition-[margin-left] duration-300 ease h-full w-full",
				{
					[`ml-[${marginLeft || "0px"}]`]: true,
					[`overflow-y-${overflowy || "auto"}`]: true,
					[`overflow-x-${overflowX || "hidden"}`]: true,
				},
				className,
			)}
			{...props}
		/>
	)
}

export function ChatPageContainer({
	className,
	marginLeft,
	...props
}: React.HTMLAttributes<HTMLDivElement> & { marginLeft?: string }) {
	return (
		<Box
			className={cn(
				"flex relative transition-[margin-left] duration-300 ease min-h-screen",
				{
					[`ml-[${marginLeft || "0px"}]`]: true,
				},
				className,
			)}
			{...props}
		/>
	)
}

export function ChatMainContentBox({
	className,
	marginLeft,
	overflowy,
	overflowX,
	...props
}: React.HTMLAttributes<HTMLDivElement> & {
	marginLeft?: string
	overflowy?: string
	overflowX?: string
}) {
	return (
		<Box
			className={cn(
				"flex-1 transition-[margin-left] duration-300 ease flex flex-col min-h-screen",
				{
					[`ml-[${marginLeft || "0px"}]`]: true,
					[`overflow-y-${overflowy || "hidden"}`]: true,
					[`overflow-x-${overflowX || "hidden"}`]: true,
				},
				className,
			)}
			{...props}
		/>
	)
}
