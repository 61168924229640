/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import { Upload } from "lucide-react"
import React, { type ChangeEvent, type DragEvent, useCallback, useState } from "react"

interface DragAndDropFileAreaProps {
	handleFiles: (files: File[]) => void
	supportedFileTypes: string[]
	maxFiles?: number
}

/**
 * @description Drag and drop file area
 */
const DragAndDropFileArea: React.FC<DragAndDropFileAreaProps> = ({
	handleFiles,
	supportedFileTypes,
	maxFiles = 100,
}) => {
	const maxFileSize = 100 * 1024 * 1024 // 100MB default
	const [dragging, setDragging] = useState(false)
	const [fileLimitError, setFileLimitError] = useState<string>("")
	const [fileSizeError, setFileSizeError] = useState<string>("")
	const fileInputRef = React.useRef<HTMLInputElement>(null)

	const handleAreaClick = () => {
		fileInputRef.current?.click()
	}

	const validateFiles = (files: File[]): File[] => {
		return files.filter((file) => {
			const fileExtension = file.name.split(".").pop()?.toLowerCase()
			const isFileTypeValid = supportedFileTypes.some((type) => {
				const matchesMimeType = type.toLowerCase() === file.type.toLowerCase()
				const matchesExtension = type.toLowerCase() === `.${fileExtension}`
				return matchesMimeType || matchesExtension
			})
			const isFileSizeValid = file.size <= maxFileSize
			return isFileTypeValid && isFileSizeValid
		})
	}

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files
		if (files && files.length > 0) {
			let selectedFiles = Array.from(files)

			// Check for files exceeding size limit
			const oversizedFiles = selectedFiles.filter((file) => file.size > maxFileSize)
			if (oversizedFiles.length > 0) {
				setFileSizeError(
					`Some files exceed the 100MB limit: ${oversizedFiles.map((f) => f.name).join(", ")}`,
				)
			} else {
				setFileSizeError("")
			}

			// Enforce maxFiles limit
			if (maxFiles && selectedFiles.length > maxFiles) {
				setFileLimitError(`You can only upload up to ${maxFiles} file(s).`)
				selectedFiles = selectedFiles.slice(0, maxFiles)
			} else {
				setFileLimitError("")
			}

			const validFiles = validateFiles(selectedFiles)
			handleFiles(validFiles)
		}
	}

	const handleDrag = useCallback((event: DragEvent<HTMLElement>) => {
		event.preventDefault()
		event.stopPropagation()
	}, [])

	const handleDragEnter = () => setDragging(true)
	const handleDragLeave = () => setDragging(false)

	const handleDrop = (event: DragEvent<HTMLElement>) => {
		handleDrag(event)
		setDragging(false)
		const files = event.dataTransfer.files
		if (files?.length > 0) {
			let selectedFiles = Array.from(files)

			// Enforce maxFiles limit
			if (maxFiles && selectedFiles.length > maxFiles) {
				setFileLimitError(`You can only upload up to ${maxFiles} file(s).`)
				selectedFiles = selectedFiles.slice(0, maxFiles)
			} else {
				setFileLimitError("")
			}

			const validFiles = validateFiles(selectedFiles)
			handleFiles(validFiles)
		}
	}

	return (
		<div
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDragOver={handleDrag}
			onDrop={handleDrop}
			className={cn(
				"p-8 border border-input rounded-lg text-center max-h-[80vh] overflow-y-auto w-full cursor-pointer",
				dragging && "border-primary bg-secondary",
			)}
			onClick={handleAreaClick}
		>
			<Upload className="mx-auto mb-4 h-6 w-6 " />
			<h3 className="text-lg font-semibold">
				Drag and drop files here or click to browse.
				<label htmlFor="file-upload-button" className="cursor-pointer">
					<Input
						ref={fileInputRef}
						type="file"
						className="hidden"
						onChange={handleFileChange}
						multiple={maxFiles !== 1}
					/>
				</label>
			</h3>
			<p className="mt-2 text-sm text-muted-foreground">
				Supported file types: {supportedFileTypes.join(", ")}
			</p>
			{fileLimitError && <p className="mt-2 text-sm text-red-600">{fileLimitError}</p>}
			{fileSizeError && <p className="mt-2 text-sm text-red-600">{fileSizeError}</p>}
		</div>
	)
}

export default DragAndDropFileArea
