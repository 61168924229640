/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { AddPatentsModal, Loader } from "@/components"
import { Button } from "@/components/ui/button"
import { H4 } from "@/components/ui/typography"
import { usePortfolio, useProject } from "@/hooks"
import { useAreProcessesPending } from "@/store"
import { useProcessStore } from "@/store"
import { DocumentRole, ProcessType } from "@/types"
import { PageName } from "@/types/pages"
import { PlusIcon } from "@radix-ui/react-icons"
import type React from "react"
import { useState } from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import { PortfolioProjectTable } from "./components/PortfolioProjectTable"

const PortfolioSubjectsPage: React.FC = () => {
	const { processes } = useProcessStore()
	const { updateProjectDetails } = useProject()
	const { removeProjectsFromPortfolio, projects, portfolioId, isLoading } =
		usePortfolio()

	const isAddSubjectDisabled = useAreProcessesPending(
		[ProcessType.GENERATE_CONTEXT, ProcessType.ADD_PRIOR_ART],
		null,
		portfolioId,
	)

	const [showAddNewModal, setShowAddNewModal] = useState(false)

	return (
		<ProjectPage pageName={PageName.PortfolioProjects}>
			<div>
				{isLoading.portfolio ? (
					<Loader message="Loading..." />
				) : (
					<>
						{projects?.length > 0 ? (
							<PortfolioProjectTable />
						) : (
							<div className="flex justify-center items-center flex-col mt-12">
								<H4>This portfolio has no subjects.</H4>
								<Button
									onClick={() => {
										setShowAddNewModal(true)
									}}
									disabled={isAddSubjectDisabled}
									className="mt-3"
									variant="outline"
								>
									Add Subjects
									<PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
								</Button>
							</div>
						)}
					</>
				)}
			</div>

			<AddPatentsModal
				open={showAddNewModal}
				handleClose={() => {
					setShowAddNewModal(false)
				}}
				documentRole={DocumentRole.SUBJECT}
				showProcessSettings={false}
			/>
		</ProjectPage>
	)
}

export default PortfolioSubjectsPage
