/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible"
import { Label } from "@/components/ui/label"
import { Table, TableBody } from "@/components/ui/table"
import type { Claim } from "@/types"
import {
	ChevronDownIcon,
	ChevronUpIcon,
	Pencil1Icon,
	PlusIcon,
} from "@radix-ui/react-icons"
import type React from "react"
import { useRef, useState } from "react"
import ClaimRow from "./ClaimRow"
import UpdateClaimsModal from "./UpdateClaimsModal"

interface ClaimListProps {
	editingBool?: boolean
	startCollapsed?: boolean
	list: Claim[]
	highlightedRef?: React.MutableRefObject<HTMLTableRowElement | null>
	citationText?: string
}

const ClaimList: React.FC<ClaimListProps> = ({
	editingBool,
	startCollapsed,
	list,
	highlightedRef,
	citationText,
}) => {
	const sectionTitle = "Claims"
	const [isOpen, setIsOpen] = useState(!startCollapsed)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showDependentClaims, setShowDependentClaims] = useState(true)
	const listRef = useRef<HTMLDivElement>(null)

	const handleEditButton = () => {
		setShowEditModal(true)
	}

	return (
		<div className="border rounded-lg mt-3 p-2 w-full" ref={listRef}>
			{list?.length === 0 ? (
				<div className="flex items-center justify-between gap-2 pl-2">
					<Label className="mb-0">{sectionTitle}</Label>
					{editingBool && (
						<Button variant="ghost" size="icon" onClick={handleEditButton} aria-label="add">
							<PlusIcon className="h-4 w-4" />
						</Button>
					)}
				</div>
			) : (
				<Collapsible open={isOpen} onOpenChange={setIsOpen}>
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-2 pl-2">
							<Label className="mb-0">{sectionTitle}</Label>
							{editingBool && (
								<Button
									variant="ghost"
									size="icon"
									onClick={handleEditButton}
									aria-label="edit"
								>
									<Pencil1Icon className="h-4 w-4" />
								</Button>
							)}
							{/* <Button
                variant="outline"
                size="sm"
                onClick={() => setShowDependentClaims(!showDependentClaims)}
                className="text-xs"
              >
                {showDependentClaims ? "Hide" : "Show"} Dependent Claims
              </Button> */}
						</div>
						<CollapsibleTrigger asChild>
							<Button variant="ghost" size="icon">
								{isOpen ? (
									<ChevronUpIcon className="h-4 w-4" />
								) : (
									<ChevronDownIcon className="h-4 w-4" />
								)}
							</Button>
						</CollapsibleTrigger>
					</div>
					<CollapsibleContent>
						<Table>
							<TableBody>
								{list.map((claim) => {
									if (!claim) return null
									if (!showDependentClaims && !claim.isIndependent) return null

									// Optional highlight check
									const isHighlighted =
										citationText &&
										claim.limitations?.[0]?.text.slice(0, 75) === citationText.slice(0, 75)

									return (
										<ClaimRow
											key={claim.id}
											claim={claim}
											editingBool={editingBool}
											isHighlighted={isHighlighted}
											highlightedRef={isHighlighted ? highlightedRef : null}
										/>
									)
								})}
							</TableBody>
						</Table>
					</CollapsibleContent>
				</Collapsible>
			)}
			<UpdateClaimsModal open={showEditModal} onClose={() => setShowEditModal(false)} />
		</div>
	)
}

export default ClaimList
