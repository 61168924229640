/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import SearchDocumentsComponent from "@/features/search/SearchDocumentsComponent"
import { useProject } from "@/hooks"
import { PageName } from "@/types/pages"
import ProjectPage from "../../components/projects/ProjectPage"

/**
 * @description Renders the project search page
 */
const ProjectSearchPage = () => {
	const { isLoading } = useProject()

	return (
		<ProjectPage pageName={PageName.Search}>
			{isLoading.projectReferences || isLoading.project ? (
				<div className="flex justify-center items-center flex-col mt-12">
					<Loader message="Loading..." />
				</div>
			) : (
				<SearchDocumentsComponent />
			)}
		</ProjectPage>
	)
}
export default ProjectSearchPage
