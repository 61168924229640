import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import type { ClaimLimitationEdit } from "@/types"
import { Cross1Icon } from "@radix-ui/react-icons"
import { useEffect, useState } from "react"

interface LimitationEditorProps {
	limitation: ClaimLimitationEdit
	onUpdate: (limitation: ClaimLimitationEdit) => void
	onDelete: () => void
	isLoading: boolean
}

function LimitationEditor({
	limitation,
	onUpdate,
	onDelete,
	isLoading,
}: LimitationEditorProps) {
	// Local state for editing
	const [localLabel, setLocalLabel] = useState(limitation.label)
	const [localText, setLocalText] = useState(limitation.text)

	// Update local state when prop changes
	useEffect(() => {
		setLocalLabel(limitation.label)
		setLocalText(limitation.text)
	}, [limitation.label, limitation.text])

	const handleLabelChange = (newLabel: string) => {
		if (newLabel === "" || /^[0-9]+\.?([0-9]+\.?)*$/.test(newLabel)) {
			setLocalLabel(newLabel)
		}
	}

	const handleLabelBlur = () => {
		if (localLabel !== limitation.label) {
			onUpdate({ ...limitation, label: localLabel })
		}
	}

	const handleTextChange = (newText: string) => {
		setLocalText(newText)
	}

	const handleTextBlur = () => {
		if (localText !== limitation.text) {
			onUpdate({
				...limitation,
				text: localText,
				// html: `<div class="claim-text">${localText}</div>`,
			})
		}
	}

	return (
		<div className="flex gap-2 items-start p-2 border rounded-md bg-white">
			<Input
				className="w-24 flex-shrink-0"
				value={localLabel}
				onChange={(e) => handleLabelChange(e.target.value)}
				onBlur={handleLabelBlur}
				placeholder="1.1"
				disabled={isLoading}
				pattern="[0-9.]+"
			/>
			<AutosizeTextarea
				value={localText}
				onChange={(e) => handleTextChange(e.target.value)}
				onBlur={handleTextBlur}
				placeholder="Enter limitation text..."
				disabled={isLoading}
			/>
			<Button variant="ghost" size="icon" onClick={onDelete} disabled={isLoading}>
				<Cross1Icon className="h-4 w-4" />
			</Button>
		</div>
	)
}

export default LimitationEditor
