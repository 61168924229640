import type {
	ChunkLocation,
	CopyChunkToLimitationsRequest,
	CreateChunkToLimitationRequest,
	FindMoreCitationsForLimitationRequest,
	GetDoesIprExistRequest,
	GetEvaluationRequest,
	GetInvalidityChartDataRequest,
	GetProjectExportRequest,
	GetRemainingParagraphsRequest,
	InvalidityChartData,
	InvaliditySummaryRow,
	ProcessParagraphsAsInvaliditiesRequest,
	PruneChunkToLimitationByColorRequest,
	PruneChunkToLimitationByScoreRequest,
	ToggleRemoveAllDocumentChunkCitationsRequest,
	ToggleVerifyAllDocumentCitationsRequest,
	UpdateChunkToLimitationRequest,
} from "@/types"
import type { UpdateChunkToLimitationBoilerplateRequest } from "@/types/request"
import { apiClient } from "./client"

export const chartsApi = {
	async getSummaryChartData(
		projectId: string,
		assertedClaimsOnly?: boolean,
	): Promise<InvaliditySummaryRow[]> {
		const payload = {
			project_id: projectId,
			...(assertedClaimsOnly && { asserted_claims_only: assertedClaimsOnly }),
		}

		return await apiClient.get("get_summary_chart", payload)
	},

	async getInvalidityChartData(
		projectId: string,
		documentIds: string[],
		limitationIds: string[],
		colors: string[],
	): Promise<InvalidityChartData> {
		const payload: GetInvalidityChartDataRequest = {
			project_id: projectId,
			document_ids: documentIds,
			limitation_ids: limitationIds,
			colors: colors,
		}
		return await apiClient.get("get_invalidity_chart_data", payload)
	},

	async updateChunkToLimitation(
		documentChunkId: string,
		claimLimitationId: string,
		options: {
			score?: number
			color?: string
			removed?: boolean
			verified?: boolean
		},
	) {
		const payload: UpdateChunkToLimitationRequest = {
			document_chunk_id: documentChunkId,
			claim_limitation_id: claimLimitationId,
			...(options.score !== undefined && { score: options.score }),
			...(options.color !== undefined && { color: options.color }),
			...(options.removed !== undefined && { removed: options.removed }),
			...(options.verified !== undefined && { verified: options.verified }),
		}
		return await apiClient.post("post_update_chunk_to_limitation", payload)
	},

	async createChunkToLimitation(
		documentChunkId: string,
		claimLimitationId: string,
		color: string,
		projectId: string,
	) {
		const payload: CreateChunkToLimitationRequest = {
			document_chunk_id: documentChunkId,
			claim_limitation_id: claimLimitationId,
			color: color,
			project_id: projectId,
		}
		return await apiClient.post("post_create_chunk_to_limitation", payload)
	},

	async toggleRemoveAllDocumentChunkCitations(documentChunkId: string) {
		const payload: ToggleRemoveAllDocumentChunkCitationsRequest = {
			document_chunk_id: documentChunkId,
		}
		return await apiClient.post(
			"post_toggle_remove_all_document_chunk_citations",
			payload,
		)
	},

	async toggleVerifyAllDocumentCitations(documentId: string, verified: boolean) {
		const payload: ToggleVerifyAllDocumentCitationsRequest = {
			document_id: documentId,
			verified: verified,
		}
		return await apiClient.post("post_toggle_verify_all_document_citations", payload)
	},

	async findMoreCitationsForLimitation(
		claimLimitationId: string,
		documentIds: string[],
		positiveExamples: string[],
		negativeExamples: string[],
		header?: string,
		footer?: string,
	) {
		const payload: FindMoreCitationsForLimitationRequest = {
			document_ids: documentIds,
			claim_limitation_id: claimLimitationId,
			positive: positiveExamples,
			negative: negativeExamples,
			header: header,
			footer: footer,
		}
		return await apiClient.post("post_find_more_limitation_citations", payload)
	},

	async copyInvalidityCitations(
		projectId: string,
		targetClaimNumber: string,
		sourceClaimNumber: string,
		referenceId: string,
		deleteExisting: boolean,
		applyToAllReferences: boolean,
		isFeature: boolean,
	) {
		const payload = {
			project_id: projectId,
			target_claim_number: targetClaimNumber,
			source_claim_number: sourceClaimNumber,
			delete_existing: deleteExisting,
			apply_to_all_references: applyToAllReferences,
			reference_id: referenceId,
			is_feature: isFeature,
		}
		return await apiClient.post("post_copy_invalidity_citations", payload)
	},

	async pruneChunkToLimitationByColor(documentIds: string[], colors: string[]) {
		const payload: PruneChunkToLimitationByColorRequest = {
			document_ids: documentIds,
			colors: colors,
		}
		return await apiClient.post("post_prune_chunk_to_limitation_by_color", payload)
	},

	async pruneChunkToLimitationByScore(documentIds: string[], score: number) {
		const payload: PruneChunkToLimitationByScoreRequest = {
			document_ids: documentIds,
			score: score,
		}
		return await apiClient.post("post_prune_chunk_to_limitation_by_score", payload)
	},

	async updateChunkToLimitationBoilerplate(
		patentId: string,
		documentId: string,
		header: string,
		footer: string,
		claimLimitationIds?: string[],
		updateAllElements = false,
	) {
		const payload: UpdateChunkToLimitationBoilerplateRequest = {
			patent_id: patentId,
			document_id: documentId,
			header: header,
			footer: footer,
			claim_limitation_ids: claimLimitationIds,
			update_all_elements: updateAllElements,
		}
		return await apiClient.post("/post_update_chunk_to_limitation_boilerplate", payload)
	},

	async getProjectExport(
		projectId: string,
		exportType: string,
		includeClaims: boolean,
		includeSummary: boolean,
		introductionLanguage: string,
		documentIds: string[],
		colorSubset: string[],
		limitationIds: string[],
		isCitationBefore: boolean,
		patentCitationFormat: string,
		applicationCitationFormat: string,
		documentCitationFormat: string,
		foreignDocumentCitationFormat: string,
		figureFormat: string,
		figurePlacement: string,
		titleFormat: string,
	) {
		const params: GetProjectExportRequest = {
			project_id: projectId,
			export_type: exportType,
			include_claims: includeClaims,
			include_summary: includeSummary,
			introduction_language: introductionLanguage,
			document_ids: documentIds,
			color_subset: colorSubset,
			limitation_ids: limitationIds,
			is_citation_before: isCitationBefore,
			patent_citation_format: patentCitationFormat,
			application_citation_format: applicationCitationFormat,
			document_citation_format: documentCitationFormat,
			foreign_document_citation_format: foreignDocumentCitationFormat,
			figure_citation_format: figureFormat,
			figure_placement: figurePlacement,
			title_format: titleFormat,
		}
		return await apiClient.get("get_invalidity_chart_export", params)
	},

	async toggleChartCitationVerification(
		projectId: string,
		referenceId: string,
		verified: boolean,
	) {
		const payload = {
			project_id: projectId,
			reference_id: referenceId,
			verified: verified,
		}
		return await apiClient.post("post_toggle_chart_citation_verification", payload)
	},

	async getDoesIprExist(patentNumber: string) {
		const params: GetDoesIprExistRequest = {
			patent_number: patentNumber,
		}
		return await apiClient.get("get_does_ipr_exist", params)
	},

	async getEvaluation(projectId: string, referenceIds: string[], patentNumber: string) {
		const params: GetEvaluationRequest = {
			project_id: projectId,
			reference_ids: referenceIds,
			patent_number: patentNumber,
		}
		return await apiClient.get("get_evaluation", params)
	},

	async getRemainingParagraphs(projectId: string, referenceId: string) {
		const params: GetRemainingParagraphsRequest = {
			project_id: projectId,
			reference_id: referenceId,
		}
		return await apiClient.get("get_remaining_paragraphs", params)
	},

	async processParagraphsAsInvalidities(
		projectId: string,
		invalidityId: string,
		referenceId: string,
		iprNumber: string,
		paragraphNumbers: string[],
	) {
		const payload: ProcessParagraphsAsInvaliditiesRequest = {
			project_id: projectId,
			invalidity_id: invalidityId,
			reference_id: referenceId,
			paragraph_numbers: paragraphNumbers.map((num) => Number.parseInt(num)),
			ipr_number: iprNumber,
		}
		return await apiClient.post("process_paragraphs_as_invalidities", payload)
	},

	async copyChunkToLimitations(
		documentId: string,
		sourceLimitationId: string,
		targetLimitationId: string,
		replace: boolean,
	) {
		const payload: CopyChunkToLimitationsRequest = {
			document_id: documentId,
			source_limitation_id: sourceLimitationId,
			target_limitation_id: targetLimitationId,
			replace: replace,
		}
		return await apiClient.post("post_copy_chunk_to_limitations", payload)
	},

	// Helper method to clean location object
	cleanLocation(location: ChunkLocation): ChunkLocation {
		const cleanLocation: ChunkLocation = {}

		if (location.paragraphs) {
			cleanLocation.paragraphs = location.paragraphs
		}

		if (
			location.pages?.filter((page) => page !== null && !Number.isNaN(page)).length > 0
		) {
			cleanLocation.pages = location.pages.filter(
				(page) => page !== null && !Number.isNaN(page),
			)
		}

		if (
			location.lines?.filter((line) => line !== null && !Number.isNaN(line)).length > 0
		) {
			cleanLocation.lines = location.lines.filter(
				(line) => line !== null && !Number.isNaN(line),
			)
		}

		if (
			location.columns?.filter((column) => column !== null && !Number.isNaN(column))
				.length > 0
		) {
			cleanLocation.columns = location.columns.filter(
				(column) => column !== null && !Number.isNaN(column),
			)
		}

		return cleanLocation
	},
}
