/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import { Button } from "@/components/ui/button"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { Cross2Icon } from "@radix-ui/react-icons"

interface ItemTagProps {
	index: number
	label: string
	handleRemoveItem: (label: string, index: number) => void
	disabled?: boolean
}

/**
 * @description Item tag used in search filters
 * @param {number} index - The index of the item.
 * @param {string} label - The label of the item.
 * @param {function} handleRemoveItem - Function to handle the removal of an item.
 * @param {boolean} disabled - Whether the item is disabled.
 */
function ItemTag({ index, label, handleRemoveItem, disabled = false }: ItemTagProps) {
	return (
		<div
			key={index}
			className="inline-flex items-center py-1 px-2.5 bg-gray-100 text-gray-800 rounded-md transition-colors duration-200 hover:bg-gray-200"
		>
			<span
				title={label}
				className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px] px-2"
			>
				{label.length < 50 ? label : `${label.slice(0, 50)}...`}
			</span>
			{!disabled && (
				<Tooltip>
					<TooltipTrigger asChild>
						<Button
							variant="ghost"
							size="icon"
							className="h-4 w-4 ml-1 p-0"
							onClick={() => handleRemoveItem(label, index)}
						>
							<Cross2Icon className="h-4 w-4" />
						</Button>
					</TooltipTrigger>
					<TooltipContent>Remove</TooltipContent>
				</Tooltip>
			)}
		</div>
	)
}

export default ItemTag
