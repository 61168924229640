/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { InfoIcon } from "lucide-react"
import type React from "react"
import { useState } from "react"

interface InfoPopoverProps {
	content: string | React.ReactNode
	className?: string
}

/**
 * @description Info popover component that triggers on hover
 *
 * @param {object} content - The content of the popover
 */
const InfoPopover: React.FC<InfoPopoverProps> = ({ content, className }) => {
	const [isOpen, setIsOpen] = useState(false)
	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger asChild>
				<div
					className={`inline-flex items-center justify-center h-4 w-4 group ${className}`}
					onMouseEnter={() => setIsOpen(true)}
					onMouseLeave={() => setIsOpen(false)}
				>
					<InfoIcon className="h-4 w-4 text-muted-foreground" />
				</div>
			</PopoverTrigger>
			<PopoverContent className="min-w-[30vw] max-w-[50vw]">
				{typeof content === "string" ? (
					<p className="text-sm text-muted-foreground">{content}</p>
				) : (
					content
				)}
			</PopoverContent>
		</Popover>
	)
}

export default InfoPopover
