import { Loader } from "@/components"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { ProjectsTable } from "@/features/home/components"
import useUserProjects from "@/hooks/useUserProjects"
import { type ProjectListItem, ProjectStatus } from "@/types"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type React from "react"

interface ArchivedProjectsModalProps {
	open: boolean
	handleClose: () => void
}

/**
 * @description Archived projects modal
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 */
const ArchivedProjectsModal: React.FC<ArchivedProjectsModalProps> = ({
	open,
	handleClose,
}) => {
	const { archivedProjects, isLoading } = useUserProjects()
	if (!archivedProjects) return null

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="sm:max-w-[800px]">
				<DialogHeader>
					<DialogTitle>Archived projects</DialogTitle>
				</DialogHeader>
				{isLoading.archivedProjects ? (
					<Loader />
				) : (
					<ProjectsTable
						tableType={ProjectStatus.ARCHIVED}
						isInArchive={true}
						height="h-[calc(100vh-200px)]"
						projects={archivedProjects as ProjectListItem[]}
					/>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default ArchivedProjectsModal
