/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import * as Sentry from "@sentry/browser"
import { PostHogProvider } from "posthog-js/react" // Ensure correct import
import ReactDOM from "react-dom/client"
import App from "./App"
import "./globals.css"
import reportWebVitals from "./reportWebVitals"

const replay = Sentry.replayIntegration({
	maskAllText: false,
	blockAllMedia: false,
})

Sentry.init({
	dsn: "https://23f7d0517404216cfff767da65169df3@o4507141887164416.ingest.us.sentry.io/4507143156727808",
	integrations: [Sentry.browserTracingIntegration(), replay],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [/^https:\/\/app\.tryandai\.com\//],
	// Session Replay
	replaysSessionSampleRate: 0, // Don't record any sessions unless explicitly set in useSentryReaplay.ts
	replaysOnErrorSampleRate: 0,
	environment: process.env.SENTRY_ENVIRONMENT || "development",
})

// Import font
const link = document.createElement("link")
link.href =
	"https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap"
link.rel = "stylesheet"
document.head.appendChild(link)

const options: { api_host: string | undefined } = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
	// <React.StrictMode>
	// <ThemeProvider theme={theme}>
	//   <StyledThemeProvider theme={theme}>
	<PostHogProvider
		apiKey={process.env.REACT_APP_POSTHOG_API_KEY as string}
		options={options}
	>
		<App />
	</PostHogProvider>,
	//   </StyledThemeProvider>
	// </ThemeProvider>
	// </React.StrictMode>
)
reportWebVitals()
