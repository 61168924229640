/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DragAndDropFileArea, ProcessSettings } from "@/components"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import {
	useFileHandler,
	useProcessDocuments,
	useProjectAndPortfolioIds,
	useUnprocessedDocuments,
} from "@/hooks"
import { DocumentRole, ProcessType, UnprocessedDocumentType } from "@/types"
import { AlertTriangle } from "lucide-react"
import type React from "react"
import { useMemo, useState } from "react"
import UnprocessedDocumentsList from "./UnprocessedDocumentsList"

interface AddDocumentsModalProps {
	open: boolean
	handleClose: () => void
	title?: string
	buttonLabel?: string
	showProcessSettings?: boolean
	maxFiles?: number
	documentRole?: DocumentRole
}

/**
 * @description Upload files modal
 */
const AddDocumentsModal: React.FC<AddDocumentsModalProps> = ({
	open,
	handleClose,
	title = "Upload Files",
	buttonLabel = "Add",
	showProcessSettings = true,
	maxFiles,
	documentRole = DocumentRole.PRIOR_ART,
}) => {
	const { processDocuments } = useProcessDocuments()
	const { handleFileChange, fileTypeError, fileLimitError, handleRemoveFile } =
		useFileHandler(maxFiles)
	const {
		addUnprocessedDocuments,
		resetAll,
		unprocessedDocuments,
		removeUnprocessedDocument,
		updateSelectedKindCode,
	} = useUnprocessedDocuments()
	const { projectId, portfolioId } = useProjectAndPortfolioIds()

	// State management
	const [useClaimsCheckboxChecked, setUseClaimsCheckboxChecked] = useState<boolean>(true)

	// Close modal and reset state
	const handleCloseClick = () => {
		resetAll()
		handleFileChange([])
		handleClose()
	}

	const handleAddClick = async () => {
		handleClose()
		await processDocuments(
			ProcessType.ADD_PRIOR_ART,
			portfolioId,
			projectId,
			unprocessedDocuments,
			true, // chart
			false, // claims in charting
		)
		resetAll()
	}

	const isButtonDisabled = useMemo(() => {
		return unprocessedDocuments.length === 0
	}, [unprocessedDocuments.length])

	const handleFileChangeInternal = (inputFiles: File[]) => {
		const validFiles = handleFileChange(inputFiles)
		addUnprocessedDocuments(documentRole, UnprocessedDocumentType.REFERENCE, {
			files: validFiles,
		})
	}

	const handleRemoveUploadedFile = (documentId: string) => {
		const document = unprocessedDocuments.find((doc) => doc.id === documentId)
		if (document) {
			removeUnprocessedDocument(documentId)
			handleRemoveFile(document.file)
		}
	}

	return (
		<Dialog open={open} onOpenChange={handleCloseClick}>
			<DialogContent className="w-[80vw] min-w-[300px] max-w-[1200px]">
				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
				</DialogHeader>

				{/* File Upload Section */}
				<div className="flex flex-col space-y-4">
					<DragAndDropFileArea
						handleFiles={handleFileChangeInternal}
						supportedFileTypes={[".pdf", ".txt"]}
						maxFiles={maxFiles}
					/>
				</div>

				{/* Display Selected Files */}
				{unprocessedDocuments.length > 0 && (
					<div className="mt-4">
						<UnprocessedDocumentsList
							documents={unprocessedDocuments}
							onRemove={handleRemoveUploadedFile}
							updateSelectedKindCode={updateSelectedKindCode}
						/>
					</div>
				)}

				{/* File Limit Error Message */}
				{fileLimitError && (
					<Alert variant="destructive">
						<AlertTriangle className="h-4 w-4" />
						<AlertDescription>{fileLimitError}</AlertDescription>
					</Alert>
				)}

				{/* File Type Error Message */}
				{fileTypeError && (
					<Alert variant="destructive">
						<AlertTriangle className="h-4 w-4" />
						<AlertDescription>{fileTypeError}</AlertDescription>
					</Alert>
				)}

				{/* Footer */}
				<DialogFooter className="mt-4">
					<div className="flex items-center justify-between w-full">
						{showProcessSettings && (
							<ProcessSettings
								useClaimsCheckboxChecked={useClaimsCheckboxChecked}
								setUseClaimsCheckboxChecked={setUseClaimsCheckboxChecked}
							/>
						)}
						<Button onClick={handleAddClick} disabled={isButtonDisabled}>
							{buttonLabel}
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default AddDocumentsModal
