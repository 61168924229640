/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ErrorMessage, Loader } from "@/components"
import { useProject, useSearchDocuments } from "@/hooks"
import { useNavigationState } from "@/hooks"
import type { CitationSearchResult } from "@/types"
import { useState } from "react"
import SearchDocumentsHeader from "./components/SearchDocumentsHeader"
import SearchResultsTable from "./components/SearchResultsTable"
/**
 * @description Renders the project search page
 */
const SearchDocumentsComponent = () => {
	const { semanticSearchDocuments } = useSearchDocuments()
	const { projectId, projectPriorArtDocuments } = useProject()
	const { isDocumentsPage } = useNavigationState()
	// Local state setup
	const [showError, setShowError] = useState(false)
	const [searchQuery, setSearchQuery] = useState("")
	const [sentSearchQuery, setSentSearchQuery] = useState("")
	const [showSpinner, setShowSpinner] = useState(false)
	const [searchResults, setSearchResults] = useState<CitationSearchResult[] | null>(null)
	const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>(
		projectPriorArtDocuments?.map((ref) => ref.documentId) || [],
	)
	const [searchMode, setSearchMode] = useState("semantic")

	const handleDocumentChange = (selectedIds: string[]) => {
		setSelectedDocumentIds(selectedIds)
	}

	// Handles the semantic search
	const handleSemanticSearch = async () => {
		setSearchResults(null)
		setShowError(false)

		if (selectedDocumentIds?.length === 0) {
			setSelectedDocumentIds(projectPriorArtDocuments as string[]) // Set default docs in project mode
		}

		const trimmedSearchQuery = searchQuery.trim()
		setSentSearchQuery(trimmedSearchQuery)

		if (trimmedSearchQuery !== "" && trimmedSearchQuery !== null) {
			setShowSpinner(true)
			const response = await semanticSearchDocuments(
				projectId,
				trimmedSearchQuery,
				selectedDocumentIds,
				searchMode,
			)
			if (response.success) {
				setShowSpinner(false)
				setShowError(false)
				setSearchResults(response.data.sources)
			} else {
				setShowError(true)
				setShowSpinner(false)
			}
		}
	}

	return (
		<>
			<div className="sticky top-0 bg-background z-10">
				<SearchDocumentsHeader
					handleSemanticSearch={handleSemanticSearch}
					searchMode={searchMode}
					setSearchMode={setSearchMode}
					handleDocumentChange={handleDocumentChange}
					searchQuery={searchQuery}
					setSearchQuery={setSearchQuery}
					disabled={false}
					documents={projectPriorArtDocuments}
				/>
				{showSpinner && <Loader message={"Searching the documents..."} />}
				{showError && <ErrorMessage />}
			</div>
			{searchResults && (
				<SearchResultsTable
					searchResults={searchResults}
					sentSearchQuery={sentSearchQuery}
					height="h-[calc(100vh-125px)]"
				/>
			)}
		</>
	)
}
export default SearchDocumentsComponent
