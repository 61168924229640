import { InfoPopover } from "@/components"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItemWithText } from "@/components/ui/radio-group"
import { CitationPolicy } from "@/types"
import type React from "react"
import { useState } from "react"

interface ProcessReferencesModalProps {
	isOpen: boolean
	onOpenChange: (isOpen: boolean) => void
	onConfirm: (mode: CitationPolicy) => void
	documentNames?: string[]
	isReprocess: boolean
}

/**
 * @description Process references modal
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onOpenChange - Function to open/close the modal.
 * @param {function} onConfirm - Function to confirm the modal.
 */
export const ProcessReferencesModal: React.FC<ProcessReferencesModalProps> = ({
	isOpen,
	onOpenChange,
	onConfirm,
	documentNames,
	isReprocess,
}) => {
	const [mode, setMode] = useState<CitationPolicy>(CitationPolicy.APPEND)

	const chartOptionsContent = (
		<>
			<p className="mb-2 text-sm">
				<span className="font-bold">Overwrite existing</span> may overwrite any existing
				citations, including verified citations.
			</p>
			<p className="mb-2 text-sm">
				<span className="font-bold">Append new</span> will add the new citations to the
				existing citations. All existing citations will remain.
			</p>
			<p className="mb-2 text-sm">
				<span className="font-bold">Protect verified</span> will keep existing verified
				citations and may overwrite any unverified citations.
			</p>
		</>
	)

	return (
		<Dialog open={isOpen} onOpenChange={onOpenChange}>
			<DialogContent className="sm:max-w-[600px] max-h-[80vh]">
				<DialogHeader>
					<DialogTitle>
						{isReprocess ? "Reprocess" : "Rechart"}
						{" references?"}
					</DialogTitle>
					<DialogDescription>
						{isReprocess ? "Reprocessing" : "Recharting"} will overwrite all citations you
						have added or edited in charts. This action cannot be undone.
					</DialogDescription>
				</DialogHeader>
				{documentNames?.length > 0 && (
					<div className="flex flex-wrap gap-2 mt-2">
						{documentNames.map((name) => (
							<Badge variant="outline" key={name}>
								{name}
							</Badge>
						))}
					</div>
				)}
				<div className="py-4">
					<div className="flex items-center gap-2">
						<Label className="mb-0">Chart options</Label>
						<InfoPopover content={chartOptionsContent} />
					</div>
					<RadioGroup
						value={mode}
						onValueChange={(value: CitationPolicy) => setMode(value)}
						className="mt-2"
					>
						<div className="flex flex-row gap-4">
							<div className="flex items-center space-x-2">
								<RadioGroupItemWithText
									value={CitationPolicy.APPEND}
									id="append"
									label="Append new"
								/>
							</div>
							<div className="flex items-center space-x-2">
								<RadioGroupItemWithText
									value={CitationPolicy.VERIFIED}
									id="verified"
									label="Protect verified"
								/>
							</div>
							<div className="flex items-center space-x-2">
								<RadioGroupItemWithText
									value={CitationPolicy.OVERWRITE}
									id="overwrite"
									label="Overwrite existing"
								/>
							</div>
						</div>
					</RadioGroup>
				</div>
				<DialogFooter>
					<div className="flex gap-2">
						<Button variant="outline" onClick={() => onOpenChange(false)}>
							Cancel
						</Button>
						<Button onClick={() => onConfirm(mode as CitationPolicy)}>
							{isReprocess ? "Reprocess" : "Rechart"}
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
