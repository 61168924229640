/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { H4 } from "@/components/ui/typography"
import { OrganizationModal } from "@/features/admin/superadmin/components"
import type { OrganizationSummary } from "@/types"
import { PlusIcon } from "@radix-ui/react-icons"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

/**
 * @description Organization details table
 */
function OrganizationDetailsTable({
	organizations,
}: {
	organizations: OrganizationSummary[]
}) {
	const navigate = useNavigate()
	const [isAddOrgModalOpen, setAddOrgModalOpen] = useState(false)

	const columns = [
		{ key: "organizationName", label: "Name" },
		{ key: "seats", label: "Seats" },
		{ key: "projects", label: "Projects" },
		{ key: "documentsAddedCount", label: "Documents Added" },
		{ key: "documentsExportedCount", label: "Documents Exported" },
		{ key: "amountDue", label: "Amount Due" },
		{ key: "amountSpent", label: "Amount Spent" },
	]

	const handleOrgClick = (orgId: string) => {
		navigate(`/superadmin/organizations/${orgId}`)
	}

	return (
		<Card>
			<CardHeader>
				<div className="flex justify-between items-center">
					<H4>Organizations</H4>
					<Button size="icon" onClick={() => setAddOrgModalOpen(true)}>
						<PlusIcon />
					</Button>
				</div>
			</CardHeader>
			<CardContent>
				<Table>
					<TableHeader>
						<TableRow>
							{columns.map((col) => (
								<TableHead key={col.key}>{col.label}</TableHead>
							))}
						</TableRow>
					</TableHeader>
					<TableBody>
						{organizations?.map((org) => (
							<TableRow onClick={() => handleOrgClick(org.id)} key={`${org.name}`}>
								{columns.map((col) =>
									org[col.key] !== null ? (
										<TableCellCondensed key={col.key}>
											{col.key.includes("Date")
												? new Date(org[col.key]).toLocaleDateString()
												: org[col.key]}
										</TableCellCondensed>
									) : (
										<TableCellCondensed>-</TableCellCondensed>
									),
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>

			<OrganizationModal
				open={isAddOrgModalOpen}
				onClose={() => setAddOrgModalOpen(false)}
			/>
		</Card>
	)
}

export default OrganizationDetailsTable
