import type { Patent } from "@/types"

interface PatentMetadataProps {
	patent: Patent
}

const PatentMetadata: React.FC<PatentMetadataProps> = ({ patent }) => {
	const metadataFields = [
		{
			key: "inventors",
			label: patent.inventors?.length > 1 ? "Inventors" : "Inventor",
			value: Array.isArray(patent.inventors)
				? patent.inventors.join(", ")
				: patent.inventors,
		},
		{
			key: "assignee",
			label: "Assignee",
			value: patent.assignee,
		},
		{
			key: "application_number",
			label: "Application",
			value: patent.applicationNumber,
		},
		{
			key: "primary_examiner",
			label: "Primary Examiner",
			value: patent.primaryExaminer,
		},
		{
			key: "cpc_codes",
			label: "CPC Codes",
			value: Array.isArray(patent.cpcCodes) ? patent.cpcCodes.join(", ") : patent.cpcCodes,
		},
	].filter((field) => field.value && field.value !== "")

	return (
		<div className="flex flex-wrap gap-2">
			{metadataFields.map((field, index) => (
				<div key={field.key} className="flex flex-wrap items-center gap-2">
					<span className="text-gray-500 text-sm whitespace-nowrap">{field.label}</span>
					<span className="text-gray-900 text-sm break-all">{field.value || "—"}</span>
					{index < metadataFields.length - 1 && <span className="text-gray-400">•</span>}
				</div>
			))}
		</div>
	)
}

export default PatentMetadata
