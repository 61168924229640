import { useAppStateStore } from "@/store"
import type { OfficeAction, Document as Patent } from "@/types"
import { Document, HeadingLevel, Packer, Paragraph, TextRun } from "docx"
import { saveAs } from "file-saver"
import { nanoid } from "nanoid"

/**
 * @description Exports office action draft to a Microsoft Word file
 * @param {OfficeAction} officeAction - The office action to export
 * @param {Document} document - The document details
 */
export const exportOfficeActionDraftToWord = async (
	officeAction: OfficeAction,
	patent: Patent,
) => {
	if (!patent || !officeAction) {
		return
	}

	const state = useAppStateStore.getState()
	const key = nanoid().toString()
	state.addLoadingMessage("Downloading office action draft...", key)

	try {
		const doc = new Document({
			sections: [
				{
					properties: {},
					children: [
						// Patent Information Section
						new Paragraph({
							heading: HeadingLevel.HEADING_1,
							children: [new TextRun({ text: "Patent Information", bold: true })],
						}),
						new Paragraph({
							children: [
								new TextRun({ text: "Name: ", bold: true }),
								new TextRun(patent.title || "N/A"),
							],
						}),
						new Paragraph({
							children: [
								new TextRun({ text: "Number: ", bold: true }),
								new TextRun(patent.patent.number || "N/A"),
							],
						}),
						new Paragraph({
							children: [
								new TextRun({ text: "Publication Date: ", bold: true }),
								new TextRun(patent.patent.publicationDate || "N/A"),
							],
						}),
						new Paragraph({
							children: [
								new TextRun({ text: "Inventors: ", bold: true }),
								new TextRun(patent.patent.inventors?.join(", ") || "N/A"),
							],
						}),
						new Paragraph({
							children: [
								new TextRun({ text: "Assignee: ", bold: true }),
								new TextRun(patent.patent.assignee || "N/A"),
							],
						}),
						new Paragraph({
							children: [
								new TextRun({ text: "Abstract: ", bold: true }),
								new TextRun(patent.patent.abstract || "N/A"),
							],
						}),

						// Office Action Information
						new Paragraph({
							heading: HeadingLevel.HEADING_1,
							children: [
								new TextRun({
									text: "\nOffice Action Information",
									bold: true,
								}),
							],
							spacing: { before: 400 },
						}),
						new Paragraph({
							children: [
								new TextRun({ text: "Notification Date: ", bold: true }),
								new TextRun(officeAction.notificationDate || "N/A"),
							],
						}),

						// Claims Section
						new Paragraph({
							heading: HeadingLevel.HEADING_1,
							children: [new TextRun({ text: "\nClaim Details", bold: true })],
							spacing: { before: 400 },
						}),
						...(officeAction.claimDetails?.flatMap(
							(claim: { reasons: any[]; claimNumber: string; claimText: string }) => [
								new Paragraph({
									heading: HeadingLevel.HEADING_2,
									children: [
										new TextRun({
											text: `Claim ${claim.claimNumber}`,
											bold: true,
										}),
									],
									spacing: { before: 300 },
								}),
								new Paragraph({
									children: [
										new TextRun({ text: "Claim Text: ", bold: true }),
										new TextRun(claim.claimText),
									],
								}),
								...claim.reasons.flatMap((reason) => [
									// Arguments
									...(reason.arguments?.length
										? [
												new Paragraph({
													children: [new TextRun({ text: "Arguments:", bold: true })],
													spacing: { before: 200 },
												}),
												...reason.arguments.map(
													(arg) =>
														new Paragraph({
															children: [new TextRun({ text: `• ${arg}` })],
															spacing: { before: 100 },
														}),
												),
											]
										: []),
									// Amendments
									...(reason.amendments?.length
										? [
												new Paragraph({
													children: [new TextRun({ text: "Amendments:", bold: true })],
													spacing: { before: 200 },
												}),
												...reason.amendments.map(
													(amendment) =>
														new Paragraph({
															children: [new TextRun({ text: amendment.text })],
															spacing: { before: 100 },
														}),
												),
											]
										: []),
								]),
							],
						) || []),
					],
				},
			],
		})

		const blob = await Packer.toBlob(doc)

		const fileName = `${patent.patent?.number || "patent"}_office-action_${
			officeAction.notificationDate || "export"
		}.docx`
		saveAs(blob, fileName)
	} catch (_error) {
		state.addErrorMessage("Error downloading office action draft. Try again later.")
	} finally {
		state.removeLoadingMessage(key)
	}
}
