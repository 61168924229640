/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { AdminPageLayout, NoAdminAccessMessage } from "@/features/admin/components"
import { OrganizationDetailsTable } from "@/features/admin/superadmin/components"
import { useUserStore } from "@/store"
import { RoleType } from "@/types"
import { PageName } from "@/types/pages"
import { useMemo } from "react"
import { useSuperAdminOrganizations } from "../hooks/useSuperAdminOrganizations"

interface SuperAdminOrganizationPageProps {
	pageName?: string
}

/**
 * SuperAdminOrganizationPage component handles the display of organization management
 * for super administrators.
 */
function SuperAdminOrganizationPage({
	pageName = "Super Admin - Organizations",
}: SuperAdminOrganizationPageProps) {
	// Hooks
	const { isLoading, organizations } = useSuperAdminOrganizations()
	const { role } = useUserStore()

	// Memoized values
	const isSuperAdmin = useMemo(() => role === RoleType.OWNER, [role])

	// Render helpers
	const renderContent = () => {
		if (!isSuperAdmin) return <NoAdminAccessMessage />
		if (isLoading) return <Loader />
		if (!organizations) return <div>No organizations found</div>

		return (
			<div className="space-y-8 mt-2">
				<OrganizationDetailsTable organizations={organizations} />
			</div>
		)
	}

	return (
		<AdminPageLayout isSuperAdmin={true} page={PageName.SuperAdminOverview}>
			{renderContent()}
		</AdminPageLayout>
	)
}

export default SuperAdminOrganizationPage
