/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { components } from "schema"
import type { KeysToCamelCase } from "./utils"

export enum RoleType {
	ADMIN = "Admin",
	OWNER = "Owner",
	MEMBER = "Member",
}

export type User = KeysToCamelCase<components["schemas"]["AuthUser"]>
export type UserMetadata = KeysToCamelCase<components["schemas"]["UserMetadata"]>
export type Organization = KeysToCamelCase<components["schemas"]["Organization"]>

export type AdminOverview = KeysToCamelCase<components["schemas"]["AdminOverview"]>

export type OrganizationPlan = KeysToCamelCase<
	components["schemas"]["OrganizationPlan"]
>
export type OrganizationSummary = KeysToCamelCase<
	components["schemas"]["OrganizationSummary"]
>

export type PortfolioSummary = KeysToCamelCase<
	components["schemas"]["PortfolioSummary"]
>
export type ProjectSummary = KeysToCamelCase<components["schemas"]["ProjectSummary"]>

// Billing
export type BillingCycleSummary = KeysToCamelCase<
	components["schemas"]["BillingCycleSummary"]
>
export type StripeInvoice = KeysToCamelCase<components["schemas"]["StripeInvoice"]>
export type StripeInvoiceLine = KeysToCamelCase<
	components["schemas"]["StripeInvoiceLine"]
>

export interface AdminBillingData {
	invoices: StripeInvoice[]
	plan: OrganizationPlan
	cycleSummary: BillingCycleSummary
}
