import { Button } from "@/components/ui/button"
import type { ProjectDocumentMetadata } from "@/types"
import { memo } from "react"
interface ReferenceTableActionsProps {
	isStandardsPortfolio: boolean
	isReferenceDisabled: boolean
	references: ProjectDocumentMetadata[]
	onPatch: () => void
	onPrune: () => void
	onSearch: () => void
}

export const ReferencesTableActions = memo(
	({
		isStandardsPortfolio,
		isReferenceDisabled,
		references,
		onPatch,
		onPrune,
		onSearch,
	}: ReferenceTableActionsProps) => {
		if (isStandardsPortfolio) return null

		return (
			<div className="flex gap-2 ml-2">
				<Button
					variant="outline"
					onClick={onPatch}
					disabled={isReferenceDisabled}
					className="h-9"
				>
					Patch
				</Button>
				{references?.length > 0 && (
					<Button
						variant="outline"
						onClick={onPrune}
						disabled={isReferenceDisabled}
						className="h-9"
					>
						Prune
					</Button>
				)}
				<Button
					variant="outline"
					onClick={onSearch}
					disabled={isReferenceDisabled}
					className="h-9"
				>
					Search
				</Button>
			</div>
		)
	},
)

ReferencesTableActions.displayName = "TableActions"
