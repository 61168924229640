import { Loader } from "@/components"
import { TableCellCondensed, TableRow } from "@/components/ui/table"
import { useInvaliditySummaryData } from "@/features/charts/hooks/queries/useInvaliditySummaryData"
import { usePortfolio } from "@/hooks"
import { ChartColor } from "@/types"
import type { PortfolioMatrixColumn } from "@/types/project"
import { ChevronDownIcon, ChevronRightIcon, Cross2Icon } from "@radix-ui/react-icons"
import type React from "react"

interface PortfolioMatrixRowProps {
	column: PortfolioMatrixColumn
	patentIds: string[]
	onCellClick: (subjectId: string, referenceId: string) => void
	onRowToggle: (id: string) => void
	isExpanded: boolean
	hideEmptyRows: boolean
}

export const PortfolioMatrixRow: React.FC<PortfolioMatrixRowProps> = ({
	column,
	patentIds,
	onCellClick,
	onRowToggle,
	isExpanded,
	hideEmptyRows,
}) => {
	const { projects } = usePortfolio()
	const expandedProjectId = isExpanded
		? projects?.find((p) => p.subjectId === column.subject.documentId)?.id
		: undefined

	const { data: summaryData, isLoading: isSummaryChartLoading } =
		useInvaliditySummaryData(expandedProjectId)

	const filteredSummaryData = hideEmptyRows
		? summaryData?.data?.filter((row) =>
				Object.values(row).some(
					(value) =>
						value !== ChartColor.GRAY && value !== "NONE" && value !== row.limitationNumber,
				),
			)
		: summaryData?.data

	return (
		<>
			<TableRow>
				<TableCellCondensed className="sticky left-0 bg-background font-medium whitespace-nowrap">
					<div className="flex items-center gap-2">
						<button
							type="button"
							onClick={() => onRowToggle(column.subject.documentId)}
							className="p-1 hover:bg-background rounded"
						>
							{isExpanded ? (
								<ChevronDownIcon className="w-4 h-4" />
							) : (
								<ChevronRightIcon className="w-4 h-4" />
							)}
						</button>
						{column.subject.nickname}
					</div>
				</TableCellCondensed>
				{patentIds.map((patentId) => (
					<TableCellCondensed
						key={patentId}
						className="p-2 min-w-[45px] cursor-pointer"
						onClick={() => {
							const priorArt = column.priorArts.find((art) => art.id === patentId)
							if (priorArt) onCellClick(column.subject.documentId, patentId)
						}}
					>
						<div
							className="h-7 rounded mx-auto text-center flex items-center justify-center"
							style={{
								backgroundColor: (() => {
									const priorArt = column.priorArts.find((art) => art.id === patentId)
									if (!priorArt || priorArt.color === "NONE") return "transparent"
									return adjustColorShade(priorArt.color, priorArt.percentage, column.priorArts)
								})(),
							}}
						>
							{(() => {
								const priorArt = column.priorArts.find((art) => art.id === patentId)
								if (
									!priorArt ||
									priorArt.color === ChartColor.GRAY ||
									priorArt.color === "NONE"
								) {
									return <Cross2Icon className="w-4 h-4 text-gray-500" />
								}
								return <div className="text-xs font-medium text-black">{priorArt.number}</div>
							})()}
						</div>
					</TableCellCondensed>
				))}
			</TableRow>
			{isExpanded &&
				(isSummaryChartLoading ? (
					<TableRow className="bg-accent">
						<TableCellCondensed colSpan={patentIds.length + 1} className="text-center">
							<Loader message="Fetching invalidity chart and navigating..." />
						</TableCellCondensed>
					</TableRow>
				) : (
					filteredSummaryData?.map((row, index) => (
						<TableRow
							key={`${column.subject.documentId}-summary-${index}`}
							className="bg-accent"
						>
							<TableCellCondensed className="sticky left-0 font-medium pl-8 bg-accent">
								<div className="sticky left-0 z-50 text-right">
									{row.limitationNumber?.match(/^[\d.]+[A-Za-z]?(?=\s|$)/)?.[0] ||
										row.limitationNumber}
								</div>
							</TableCellCondensed>
							{patentIds.map((patentId) => (
								<TableCellCondensed
									key={`${column.subject.documentId}-${patentId}-${index}`}
									className="p-2"
								>
									<div
										className="w-full h-6 rounded"
										style={{ backgroundColor: row[patentId] }}
									/>
								</TableCellCondensed>
							))}
						</TableRow>
					))
				))}
		</>
	)
}

// Utility function for adjusting color shade
const adjustColorShade = (
	baseColor: string,
	percentage: number,
	priorArts: PortfolioMatrixColumn["priorArts"],
): string => {
	if (!baseColor || baseColor === ChartColor.GRAY) return ChartColor.GRAY
	if (baseColor === "NONE") return "transparent"

	// Find all percentages for this color
	const allPercentages = priorArts
		.filter((art) => art.color === baseColor)
		.map((art) => art.percentage)

	// Calculate relative position
	const min = Math.min(...allPercentages)
	const max = Math.max(...allPercentages)
	const range = max - min

	// Convert to relative scale (0.4 to 1.0)
	// If range is 0, use middle value (0.7)
	const relativeScale = range === 0 ? 0.7 : 0.4 + ((percentage - min) / range) * 0.6

	// For hex colors
	if (baseColor.startsWith("#")) {
		const r = Number.parseInt(baseColor.slice(1, 3), 16)
		const g = Number.parseInt(baseColor.slice(3, 5), 16)
		const b = Number.parseInt(baseColor.slice(5, 7), 16)

		const newR = Math.round(r + (255 - r) * (1 - relativeScale))
		const newG = Math.round(g + (255 - g) * (1 - relativeScale))
		const newB = Math.round(b + (255 - b) * (1 - relativeScale))

		return `rgb(${newR}, ${newG}, ${newB})`
	}

	return baseColor
}

export default PortfolioMatrixRow
