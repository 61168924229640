/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { H4 } from "@/components/ui/typography"
import type { User } from "@/types"
import {
	CheckCircledIcon,
	CheckIcon,
	CrossCircledIcon,
	Pencil1Icon,
	PlusIcon,
	TrashIcon,
} from "@radix-ui/react-icons"
import { useState } from "react"
import {
	ActivateUserModal,
	AddUserModal,
	DeactivateUserModal,
	DeleteUserModal,
} from ".."
import { useAdminUsers } from "../../hooks/useAdminUsers"

interface UsersTableProps {
	orgName: string
	orgId: string
	users: User[]
}

/**
 * @description Table to display and manage users within an organization.
 * @param {string} orgName - Name of the organization to display users for.
 */
function UsersTable({ users, orgName, orgId }: UsersTableProps) {
	const { updateUserRole } = useAdminUsers(orgId)

	// Local state for modals and role editing
	const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
	const [isDeactivateModalOpen, setDeactivateModalOpen] = useState<boolean>(false)
	const [isActivateModalOpen, setActivateModalOpen] = useState<boolean>(false)
	const [isAddUserModalOpen, setAddUserModalOpen] = useState<boolean>(false)
	const [isEditingRole, setIsEditingRole] = useState<string>("")
	const [newRole, setNewRole] = useState<string>("")
	const [selectedUser, setSelectedUser] = useState<User | null>(null)

	/**
	 * @description Handles the role change for a user
	 */
	const handleRoleChange = async (userId: string, _email: string) => {
		updateUserRole(
			{ userId, role: newRole, orgId },
			{
				onSuccess: () => {
					setNewRole("")
					setIsEditingRole("") // close out editing mode
				},
			},
		)
	}

	// Simplified modal handlers - just manage state
	const handleDisable = (user: User) => {
		setSelectedUser(user)
		setDeactivateModalOpen(true)
	}

	const handleDelete = (user: User) => {
		setSelectedUser(user)
		setDeleteModalOpen(true)
	}

	const handleActivate = (user: User) => {
		setSelectedUser(user)
		setActivateModalOpen(true)
	}

	const handleAddUser = () => {
		setAddUserModalOpen(true)
	}

	const formatTimestamp = (timestamp: number | string | null): string => {
		if (!timestamp) return "N/A"
		const date = new Date(Number(timestamp) * 1000)
		const today = new Date()
		const yesterday = new Date(today)
		yesterday.setDate(yesterday.getDate() - 1)

		const timeOptions: Intl.DateTimeFormatOptions = {
			hour: "2-digit",
			minute: "2-digit",
			timeZoneName: "short",
		}

		if (date.toDateString() === today.toDateString()) {
			return `Today, ${date.toLocaleTimeString(undefined, timeOptions)}`
		}
		if (date.toDateString() === yesterday.toDateString()) {
			return `Yesterday, ${date.toLocaleTimeString(undefined, timeOptions)}`
		}
		return date.toLocaleString(undefined, {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			timeZoneName: "short",
		})
	}

	return (
		<Card>
			<CardHeader>
				<div className="flex justify-between">
					<H4>Users</H4>
					<div className="flex justify-end">
						<Button size="icon" onClick={handleAddUser}>
							<PlusIcon />
						</Button>
					</div>
				</div>
			</CardHeader>
			<CardContent>
				<div>
					{users && users.length > 0 ? (
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead>Email</TableHead>
									<TableHead>Role</TableHead>
									<TableHead>Status</TableHead>
									<TableHead>Created At</TableHead>
									<TableHead>Last Active At</TableHead>
									<TableHead className="flex justify-end" />
								</TableRow>
							</TableHeader>
							<TableBody>
								{users.map((user) => (
									<TableRow key={user.email}>
										<TableCellCondensed>{user.email}</TableCellCondensed>
										<TableCellCondensed>
											{isEditingRole === user.email ? (
												<Select value={newRole} onValueChange={(value) => setNewRole(value)}>
													<SelectTrigger>
														<SelectValue placeholder="Select role" />
													</SelectTrigger>
													<SelectContent>
														<SelectItem value="Admin">Admin</SelectItem>
														<SelectItem value="User">User</SelectItem>
													</SelectContent>
												</Select>
											) : (
												user.role
											)}
										</TableCellCondensed>
										<TableCellCondensed>
											{!user.emailConfirmed
												? "Pending email confirmation"
												: user.enabled
													? "Active"
													: "Disabled"}
										</TableCellCondensed>
										<TableCellCondensed>{formatTimestamp(user.createdAt)}</TableCellCondensed>
										<TableCellCondensed>{formatTimestamp(user.lastActiveAt)}</TableCellCondensed>
										<TableCellCondensed className="flex justify-end">
											<div className="flex gap-1">
												{isEditingRole === user.email ? (
													<Tooltip>
														<TooltipTrigger asChild>
															<Button
																size="icon"
																variant="outline"
																onClick={() => handleRoleChange(user.id, user.email)}
															>
																<CheckIcon />
															</Button>
														</TooltipTrigger>
														<TooltipContent>
															<p>Confirm role change</p>
														</TooltipContent>
													</Tooltip>
												) : (
													<Tooltip>
														<TooltipTrigger asChild>
															<Button
																size="icon"
																variant="outline"
																onClick={() => {
																	setIsEditingRole(user.email)
																	setNewRole(user.role)
																}}
															>
																<Pencil1Icon />
															</Button>
														</TooltipTrigger>
														<TooltipContent>
															<p>Edit user role</p>
														</TooltipContent>
													</Tooltip>
												)}
												{user.enabled ? (
													<Tooltip>
														<TooltipTrigger asChild>
															<Button
																variant="outline"
																size="icon"
																onClick={() => handleDisable(user)}
															>
																<CrossCircledIcon />
															</Button>
														</TooltipTrigger>
														<TooltipContent>
															<p>Deactivate user</p>
														</TooltipContent>
													</Tooltip>
												) : (
													<Tooltip>
														<TooltipTrigger asChild>
															<Button
																variant="outline"
																size="icon"
																onClick={() => handleActivate(user)}
															>
																<CheckCircledIcon />
															</Button>
														</TooltipTrigger>
														<TooltipContent>
															<p>Activate user</p>
														</TooltipContent>
													</Tooltip>
												)}
												<Tooltip>
													<TooltipTrigger asChild>
														<Button variant="outline" size="icon" onClick={() => handleDelete(user)}>
															<TrashIcon />
														</Button>
													</TooltipTrigger>
													<TooltipContent>
														<p>Delete user</p>
													</TooltipContent>
												</Tooltip>
											</div>
										</TableCellCondensed>
									</TableRow>
								))}
							</TableBody>
						</Table>
					) : (
						<p>No users found.</p>
					)}
				</div>
			</CardContent>

			{/* AddUserModal */}
			<AddUserModal
				open={isAddUserModalOpen}
				onClose={() => setAddUserModalOpen(false)}
				users={users}
				orgId={orgId}
				orgName={orgName}
			/>
			{/* DeactivateUserModal */}
			{selectedUser && (
				<DeactivateUserModal
					open={isDeactivateModalOpen}
					onClose={() => setDeactivateModalOpen(false)}
					userId={selectedUser.id}
					userEmail={selectedUser.email}
					orgId={orgId}
				/>
			)}
			{/* ActivateUserModal */}
			{selectedUser && (
				<ActivateUserModal
					open={isActivateModalOpen}
					onClose={() => setActivateModalOpen(false)}
					userId={selectedUser.id}
					userEmail={selectedUser.email}
					orgId={orgId}
				/>
			)}
			{/* DeleteUserModal */}
			{selectedUser && (
				<DeleteUserModal
					open={isDeleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
					userId={selectedUser.id}
					userEmail={selectedUser.email}
					orgId={orgId}
				/>
			)}
		</Card>
	)
}

export default UsersTable
