/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DocumentViewer, Loader } from "@/components"
import { DataTableCell } from "@/components/ui/table"
import {} from "@/components/ui/tooltip"
import { useFeedbackData } from "@/features/charts/hooks/useFeedbackData"
import { useInvaliditySettings, useProject } from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import type { ChunkToLimitation } from "@/types"
import { convertToUtcDateString } from "@/utils/dateUtils"
import { formatPatentNumber } from "@/utils/projectUtils"
import {} from "lucide-react"
import type React from "react"
import { useState } from "react"
import useInvalidityChart from "../hooks/mutations/useInvalidityChart"
import InvalidityTableCellCollapsibleCitations from "./InvalidityTableCellCollapsibleCitations"
import InvalidityTableCitation from "./InvalidityTableCitation"
import InvalidityTableCitationCellTooltips from "./InvalidityTableCitationCellTooltips"
import CopyCitationsModal from "./modals/CopyCitationsModal"
import EditHeaderFooterModal from "./modals/EditHeaderFooterModal"

interface InvalidityTableCitationCellProps {
	documentId: string
	claimLimitationId: string
	claimNumber: string
	claimText: string
	citations: ChunkToLimitation[]
}

/**
 * @description Reference table citation cell
 * @param {string} documentId - The ID of the document.
 * @param {string} claimLimitationId - The ID of the claim limitation.
 * @param {string} claimNumber - The claim number.
 * @param {string} claimText - The claim text.
 * @param {InvalidityCitation[]} citations - The citations.
 */
const InvalidityTableCitationCell: React.FC<InvalidityTableCitationCellProps> = ({
	documentId,
	claimLimitationId,
	claimNumber,
	claimText,
	citations = [],
}) => {
	const { projectId, projectSubject } = useProject()
	const { settings } = useInvaliditySettings()
	const { getDocumentName } = useDocumentNaming()
	const { findMoreCitationsForLimitation } = useInvalidityChart()
	// const { retrieveInvalidityForClaim } = useInvalidityChart()
	const { positiveFeedbackData, negativeFeedbackData } = useFeedbackData(
		projectId,
		documentId,
		claimLimitationId,
	)

	const [isInvalidityLoading, setIsInvalidityLoading] = useState(false)
	const [showNoMoreCitations, setShowNoMoreCitations] = useState(false)
	const [showDocumentViewer, setShowDocumentViewer] = useState(false)
	const [showCopyCitationsModal, setShowCopyCitationsModal] = useState<
		"append" | "replace" | null
	>(null)
	const [isHeaderFooterModalOpen, setIsHeaderFooterModalOpen] = useState(false)
	const [showAddCitationModal, setShowAddCitationModal] = useState(false)

	const shownCitations = citations.filter((citation) => !citation.removed)

	const removedCitations = citations.filter((citation) => citation.removed === true)
	const verifiedCitations = citations.filter((citation) => citation.verified === true)

	const formatHeaderFooter = (text: string) => {
		return text
			.replaceAll("{{element_number}}", claimNumber)
			.replaceAll("{{reference_name}}", getDocumentName(documentId))
			.replaceAll("{{element_language}}", claimText)
			.replaceAll("{{subject_nickname}}", projectSubject?.nickname)
			.replaceAll(
				"{{subject_number}}",
				formatPatentNumber(projectSubject?.document?.patent?.number),
			)
			.replaceAll("{{subject_nickname}}", projectSubject?.nickname)
			.replaceAll(
				"{{subject_number}}",
				formatPatentNumber(projectSubject?.document?.patent?.number),
			)
			.replaceAll(
				"{{subject_priority_date}}",
				convertToUtcDateString(projectSubject?.document?.patent?.priorityDate),
			)
	}

	const findMoreCitations = async () => {
		setIsInvalidityLoading(true)
		try {
			const positiveExamples = positiveFeedbackData.map(
				(citation) => citation.documentChunk.text,
			)
			const negativeExamples = negativeFeedbackData.map(
				(citation) => citation.documentChunk.text,
			)
			await findMoreCitationsForLimitation({
				claimLimitationId,
				documentIds: [documentId],
				positiveExamples,
				negativeExamples,
				header: shownCitations ? shownCitations[0].header : null,
				footer: shownCitations ? shownCitations[0].footer : null,
			})
		} finally {
			setIsInvalidityLoading(false)
		}
	}

	return (
		<DataTableCell className="gap-2">
			<div className="mb-4">
				{showNoMoreCitations && (
					<div className="mb-3 text-center">
						<p className="text-sm text-muted-foreground">
							No more citations found. Provide feedback to find more.
						</p>
					</div>
				)}
				{isInvalidityLoading ? (
					<div className="mb-2">
						<Loader message="Retrieving invalidity..." />
					</div>
				) : (
					<div className="flex items-center justify-center space-x-2 ">
						<InvalidityTableCitationCellTooltips
							isInvalidityLoading={isInvalidityLoading}
							showNoMoreCitations={showNoMoreCitations}
							onFindMore={findMoreCitations}
							onAddFromDocument={() => setShowDocumentViewer(true)}
							onAppendEvidence={() => setShowCopyCitationsModal("append")}
							onReplaceEvidence={() => setShowCopyCitationsModal("replace")}
							onEditHeaderFooter={() => setIsHeaderFooterModalOpen(true)}
						/>
						<DocumentViewer
							open={showDocumentViewer}
							handleClose={() => setShowDocumentViewer(false)}
							documentId={documentId}
							selectedLimitationId={claimLimitationId}
							startInChartMode={true}
						/>
					</div>
				)}
			</div>
			{settings?.headerFormat && (
				<p className="text-sm text-muted-foreground mb-4">
					{formatHeaderFooter(settings?.headerFormat)}
				</p>
			)}

			{shownCitations.map((citation, citationIndex) => (
				<InvalidityTableCitation
					key={`${1}-${documentId}-${claimLimitationId}-${citationIndex}`}
					citation={citation}
					setShowNoMoreCitations={setShowNoMoreCitations}
				/>
			))}

			{settings?.footerFormat && (
				<p className="text-sm text-muted-foreground mt-4 mb-3">
					{formatHeaderFooter(settings?.footerFormat)}
				</p>
			)}
			<InvalidityTableCellCollapsibleCitations
				positiveFeedbackData={positiveFeedbackData}
				negativeFeedbackData={negativeFeedbackData}
				removedCitations={removedCitations}
				verifiedCitations={verifiedCitations}
			/>

			<CopyCitationsModal
				open={showCopyCitationsModal !== null}
				handleClose={() => setShowCopyCitationsModal(null)}
				mode={showCopyCitationsModal}
				documentId={documentId}
				claimLimitationId={claimLimitationId}
				claimNumber={claimNumber}
			/>

			<EditHeaderFooterModal
				isOpen={isHeaderFooterModalOpen}
				onClose={() => setIsHeaderFooterModalOpen(false)}
				currentElementNumber={claimNumber}
				currentElementLanguage={claimText}
				claimLimitationId={claimLimitationId}
				documentId={documentId}
				cellHeader={shownCitations ? shownCitations[0]?.header : ""}
				cellFooter={shownCitations ? shownCitations[0]?.footer : ""}
			/>

			<DocumentViewer
				open={showDocumentViewer}
				handleClose={() => setShowDocumentViewer(false)}
				documentId={documentId}
				selectedLimitationId={claimLimitationId}
				startInChartMode={true}
			/>
		</DataTableCell>
	)
}

export default InvalidityTableCitationCell
