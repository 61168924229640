/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */

import { Loader } from "@/components"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { ScrollArea } from "@/components/ui/scroll-area"
import { ScrollTopButton } from "@/components/ui/scroll-top-button"
import { Separator } from "@/components/ui/separator"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import {
	useClaims,
	useEvalPipeline,
	useProject,
	useProjectAndPortfolioIds,
	useReferences,
} from "@/hooks"
import { useAppStateStore, useAreProcessesPending, useProjectStore } from "@/store"
import { ChartColor, conflictingChartProcesses } from "@/types"
import { PageName } from "@/types/pages"
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons"
import type React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import ClaimSelect from "../../components/claims/ClaimMultiSelect"
import ProjectPage from "../../components/projects/ProjectPage"
import {
	ColorSelect,
	ExportChartsModal,
	InvalidityTable,
	PruneInvalidityColorModal,
	PruneInvalidityScoreModal,
	SummaryTable,
} from "./components"
import DocumentSelect from "./components/selects/DocumentSelect"
import { useInvalidityChartData, useInvaliditySummaryData } from "./hooks"

/**
 * @description Renders the invalidity table page
 */
const InvalidityChartsPage: React.FC = () => {
	const { getDoesIprExist } = useEvalPipeline()
	const { invalidityChartData, isLoadingChartData, isErrorChartData } =
		useInvalidityChartData()
	const { project, projectPriorArtDocuments, projectSubject } = useProject()
	const { portfolioId, projectId } = useProjectAndPortfolioIds()
	const { projectClaims } = useClaims()
	const { deleteReferences } = useReferences()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()
	const {
		selectedReferences,
		updateSelectedReferences,
		updateSelectedLimitations,
		showAssertedClaims,
		selectedColors,
		updateSelectedColors,
	} = useProjectStore()

	const {
		data: summaryData,
		isLoading: isSummaryChartLoading,
		isError: isSummaryChartError,
	} = useInvaliditySummaryData(projectId ?? "", showAssertedClaims)

	const [showExportModal, setShowExportModal] = useState<boolean>(false)
	const [showPruneColorModal, setShowPruneColorModal] = useState(false)
	const [showPruneScoreModal, setShowPruneScoreModal] = useState(false)
	const [isSummaryExpanded, setIsSummaryExpanded] = useState(false)
	const [doesIprExist, setDoesIprExist] = useState(false)
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [rowVisibility, setRowVisibility] = useState<"show" | "hide">("show")
	const [showScrollTop, setShowScrollTop] = useState(false)

	const isActionDisabled = useAreProcessesPending(
		conflictingChartProcesses,
		projectId || null,
		portfolioId || null,
	)

	// Define all callbacks with useCallback here
	const handleReferenceSelect = useCallback(
		async (referenceIds: string[]) => {
			updateSelectedReferences(referenceIds)
		},
		[updateSelectedReferences],
	)

	const handleColorSelect = useCallback(
		async (colors: string[]) => {
			updateSelectedColors(colors)
		},
		[updateSelectedColors],
	)

	const handleClaimSelect = useCallback(
		async (claimIds: string[]) => {
			updateSelectedLimitations(claimIds)
		},
		[updateSelectedLimitations],
	)

	const selectChartDisplay = useCallback(
		async (claimLimitationId: string, documentId: string) => {
			if (claimLimitationId) {
				updateSelectedLimitations([claimLimitationId])
			}
			if (documentId) {
				updateSelectedReferences([documentId])
			}
		},
		[updateSelectedLimitations, updateSelectedReferences],
	)

	const handleRemoveReferenceFromProject = useCallback(
		async (referenceId: string) => {
			if (!referenceId || !projectId) return
			await deleteReferences([referenceId])
		},
		[projectId, deleteReferences],
	)

	const patentNumber = useMemo(
		() => projectSubject?.document?.patent?.number,
		[projectSubject?.document?.patent?.number],
	)

	useEffect(() => {
		if (!patentNumber) return

		let isSubscribed = true
		const checkIpr = async () => {
			try {
				// TODO: @DYLAN update this accordingly
				// const response = await getDoesIprExist(patentNumber)
				const response = {
					success: true,
					data: false,
				}
				if (isSubscribed && response.success) {
					setDoesIprExist(response.data)
				}
			} catch (_error) {
				if (isSubscribed) {
					addErrorMessage("Error checking if IPR exists")
				}
			}
		}

		checkIpr()
		return () => {
			isSubscribed = false
		}
	}, [patentNumber, getDoesIprExist, addErrorMessage])

	useEffect(() => {
		if (showAssertedClaims && projectClaims && projectClaims.length > 0) {
			updateSelectedLimitations(projectClaims)
		} else if (showAssertedClaims) {
			updateSelectedLimitations([])
		}
	}, [showAssertedClaims, projectClaims])

	useEffect(() => {
		// Only run this once on mount
		const initializeReferences = () => {
			updateSelectedReferences([])
		}
		initializeReferences()
	}, [])

	useEffect(() => {
		const scrollArea = document.querySelector("[data-radix-scroll-area-viewport]")
		let lastScrollY = 0

		const handleScroll = (e: Event) => {
			const target = e.target as HTMLElement
			const currentScrollY = target.scrollTop
			const isScrollingUp = currentScrollY < lastScrollY
			const isScrolledEnough = currentScrollY > 200

			setShowScrollTop(isScrollingUp && isScrolledEnough)
			lastScrollY = currentScrollY
		}

		if (scrollArea) {
			scrollArea.addEventListener("scroll", handleScroll, { passive: true })
			return () => scrollArea.removeEventListener("scroll", handleScroll)
		}
	}, [])

	const handleCloseMenu = () => {
		setDropdownOpen(false)
	}

	const handlePruneMenuClick = (pruneType: "color" | "score") => {
		handleCloseMenu()
		if (pruneType === "color") {
			setShowPruneColorModal(true)
		} else {
			setShowPruneScoreModal(true)
		}
	}

	// The summaryData object has shape:
	// { data: [], summaryChartHeaders: [], summaryChartRowHeightCollapsed: number }
	const chartData = summaryData?.data || []
	const chartHeaders = summaryData?.summaryChartHeaders || []
	const chartRowHeightCollapsed = summaryData?.summaryChartRowHeightCollapsed || 0

	if (!project) {
		return (
			<ProjectPage pageName={PageName.ProjectCharts}>
				<div className="flex justify-center items-center flex-col mt-12">
					<Loader message="Loading project data..." />
				</div>
			</ProjectPage>
		)
	}

	return (
		<ProjectPage pageName={PageName.ProjectCharts}>
			<div className="relative">
				<ScrollArea className="px-3 h-[calc(100vh-60px)]">
					<div className="space-y-2">
						<div className="w-full pt-2">
							<SummaryTable
								hideEmptyRows={false}
								data={chartData}
								summaryChartHeaders={chartHeaders}
								summaryChartRowHeightCollapsed={chartRowHeightCollapsed}
								selectedReferences={selectedReferences}
								handleSelect={selectChartDisplay}
								handleRemoveReferenceFromProject={handleRemoveReferenceFromProject}
								isExpanded={isSummaryExpanded}
								hideNonAsserted={showAssertedClaims}
							/>
						</div>
						<Separator className="my-2" />

						<div className="flex flex-wrap gap-2 items-center">
							<div className="flex items-center gap-2">
								<Tooltip>
									<TooltipTrigger asChild>
										<Button
											variant="outline"
											size="icon"
											onClick={() => setIsSummaryExpanded(!isSummaryExpanded)}
											className="h-9 w-9 flex-shrink-0"
										>
											{isSummaryExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
										</Button>
									</TooltipTrigger>
									<TooltipContent>
										{isSummaryExpanded ? <p>Collapse Summary</p> : <p>Expand Summary</p>}
									</TooltipContent>
								</Tooltip>
							</div>
							<ClaimSelect
								claims={projectClaims}
								onClaimSelect={handleClaimSelect}
								className="h-9"
							/>

							<DocumentSelect
								handleDocumentSelect={handleReferenceSelect}
								documents={projectPriorArtDocuments}
								className="h-9"
							/>
							<ColorSelect
								colors={[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED]}
								selectedColors={selectedColors}
								setSelectedColors={handleColorSelect}
								className="h-9"
							/>
							<DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
								<DropdownMenuTrigger asChild>
									<Button variant="outline" className="h-9" disabled={isActionDisabled}>
										Prune
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent>
									<DropdownMenuItem onClick={() => handlePruneMenuClick("color")}>
										By Color
									</DropdownMenuItem>
									<DropdownMenuItem onClick={() => handlePruneMenuClick("score")}>
										By Score
									</DropdownMenuItem>
								</DropdownMenuContent>
							</DropdownMenu>
							<Button
								onClick={() => setShowExportModal(true)}
								disabled={isActionDisabled}
								className="h-9"
							>
								Download
							</Button>
						</div>
						<div className="chart-container">
							{isLoadingChartData ? (
								<div className="flex justify-center items-center flex-col mt-12">
									<Loader message="Loading chart data..." />
								</div>
							) : selectedReferences.length > 0 ? (
								invalidityChartData ? (
									<div className="w-full overflow-x-auto">
										<InvalidityTable
											onFillStart={() => {}}
											onFillEnd={() => {}}
											rowVisibility={rowVisibility}
											chartData={invalidityChartData}
											isLoading={isLoadingChartData}
											key={selectedReferences.join(",")}
										/>
									</div>
								) : null
							) : (
								<div className="flex flex-col items-center justify-center mt-12">
									<p className="text-center">Select reference(s) to view chart</p>
								</div>
							)}
						</div>
					</div>
				</ScrollArea>

				<ScrollTopButton className="absolute bottom-4 left-6 z-50" show={showScrollTop} />
			</div>

			<ExportChartsModal
				open={showExportModal}
				handleClose={() => setShowExportModal(false)}
			/>
			<PruneInvalidityColorModal
				open={showPruneColorModal}
				onOpenChange={setShowPruneColorModal}
				documents={projectPriorArtDocuments}
			/>
			<PruneInvalidityScoreModal
				open={showPruneScoreModal}
				handleClose={() => setShowPruneScoreModal(false)}
				documents={projectPriorArtDocuments}
			/>
		</ProjectPage>
	)
}

export default InvalidityChartsPage
