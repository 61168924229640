import { Separator } from "@/components/ui/separator"
import type { ClaimStatusReason, ReferenceParagraphCitation } from "@/types"
import { memo } from "react"
import { ClaimDetailsCollapsibleRightHalf } from "./components/ClaimDetailsCollapsibleRightHalf"

interface ClaimDetailsCollapsibleProps {
	selectedReason: (ClaimStatusReason & { index: number; claimIndex: number }) | null
	reasons: ClaimStatusReason[]
	claimText: string
	status: string
	isCitationsSectionVisible: boolean
	isEditing: boolean
}

export const ClaimDetailsCollapsible = memo(
	({
		selectedReason,
		reasons,
		claimText,
		status,
		isCitationsSectionVisible,
		isEditing,
	}: ClaimDetailsCollapsibleProps) => {
		if (!selectedReason) return null

		const isObjected = status.toLowerCase() === "objected"
		const citationsSection = RenderCitations(
			isObjected
				? reasons.flatMap((r) => r.referenceCitations ?? [])
				: (selectedReason.referenceCitations ?? []),
		)
		const rightHalfOpen = isEditing || (!isEditing && isCitationsSectionVisible)

		return (
			<div className="mt-2 mb-3 space-y-4">
				<div className="flex gap-4">
					{/* Left half: Claim Language + Examiner Commentary */}
					<div className={`space-y-8 ${rightHalfOpen ? "w-1/2 mr-2" : "w-full px-[4vw]"}`}>
						<div className="space-y-2">
							<p className="text-sm font-bold">Claim Language</p>
							<p className="text-sm text-justify">{claimText}</p>
						</div>
						<div className="space-y-2">
							<p className="text-sm font-bold">Examiner Commentary</p>
							{isObjected ? (
								<div className="space-y-4">
									{reasons.map((reason, index) => (
										<div key={index}>
											<p className="text-sm whitespace-pre-line text-justify">
												{reason.examinerCommentary[index]}
											</p>
											{index < reasons.length - 1 && <Separator className="my-2" />}
										</div>
									))}
								</div>
							) : (
								<p className="text-sm whitespace-pre-line text-justify">
									{selectedReason.examinerCommentary[0]}
								</p>
							)}
						</div>
					</div>

					{/* Right half: Cited Art Paragraphs and Drafting */}
					{rightHalfOpen && (
						<ClaimDetailsCollapsibleRightHalf
							claimText={claimText}
							selectedReason={selectedReason as ClaimStatusReason}
							isEditing={isEditing}
							isCitationsSectionVisible={isCitationsSectionVisible}
							citationsSection={citationsSection}
						/>
					)}
				</div>
			</div>
		)
	},
)

const RenderCitations = (citations: ReferenceParagraphCitation[]) =>
	citations.map((citation, index, array) => (
		<div key={index}>
			<div className="relative">
				<div
					className="absolute left-0 top-1 w-3 h-3 rounded-sm"
					style={{ backgroundColor: citation.color }}
				/>
				<p className="text-sm whitespace-pre-line pl-4">
					<span className="text-muted-foreground">
						{`${citation.referenceNickname} [${citation.paragraphNumber}]`}
					</span>
					{`: ${citation.paragraphText}`}
				</p>
			</div>
			{index < array.length - 1 && <Separator className="my-4" />}
		</div>
	))

ClaimDetailsCollapsible.displayName = "ReasonsCollapsible"
