/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import type { DocumentChunk } from "@/types"
import { formatDocumentChunkLocation } from "@/utils/projectUtils"
import type React from "react"
import { useState } from "react"

interface DeleteCitationFromAllElementsModalProps {
	open: boolean
	handleClose: () => void
	handleDelete: (citationId: string) => Promise<void>
	chunk: DocumentChunk
}

/**
 * @description Delete citation from all elements modal
 *
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} handleDelete - Function to delete the citation from all elements.
 * @param {InvalidityCitation} citation - The citation to delete.
 * @param {boolean} isPatent - Whether the citation is a patent.
 */
const DeleteCitationFromAllElementsModal: React.FC<
	DeleteCitationFromAllElementsModalProps
> = ({ open, handleClose, handleDelete, chunk }) => {
	const [isLoading, setIsLoading] = useState(false)

	const handleDeleteClick = async () => {
		setIsLoading(true)
		try {
			await handleDelete(chunk.id)
		} catch (error) {
			console.error("Error deleting project:", error)
		} finally {
			setIsLoading(false)
			handleClose()
		}
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-w-4xl">
				<DialogHeader>
					<DialogTitle>Remove all citation instances?</DialogTitle>
					<DialogDescription>
						This action cannot be undone. All instances of the below citation will be removed
						from all elements in the chart.
					</DialogDescription>
				</DialogHeader>

				<div className="max-h-[300px] overflow-y-auto">
					<p className="text-sm font-bold mb-1">
						{formatDocumentChunkLocation(chunk.location)}
					</p>
					<p className="text-sm mb-1">{chunk.text}</p>
				</div>

				<DialogFooter>
					<Button variant="outline" onClick={handleClose} disabled={isLoading}>
						Cancel
					</Button>
					<LoadingButton
						variant="destructive"
						onClick={handleDeleteClick}
						disabled={isLoading}
						loading={isLoading}
					>
						{isLoading ? "Removing..." : "Remove"}
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default DeleteCitationFromAllElementsModal
