import { Label } from "@/components/ui/label"
import { CondensedParagraph } from "@/components/ui/typography"
import type { Patent } from "@/types"
import type React from "react"

/**
 * @description Subject abstract and images component
 * @param {object} details - The details to display
 */
interface PatentAbstractImagesProps {
	patent: Patent
	showTitle?: boolean
	citationText?: string
	highlightedRef?: React.RefObject<HTMLDivElement>
	className?: string
}

const PatentAbstract: React.FC<PatentAbstractImagesProps> = ({
	patent,
	showTitle = true,
	citationText,
	highlightedRef,
	className,
}) => {
	return (
		<>
			{patent.abstract && (
				<div className="mb-4">
					<Label className="mb-2">Abstract</Label>
					<CondensedParagraph>
						{citationText && patent?.abstract ? (
							<>
								{patent?.abstract
									.split(new RegExp(citationText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i"))
									.map((part, i) =>
										part.toLowerCase() === citationText?.toLowerCase() ? (
											<span key={i} className="bg-blue-100">
												{part}
											</span>
										) : (
											part
										),
									)}
							</>
						) : (
							patent?.abstract
						)}
					</CondensedParagraph>
				</div>
			)}
		</>
	)
}

export default PatentAbstract
