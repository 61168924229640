/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, SidebarPage } from "@/components"
import SearchDocumentsComponent from "@/features/search/SearchDocumentsComponent"
import useDocument from "@/hooks/useDocument"
import { PageContentBox } from "@/styled"
import { useParams } from "react-router-dom"
import UserDocumentPageHeader from "./components/UserDocumentPageHeader"

/**
 * @description Renders the user document search page
 */
const UserDocumentsSearchPage = () => {
	const { documentId } = useParams()
	const { document, isLoading, isError } = useDocument(documentId)
	const pageName = document?.document.title
		? `${document.document.title} - Search`
		: "Search"

	return (
		<SidebarPage page={pageName}>
			<UserDocumentPageHeader name={document?.document.title || "Untitled"} />
			{isLoading ? (
				<Loader />
			) : isError ? (
				<div>Error loading document</div>
			) : (
				<PageContentBox overflowy="hidden">
					<SearchDocumentsComponent />
				</PageContentBox>
			)}
		</SidebarPage>
	)
}
export default UserDocumentsSearchPage
