import { TableCell, TableRow } from "@/components/ui/table"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import type { Claim } from "@/types"
import { CheckCircledIcon } from "@radix-ui/react-icons"
import type React from "react"
import ClaimLimitation from "./ClaimLimitation"

interface ClaimProps {
	claim: Claim
	editingBool?: boolean
	isHighlighted?: boolean
	highlightedRef?: React.MutableRefObject<HTMLTableRowElement | null> | null
}

const ClaimRow: React.FC<ClaimProps> = ({
	claim,
	editingBool,
	isHighlighted,
	highlightedRef,
}) => {
	return (
		<TableRow
			ref={highlightedRef || null}
			className={`
        ${isHighlighted ? "bg-blue-100" : ""}
        ${claim.isIndependent ? "border-t-2 border-gray-200" : ""}
        hover:bg-gray-50 transition-colors
      `}
		>
			<TableCell className="py-4">
				{claim.limitations?.map((limitation) => (
					<ClaimLimitation
						key={limitation.id}
						limitation={limitation}
						isClaimIndependent={claim.isIndependent}
					/>
				))}
			</TableCell>

			{editingBool && (
				<TableCell className="w-[10px] align-top">
					{claim.isAsserted && (
						<Tooltip>
							<TooltipTrigger asChild>
								<CheckCircledIcon className="h-4 w-4 text-blue-500" />
							</TooltipTrigger>
							<TooltipContent>Asserted</TooltipContent>
						</Tooltip>
					)}
				</TableCell>
			)}
		</TableRow>
	)
}

export default ClaimRow
