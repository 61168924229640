import { processApi } from "@/api/processApi"
import {
	useFileUtils,
	useOfficeAction,
	usePatentDetails,
	useProcessDocuments,
} from "@/hooks"
import { useAppStateStore } from "@/store"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { ProjectType, UnprocessedDocumentType } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
/**
 * @description Hook for handling project creation
 * @todo add back OA handling
 */
const useCreateProject = ({
	unprocessedDocuments,
	unprocessedSubjectDocuments,
	unprocessedOfficeActionDocuments,
}) => {
	const navigate = useNavigate()
	const { convertToDocumentsToProcess } = useProcessDocuments()
	const { getUploadedFileKeysAndUrls } = useFileUtils()
	const { getOfficeActionApplicationNumber } = useOfficeAction()
	const { getPatentDetails } = usePatentDetails()
	const { addErrorMessage } = useAppStateStore()
	// Project details
	const [projectName, setProjectName] = useState<string>("")
	const [clientNumber, setClientNumber] = useState<string>("")
	const [projectType, setProjectType] = useState<ProjectType>(undefined) // INV, APP, or SEP
	const [spinnerText, setSpinnerText] = useState<string>("")
	const [selectedDocumentType, setSelectedDocumentType] =
		useState<UnprocessedDocumentType | null>(null)

	// Reset function
	const resetCreateProjectState = () => {
		setProjectName("")
		setClientNumber("")
		setProjectType(undefined)
		setSpinnerText("")
		setSelectedDocumentType(null)
	}

	// Update functions
	const updateProjectName = (name: string) => setProjectName(name)
	const updateClientNumber = (number: string) => setClientNumber(number)
	const updateProjectType = (type: ProjectType) => setProjectType(type)

	const queryClient = useQueryClient()

	const createProjectMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.create(),
		mutationFn: async () => {
			const documentsToProcess = await convertToDocumentsToProcess(unprocessedDocuments)

			return processApi.createProject(
				projectName,
				projectType,
				clientNumber,
				documentsToProcess,
				false,
			)
		},
		onMutate: () => {
			setSpinnerText(
				`Creating ${unprocessedSubjectDocuments.length > 1 ? "portfolio" : "project"}...`,
			)
		},
		onSuccess: (rProjectId) => {
			// Invalidate active projects
			queryClient.invalidateQueries({ queryKey: QUERY_KEYS.projects.active() })

			if (unprocessedSubjectDocuments.length > 1) {
				navigate(`/portfolio/${rProjectId}/subjects`)
			} else {
				navigate(`/project/${rProjectId}/subject`)
			}
		},
		onError: () => {
			addErrorMessage("Error creating project from number")
		},
	})

	const getSubjectFromOfficeAction = async (file: File) => {
		const file_keys = await getUploadedFileKeysAndUrls([file])

		const applicationNumber = await getOfficeActionApplicationNumber(
			file_keys[file.name].s3_key,
		)

		const detailsResponse = await getPatentDetails([applicationNumber], true)
		return {
			details: detailsResponse,
			success: !detailsResponse.error,
		}
	}

	return {
		// Project details
		projectName,
		updateProjectName,
		clientNumber,
		updateClientNumber,
		projectType,
		updateProjectType,

		// Project errors
		projectError: createProjectMutation.isError,

		// Project creation
		isProjectCreationInProgress: createProjectMutation.isPending,
		spinnerText,
		resetCreateProjectState,
		createProject: createProjectMutation.mutateAsync,

		// Selected document type
		setSelectedDocumentType,
		selectedDocumentType,

		getSubjectFromOfficeAction,
	}
}

export default useCreateProject
