/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import Sidebar from "@/components/sidebar/Sidebar"
import { usePlatformUtils } from "@/hooks"
import { MainContentBox, PageContainer } from "@/styled"
import { PAGE_DISPLAY_NAMES, type Pages } from "@/types/pages"
import type React from "react"
import type { ReactNode } from "react"
import AlertMessages from "../alerts/AlertMessages"

/**
 * @description The sidebar page component
 * @param {ReactNode} children - The children of the component
 */
interface SidebarPageProps {
	children: ReactNode
	page: Pages | string
}

const SidebarPage: React.FC<SidebarPageProps> = ({ children, page }) => {
	const { usePageTitle } = usePlatformUtils()
	usePageTitle(PAGE_DISPLAY_NAMES[page] || page)
	return (
		<PageContainer>
			<Sidebar />
			<MainContentBox>{children}</MainContentBox>
			<AlertMessages />
		</PageContainer>
	)
}

export default SidebarPage
