import { api } from "@/api"
import { useNavigationState } from "@/hooks"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

export function useProjectAndPortfolioIds() {
	const { projectId: routeProjectId, portfolioId } = useParams<{
		projectId: string
		portfolioId: string
	}>()
	const { isDocumentsPage } = useNavigationState()

	const personalProjectQuery = useQuery({
		queryKey: QUERY_KEYS.personalProject(),
		queryFn: () => api.getUserPersonalProjectId(),
		enabled: isDocumentsPage,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		projectId: isDocumentsPage ? personalProjectQuery.data : (routeProjectId ?? null),
		portfolioId: portfolioId ?? null,
		isLoadingPersonalProject: personalProjectQuery.isLoading,
		isErrorPersonalProject: personalProjectQuery.isError,
	}
}

export default useProjectAndPortfolioIds
