/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import type React from "react"
import { useState } from "react"

interface DeleteProjectModalProps {
	open: boolean
	handleClose: () => void
	handleDelete: () => Promise<void>
	name: string
}

/**
 * @description Delete project modal
 *
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} handleDelete - Function to delete the project.
 * @param {string} name - The name of the project to delete.
 */
const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({
	open,
	handleClose,
	handleDelete,
	name = "",
}) => {
	const [isLoading, setIsLoading] = useState(false)

	const handleDeleteClick = async () => {
		setIsLoading(true)
		try {
			await handleDelete()
			handleClose()
		} catch (error) {
			console.error("Error deleting project:", error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-w-2xl">
				<DialogHeader>
					<DialogTitle>Delete project {name ? `"${name}"` : ""}?</DialogTitle>
					<DialogDescription>
						This action cannot be undone. All users on this project will immediately lose
						access. All project data will be deleted and cannot be recovered.
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button variant="outline" onClick={handleClose} disabled={isLoading}>
						Cancel
					</Button>
					<LoadingButton
						variant="destructive"
						onClick={handleDeleteClick}
						disabled={isLoading}
						loading={isLoading}
					>
						{isLoading ? "Deleting..." : "Delete"}
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default DeleteProjectModal
