import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useInvalidityChart } from "@/features/charts/hooks"
import { useFeedbackChartDataStore } from "@/features/charts/hooks/useFeedbackChartDataStore"
import { useProjectAndPortfolioIds } from "@/hooks"
import { cn } from "@/lib/utils"
import { ChartColor, type ChunkToLimitation } from "@/types"
import { Cross2Icon, PlusIcon } from "@radix-ui/react-icons"
import { BadgeCheckIcon, ThumbsDownIcon, ThumbsUpIcon } from "lucide-react"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useCallback, useMemo, useState } from "react"

interface InvalidityCitationActionsProps {
	citation: ChunkToLimitation
	showVerify?: boolean
	showRemove?: boolean
	showFeedback?: boolean
	showColor?: boolean
	children?: React.ReactNode
}

const CitationActionButton: React.FC<{
	onClick: () => void
	icon: React.ReactNode
	tooltipText: string
	isActive?: boolean
	disabled?: boolean
	activeColor?: string
}> = ({ onClick, icon, tooltipText, isActive, disabled, activeColor }) => (
	<Tooltip>
		<TooltipTrigger asChild>
			<div>
				<Button variant="ghost" size="icon" onClick={onClick} disabled={disabled}>
					{React.cloneElement(icon as React.ReactElement, {
						className: cn("h-4 w-4", isActive && activeColor),
					})}
				</Button>
			</div>
		</TooltipTrigger>
		<TooltipContent>{tooltipText}</TooltipContent>
	</Tooltip>
)

const InvalidityCitationActions: React.FC<InvalidityCitationActionsProps> = ({
	citation,
	showVerify = true,
	showRemove = true,
	showFeedback = true,
	showColor = true,
	children,
}) => {
	const { updateCitation } = useInvalidityChart()
	const { feedbackChartData, removeFeedbackCitation, addFeedbackCitation } =
		useFeedbackChartDataStore()
	const { projectId } = useProjectAndPortfolioIds()

	// Color picker state
	const [isChangingColor, setIsChangingColor] = useState<boolean>(false)

	// Feedback logic
	const documentId = useMemo(() => citation.documentChunk.documentId, [citation])
	const claimLimitationId = useMemo(() => citation.claimLimitationId, [citation])
	const getCitationFeedback = useCallback(
		(cit: ChunkToLimitation) =>
			feedbackChartData[projectId]?.[documentId]?.[claimLimitationId]?.find(
				(c) => c.documentChunk.id === cit.documentChunk.id,
			)?.feedback,
		[feedbackChartData, projectId, documentId, claimLimitationId],
	)

	const [citationFeedback, setCitationFeedback] = useState<
		"positive" | "negative" | undefined
	>(getCitationFeedback(citation))

	const handleColorChange = (color: string) => {
		updateCitation({
			documentId: citation.documentChunk.documentId,
			claimId: citation.claimLimitation.claimId,
			documentChunkId: citation.documentChunk.id,
			claimLimitationId: citation.claimLimitationId,
			options: {
				color,
			},
		})
		setIsChangingColor(false)
	}

	const handleFeedbackClick = (
		citation: ChunkToLimitation,
		type: "positive" | "negative",
	) => {
		const currentFeedback = getCitationFeedback(citation)
		if (currentFeedback === type) {
			removeFeedbackCitation(
				projectId,
				citation.documentChunk.documentId,
				citation.claimLimitationId,
				citation.documentChunk.id,
			)
			setCitationFeedback(undefined)
		} else {
			addFeedbackCitation(projectId, documentId, claimLimitationId, {
				...citation,
				feedback: type,
			})
			setCitationFeedback(type)
		}
	}

	const handleToggleVerifyCitation = async () => {
		await updateCitation({
			documentId: citation.documentChunk.documentId,
			claimId: citation.claimLimitation.claimId,
			documentChunkId: citation.documentChunk.id,
			claimLimitationId: citation.claimLimitationId,
			options: { verified: !citation.verified },
		})
	}

	const handleToggleRemoveCitation = async () => {
		await updateCitation({
			documentId: citation.documentChunk.documentId,
			claimId: citation.claimLimitation.claimId,
			documentChunkId: citation.documentChunk.id,
			claimLimitationId: citation.claimLimitationId,
			options: { removed: !citation.removed },
		})
	}

	return (
		<div className="flex gap-4 items-start">
			{/* Color Picker Section */}
			{showColor && (
				<div className="flex-shrink-0">
					<Popover open={isChangingColor} onOpenChange={setIsChangingColor}>
						<PopoverTrigger asChild>
							<div>
								<Button
									variant="ghost"
									size="icon"
									style={{
										backgroundColor: citation.color || "defaultBackgroundColor",
									}}
									onClick={() => setIsChangingColor(true)}
								/>
							</div>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-1">
							<div className="flex flex-row">
								{Object.values(ChartColor)
									.filter((color) => color !== ChartColor.GRAY)
									.map(
										(color) =>
											citation.color !== color && (
												<div
													key={color}
													className="w-9 h-9 m-1 cursor-pointer rounded-md"
													style={{ backgroundColor: color }}
													onClick={() => handleColorChange(color)}
												/>
											),
									)}
							</div>
						</PopoverContent>
					</Popover>
				</div>
			)}

			{/* Document chunk */}
			<div className="flex-1">{children}</div>

			{/* Action Buttons */}
			<div className="flex-shrink-0 flex flex-col gap-2">
				<div className="flex gap-1">
					{showVerify && (
						<CitationActionButton
							onClick={handleToggleVerifyCitation}
							icon={<BadgeCheckIcon />}
							tooltipText="Verify citation"
							isActive={citation.verified}
							activeColor="text-blue-500"
						/>
					)}
					{showRemove && (
						<CitationActionButton
							onClick={handleToggleRemoveCitation}
							icon={citation.removed ? <PlusIcon /> : <Cross2Icon />}
							tooltipText={citation.removed ? "Add citation back" : "Remove citation"}
						/>
					)}
				</div>
				{showFeedback && (
					<div className="flex gap-1">
						<CitationActionButton
							onClick={() => handleFeedbackClick(citation, "positive")}
							icon={<ThumbsUpIcon />}
							tooltipText="Positive feedback"
							isActive={citationFeedback === "positive"}
							activeColor="text-green-500"
						/>
						<CitationActionButton
							onClick={() => handleFeedbackClick(citation, "negative")}
							icon={<ThumbsDownIcon />}
							tooltipText="Negative feedback"
							isActive={citationFeedback === "negative"}
							activeColor="text-red-500"
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default InvalidityCitationActions
