import type { ProjectMetadata } from "@/types"
import type { QueryClient } from "@tanstack/react-query"
import { QUERY_KEYS } from "./keys"

export const invalidateUserRelatedQueries = (
	queryClient: QueryClient,
	orgId?: string,
) => {
	queryClient.invalidateQueries({ queryKey: QUERY_KEYS.admin.users(orgId) })
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.admin.overview(orgId),
	})
}

export const invalidateClaimRelatedQueries = (
	queryClient: QueryClient,
	projectId: string,
) => {
	queryClient.invalidateQueries({
		queryKey: [
			QUERY_KEYS.project.claims(projectId),
			QUERY_KEYS.project.settings(projectId),
			//only invalidate if isFeature is flase and type is claim for items below
			QUERY_KEYS.invalidity.chartData(projectId),
			QUERY_KEYS.invalidity.summaryData(projectId),
		],
	})
}

export const invalidateInvalidityRelatedQueries = (
	queryClient: QueryClient,
	projectId: string,
	selectedReferences: string[],
	selectedLimitations: string[],
	selectedColors: string[],
) => {
	// Reset the infinite query for chart data
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.invalidity.chartData(
			projectId,
			selectedReferences,
			selectedLimitations,
			selectedColors,
		),
	})
	// Regular invalidation for summary data is fine since it's not paginated
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.invalidity.summaryData(projectId),
	})
}

export const invalidateSummaryData = (queryClient: QueryClient, projectId: string) => {
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.invalidity.summaryData(projectId),
	})
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.invalidity.chartData(projectId),
	})
}

export const invalidateOrgRelatedQueries = (
	queryClient: QueryClient,
	orgId?: string,
) => {
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.admin.overview(orgId),
	})
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.admin.projects(orgId),
	})
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.admin.billing(orgId),
	})
}

/**
 * @description Invalidates queries related to project document references.
 * Useful for forcing refetch of references in useProject or anywhere else that relies on them.
 */
export const invalidateDocumentReferences = (
	queryClient: QueryClient,
	projectId: string,
) => {
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.project.documents(projectId),
	})
}

export const invalidateUserProjectRelatedQueries = (queryClient: QueryClient) => {
	queryClient.invalidateQueries({ queryKey: QUERY_KEYS.projects.active() })
	queryClient.invalidateQueries({ queryKey: QUERY_KEYS.projects.archived() })
}

export const invalidateProjectNameRelatedQueries = (
	queryClient: QueryClient,
	portfolioId: string,
) => {
	queryClient.invalidateQueries({ queryKey: QUERY_KEYS.projects.active() })
	queryClient.invalidateQueries({ queryKey: QUERY_KEYS.projects.archived() })
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.portfolio.metadata(portfolioId),
	})
}

export const invalidateQueriesContainingId = (queryClient: QueryClient, id: string) => {
	// Get all active queries from the cache
	const queries = queryClient.getQueryCache().findAll()

	// Invalidate any query whose key (serialized to string) contains the id
	queries.forEach((query) => {
		if (JSON.stringify(query.queryKey).includes(id)) {
			queryClient.invalidateQueries({ queryKey: query.queryKey })
		}
	})
}

export const invalidateDocumentRelatedQueries = (
	queryClient: QueryClient,
	documentId: string,
	projectId?: string,
) => {
	// First invalidate any queries containing the document ID
	invalidateQueriesContainingId(queryClient, documentId)

	// If projectId is provided, check if this document is the project's subject
	if (projectId) {
		const projectData: ProjectMetadata = queryClient.getQueryData(
			QUERY_KEYS.project.metadata(projectId),
		)
		if (projectData?.subjectId === documentId) {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.subject(projectId),
			})
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.metadata(projectId),
			})
		}
	}
}
