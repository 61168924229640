/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import {
	usePortfolio,
	usePortfolioDocuments,
	useProject,
	useProjectAndPortfolioIds,
} from "@/hooks"
import { DocumentRole } from "@/types"
import { PageName } from "@/types/pages"
import type React from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import { ReferencesTable } from "./components"

/**
 * @description Reference details page for invalidity project or portfolio
 */
const ReferencesPage: React.FC = () => {
	const { portfolioId, projectId } = useProjectAndPortfolioIds()
	const { projectPriorArtDocuments, isLoading } = useProject()
	const { isLoading: portfolioIsLoading } = usePortfolio()
	const { priorArtDocuments } = usePortfolioDocuments(portfolioId)

	const references = projectId
		? projectPriorArtDocuments.filter(
				(ref) => ref.documentRole === DocumentRole.PRIOR_ART,
			)
		: priorArtDocuments
	const isLoadingData = projectId
		? isLoading.projectReferences
		: portfolioIsLoading.portfolioDocuments

	if (isLoadingData) {
		return (
			<ProjectPage pageName={PageName.ProjectReferences}>
				<div className="flex justify-center items-center flex-col mt-12">
					<Loader message="Loading..." />
				</div>
			</ProjectPage>
		)
	}

	return (
		<ProjectPage pageName={PageName.ProjectReferences}>
			{references && <ReferencesTable documents={references} />}
		</ProjectPage>
	)
}

export default ReferencesPage
