import { api } from "@/api"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { ApiResponse } from "@/types"
import { useApi } from "./"

/**
 * @description Hook for handling evaluation and IPR operations
 * @returns {object} - The eval operations
 */
const useEvalPipeline = () => {
	const { handleError } = useApi()
	// const { updateChartData } = useProjectStore();

	/**
	 * @description Checks if there exists a final written decision for the given subject patent
	 * @param {string} patentNumber - The patent number to check for
	 */
	const getDoesIprExist = async (patentNumber: string): Promise<ApiResponse> => {
		try {
			if (!patentNumber) {
				return { success: false, message: "Patent number is required" }
			}
			const exists = await api.getDoesIprExist(patentNumber)
			return { success: true, data: exists }
		} catch (error) {
			return handleError(error, "Error checking if IPR exists")
		}
	}

	/**
	 * @description Returns model evaluation for a given project and references
	 * @param {string} projectId - The id of the project to evaluate
	 * @param {array} referenceIds - The ids of the references associated with the project
	 * @param {string} patentNumber - The patent number to evaluate
	 */
	const getEvaluation = async (
		projectId: string,
		referenceIds: string[],
		patentNumber: string,
	): Promise<ApiResponse> => {
		try {
			const rawEvaluation: any = await api.getEvaluation(
				projectId,
				referenceIds,
				patentNumber,
			)
			const transformedEvaluation = rawEvaluation.map((item: any) => ({
				claim: item.Claim,
				claimLanguage: item["Claim Language"],
				modelReferences: item["Model References"],
				pdfReferences: item["PDF References"],
				missingReferences: item["Missing References"],
				incorrectReferences: item["Incorrect References"],
				accuracy: item.Accuracy,
				precision: item.Precision,
				recall: item.Recall,
			}))

			// updateChartData(transformedEvaluation);
			return {
				success: true,
				data: transformedEvaluation,
			}
		} catch (error) {
			return handleError(error, "Error fetching evaluation data")
		}
	}

	/**
	 * @description Returns the remaining paragraphs for a given project and reference
	 * @param {string} projectId - The id of the project to get remaining paragraphs for
	 * @param {string} referenceId - The id of the reference to get remaining paragraphs for
	 */
	const getRemainingParagraphs = async (
		projectId: string,
		referenceId: string,
	): Promise<ApiResponse> => {
		try {
			const response = await api.getRemainingParagraphs(projectId, referenceId)
			return {
				success: true,
				data: response,
			}
		} catch (error) {
			return handleError(error, "Error fetching remaining paragraphs")
		}
	}

	/**
	 * @description Processes paragraphs as invalidities for a given project and reference
	 * @param {string} projectId - The id of the project to process paragraphs as invalidities for
	 * @param {string} invalidityId - The id of the invalidity to process paragraphs as
	 * @param {string} referenceId - The id of the reference to process paragraphs as
	 * @param {string} iprNumber - The patent number to process paragraphs as
	 * @param {string[]} paragraphNumbers - The paragraph numbers to process as invalidities
	 */
	const processParagraphsAsInvalidities = async (
		projectId: string,
		invalidityId: string,
		referenceId: string,
		iprNumber: string,
		paragraphNumbers: string[],
	): Promise<ApiResponse> => {
		try {
			const response = await api.processParagraphsAsInvalidities(
				projectId,
				invalidityId,
				referenceId,
				iprNumber,
				paragraphNumbers,
			)

			return {
				success: true,
				data: response,
			}
		} catch (error) {
			return handleError(error, "Error processing paragraphs as invalidities")
		}
	}

	return {
		getDoesIprExist,
		getEvaluation,
		getRemainingParagraphs,
		processParagraphsAsInvalidities,
	}
}

export default useEvalPipeline
