/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import {} from "@/components/ui/context-menu"
import {} from "@/components/ui/table"
import { H4 } from "@/components/ui/typography"
import { usePortfolio, usePortfolioDocuments } from "@/hooks"
import { PageName } from "@/types/pages"
import {} from "@radix-ui/react-icons"
import type React from "react"
import { useState } from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import { PortfolioMatrixTable } from "./PortfolioMatrixTable"

const PortfolioOverviewPage: React.FC = () => {
	const [hideEmptyRows, setHideEmptyRows] = useState(true)
	const { portfolioId, portfolioName, portfolio, isLoading } = usePortfolio()
	const { priorArtDocuments } = usePortfolioDocuments(portfolioId)

	const pageName = portfolioName ? `${portfolioName} - Portfolio` : "Portfolio"

	return (
		<ProjectPage pageName={PageName.PortfolioOverview}>
			<div className="bg-background">
				{isLoading.portfolioMatrix ? (
					<div className="mt-10">
						<Loader message="Loading..." />
					</div>
				) : (
					<>
						{!portfolio?.projects?.length ? (
							<div className="flex justify-center items-center flex-col mt-12">
								<H4>This portfolio has no subjects.</H4>
							</div>
						) : priorArtDocuments?.length > 0 ? (
							<div className="h-[calc(100vh-55px)] overflow-auto">
								<PortfolioMatrixTable
									hideEmptyRows={hideEmptyRows}
									setHideEmptyRows={setHideEmptyRows}
								/>
							</div>
						) : (
							<div className="flex justify-center items-center flex-col mt-12">
								<H4>This portfolio has no references.</H4>
							</div>
						)}
					</>
				)}
			</div>
		</ProjectPage>
	)
}

export default PortfolioOverviewPage
