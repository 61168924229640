/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { formatPatentNumber } from "@/utils"
import { useProject } from "."
import usePortfolioDocuments from "./references/usePortfolioDocuments"
import useProjectDocuments from "./references/useProjectDocuments"
import useProjectAndPortfolioIds from "./useProjectAndPortfolioIds"

const useDocumentNaming = () => {
	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const { priorArtDocuments: projectPriorArtDocuments } = useProjectDocuments(
		projectId || "",
	)
	const { projectSubject } = useProject()
	const { priorArtDocuments: portfolioDocuments } = usePortfolioDocuments(
		portfolioId || "",
	)

	const getDocumentName = (id: string): string => {
		if (projectId) {
			const doc =
				projectPriorArtDocuments?.find((ref) => ref.documentId === id) ||
				(projectSubject.documentId === id ? projectSubject : null)

			return (
				doc?.nickname ||
				formatPatentNumber(doc?.document.patent.number) ||
				doc?.document.title ||
				""
			)
		}

		if (portfolioId) {
			return getDocumentPortfolioName(id)
		}

		return ""
	}

	const getDocumentPortfolioName = (id: string): string => {
		const doc = portfolioDocuments?.find((ref) => ref.documentId === id)
		return (
			doc?.nickname ||
			formatPatentNumber(doc?.document.patent.number) ||
			doc?.document.title ||
			""
		)
	}

	return { getDocumentName, getDocumentPortfolioName }
}

export default useDocumentNaming
