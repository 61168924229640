import { api } from "@/api"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import type { ApiResponse } from "@/types"

/**
 * @description Hook for handling references
 */
const useElementStrengths = () => {
	const { handleError } = useApi()

	const getElementStrengths = async (
		project_id: string,
	): Promise<
		ApiResponse<{
			claims: { weak: any[]; strong: any[] }
		}>
	> => {
		try {
			const response: any = await api.getElementStrengths(project_id)
			return { success: true, data: response }
		} catch (error) {
			return handleError(error, "Error getting element strengths")
		}
	}

	return {
		getElementStrengths,
	}
}

export default useElementStrengths
