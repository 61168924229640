/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"
import { DocumentStatus } from "@/types"
import { TrashIcon } from "lucide-react"
import { useState } from "react"
import { Spinner } from "../ui/spinner"
import { TableColumn } from "./TableColumn"
import { TableFilter } from "./TableFilter"
import { TableSort } from "./TableSort"
import { TableTags } from "./TableTags"

export function TableActionBar({
	table,
	selectedRowsCount,
	enableRowDelete,
	onDeleteSelected,
	children,
	multiSorting,
	setMultiSorting,
	filters,
	handleTagUpdate,
	tags,
	showTag,
	showColumns,
	showReprocess,
	showRechart,
	title,
	isLoading,
	onReprocessSelected,
	onDismissSelected,
}) {
	const [selectedTags, setSelectedTags] = useState<string[]>([])
	const [tagOptions, setTagOptions] = useState<string[]>(tags)

	const hasReprocessableRows = () => {
		const selectedRows = table.getSelectedRowModel().rows
		return selectedRows.some((row) =>
			[DocumentStatus.REPROCESS, DocumentStatus.RECHART].includes(row.original.status),
		)
	}

	const statusStyle =
		"inline-flex items-center justify-center whitespace-nowrap text-sm font-medium h-9 px-3 py-2 text-pr hover:bg-blue-500 hover:text-white transition-colors cursor-pointer border-l border-input dark:border-white"

	return (
		<div className="flex items-center justify-between p-2 w-full">
			<div className={cn("flex items-center", title ? "w-full" : "space-x-1 gap-1")}>
				{title && (
					<p className="text-base font-medium p-1 h-auto whitespace-nowrap pl-2">{title}</p>
				)}
				<div className={cn("flex space-x-1 gap-1", title && "ml-auto")}>
					{showColumns && <TableColumn table={table} />}

					<TableSort
						table={table}
						multiSorting={multiSorting}
						setMultiSorting={setMultiSorting}
					/>

					{Object.keys(filters).length > 0 && (
						<TableFilter table={table} filters={filters} />
					)}

					{((selectedRowsCount > 0 && enableRowDelete) || isLoading) && (
						<div className="flex items-center rounded-md bg-accent border border-input h-9">
							<div className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium h-9 px-4 py-2 text-pr">
								<span>{selectedRowsCount} selected</span>
							</div>

							<>
								{showReprocess && (
									<div className={statusStyle} onClick={() => onReprocessSelected(true)}>
										<span>Reprocess</span>
									</div>
								)}

								{showRechart && (
									<div className={statusStyle} onClick={() => onReprocessSelected(false)}>
										<span>Rechart</span>
									</div>
								)}

								{hasReprocessableRows() && (
									<div className={statusStyle} onClick={onDismissSelected}>
										<span>Reset status</span>
									</div>
								)}
								{showTag && (
									<TableTags
										tagOptions={tagOptions}
										selectedTags={selectedTags}
										setSelectedTags={setSelectedTags}
										onClose={handleTagUpdate}
										onApply={handleTagUpdate}
									/>
								)}
								<Tooltip>
									<TooltipTrigger>
										{isLoading ? (
											<div className="h-9 w-9 flex items-center justify-center bg-red-500 text-white transition-colors cursor-pointer border-l border-input rounded-r-md dark:border-white">
												<Spinner className="h-4 w-4 text-white" />
											</div>
										) : (
											<div
												className="h-9 w-9 flex items-center justify-center hover:bg-red-500 hover:text-white transition-colors cursor-pointer border-l border-input rounded-r-md dark:border-white"
												onClick={onDeleteSelected}
											>
												<TrashIcon className="h-4 w-4" />
											</div>
										)}
									</TooltipTrigger>
									<TooltipContent>Delete</TooltipContent>
								</Tooltip>
							</>
						</div>
					)}
				</div>
			</div>

			<div className="flex items-center space-x-1 gap-1 pl-1">{children}</div>
		</div>
	)
}
