/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DataTableCell, DataTableRowOutline } from "@/components/ui/table"
import type { ChunkToLimitation, ClaimLimitation } from "@/types"
import { parseHtml } from "@/utils/parseHtml"
import type React from "react"
import { useMemo } from "react"
import InvalidityTableCitationCell from "./InvalidityTableCitationCell"

// Define the types for the props
interface InvalidityTableLimitationRowProps {
	limitation: ClaimLimitation
	chunkToLimitations: {
		[documentId: string]: ChunkToLimitation[]
	}
	rowVisibility: string
}

/**
 * @description Data table row
 * @param {Record<string, any>} row - The row to display.
 * @param {number} rowIndex - The index of the row.
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 */
const InvalidityTableLimitationRow: React.FC<InvalidityTableLimitationRowProps> = ({
	limitation,
	chunkToLimitations,
	rowVisibility,
}) => {
	if (!limitation) return null

	const limitationId = useMemo(() => limitation.id, [limitation])

	const hasAnyCitations = () => {
		return Object.values(chunkToLimitations).some((citations) => citations.length > 0)
	}

	return (
		<DataTableRowOutline
			key={limitationId}
			className={`${!hasAnyCitations() && rowVisibility === "collapse" ? "h-[40px]" : ""}`}
			style={{ position: "relative" }}
		>
			<DataTableCell key={`${limitationId}-claim_number`}>
				<span>[{limitation.label}]</span>
			</DataTableCell>
			<DataTableCell
				key={`${limitationId}-claim_text`}
				className={`align-top max-w-[400px] ${!hasAnyCitations() && rowVisibility === "collapse" ? "truncate" : ""}`}
			>
				<div
					className={`overflow-x-auto ${!hasAnyCitations() && rowVisibility === "collapse" ? "truncate" : ""}`}
				>
					<span>{parseHtml(limitation.text)}</span>
				</div>
				<div className="mt-2">
					<span className="text-sm text-gray-600 italic">{limitation.commentary}</span>
				</div>
			</DataTableCell>

			{Object.entries(chunkToLimitations).map(([documentId, citations]) => (
				<InvalidityTableCitationCell
					key={`${limitationId}-${documentId}`}
					documentId={documentId}
					claimNumber={limitation.label}
					claimText={limitation.text}
					claimLimitationId={limitationId}
					citations={citations}
				/>
			))}
		</DataTableRowOutline>
	)
}

export default InvalidityTableLimitationRow
