import { useCallback } from "react"

const usePlatformUtils = () => {
	const usePageTitle = useCallback((title: string): void => {
		document.title = `${title} - &AI`
	}, [])

	return {
		usePageTitle,
	}
}

export default usePlatformUtils
