import { useProjectStore } from "@/store"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { Claim, InvalidityClaimRowData } from "@/types"
import type React from "react"
import InvalidityTableLimitationRow from "./InvalidityTableLimitationRow"

interface InvalidityTableClaimRowProps {
	row: InvalidityClaimRowData // { [limitationId: string]: { [documentId: string]: ChunkToLimitation[] } }
	claim: Claim
	rowVisibility: string
}

/**
 * @description Data table row
 * @param {Record<string, any>} row - The row to display.
 * @param {number} rowIndex - The index of the row.
 * @param {function} fetchReferenceChartData - Function to fetch the reference chart data.
 */
const InvalidityTableClaimRow: React.FC<InvalidityTableClaimRowProps> = ({
	row,
	rowVisibility,
	claim,
}) => {
	const { selectedLimitations } = useProjectStore()
	return (
		<>
			{claim?.limitations
				.filter(
					(limitation) =>
						selectedLimitations.length === 0 || selectedLimitations.includes(limitation.id),
				)
				.map((limitation) => (
					<InvalidityTableLimitationRow
						key={`${limitation.id}`}
						chunkToLimitations={row[limitation.id]}
						rowVisibility={rowVisibility}
						limitation={limitation}
					/>
				))}
		</>
	)
}

export default InvalidityTableClaimRow
