import { Button } from "@/components/ui/button"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import {
	AlignVerticalSpaceAround,
	CopyPlusIcon,
	PlusIcon,
	ReplaceIcon,
	SearchIcon,
} from "lucide-react"
import type React from "react"

interface TooltipButtonProps {
	icon: React.ReactNode
	tooltipText: string
	onClick: () => void
	disabled?: boolean
	srText?: string
}

const TooltipButton: React.FC<TooltipButtonProps> = ({
	icon,
	tooltipText,
	onClick,
	disabled = false,
	srText,
}) => (
	<Tooltip>
		<TooltipTrigger asChild>
			<Button variant="outline" size="icon" disabled={disabled} onClick={onClick}>
				{icon}
				{srText && <span className="sr-only">{srText}</span>}
			</Button>
		</TooltipTrigger>
		<TooltipContent>
			<p>{tooltipText}</p>
		</TooltipContent>
	</Tooltip>
)

interface InvalidityTableCitationCellTooltipsProps {
	isInvalidityLoading: boolean
	showNoMoreCitations: boolean
	onFindMore: () => void
	onAddFromDocument: () => void
	onAppendEvidence: () => void
	onReplaceEvidence: () => void
	onEditHeaderFooter: () => void
}

const InvalidityTableCitationCellTooltips: React.FC<
	InvalidityTableCitationCellTooltipsProps
> = ({
	isInvalidityLoading,
	showNoMoreCitations,
	onFindMore,
	onAddFromDocument,
	onAppendEvidence,
	onReplaceEvidence,
	onEditHeaderFooter,
}) => {
	const isDisabled = isInvalidityLoading || showNoMoreCitations

	return (
		<div className="flex items-center justify-center space-x-2">
			<TooltipButton
				icon={<SearchIcon className="h-4 w-4" />}
				tooltipText="Find more"
				onClick={onFindMore}
				disabled={isDisabled}
				srText="Find more citations"
			/>
			<TooltipButton
				icon={<PlusIcon className="h-4 w-4" />}
				tooltipText="Add from document"
				onClick={onAddFromDocument}
				disabled={isDisabled}
				srText="Add citation"
			/>
			<TooltipButton
				icon={<CopyPlusIcon className="h-4 w-4" />}
				tooltipText="Append evidence"
				onClick={onAppendEvidence}
				disabled={isDisabled}
				srText="Copy evidence from"
			/>
			<TooltipButton
				icon={<ReplaceIcon className="h-4 w-4" />}
				tooltipText="Replace evidence"
				onClick={onReplaceEvidence}
				disabled={isDisabled}
				srText="Replace evidence"
			/>
			<TooltipButton
				icon={<AlignVerticalSpaceAround className="h-4 w-4" />}
				tooltipText="Edit header/footer"
				onClick={onEditHeaderFooter}
				disabled={isDisabled}
				srText="Edit header/footer"
			/>
		</div>
	)
}

export default InvalidityTableCitationCellTooltips
