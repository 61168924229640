/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { api } from "@/api"
import { DocumentMetadataTable, Loader } from "@/components"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import { useProject } from "@/hooks"
import { useAppStateStore } from "@/store"
import type { ProjectDocumentMetadata } from "@/types"
import type React from "react"
import { useEffect, useState } from "react"

interface PruneReferencesModalProps {
	open: boolean
	onOpenChange: (open: boolean) => void
}

/**
 * @description Prune references modal
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} onOpenChange - Function to close the modal
 */
const PruneReferencesModal: React.FC<PruneReferencesModalProps> = ({
	open,
	onOpenChange,
}) => {
	const { updateProjectDetails, projectId } = useProject()
	const { addErrorMessage } = useAppStateStore()

	const [error, setError] = useState<string | boolean>(false)
	const [isSourcesLoading, setIsSourcesLoading] = useState(false)
	const [foundSources, setFoundSources] = useState<ProjectDocumentMetadata[]>([])
	const [selectedReferences, setSelectedReferences] = useState<
		ProjectDocumentMetadata[]
	>([])
	const [isPruneLoading, setIsPruneLoading] = useState(false)

	// Fetch sources to prune on modal open
	useEffect(() => {
		const pruneResponse = async () => {
			try {
				setIsSourcesLoading(true)
				const documentsToPrune: any = await api.pruneReferences(projectId)
				setFoundSources(documentsToPrune)
			} catch (_error) {
				addErrorMessage("Failed to fetch sources to prune.")
			} finally {
				setIsSourcesLoading(false)
			}
		}

		if (open) {
			resetModal()
			pruneResponse()
		}
	}, [open])

	const handlePruneReferences = async (references: string[]) => {
		setIsPruneLoading(true)
		const _response = await updateProjectDetails({
			projectId: projectId,
			options: { old_references: references },
		})
		setIsPruneLoading(false)
		handleClose()
	}

	const resetModal = () => {
		setError(false)
		setFoundSources([])
		setSelectedReferences([])
		setIsSourcesLoading(false)
		setIsPruneLoading(false)
	}

	const handleClose = () => {
		resetModal()
		onOpenChange(false)
	}

	const handleRowSelection = (selectedRows: ProjectDocumentMetadata[]) => {
		setSelectedReferences(selectedRows)
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col min-w-[600px]">
				<DialogHeader>
					<DialogTitle className="flex items-center gap-2">Prune references</DialogTitle>
				</DialogHeader>

				{isSourcesLoading ? (
					<Loader message={"Finding sources to prune..."} />
				) : (
					<div className="flex flex-col h-[calc(85vh-200px)]">
						{foundSources?.length > 0 ? (
							<DocumentMetadataTable
								documents={foundSources}
								height="calc(85vh-175px)"
								onRowSelection={handleRowSelection}
								isSearch={true}
							/>
						) : (
							<div className="mt-4 text-center">
								<p>No references found to prune. Prune manually from the table or charts.</p>
							</div>
						)}
					</div>
				)}

				{error && (
					<Alert variant="destructive">
						<AlertDescription>{error}</AlertDescription>
					</Alert>
				)}

				<DialogFooter>
					{foundSources?.length > 0 && (
						<>
							{!isPruneLoading && (
								<Button
									onClick={() =>
										handlePruneReferences(foundSources.map((source) => source.documentId))
									}
									variant={selectedReferences.length === 0 ? "default" : "outline"}
									disabled={isPruneLoading}
								>
									Prune All
								</Button>
							)}
							<LoadingButton
								onClick={() =>
									handlePruneReferences(selectedReferences.map((ref) => ref.documentId))
								}
								disabled={selectedReferences.length === 0 || isPruneLoading}
							>
								{isPruneLoading ? "Pruning..." : "Prune Selected"}
							</LoadingButton>
						</>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default PruneReferencesModal
