import type { Process, ProcessType } from "@/types"
import { useMemo } from "react"
import { create } from "zustand"

type ProcessStore = {
	processes: Process[]
	addProcess: (process: Omit<Process, "status">) => void
	updateProcess: (id: string, status: Process["status"]) => void
	removeProcess: (id: string) => void
}

export const useProcessStore = create<ProcessStore>((set, _get) => ({
	processes: [],
	addProcess: (process) =>
		set((state) => ({
			processes: [...state.processes, { ...process, status: "pending" }],
		})),
	updateProcess: (id, status) =>
		set((state) => ({
			processes: state.processes.map((p) => (p.id === id ? { ...p, status } : p)),
		})),
	removeProcess: (id) =>
		set((state) => ({
			processes: state.processes.filter((p) => p.id !== id),
		})),
}))

export const useAreProcessesPending = (
	types: ProcessType[],
	projectId?: string | null,
	portfolioId?: string | null,
) => {
	const processes = useProcessStore((state) => state.processes)

	return useMemo(
		() =>
			processes.some(
				(p) =>
					types.includes(p.type as ProcessType) &&
					p.status === "pending" &&
					((portfolioId && p.portfolioId === portfolioId) ||
						(projectId && p.projectId === projectId)),
			),
		[processes, types, projectId, portfolioId],
	)
}

export default useProcessStore
