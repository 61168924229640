import { apiClient } from "@/api/client"
import { useAppStateStore } from "@/store"
import { useAuthInfo, useLogoutFunction } from "@propelauth/react"
import { useEffect } from "react"

export function useSetupApiAuth() {
	const { accessToken } = useAuthInfo()
	const logout = useLogoutFunction()
	const { addErrorMessage } = useAppStateStore()

	useEffect(() => {
		// Whenever the token changes, update the API service
		if (accessToken) {
			apiClient.setAuthToken(accessToken)
		}
	}, [accessToken])

	useEffect(() => {
		// Set up 401 handling so the user is logged out automatically
		apiClient.setUnauthorizedHandler(async () => {
			await logout(true)
			addErrorMessage("Your session has expired. Please sign in again.")
		})
	}, [logout, addErrorMessage])
}
