import { Checkbox } from "@/components/ui/checkbox"
import type { OfficeAction } from "@/types"
import type { ColumnDef } from "@tanstack/react-table"

export const getCommonColumns = (): ColumnDef<OfficeAction, any>[] => [
	{
		id: "select",
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllPageRowsSelected()}
				onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
				aria-label="Select all"
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={(value) => row.toggleSelected(!!value)}
				aria-label="Select row"
				onClick={(e) => e.stopPropagation()}
			/>
		),
		enableSorting: false,
		enableHiding: false,
	},
]

export const getOfficeActionColumns = (): ColumnDef<OfficeAction, any>[] => [
	{
		accessorKey: "document.notificationDate",
		header: "Notification Date",
		cell: ({ row }) => {
			const doc = row.original
			if (doc?.notificationDate) {
				return doc.notificationDate
					? new Date(doc.notificationDate).toLocaleDateString("en-US", {
							year: "numeric",
							month: "short",
							day: "numeric",
						})
					: "—"
			}
			return "—"
		},
	},
	{
		accessorKey: "document.primaryExaminer",
		header: "Primary Examiner",
		cell: ({ row }) => {
			const doc = row.original
			return doc.primaryExaminer ?? "—"
		},
	},
]
