import { chartsApi } from "@/api/chartsApi"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi, useProject, useProjectAndPortfolioIds } from "@/hooks"
import { useAppStateStore, useProcessStore } from "@/store"
import { type ApiResponse, ProcessType } from "@/types"
import { nanoid } from "nanoid"

export const useInvalidityChartExport = () => {
	const { handleError } = useApi()
	const { addErrorMessage } = useAppStateStore()
	const { addProcess, removeProcess } = useProcessStore()
	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const { project } = useProject()

	const getInvalidityChartExport = async (
		includeClaims: boolean,
		includeSummary: boolean,
		introductionLanguage: string,
		documentIds: string[],
		colors: string[],
		claims: string[],
		exportType: string,
		isCitationBefore: boolean,
		patentCitationFormat: string,
		applicationCitationFormat: string,
		documentCitationFormat: string,
		foreignDocumentCitationFormat: string,
		figureFormat: string,
		figurePlacement: string,
		titleFormat: string,
	): Promise<ApiResponse> => {
		const key = nanoid()
		try {
			addProcess({
				id: key,
				type: ProcessType.DOWNLOAD_CHART,
				projectId: projectId,
				portfolioId: portfolioId,
			})
			const response: any = await chartsApi.getProjectExport(
				projectId,
				exportType,
				includeClaims,
				includeSummary,
				introductionLanguage,
				documentIds,
				colors,
				claims,
				isCitationBefore,
				patentCitationFormat,
				applicationCitationFormat,
				documentCitationFormat,
				foreignDocumentCitationFormat,
				figureFormat,
				figurePlacement,
				titleFormat,
			)

			const downloadFile = async (url: string, fileType: string) => {
				const fileResponse = await fetch(url)
				if (!fileResponse.ok) throw new Error(`Failed to download the ${fileType} file`)
				const fileBlob = await fileResponse.blob()
				const localUrl = window.URL.createObjectURL(fileBlob)
				const link = document.createElement("a")
				link.href = localUrl
				const fileName = project.name.replace(/[\s']/g, "")
				const extension = fileType === "excel" ? "xlsx" : "zip"
				link.setAttribute("download", `${fileName}_${fileType}.${extension}`)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				window.URL.revokeObjectURL(localUrl)
			}

			if (response.urls) {
				// Handle multiple URLs
				if (response.urls.word) {
					await downloadFile(response.urls.word, "word")
				}
				if (response.urls.excel) {
					await downloadFile(response.urls.excel, "excel")
				}
			} else if (response.url) {
				// Handle single URL
				await downloadFile(response.url, exportType)
			} else {
				if (process.env.NODE_ENV !== "production") {
					console.error("No download URL found")
				}
				addErrorMessage("Error downloading file. Try again later.")
				return
			}
			return { success: true, data: response }
		} catch (error) {
			return handleError(error, "Error getting element chart export for word")
		} finally {
			removeProcess(key)
		}
	}

	return {
		getInvalidityChartExport,
	}
}

export default useInvalidityChartExport
