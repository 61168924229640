/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react"

/**
 * @description Hook for handling uploading files
 * @param {number} maxFiles - The maximum number of files to upload
 * @returns {object} - The file handler operations
 */
const useFileHandler = (maxFiles?: number) => {
	const [files, setFiles] = useState<File[]>([])
	const [fileTypeError, setFileTypeError] = useState<string | null>(null)
	const [fileLimitError, setFileLimitError] = useState<string>("")

	// Handle file selection and validation
	const handleFileChange = (selectedFiles: File[]) => {
		// Check file limit
		const currentFileCount = files.length
		const newFileCount = selectedFiles.length
		const totalFiles = currentFileCount + newFileCount

		if (maxFiles && totalFiles > maxFiles) {
			setFileLimitError(`You can only upload up to ${maxFiles} file(s).`)
			return
		}
		setFileLimitError("")

		const validFiles: File[] = []
		let errorMessage = ""

		for (const file of selectedFiles) {
			const isValidType = file.type === "application/pdf" || file.type === "text/plain"
			if (isValidType) {
				validFiles.push(file)
			} else {
				errorMessage += `${file.name}: Unsupported file type. Please upload PDF or TXT files. `
			}
		}

		setFiles((prevFiles) => [...prevFiles, ...validFiles])
		setFileTypeError(errorMessage || null)
		return validFiles
	}

	// Remove a selected file
	const handleRemoveFile = (fileToRemove: File) => {
		setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove))
		// Reset fileLimitError when a file is removed
		setFileLimitError("")
	}

	// Reset all state
	const resetAll = () => {
		setFiles([])
		setFileTypeError(null)
		setFileLimitError("")
	}

	return {
		files,
		fileTypeError,
		fileLimitError,
		handleFileChange,
		handleRemoveFile,
		resetAll,
	}
}

export default useFileHandler
