import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import { useClaims } from "@/hooks"
import { useUndoRedo } from "@/hooks/useUndoRedo"
import { useAppStateStore } from "@/store"
import type { ClaimEdit, ClaimLimitationEdit } from "@/types"
import { Redo, Undo } from "lucide-react"
import { useState } from "react"
import ClaimEditor from "./ClaimEditor"

interface UpdateClaimsModalProps {
	open: boolean
	onClose: () => void
}

function UpdateClaimsModal({ open, onClose }: UpdateClaimsModalProps) {
	const { addSuccessMessage, addErrorMessage } = useAppStateStore()
	const {
		projectClaims,
		updateClaims,
		isLoading: { updateClaims: isUpdatingClaims },
	} = useClaims()

	// Replace the claims useState with useUndoRedo
	const {
		present: claims,
		canUndo,
		canRedo,
		set: setClaims,
		undo,
		redo,
		reset: resetClaims,
	} = useUndoRedo<ClaimEdit[]>((projectClaims as ClaimEdit[]) || [])

	const [isLoading, setIsLoading] = useState(false)

	// Reset state when modal opens/closes
	const handleOpenChange = (isOpen: boolean) => {
		if (isOpen) {
			resetClaims(projectClaims || [])
		}
		onClose()
	}

	// New handler for bulk save
	const handleSave = async () => {
		try {
			setIsLoading(true)
			await updateClaims(claims)
			addSuccessMessage("Claims updated successfully")
			onClose()
		} catch (error) {
			addErrorMessage("Failed to update claims")
		} finally {
			setIsLoading(false)
		}
	}

	// Handlers to pass down to ClaimEditor
	const handleClaimUpdate = (updatedClaim: ClaimEdit) => {
		const newClaims = claims.map((claim) =>
			claim.id === updatedClaim.id ? updatedClaim : claim,
		)
		setClaims(newClaims)
	}

	const handleClaimDelete = (claimId: string) => {
		const newClaims = claims.filter((claim) => claim.id !== claimId)
		setClaims(newClaims)
	}

	const handleLimitationUpdate = ({
		claimLimitationId,
		options,
	}: { claimLimitationId: string; options: ClaimLimitationEdit }) => {
		const newClaims = claims.map((claim) => ({
			...claim,
			limitations: claim.limitations?.map((limitation) =>
				limitation.id === claimLimitationId ? { ...limitation, ...options } : limitation,
			),
		}))
		setClaims(newClaims)
	}

	const handleLimitationDelete = (limitationId: string) => {
		const newClaims = claims.map((claim) => ({
			...claim,
			limitations: claim.limitations?.filter(
				(limitation) => limitation.id !== limitationId,
			),
		}))
		setClaims(newClaims)
	}

	const handleAddLimitation = (claimId: string, limitation: ClaimLimitationEdit) => {
		const newClaims = claims.map((claim) =>
			claim.id === claimId
				? { ...claim, limitations: [...(claim.limitations || []), limitation] }
				: claim,
		)
		setClaims(newClaims)
	}

	// Add new handler for creating claims
	const handleAddClaim = () => {
		const lastClaim = claims[claims.length - 1]
		const newNumber = lastClaim ? lastClaim.number + 1 : 1

		const newClaim: ClaimEdit = {
			id: crypto.randomUUID(),
			number: newNumber,
			isAsserted: true,
			isIndependent: false,
			limitations: [],
		}

		setClaims([...claims, newClaim])
	}

	return (
		<Dialog open={open} onOpenChange={handleOpenChange} modal>
			<DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col overflow-hidden">
				<DialogHeader>
					<DialogTitle className="flex items-center gap-2">
						Edit claims
						<div className="flex gap-2">
							<Button
								size="icon"
								variant="ghost"
								onClick={undo}
								disabled={!canUndo || isLoading}
							>
								<Undo size={16} />
							</Button>
							<Button
								size="icon"
								variant="ghost"
								onClick={redo}
								disabled={!canRedo || isLoading}
							>
								<Redo size={16} />
							</Button>
						</div>
					</DialogTitle>
				</DialogHeader>

				<div className="flex-grow overflow-auto min-h-0">
					{claims.map((claim) => (
						<ClaimEditor
							key={claim.id}
							claim={claim}
							onUpdate={handleClaimUpdate}
							onDelete={() => handleClaimDelete(claim.id)}
							onUpdateLimitation={handleLimitationUpdate}
							onDeleteLimitation={handleLimitationDelete}
							onAddLimitation={(limitation) => handleAddLimitation(claim.id, limitation)}
							isLoading={{
								claim: isLoading,
								limitation: isLoading,
							}}
						/>
					))}
					<div className="mt-4 px-4">
						<Button variant="outline" onClick={handleAddClaim} disabled={isLoading}>
							Add Claim
						</Button>
					</div>
				</div>

				<div className="flex gap-2 justify-end">
					<Button variant="outline" onClick={onClose} disabled={isLoading}>
						Cancel
					</Button>
					<LoadingButton loading={isLoading || isUpdatingClaims} onClick={handleSave}>
						{isLoading ? "Saving..." : "Save"}
					</LoadingButton>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default UpdateClaimsModal
