import type { SearchRequest } from "@/types"
import { apiClient } from "./client"

export const searchApi = {
	async search(
		projectId: string,
		keywords: string[],
		options: { [key: string]: any } = {},
	) {
		const payload: SearchRequest = {
			project_id: projectId,
			keywords: keywords,
			max_results: 10,
			claim_numbers: [],
			feature_numbers: [],
			claim_type: "Independent Claims",
			dedupe_family_id: true,
			...options,
		}
		return await apiClient.post("search", payload)
	},
}
