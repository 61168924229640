import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { ChartColor } from "@/types"
import type React from "react"
import { useState } from "react"

/**
 * Props for ColorPopover
 * @param currentColor        The color currently set (if any)
 * @param onColorChange       Handler to call when user selects a color
 * @param disabledColors      If you want to exclude certain colors from the picker (defaults to [ChartColor.GRAY])
 * @param buttonStyle         Optional additional styling for the trigger button
 */
interface ColorPopoverProps {
	currentColor?: string
	onColorChange: (color: string) => void
	disabledColors?: ChartColor[]
	buttonStyle?: React.CSSProperties
}

export const ColorPopover: React.FC<ColorPopoverProps> = ({
	currentColor,
	onColorChange,
	disabledColors = [ChartColor.GRAY, currentColor],
	buttonStyle,
}) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger asChild>
				<div>
					<Button
						variant="ghost"
						size="icon"
						style={{
							backgroundColor: currentColor || "transparent",
							...buttonStyle,
						}}
						onClick={() => setIsOpen(true)}
					/>
				</div>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-1">
				<div className="flex flex-row gap-1">
					{Object.values(ChartColor)
						.filter((color) => !disabledColors.includes(color))
						.map((color) => (
							<div
								key={color}
								className={cn(
									"w-9 h-9 m-1 cursor-pointer rounded-md border",
									currentColor === color ? "ring-2 ring-offset-2 ring-blue-500" : "",
								)}
								style={{ backgroundColor: color }}
								onClick={() => {
									onColorChange(color)
									setIsOpen(false)
								}}
							/>
						))}
				</div>
			</PopoverContent>
		</Popover>
	)
}
