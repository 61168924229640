/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import ImageViewer from "@/components/images/ImageViewer"
import { useState } from "react"
import { Card } from "../ui/card"

interface FigureProps {
	imageUrl: string
	imageName: string
}

function Figure({ imageUrl, imageName }: FigureProps) {
	const [imageOpen, setImageOpen] = useState<boolean>(false)
	const [isValidAspectRatio, setIsValidAspectRatio] = useState<boolean>(true)

	const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
		const img = event.currentTarget
		const aspectRatio = img.naturalWidth / img.naturalHeight

		// Temporary barcode filter, any width:height ratio that's greater than 9
		if (aspectRatio > 9) {
			setIsValidAspectRatio(false)
		}
	}

	if (!isValidAspectRatio) {
		return <div /> // Return an empty div if the aspect ratio is invalid
	}

	return (
		<>
			<Card
				className="shrink-0 hover:border-gray-400 inline-block"
				onClick={() => setImageOpen(true)}
			>
				<div className="h-[100px] m-2">
					<img
						src={imageUrl}
						alt={`Figure: ${imageName}`}
						className="rounded-md object-contain h-full"
						onLoad={handleImageLoad}
					/>
				</div>
			</Card>

			<ImageViewer
				imageUrl={imageUrl}
				imageName={imageName}
				open={imageOpen}
				handleClose={() => setImageOpen(false)}
			/>
		</>
	)
}

export default Figure
