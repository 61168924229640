/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { CheckboxWithText } from "@/components/ui/checkbox"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { FloatingLabelInput } from "@/components/ui/floating-input"
import { LoadingButton } from "@/components/ui/loading-button"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import type { Organization } from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { VisuallyHidden } from "@radix-ui/react-visually-hidden"
import { type ChangeEvent, useEffect } from "react"
import { type Control, Controller, type FieldErrors, useForm } from "react-hook-form"
import { z } from "zod"
import { useSuperAdminOrganizations } from "../../hooks/useSuperAdminOrganizations"

interface OrganizationModalProps {
	open: boolean
	onClose: () => void
	org?: Organization
	isEdit?: boolean
}

const organizationSchema = z.object({
	name: z.string().min(1, "Organization name is required"),
	invoiceType: z.enum(["NONE", "MONTHLY", "YEARLY"]).optional(),
	monthlyLicensePrice: z.number().nullable().optional(),
	yearlyLicensePrice: z.number().nullable().optional(),
	monthlySeatPrice: z.number().nullable().optional(),
	yearlySeatPrice: z.number().nullable().optional(),
	documentPrice: z.number().nullable().optional(),
	documentsAddedPrice: z.number().nullable().optional(),
	documentsExportedPrice: z.number().nullable().optional(),
	uniqueDocumentsPricing: z.boolean().optional(),
	invProjectPrice: z.number().nullable().optional(),
	pfaProjectPrice: z.number().nullable().optional(),
	appProjectPrice: z.number().nullable().optional(),
	clientNumbers: z.array(z.string()).nullable().optional(),
	passthroughBilling: z.boolean().optional(),
	maxSeats: z.number().nullable().optional(),
})

type OrganizationFormData = z.infer<typeof organizationSchema>

const initialState: OrganizationFormData = {
	name: "",
	invoiceType: "NONE",
	monthlyLicensePrice: null,
	yearlyLicensePrice: null,
	monthlySeatPrice: null,
	yearlySeatPrice: null,
	documentPrice: null,
	documentsAddedPrice: null,
	documentsExportedPrice: null,
	uniqueDocumentsPricing: false,
	invProjectPrice: null,
	pfaProjectPrice: null,
	appProjectPrice: null,
	clientNumbers: [],
}

function OrganizationModal({ open, onClose, org, isEdit }: OrganizationModalProps) {
	const {
		updateOrganization,
		createOrganization,
		isLoading,
		isCreatePending,
		isUpdatePending,
	} = useSuperAdminOrganizations()

	const {
		control,
		reset,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<OrganizationFormData>({
		resolver: zodResolver(organizationSchema),
		defaultValues: isEdit && org ? org : initialState,
	})

	const invoiceType = watch("invoiceType")
	const isLoadingState = isLoading || isUpdatePending || isCreatePending

	useEffect(() => {
		if (open) {
			if (isEdit && org) {
				reset(org)
			} else {
				reset(initialState)
			}
		}
	}, [isEdit, org, reset, open])

	const onSubmit = async (data: OrganizationFormData) => {
		try {
			if (isEdit && org) {
				await updateOrganization({
					organizationId: org.id!,
					newOrg: {
						id: org.id!,
						name: data.name!,
						createdAt: org.createdAt!,
						clientNumbers: data.clientNumbers || [],
						invoiceType: data.invoiceType,
						passthroughBilling: data.passthroughBilling,
						monthlyLicensePrice: data.monthlyLicensePrice,
						yearlyLicensePrice: data.yearlyLicensePrice,
						monthlySeatPrice: data.monthlySeatPrice,
						yearlySeatPrice: data.yearlySeatPrice,
						documentPrice: data.documentPrice,
						documentsAddedPrice: data.documentsAddedPrice,
						documentsExportedPrice: data.documentsExportedPrice,
						uniqueDocumentsPricing: data.uniqueDocumentsPricing,
						invProjectPrice: data.invProjectPrice,
						pfaProjectPrice: data.pfaProjectPrice,
						appProjectPrice: data.appProjectPrice,
						maxSeats: data.maxSeats,
					},
				})
			} else {
				await createOrganization({
					id: "",
					name: data.name!,
					createdAt: new Date().toISOString(),
					clientNumbers: data.clientNumbers || [],
					...data,
				})
			}
			onClose()
		} catch (error) {
			console.error("Error saving organization:", error)
		}
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>{isEdit ? "Edit" : "Add"} organization</DialogTitle>
					<VisuallyHidden asChild>{org?.name}</VisuallyHidden>
				</DialogHeader>
				<form
					onSubmit={handleSubmit(
						(data) => {
							onSubmit(data)
						},
						(_formErrors) => {},
					)}
					className="pb-4"
				>
					<ScrollArea className="max-h-[calc(100vh-200px)] mx-2">
						<Controller
							name="name"
							control={control}
							render={({ field }) => (
								<FloatingLabelInput
									id="name"
									label="Organization Name"
									{...field}
									className="mt-2 mb-2"
									error={errors.name?.message}
									disabled={isLoadingState}
								/>
							)}
						/>

						<Controller
							name="invoiceType"
							control={control}
							render={({ field }) => (
								<Select
									onValueChange={field.onChange}
									value={field.value}
									disabled={isLoadingState}
								>
									<SelectTrigger className="mb-2">
										<SelectValue placeholder="Select Invoice Type" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="NONE">None</SelectItem>
										<SelectItem value="MONTHLY">Monthly</SelectItem>
										<SelectItem value="YEARLY">Yearly</SelectItem>
									</SelectContent>
								</Select>
							)}
						/>

						{invoiceType === "MONTHLY" && (
							<PricingInputs
								fields={[
									{
										name: "monthlyLicensePrice",
										label: "Monthly License Price",
									},
									{ name: "monthlySeatPrice", label: "Monthly Seat Price" },
								]}
								control={control}
								errors={errors}
								disabled={isLoadingState}
							/>
						)}

						{invoiceType === "YEARLY" && (
							<PricingInputs
								fields={[
									{ name: "yearlyLicensePrice", label: "Yearly License Price" },
									{ name: "yearlySeatPrice", label: "Yearly Seat Price" },
								]}
								control={control}
								errors={errors}
								disabled={isLoadingState}
							/>
						)}

						<PricingInputs
							fields={[
								{ name: "invProjectPrice", label: "INV Project Price" },
								{ name: "appProjectPrice", label: "APP Project Price" },
							]}
							control={control}
							errors={errors}
							disabled={isLoadingState}
						/>

						<PricingInputs
							fields={[
								{ name: "documentPrice", label: "Document Price" },
								{ name: "documentsAddedPrice", label: "Doc Added Price" },
								{
									name: "documentsExportedPrice",
									label: "Doc Exported Price",
								},
							]}
							control={control}
							errors={errors}
							disabled={isLoadingState}
						/>

						<Controller
							name="uniqueDocumentsPricing"
							control={control}
							render={({ field }) => (
								<CheckboxWithText
									id="uniqueDocumentsPricing"
									checked={field.value}
									onCheckedChange={field.onChange}
									label="Unique Documents Pricing"
									className="mb-2"
									disabled={isLoadingState}
								/>
							)}
						/>
					</ScrollArea>
					<DialogFooter>
						<Button
							variant="outline"
							disabled={isLoadingState}
							onClick={onClose}
							type="button"
						>
							Cancel
						</Button>
						<LoadingButton type="submit" loading={isLoadingState}>
							{isLoadingState ? "Saving..." : "Save Changes"}
						</LoadingButton>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	)
}

interface PricingInputProps {
	fields: Array<{ name: keyof OrganizationFormData; label: string }>
	control: Control<OrganizationFormData>
	errors: FieldErrors<OrganizationFormData>
	disabled: boolean
}

const PricingInputs = ({ fields, control, errors, disabled }: PricingInputProps) => (
	<div className="flex gap-2 mb-2 w-full">
		{fields.map(({ name, label }) => (
			<Controller
				key={name}
				name={name}
				control={control}
				render={({ field }) => (
					<FloatingLabelInput
						id={name}
						label={label}
						{...field}
						value={field.value === null ? "" : field.value.toString()}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const value = e.target.value === "" ? null : Number(e.target.value)
							field.onChange(value)
						}}
						className="w-full"
						error={errors[name]?.message}
						disabled={disabled}
					/>
				)}
			/>
		))}
	</div>
)

export default OrganizationModal
