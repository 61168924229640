/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useProject } from "@/hooks"
import type { ParentDocumentMetadata } from "@/types"
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import { XIcon } from "lucide-react"
import type React from "react"
import { useState } from "react"
interface MultiDocumentSelectProps {
	handleChange: (selected: string[]) => void
	disabled?: boolean
	documents?: ParentDocumentMetadata[]
}

const MultiDocumentSelect: React.FC<MultiDocumentSelectProps> = ({
	handleChange,
	disabled,
	documents,
}) => {
	const { subjectId } = useProject()
	const documentIds = documents?.map((doc) => doc.documentId) ?? []

	const [open, setOpen] = useState(false)
	const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>(documentIds)

	const areAllReferencesSelected = () => {
		const selectedReferences = selectedDocumentIds.filter((id) => id !== subjectId)
		return selectedReferences.length === documentIds.length
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleDocumentToggle = (id: string) => {
		const newSelection = selectedDocumentIds.includes(id)
			? selectedDocumentIds.filter((item) => item !== id)
			: [...selectedDocumentIds, id]
		setSelectedDocumentIds(newSelection)
		handleChange(newSelection)
	}

	const handleSelectAll = () => {
		setSelectedDocumentIds(
			selectedDocumentIds.length === documentIds.length ? [] : documentIds,
		)
	}

	const handleDeselectAll = () => {
		setSelectedDocumentIds([])
	}

	const getDisplayText = (id: string) => {
		const document = documents?.find((doc) => doc.documentId === id)
		return document?.nickname
	}

	return (
		<Popover
			open={open}
			onOpenChange={(isOpen) => (isOpen ? setOpen(true) : handleClose())}
		>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="justify-between w-full"
					disabled={disabled}
				>
					<div className="flex items-center space-x-1 truncate">
						{selectedDocumentIds.length === documentIds.length ? (
							<span>All documents</span>
						) : areAllReferencesSelected() ? (
							<span>All project documents</span>
						) : selectedDocumentIds.length > 0 ? (
							<>
								{selectedDocumentIds.slice(0, 2).map((id) => (
									<span key={id} className="text-secondary-foreground px-2 rounded border">
										{getDisplayText(id)}
									</span>
								))}
								{selectedDocumentIds.length > 2 && (
									<span className="text-secondary-foreground">
										+{selectedDocumentIds.length - 2} more
									</span>
								)}
							</>
						) : (
							<span>Select Documents</span>
						)}
					</div>
					<ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="p-0 min-w-[350px]" align="start">
				<ScrollArea className="h-[300px]">
					<div className="p-1">
						<Button
							variant="ghost"
							className="w-full justify-start"
							onClick={handleSelectAll}
						>
							<div className="flex items-center">
								<CheckIcon
									className={`mr-2 h-4 w-4 ${
										selectedDocumentIds.length === documentIds.length
											? "opacity-100"
											: "opacity-0"
									}`}
								/>
								<span>Select All</span>
							</div>
						</Button>
						<Button
							variant="ghost"
							className="w-full justify-start"
							onClick={handleDeselectAll}
						>
							<div className="flex items-center">
								<XIcon
									className={`mr-2 h-4 w-4 ${selectedDocumentIds.length > 0 ? "opacity-100" : "opacity-0"}`}
								/>
								<span>Deselect All</span>
							</div>
						</Button>
						{documentIds.map((id) => (
							<Button
								key={id}
								variant="ghost"
								className="w-full justify-start"
								onClick={() => handleDocumentToggle(id)}
							>
								<div className="flex items-center">
									<CheckIcon
										className={`mr-2 h-4 w-4 ${selectedDocumentIds.includes(id) ? "opacity-100" : "opacity-0"}`}
									/>
									<span>{getDisplayText(id)}</span>
									{id === subjectId && (
										<span className="ml-2 text-xs border text-secondary-foreground px-1 rounded">
											Subject
										</span>
									)}
								</div>
							</Button>
						))}
					</div>
				</ScrollArea>
			</PopoverContent>
		</Popover>
	)
}

export default MultiDocumentSelect
