/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { CountryCodeSelect, InfoPopover, ItemTags } from "@/components"
import { CheckboxWithText } from "@/components/ui/checkbox"
import { DateTimePicker } from "@/components/ui/datetime-picker"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { SUPPORTED_JURISDICTIONS } from "@/types/project"
import type React from "react"

/**
 * @description Prior art search filters
 */
const SearchFilters: React.FC<{
	assignees: string[]
	setAssignees: (assignees: string[]) => void
	toDate: Date | null
	setToDate: (toDate: Date | null) => void
	fromDate: Date | null
	setFromDate: (fromDate: Date | null) => void
	cpcCodes: string[]
	setCpcCodes: (cpcCodes: string[]) => void
	countryCodes?: string[]
	setCountryCodes?: (countryCodes: string[]) => void
	claimType?: string
	setClaimType?: (claimType: string) => void
	keywords: string[]
	setKeywords: (keywords: string[]) => void
	maxResults?: number
	setMaxResults?: (maxResults: number) => void
	useCpcCodes?: boolean
	setUseCpcCodes?: (useCpcCodes: boolean) => void
	showUseCpcCodes?: boolean
	types: string[]
	setTypes: (types: string[]) => void
	statuses: string[]
	setStatuses: (statuses: string[]) => void
	disabled: boolean
	isInCreate?: boolean
	dedupeFamilyId?: boolean
	setDedupeFamilyId?: (dedupeFamilyId: boolean) => void
}> = ({
	assignees,
	setAssignees,
	toDate,
	setToDate,
	fromDate,
	setFromDate,
	cpcCodes,
	setCpcCodes,
	countryCodes,
	setCountryCodes,
	claimType,
	setClaimType,
	keywords,
	setKeywords,
	maxResults,
	setMaxResults,
	useCpcCodes,
	setUseCpcCodes,
	disabled,
	types,
	setTypes,
	isInCreate,
	dedupeFamilyId,
	setDedupeFamilyId,
}) => {
	const assigneeContent =
		"Assignee names require an exact match. For comprehensive results, enter all valid versions of an assignee name (e.g., 'Google', 'Google Inc.', 'Google, Inc.', etc.)"

	const renderItemTags = (
		label: string,
		items: string[],
		setItems: (items: string[]) => void,
		type: string,
		infoContent?: React.ReactNode,
	) => (
		<div className="space-y-2">
			<div className="flex items-center space-x-1">
				<Label className="mr-1">{label}</Label>
				{infoContent && <InfoPopover className="ml-1 mb-1" content={assigneeContent} />}
			</div>
			<ItemTags
				items={items}
				setItems={setItems}
				type={type}
				disabled={disabled}
				isInCreate={isInCreate}
			/>
		</div>
	)

	const renderCpcTags = (
		label: string,
		items: string[],
		setItems: (items: string[]) => void,
		type: string,
		_infoContent?: React.ReactNode,
	) => (
		<div className="space-y-2">
			<div className="flex items-center space-x-2">
				<Label>{label}</Label>
			</div>
			{setUseCpcCodes && (
				<div className="flex items-center space-x-2">
					<CheckboxWithText
						id="useCpcCodes"
						checked={useCpcCodes}
						onCheckedChange={(checked) => setUseCpcCodes(checked as boolean)}
						disabled={disabled}
						label="Use CPC Codes"
					/>
				</div>
			)}
			{(!setUseCpcCodes || useCpcCodes) && (
				<ItemTags
					items={items}
					setItems={setItems}
					type={type}
					disabled={disabled}
					isInCreate={isInCreate}
				/>
			)}
		</div>
	)

	return (
		<div className="space-y-6">
			<div className="grid grid-cols-1 md:grid-cols-4 gap-4">
				<div className="space-y-4">
					{/* <div className="space-y-2">
            <Label>Result Type</Label>
            <div className="flex flex-wrap gap-4">
              {PATENT_TYPES.map((option) => (
                <div key={option} className="flex items-center space-x-2">
                  <CheckboxWithText
                    id={option}
                    checked={types.includes(option)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setTypes([...types, option]);
                      } else {
                        setTypes(types.filter((item) => item !== option));
                      }
                    }}
                    disabled={disabled}
                    label={option === "International" ? "Non-US" : option}
                  />
                  {option === "International" && (
                    <span className="ml-2 text-sm text-gray-500">(Experimental)</span>
                  )}
                </div>
              ))}
            </div>
          </div> */}

					{dedupeFamilyId !== undefined && (
						<div className="flex flex-col space-y-2">
							<Label>Deduplicate By</Label>
							<CheckboxWithText
								id={"Family ID"}
								checked={dedupeFamilyId}
								onCheckedChange={(dedupeFamilyId) => {
									if (dedupeFamilyId) {
										setDedupeFamilyId(true)
									} else {
										setDedupeFamilyId(false)
									}
								}}
								label={"Family ID"}
							/>
						</div>
					)}
				</div>
				<div className="space-y-2">
					<Label>Max Results</Label>
					<Input
						type="number"
						placeholder="Optional"
						value={maxResults}
						onChange={(e) => {
							const value = Number.parseInt(e.target.value, 10)
							if (!Number.isNaN(value) && setMaxResults) {
								setMaxResults(value)
							}
						}}
						disabled={disabled}
					/>
				</div>
				<div className="flex flex-col space-y-4">
					<div className="flex flex-col space-y-2">
						<Label>Priority From</Label>
						<DateTimePicker
							value={fromDate}
							granularity="day"
							onChange={setFromDate}
							disabled={disabled}
						/>
					</div>
					<div className="flex flex-col space-y-2">
						<Label>Priority To</Label>
						<DateTimePicker
							value={toDate}
							granularity="day"
							onChange={setToDate}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>

			{/* Render ItemTags components */}
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
				{renderItemTags("Assignees", assignees, setAssignees, "assignee", assigneeContent)}
				{renderCpcTags("CPC Codes", cpcCodes, setCpcCodes, "cpc")}
				{!isInCreate && renderItemTags("Keywords", keywords, setKeywords, "keyword")}
				{countryCodes !== undefined && setCountryCodes !== undefined && (
					<div className="space-y-2">
						<div className="flex items-center space-x-2">
							<Label>Patent Offices</Label>
						</div>
						<CountryCodeSelect
							countryCodes={SUPPORTED_JURISDICTIONS}
							selectedCountryCodes={countryCodes}
							setSelectedCountryCodes={setCountryCodes}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default SearchFilters
