/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { CreateProjectStep, ProjectType, type UnprocessedDocument } from "@/types"
import { useCallback, useMemo, useState } from "react"

/**
 * @description Hook to handle step navigation
 * @param {string} projectName - The name of the project
 * @param {ProjectType | null} projectType - The type of the project
 * @param {UnprocessedDocument[]} subjectDocuments - The subject documents
 * @returns {Object} - An object containing the current step, steps, isStepComplete, canProceedToNext, handleNextStep, and setCurrentStep
 */
const useStepNavigation = (
	projectName: string,
	projectType: ProjectType | null,
	subjectDocuments: UnprocessedDocument[],
) => {
	const [currentStep, setCurrentStep] = useState<CreateProjectStep>(
		CreateProjectStep.DETAILS,
	)
	// Completed steps state
	// const [completedSteps, setCompletedSteps] = useState<Set<CreateProjectStep>>(
	//   new Set()
	// );

	// // Function to mark a step as complete
	// const markStepComplete = (step: CreateProjectStep) => {
	//   setCompletedSteps((prev) => new Set(prev).add(step));
	// };

	// Steps definition
	const steps = useMemo(() => {
		const baseSteps = [
			{
				key: CreateProjectStep.DETAILS,
				label: "Details",
			},
			{
				key: CreateProjectStep.SUBJECT,
				label: "Subjects",
				infoPopoverContent: "Subjects",
				description:
					"At least one subject is required. If you add more than one subject, your project will be structured as a portfolio.",
			},
			{
				key: CreateProjectStep.PRIOR_ART,
				label: "References",
				infoPopoverContent: "References",
				description:
					"Adding references is optional. You can add references after project creation. References will be charted against your subjects.",
			},
			// {
			//   key: CreateProjectStep.CONTEXT,
			//   label: "Context",
			//   infoPopoverContent: "Context",
			//   description:
			//     "Adding context is optional. You can add context after project creation.",
			// },
			{
				key: CreateProjectStep.REVIEW,
				label: "Review",
			},
		]

		// // Insert Office Action step after Details if project type is APP
		if (projectType === ProjectType.APP) {
			baseSteps.splice(1, 0, {
				key: CreateProjectStep.OFFICE_ACTION,
				label: "Office Action",
				infoPopoverContent: "Office Action",
				description:
					"Adding an office action is optional. You can add an office action after project creation.",
			})
		}

		return baseSteps
	}, [projectType])

	const isStepComplete = useCallback(
		(step: CreateProjectStep): boolean => {
			switch (step) {
				case CreateProjectStep.DETAILS:
					return Boolean(projectName && projectType)
				case CreateProjectStep.SUBJECT:
					return subjectDocuments.length > 0
				default:
					return true
			}
		},
		[projectName, projectType, subjectDocuments],
	)

	const canProceedToNext = useMemo(
		() => isStepComplete(currentStep),
		[isStepComplete, currentStep],
	)

	// Function to handle step click (navigation between steps)
	const handleStepClick = useCallback(
		(step: CreateProjectStep) => {
			const targetStepIndex = steps.findIndex((s) => s.key === step)
			const currentStepIndex = steps.findIndex((s) => s.key === currentStep)

			if (targetStepIndex <= currentStepIndex) {
				// Allow navigating back to previous steps
				setCurrentStep(step)
			} else if (canProceedToNext) {
				// Allow moving to the next step only if the current step is complete
				setCurrentStep(step)
			}
		},
		[canProceedToNext, currentStep, steps],
	)

	const handleNextStep = useCallback(() => {
		const currentStepIndex = steps.findIndex((step) => step.key === currentStep)
		if (currentStepIndex < steps.length - 1 && canProceedToNext) {
			setCurrentStep(steps[currentStepIndex + 1].key)
		}
	}, [currentStep, steps, canProceedToNext])

	return {
		currentStep,
		steps,
		isStepComplete,
		canProceedToNext,
		handleNextStep,
		setCurrentStep,
		handleStepClick,
	}
}

export default useStepNavigation
