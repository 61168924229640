import { ChartColor, type ChunkLocation } from "@/types"
import { isLikelyPatentNumber } from "@/utils/dataUtils"

/**
 * @param {string} subjectId - the subjectId to be formatted
 * @description Formats the subjectId based on its length
 */
export const formatPatentNumber = (number: string) => {
	// if the subjectId is "No Reference Documents" return "No Reference Documents"
	if (!number || number === "No Reference Documents") {
		return number
	}

	// If the subjectId is less than 3 characters, return the subjectId as is
	if (number.length < 3) {
		return number
	}

	// if the subject id ends with a file extension, return it as is
	if (number.endsWith(".pdf")) {
		return number
	}

	// Check if the first two characters are letters
	let prefix = ""
	if (/^[A-Za-z]{2}/.test(number)) {
		prefix = number.slice(0, 2) // Extract first two letters as prefix
		number = number.slice(2) // Remove the extracted prefix from the original subjectId
	}
	let withoutPrefix = number

	// Check and remove the suffix 'A1' or 'B2' if present
	let suffix = ""
	const suffixMatch = withoutPrefix.match(/[A-Z]\d{0,2}$/)
	if (suffixMatch) {
		suffix = suffixMatch[0]
		withoutPrefix = withoutPrefix.slice(0, -suffix.length)
	}

	// Formatting the number part based on prefix
	let formattedNumber: string

	switch (prefix) {
		case "US":
			if (withoutPrefix.length > 8) {
				// Publication number format (e.g., 2010/0234553)
				formattedNumber = `${withoutPrefix.slice(0, 4)}/${withoutPrefix.slice(4)}`
			} else {
				// Regular patent number format
				formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			}
			break
		case "JP":
			if (/^[A-Z]/.test(withoutPrefix)) {
				// Publication number format (e.g., S63-2956)
				formattedNumber = `${withoutPrefix.slice(0, 3)}-${withoutPrefix.slice(3)}`
			} else if (withoutPrefix.length > 8) {
				// Publication number format (e.g., 2010-0234553)
				formattedNumber = `${withoutPrefix.slice(0, 4)}-${withoutPrefix.slice(4)}`
			} else {
				// Regular patent number format
				formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			}
			break
		case "KR":
			if (withoutPrefix.slice(0, 2) === "10") {
				// Publication number format (e.g., 10-1054626)
				formattedNumber = `${withoutPrefix.slice(0, 2)}-${withoutPrefix.slice(2)}`
			} else if (withoutPrefix.length > 8) {
				// Publication number format (e.g., 2010-0234553)
				formattedNumber = `${withoutPrefix.slice(0, 4)}-${withoutPrefix.slice(4)}`
			} else {
				// Regular patent number format
				formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			}
			break
		default: //includes EP, CN
			formattedNumber = withoutPrefix.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			break
	}

	// Reassemble the full formatted ID with prefix and suffix if they were removed
	return `${prefix} ${formattedNumber}`.trim()
}

export const colorRank: { [key: string]: number } = {
	[ChartColor.GREEN]: 4,
	[ChartColor.YELLOW]: 3,
	[ChartColor.RED]: 2,
	[ChartColor.GRAY]: 1,
}

/**
 * @param {array} colors - The colors to get the highest rank from
 * @returns {string} - The highest rank
 */
export const getHighestRank = (colors: string[]) => {
	const num = Math.max(...colors.map((color) => colorRank[color] || 0))
	return Object.keys(colorRank).find((key) => colorRank[key] === num)
}

/**
 * Filters out unwanted sections from document body or metadata
 * @param sections - Array of document sections to filter
 * @param options - Optional configuration for filtering
 * @returns Filtered array of sections
 */
export const filterDocumentSections = (
	sections: any[],
	options: {
		excludeStrings?: string[]
	} = {},
) => {
	const { excludeStrings = ["IS", "IS.", "U.S.", "(Continued)"] } = options

	return (
		sections?.filter((section: any) => {
			// For body sections, apply text-based filtering
			const text = section.text || ""

			// Must contain letters
			const hasLetters = /[a-zA-Z]/.test(text)

			// Strip spaces and commas for patent number check
			const strippedText = text.replace(/[\s,]/g, "")

			// Check for excluded strings
			const isExcludedString = excludeStrings.includes(text.trim())

			return hasLetters && !isExcludedString && !isLikelyPatentNumber(strippedText)
		}) || []
	)
}

export const formatDocumentChunkLocation = (
	location: ChunkLocation,
	format?: string,
) => {
	const paragraphs = location.paragraphs // TODO: handle multiple paragraphs
	const pages = location.pages
	const columns = location.columns
	const lines = location.lines

	// if (paragraphs === -1) {
	//   return "Abstract"
	// }

	// Helper function to format ranges
	const formatRange = (start: number, end: number) => {
		return start === end ? `${start}` : `${start}-${end}`
	}

	// Format column-lines
	const formatColumnLines = () => {
		if (
			!columns?.length ||
			!lines?.length ||
			columns.some((n) => n < 0) ||
			lines.some((n) => n < 0)
		) {
			return "Unknown"
		}

		// Same column case
		if (columns[0] === columns[1]) {
			return `${columns[0]}:${formatRange(lines[0], lines[1])}`
		}

		// Different columns case
		return `${columns[0]}:${lines[0]}-${columns[1]}:${lines[1]}`
	}

	const formatPages = () => {
		return pages
			? pages[0] === pages[1]
				? `Page ${pages[0]}`
				: `Pages ${pages[0]}-${pages[1]}`
			: "Unknown"
	}

	switch (format) {
		case "claim-section":
			return location.claimSection
		case "section":
			return location.section
		case "column-lines":
			return formatColumnLines()

		case "page":
			return formatPages()

		case "paragraph":
			return paragraphs ? `[${paragraphs}]` : "Unknown"

		case "page-paragraph":
			return formatPages() + (paragraphs ? `[${paragraphs}]` : "Unknown")

		default:
			if (location.claimSection) {
				return location.claimSection
			}
			if (location.section) {
				return location.section
			}
			if (columns?.length && lines?.length) {
				const columnLineFormat = formatColumnLines()
				if (columnLineFormat !== "Unknown") return columnLineFormat
			}
			if (paragraphs) {
				return `[${paragraphs}]`
			}
			if (pages) {
				return formatPages()
			}

			return "Unknown"
	}
}

export const formatInventorName = (name: string | string[]) => {
	const firstInventorName = name[0]
	if (
		firstInventorName?.includes(",") &&
		firstInventorName === firstInventorName.toUpperCase()
	) {
		const [lastName, firstName] = firstInventorName.split(",").map((part) => part.trim())
		return `${firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()} ${
			lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase()
		}`
	}
	return firstInventorName
}
