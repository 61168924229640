/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import type { UnprocessedDocument } from "@/types"
import { formatPatentNumber } from "@/utils"
import { formatInventorName } from "@/utils/projectUtils"
import { ChevronDownIcon, FileIcon } from "@radix-ui/react-icons"
import type React from "react"
import OpenInGooglePatentButton from "../patents/OpenInGooglePatentButton"

interface UnprocessedPatentProps {
	document: UnprocessedDocument
	updateSelectedKindCode: (documentId: string, kindCode: string) => void
}

/**
 * A component that displays detailed information of an unprocessed patent.
 *
 * @param {UnprocessedPatentProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered component.
 */
const UnprocessedPatentCard: React.FC<UnprocessedPatentProps> = ({
	document,
	updateSelectedKindCode,
}) => {
	const selectedKindCode =
		document.selectedKindCode ||
		document.patentDetails?.selectedKindCode ||
		Object.keys(document.patentDetails?.details || {})[0]

	const patentDetail = document.patentDetails?.details?.[selectedKindCode]

	if (!patentDetail) {
		return <div>There was an error fetching the patent details</div>
	}

	return (
		<div className="flex items-center gap-4">
			<FileIcon className="text-primary h-5 w-5" />
			<div className="space-y-1">
				<div className="flex items-center gap-2">
					<p className="text-sm font-semibold">{patentDetail.title}</p>
					<OpenInGooglePatentButton
						sourceNumber={patentDetail.number}
						prefix={patentDetail.prefix || ""}
					/>
				</div>

				<div className="flex flex-col gap-2 text-sm">
					<div className="flex items-center gap-2 flex-wrap">
						<p>{formatPatentNumber(patentDetail.number)}</p>

						{document.patentDetails?.details &&
							Object.keys(document.patentDetails.details).length > 0 && (
								<div>
									{Object.keys(document.patentDetails.details).length === 1 ? (
										<span>{selectedKindCode}</span>
									) : (
										<DropdownMenu>
											<DropdownMenuTrigger asChild>
												<Button
													variant="ghost"
													size="sm"
													className="h-auto p-0 font-normal text-gray-500 hover:text-gray-900"
												>
													{selectedKindCode}
													<ChevronDownIcon className="ml-1 h-4 w-4" />
												</Button>
											</DropdownMenuTrigger>
											<DropdownMenuContent>
												{Object.keys(document.patentDetails.details).map((kindCode) => (
													<DropdownMenuItem
														key={kindCode}
														onClick={() => updateSelectedKindCode(document.id, kindCode)}
													>
														{kindCode}
													</DropdownMenuItem>
												))}
											</DropdownMenuContent>
										</DropdownMenu>
									)}
								</div>
							)}

						{patentDetail.priorityDate && (
							<>
								<span className="text-gray-400">•</span>
								<span className="flex items-center gap-2">
									<span className="text-gray-600">Priority Date</span>
									<span className="text-black">
										{new Date(patentDetail.priorityDate).toLocaleDateString()}
									</span>
								</span>
							</>
						)}

						{patentDetail.publicationDate && (
							<>
								<span className="text-gray-400">•</span>
								<span className="flex items-center gap-2">
									<span className="text-gray-600">Publication Date</span>
									<span className="text-black">
										{new Date(patentDetail.publicationDate).toLocaleDateString()}
									</span>
								</span>
							</>
						)}

						{patentDetail.inventors && patentDetail.inventors.length > 0 && (
							<>
								<span className="text-gray-400">•</span>
								<div className="flex items-center gap-2">
									<span className="text-gray-600">Inventor</span>
									<span>{formatInventorName(patentDetail.inventors)}</span>
								</div>
							</>
						)}

						{patentDetail.assignee && (
							<>
								<span className="text-gray-400">•</span>
								<div className="flex items-center gap-2">
									<span className="text-gray-600">Assignee</span>
									<span>{patentDetail.assignee}</span>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default UnprocessedPatentCard
