import { DragAndDropFileArea, UploadedFileDisplay } from "@/components"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type React from "react"
import { useState } from "react"

interface ImageUploadModalProps {
	open: boolean
	handleClose: () => void
	handleSubmitFiles: (files: File[]) => void
}

/**
 * @description Renders a modal for uploading an image
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} handleSubmitFiles - Function to handle the submission of files.
 */
const ImageUploadModal: React.FC<ImageUploadModalProps> = ({
	open,
	handleClose,
	handleSubmitFiles,
}) => {
	// Local state setup
	const [files, setFiles] = useState<File[]>([])
	const [fileTypeError, setFileTypeError] = useState<string | null>(null)

	/**
	 * @description Handles the file change
	 * @param {File} selectedFile - the file to handle
	 */
	const handleFileChange = (selectedFiles: File[]) => {
		if (selectedFiles && selectedFiles.length > 0) {
			for (const selectedFile of selectedFiles) {
				const isFileTypeValid =
					selectedFile.type === "image/png" ||
					selectedFile.type === "image/jpeg" ||
					selectedFile.type === "image/jpg"

				const isFileSizeValid = selectedFile.size <= 5 * 1024 * 1024 // 5MB

				if (isFileTypeValid && isFileSizeValid) {
					setFileTypeError(null)
					setFiles((prevFiles) => [...prevFiles, selectedFile])
				} else {
					let errorMessage = ""
					if (!isFileTypeValid) {
						errorMessage += "File type is not supported. Please upload .png, .jpeg, or .jpg. "
					}
					if (!isFileSizeValid) {
						errorMessage += "File size must be less than 5MB."
					}
					setFileTypeError(errorMessage)
				}
			}
		}
	}

	const handleCloseClick = () => {
		resetState()
		handleClose()
	}

	const handleRemoveFile = (file: File) => {
		setFiles(files.filter((f) => f !== file))
		setFileTypeError(null)
	}

	const handleSubmit = () => {
		handleSubmitFiles(files)
		resetState()
	}

	const resetState = () => {
		setFiles([])
		setFileTypeError(null)
	}

	// TODO: handle if reference already exists in project - show message
	return (
		<Dialog open={open} onOpenChange={handleCloseClick}>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>Upload images</DialogTitle>
				</DialogHeader>

				<div className="space-y-4">
					<DragAndDropFileArea
						handleFiles={handleFileChange}
						supportedFileTypes={[".png", ".jpeg", ".jpg"]}
					/>

					{fileTypeError && (
						<Alert variant="destructive">
							<AlertDescription>{fileTypeError}</AlertDescription>
						</Alert>
					)}

					{files.map((file) => (
						<UploadedFileDisplay
							key={file.name}
							file={file}
							onRemove={() => handleRemoveFile(file)}
						/>
					))}

					<div className="mt-2 flex justify-end gap-2">
						<Button variant="outline" onClick={handleClose}>
							Cancel
						</Button>
						<Button disabled={!files.length} onClick={handleSubmit}>
							Done
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default ImageUploadModal
