/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/lib/utils"
import { useProjectStore } from "@/store"
import type { Claim } from "@/types"
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import { useEffect, useState } from "react"

interface ClaimMultiSelectProps {
	claims: Claim[]
	onClaimSelect: (selectedClaims: string[]) => void
	className?: string
}

/**
 * @description Claim select to control claims displayed in the table
 * @param {function} onClaimSelect - Function to handle the selection of claims.
 */
function ClaimMultiSelect({
	claims = [],
	onClaimSelect,
	className,
}: ClaimMultiSelectProps) {
	const [open, setOpen] = useState(false)
	const [selectedClaims, setSelectedClaims] = useState<string[]>([])
	const { selectedLimitations } = useProjectStore()

	const getPopoverWidth = () => {
		if (!claims || claims.length === 0) return 200
		const allLabels = claims.flatMap(
			(claim) => claim.limitations?.map((limitation) => limitation.label) || [],
		)
		const longestLabel = allLabels.reduce((a, b) => (a.length > b.length ? a : b), "")
		return Math.min(500, Math.max(200, longestLabel.length * 8 + 48))
	}

	const popoverWidth = getPopoverWidth()

	useEffect(() => {
		const allElements = claims.flatMap((claim) =>
			claim.limitations.map((limitation) => limitation.id),
		)
		setSelectedClaims(allElements)
	}, [claims])

	useEffect(() => {
		if (selectedLimitations) {
			setSelectedClaims(selectedLimitations)
			onClaimSelect(selectedLimitations)
		}
	}, [selectedLimitations, onClaimSelect])

	const handleElementToggle = (id: string, claim: Claim) => {
		setSelectedClaims((prev) => {
			if (prev.includes(id)) {
				// If removing a claim's first limitation, remove all its sub-limitations
				if (claim.limitations[0].id === id) {
					return prev.filter((l) => !claim.limitations.some((lim) => lim.id === l))
				}
				return prev.filter((l) => l !== id)
			}
			// If adding a claim's first limitation, add all its sub-limitations
			if (claim.limitations[0].id === id) {
				return [...prev, ...claim.limitations.map((l) => l.id)]
			}
			return [...prev, id]
		})
	}

	const handleSelectAll = () => {
		setSelectedClaims((prev) =>
			prev.length === claims.flatMap((c) => c.limitations).length
				? []
				: claims.flatMap((c) => c.limitations.map((l) => l.id)),
		)
	}

	const handleSelectAsserted = () => {
		setSelectedClaims((prev) => {
			const assertedIds = claims
				.filter((c) => c.isAsserted)
				.flatMap((c) => c.limitations.map((l) => l.id))

			return prev.length === assertedIds.length &&
				JSON.stringify(prev.sort()) === JSON.stringify(assertedIds.sort())
				? []
				: assertedIds
		})
	}

	const handleClose = () => {
		onClaimSelect(selectedClaims)
		setOpen(false)
	}

	return (
		<Popover
			open={open}
			onOpenChange={(isOpen) => (isOpen ? setOpen(true) : handleClose())}
		>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className={cn("w-[200px] justify-between", className)}
				>
					<div className="flex items-center space-x-1 truncate">
						{selectedClaims.length === claims.flatMap((c) => c.limitations).length ? (
							<span>All claims</span>
						) : selectedClaims.length > 0 ? (
							<>
								{(() => {
									const assertedIds = claims
										.filter((c) => c.isAsserted)
										.flatMap((c) => c.limitations.map((l) => l.id))

									if (
										selectedClaims.length === assertedIds.length &&
										JSON.stringify(selectedClaims.sort()) === JSON.stringify(assertedIds.sort())
									) {
										return <span>Asserted claims</span>
									}

									return claims.map((claim) => {
										const claimLimitationIds = claim.limitations.map((l) => l.id)
										const allClaimLimitationsSelected = claimLimitationIds.every((id) =>
											selectedClaims.includes(id),
										)

										if (allClaimLimitationsSelected) {
											return (
												<span
													key={claim.id}
													className="text-secondary-foreground px-1 rounded border"
												>
													{claim.number}
												</span>
											)
										}

										return claim.limitations.slice(1).map((limitation) =>
											selectedClaims.includes(limitation.id) ? (
												<span
													key={limitation.id}
													className="text-secondary-foreground px-1 rounded border"
												>
													{limitation.label}
												</span>
											) : null,
										)
									})
								})()}
							</>
						) : (
							<span>Select claims</span>
						)}
					</div>
					<ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent style={{ width: `${popoverWidth}px` }} className="p-0" align="start">
				<ScrollArea className="h-[300px]">
					<div className="p-1">
						<Button
							variant="ghost"
							className="w-full justify-between font-bold"
							onClick={handleSelectAll}
						>
							<div className="flex items-center">
								<span>Select All</span>
							</div>
							{selectedClaims.length === claims.flatMap((c) => c.limitations).length && (
								<CheckIcon className="h-4 w-4" />
							)}
						</Button>
						<Button
							variant="ghost"
							className="w-full justify-between font-bold"
							onClick={handleSelectAsserted}
						>
							<div className="flex items-center">
								<span>Asserted Claims</span>
							</div>
							{selectedClaims.length ===
								claims.filter((c) => c.isAsserted).flatMap((c) => c.limitations).length && (
								<CheckIcon className="h-4 w-4" />
							)}
						</Button>
						{claims.map((claim) => (
							<div key={claim.id} className="mb-1">
								<Button
									variant="ghost"
									className="w-full justify-between font-semibold"
									onClick={() => handleElementToggle(claim.limitations[0].id, claim)}
								>
									<div className="flex items-center">
										<span>Claim {claim.number}</span>
									</div>
									{claim.limitations.every((l) => selectedClaims.includes(l.id)) && (
										<CheckIcon className="h-4 w-4" />
									)}
								</Button>
								<div className="ml-4 border-l-2 border-muted">
									{claim.limitations.slice(1).map((limitation) => (
										<Button
											key={limitation.id}
											variant="ghost"
											className="w-full justify-between pl-6"
											onClick={() => handleElementToggle(limitation.id, claim)}
										>
											<span>{limitation.label}</span>
											{selectedClaims.includes(limitation.id) && <CheckIcon className="h-4 w-4" />}
										</Button>
									))}
								</div>
							</div>
						))}
					</div>
				</ScrollArea>
			</PopoverContent>
		</Popover>
	)
}

export default ClaimMultiSelect
