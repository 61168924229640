import { api } from "@/api"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import Loader from "@/components/info/Loader"
import { RoleType } from "@/types"
import { useAuthInfo } from "@propelauth/react"
import type React from "react"
import { useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useAppStateStore, useUserStore } from "../store"

const ProtectedRoute: React.FC = () => {
	const { isLoggedIn, loading, user, userClass } = useAuthInfo()
	const location = useLocation()
	const navigate = useNavigate()
	const { updateOrganizationId, updateRole, updateOrganizationName, organizationName } =
		useUserStore()

	// Get user details on page load
	const userEmail = user?.email || ""
	const firstName = user?.firstName || ""

	// Global store
	const { isStoreEmpty, updateIsStoreEmpty } = useAppStateStore()

	// Local state
	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		if (!loading) {
			setIsLoading(false)
		}
	}, [loading])

	// Redirect to signin if not logged in
	useEffect(() => {
		if (!isLoading && !isLoggedIn) {
			navigate("/signin", { replace: true })
		}
	}, [isLoading, isLoggedIn, navigate])

	// Fetch data on page refresh if store is empty
	useEffect(() => {
		async function loadData() {
			if (isLoggedIn && isStoreEmpty) {
				try {
					setIsLoading(true)
				} catch (error) {
					if (process.env.NODE_ENV !== "production") {
						console.error("Error loading data on authenticated page refresh:", error)
					}
				} finally {
					setIsLoading(false)
					updateIsStoreEmpty(false)
				}
			}
		}

		loadData()
	}, [
		isLoggedIn,
		isStoreEmpty,
		location.pathname,
		userEmail,
		firstName,
		organizationName,
	])

	// Set org name and role on log in
	useEffect(() => {
		async function initializeUser() {
			if (isLoggedIn && !isLoading) {
				try {
					if (userEmail.endsWith("@tryandai.com")) {
						const userExists = await api.getUserPostgres()
						if (!userExists) {
							await api.createUserPostgres()
						}
					}

					const orgs = userClass?.getOrgs()
					const orgId = orgs?.[0]?.orgId
					const orgName = orgs?.[0]?.orgName
					updateOrganizationId(orgId || "")
					updateOrganizationName(orgName || "")
					const role = userClass?.isRole(orgId || "", RoleType.ADMIN)
						? RoleType.ADMIN
						: userClass?.isRole(orgId || "", RoleType.OWNER)
							? RoleType.OWNER
							: RoleType.MEMBER
					updateRole(role || "")
				} catch (error) {
					if (process.env.NODE_ENV !== "production") {
						console.error("Error initializing user:", error)
					}
				}
			}
		}

		initializeUser()
	}, [isLoggedIn, isLoading, userEmail])

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-screen">
				<Loader />
			</div>
		)
	}

	return <Outlet />
}

export default ProtectedRoute
