import { api } from "@/api"
import { type ApiResponse, DocumentRole } from "@/types"
import { S3Operation } from "@/types/types"
import axios from "axios"
import { useApi } from "."

const useFileUtils = () => {
	const { handleError } = useApi()

	const generateFileHash = async (file: File): Promise<string> => {
		const fileBuffer = await file.arrayBuffer()
		const hashBuffer = await crypto.subtle.digest("SHA-256", fileBuffer)
		const hashArray = Array.from(new Uint8Array(hashBuffer))
		const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
		return btoa(hashHex)
	}

	const getReferenceSignedUrl = async (
		file: File,
		operation: S3Operation = S3Operation.PUT,
		documentRole: DocumentRole = DocumentRole.PRIOR_ART,
	): Promise<ApiResponse> => {
		try {
			const base64Hash = await generateFileHash(file)
			const s3_folder =
				documentRole === DocumentRole.PRIOR_ART
					? "references"
					: documentRole === DocumentRole.OFFICE_ACTION
						? "officeactions"
						: documentRole === DocumentRole.CONTEXT
							? "context"
							: "references"
			const s3_key = `${s3_folder}/${base64Hash}`

			const response = await api.getSignedUrlForFile(s3_key, operation)
			return {
				success: true,
				data: { ...(response as any), s3_key: s3_key },
				status: 200,
			}
		} catch (error) {
			return handleError(error, `Failed to get signed URL for file ${file.name}`)
		}
	}

	const getImageSignedUrl = async (
		file: File,
		operation: S3Operation,
	): Promise<ApiResponse> => {
		try {
			const base64Hash = await generateFileHash(file)
			const s3_key = `images/${base64Hash}`
			const response = await api.getSignedUrlForFile(s3_key, operation, file.type)
			return {
				success: true,
				data: { ...(response as any), s3_key: s3_key },
				status: 200,
			}
		} catch (error) {
			return handleError(error, `Failed to get signed URL for file ${file.name}`)
		}
	}

	const uploadToS3 = async (
		file: File,
		signedUrl: string,
		contentType: string,
	): Promise<{ success: boolean }> => {
		try {
			await axios.put(signedUrl, file, {
				headers: {
					"Content-Type": contentType,
				},
			})
			return { success: true }
		} catch (error) {
			console.error("Error uploading to S3:", error)
			return { success: false }
		}
	}

	const getUploadedFileKeysAndUrls = async (
		files: File[],
		documentRole: DocumentRole = DocumentRole.PRIOR_ART,
	): Promise<{ [filename: string]: { s3_key: string; url: string } }> => {
		try {
			const uploadPromises = files.map(async (file) => {
				const isImage = file.type.startsWith("image/")

				const signedUrlResponse = isImage
					? await getImageSignedUrl(file, S3Operation.PUT)
					: await getReferenceSignedUrl(file, S3Operation.PUT, documentRole)

				const contentType = file.type || "application/octet-stream"
				const s3UploadResponse = await uploadToS3(
					file,
					signedUrlResponse.data.url,
					contentType,
				)

				if (!s3UploadResponse.success) {
					throw new Error(`Failed to upload ${file.name}`)
				}

				// Get a GET signed URL for the uploaded file
				const getUrlResponse = isImage
					? await getImageSignedUrl(file, S3Operation.GET)
					: await getReferenceSignedUrl(file, S3Operation.GET, documentRole)

				return {
					[file.name]: {
						s3_key: signedUrlResponse.data.s3_key,
						url: getUrlResponse.data.url,
					},
				}
			})

			const results = await Promise.all(uploadPromises)
			return results.reduce((acc, curr) => ({ ...acc, ...curr }), {})
		} catch (error) {
			console.error("Error uploading files:", error)
			throw error
		}
	}

	return {
		generateFileHash,
		getReferenceSignedUrl,
		uploadToS3,
		getUploadedFileKeysAndUrls,
	}
}

export default useFileUtils
