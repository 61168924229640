import FigureSelectModal from "@/components/documents/sections/FigureSelectModal"
import {
	LocationInput,
	LocationRangeInput,
} from "@/components/documents/sections/body/LocationInput"
import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { ImageUploadModal } from "@/features/charts/components"
import { useFileUtils } from "@/hooks"
import { useUndoRedo } from "@/hooks/useUndoRedo"
import type { ChunkLocation, DocumentChunk } from "@/types"
import { Cross2Icon, FileIcon } from "@radix-ui/react-icons"
import { Redo, Undo } from "lucide-react"
import { useEffect, useState } from "react"

interface EditChunkDialogProps {
	chunk: DocumentChunk
	open: boolean
	onOpenChange: (open: boolean) => void
	onSave: (updatedChunk: DocumentChunk) => void
	figureUrls: string[]
}

export function EditDocumentChunkDialog({
	chunk,
	open,
	onOpenChange,
	onSave,
	figureUrls,
}: EditChunkDialogProps) {
	const {
		present: editedChunk,
		set: setEditedChunk,
		undo,
		redo,
		canUndo,
		canRedo,
		reset: resetEditedChunk,
	} = useUndoRedo<DocumentChunk>({
		...chunk,
		text: chunk.text.replace(/\n/g, " ").replace(/\s+/g, " ").trim(),
	})

	// Local states for all inputs
	const [localText, setLocalText] = useState(editedChunk.text)
	const [localLocation, setLocalLocation] = useState(editedChunk.location)
	const [imageMenuState, setImageMenuState] = useState<{
		mouseX: number | null
		mouseY: number | null
	}>({
		mouseX: null,
		mouseY: null,
	})

	const [imageUploadModalOpen, setImageUploadModalOpen] = useState<boolean>(false)
	const [figureSelectModalOpen, setFigureSelectModalOpen] = useState<boolean>(false)

	// Add state for pending image uploads
	const [pendingImageUploads, setPendingImageUploads] = useState<File[]>([])
	const { getUploadedFileKeysAndUrls } = useFileUtils()

	// Handle immediate text changes without updating undo stack
	const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setLocalText(e.target.value)
	}

	// Update undo stack on blur for text
	const handleTextBlur = () => {
		// Only update if text has actually changed
		if (localText !== editedChunk.text) {
			setEditedChunk({
				...editedChunk,
				text: localText,
			})
		}
	}

	// Handle immediate location changes without updating undo stack
	const handleLocationChange = (updates: Partial<ChunkLocation>) => {
		setLocalLocation((prev) => ({
			...prev,
			...updates,
		}))
	}

	const handleSetSelectedUrls = (selectedUrls: string[]) => {
		setFigureSelectModalOpen(false)
		setEditedChunk({
			...editedChunk,
			figureUrls: selectedUrls,
		})
	}

	// Update undo stack on blur for location
	const handleLocationBlur = () => {
		// Only update if location has actually changed
		const hasLocationChanged = Object.entries(localLocation).some(([key, value]) => {
			const currentValue = editedChunk.location[key as keyof ChunkLocation]
			return JSON.stringify(value) !== JSON.stringify(currentValue)
		})

		if (hasLocationChanged) {
			const normalizedLocation = { ...localLocation }

			// Handle null values and duplicate single values for range fields
			if (normalizedLocation.pages) {
				const [from, to] = normalizedLocation.pages
				if (from !== null && to === null) normalizedLocation.pages = [from, from]
				if (from === null && to !== null) normalizedLocation.pages = [to, to]
				if (from === null && to === null) normalizedLocation.pages = undefined
			}
			if (normalizedLocation.paragraphs) {
				const [from, to] = normalizedLocation.paragraphs
				if (from !== null && to === null) normalizedLocation.paragraphs = [from, from]
				if (from === null && to !== null) normalizedLocation.paragraphs = [to, to]
				if (from === null && to === null) normalizedLocation.paragraphs = undefined
			}
			if (normalizedLocation.columns) {
				const [from, to] = normalizedLocation.columns
				if (from !== null && to === null) normalizedLocation.columns = [from, from]
				if (from === null && to !== null) normalizedLocation.columns = [to, to]
				if (from === null && to === null) normalizedLocation.columns = undefined
			}
			if (normalizedLocation.lines) {
				const [from, to] = normalizedLocation.lines
				if (from !== null && to === null) normalizedLocation.lines = [from, from]
				if (from === null && to !== null) normalizedLocation.lines = [to, to]
				if (from === null && to === null) normalizedLocation.lines = undefined
			}

			setEditedChunk({
				...editedChunk,
				location: normalizedLocation,
			})
		}
	}

	// Update local states when editedChunk changes (e.g. from undo/redo)
	useEffect(() => {
		setLocalText(editedChunk.text)
		setLocalLocation(editedChunk.location)
	}, [editedChunk])

	const handleAttachImageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
		setImageMenuState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleCloseAddImageMenu = () => {
		setImageMenuState({ mouseX: null, mouseY: null })
	}

	const handleRemoveImageClick = (figureUrl: string) => {
		if (editedChunk.figureUrls?.includes(figureUrl)) {
			setEditedChunk({
				...editedChunk,
				figureUrls: editedChunk.figureUrls.filter((url) => url !== figureUrl),
			})
		}
	}

	const handleImageUpload = (files: File[]) => {
		setPendingImageUploads((prev) => [...prev, ...files])
		setImageUploadModalOpen(false)
	}

	const handleSave = async () => {
		try {
			// Upload any pending images first
			if (pendingImageUploads.length > 0) {
				const uploadedKeys = await getUploadedFileKeysAndUrls(pendingImageUploads)
				const imageUrls = Object.values(uploadedKeys).map((key) => key.url)

				// Combine existing figure URLs with new ones
				const allFigureUrls = [...(editedChunk.figureUrls || []), ...imageUrls]

				// Save with the combined URLs
				onSave({
					...editedChunk,
					figureUrls: allFigureUrls,
				})
			} else {
				// No new images to upload, just save the chunk
				onSave(editedChunk)
			}

			// Clear pending uploads
			setPendingImageUploads([])
			onOpenChange(false)
		} catch (error) {
			console.error("Error uploading images:", error)
			// Handle error appropriately
		}
	}

	// Modify the image display section to show both uploaded and pending images
	const renderImagePreview = () => (
		<div className="flex flex-wrap items-center gap-2">
			{/* Show existing figure URLs */}
			{editedChunk.figureUrls?.map((imageUrl) => (
				<div key={imageUrl} className="relative group border rounded-md p-2">
					<img src={imageUrl} alt="Figure" className="max-h-24" />
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant="ghost"
								size="icon"
								className="absolute top-1 right-1 opacity-0 group-hover:opacity-100"
								onClick={() => handleRemoveImageClick(imageUrl)}
							>
								<Cross2Icon className="h-4 w-4" />
							</Button>
						</TooltipTrigger>
						<TooltipContent>Remove Image</TooltipContent>
					</Tooltip>
				</div>
			))}

			{/* Show pending uploads */}
			{pendingImageUploads.map((file) => (
				<div key={file.name} className="relative group border rounded-md p-2">
					<img src={URL.createObjectURL(file)} alt="Pending Upload" className="max-h-24" />
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant="ghost"
								size="icon"
								className="absolute top-1 right-1 opacity-0 group-hover:opacity-100"
								onClick={() => setPendingImageUploads((prev) => prev.filter((f) => f !== file))}
							>
								<Cross2Icon className="h-4 w-4" />
							</Button>
						</TooltipTrigger>
						<TooltipContent>Remove Image</TooltipContent>
					</Tooltip>
				</div>
			))}

			{/* Add Image Button */}
			<Tooltip>
				<TooltipTrigger asChild>
					<Button variant="outline" size="icon" onClick={handleAttachImageClick}>
						<FileIcon className="h-4 w-4" />
					</Button>
				</TooltipTrigger>
				<TooltipContent>
					<p>Attach Images</p>
				</TooltipContent>
			</Tooltip>
		</div>
	)

	return (
		<>
			<Dialog open={open} onOpenChange={onOpenChange}>
				<DialogContent className="sm:max-w-[800px]">
					<DialogHeader>
						<DialogTitle className="flex items-center gap-2">
							Edit Document Section
							<div className="flex gap-2">
								<Button size="icon" variant="ghost" onClick={undo} disabled={!canUndo}>
									<Undo size={16} />
								</Button>
								<Button size="icon" variant="ghost" onClick={redo} disabled={!canRedo}>
									<Redo size={16} />
								</Button>
							</div>
						</DialogTitle>
						<DialogDescription>
							Changes will be applied to all citations referencing this section.
						</DialogDescription>
					</DialogHeader>
					<div className="space-y-4">
						<div className="flex flex-row gap-4">
							{/* Pages Group */}
							<LocationRangeInput
								label="Pages"
								fromValue={localLocation.pages?.[0] || null}
								toValue={localLocation.pages?.[1] || null}
								onFromChange={(value) =>
									handleLocationChange({
										pages: [value, localLocation.pages?.[1] || null],
									})
								}
								onToChange={(value) =>
									handleLocationChange({
										pages: [localLocation.pages?.[0] || null, value],
									})
								}
								onBlur={handleLocationBlur}
								className="min-w-[120px]"
							/>

							{/* Paragraphs Group */}
							<LocationRangeInput
								label="Paragraphs"
								fromValue={localLocation.paragraphs?.[0] || null}
								toValue={localLocation.paragraphs?.[1] || null}
								onFromChange={(value) =>
									handleLocationChange({
										paragraphs: [value, localLocation.paragraphs?.[1] || null],
									})
								}
								onToChange={(value) =>
									handleLocationChange({
										paragraphs: [localLocation.paragraphs?.[0] || null, value],
									})
								}
								onBlur={handleLocationBlur}
								className="min-w-[120px]"
							/>

							{/* Columns Group */}
							<LocationRangeInput
								label="Columns"
								fromValue={localLocation.columns?.[0] || null}
								toValue={localLocation.columns?.[1] || null}
								onFromChange={(value) =>
									handleLocationChange({
										columns: [value, localLocation.columns?.[1] || null],
									})
								}
								onToChange={(value) =>
									handleLocationChange({
										columns: [localLocation.columns?.[0] || null, value],
									})
								}
								onBlur={handleLocationBlur}
								className="min-w-[120px]"
							/>

							{/* Lines Group */}
							<LocationRangeInput
								label="Lines"
								fromValue={localLocation.lines?.[0] || null}
								toValue={localLocation.lines?.[1] || null}
								onFromChange={(value) =>
									handleLocationChange({
										lines: [value, localLocation.lines?.[1] || null],
									})
								}
								onToChange={(value) =>
									handleLocationChange({
										lines: [localLocation.lines?.[0] || null, value],
									})
								}
								onBlur={handleLocationBlur}
								className="min-w-[120px]"
							/>

							<LocationInput
								label="Section"
								value={localLocation.section || null}
								onChange={(value) => handleLocationChange({ section: value })}
								onBlur={handleLocationBlur}
								className="min-w-[120px]"
							/>
						</div>

						<AutosizeTextarea
							value={localText}
							placeholder="Text content"
							onChange={handleTextChange}
							onBlur={handleTextBlur}
							maxHeight={200}
						/>

						{renderImagePreview()}
					</div>

					<DialogFooter>
						<Button variant="outline" onClick={() => onOpenChange(false)}>
							Cancel
						</Button>
						<Button onClick={handleSave}>Save changes</Button>
					</DialogFooter>
				</DialogContent>

				{/* Add Image Menu */}
				<DropdownMenu
					open={imageMenuState.mouseX !== null}
					onOpenChange={handleCloseAddImageMenu}
				>
					<DropdownMenuTrigger asChild>
						<div
							style={{
								position: "fixed",
								top: imageMenuState.mouseY ?? 0,
								left: imageMenuState.mouseX ?? 0,
								visibility: "hidden",
							}}
						/>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						{figureUrls?.length > 0 && (
							<DropdownMenuItem
								onClick={() => {
									// Handle browse figures
									setFigureSelectModalOpen(true)
									handleCloseAddImageMenu()
								}}
							>
								Browse figures
							</DropdownMenuItem>
						)}
						<DropdownMenuItem
							onClick={() => {
								// Handle upload images
								setImageUploadModalOpen(true)
								handleCloseAddImageMenu()
							}}
						>
							Upload images
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</Dialog>

			<ImageUploadModal
				open={imageUploadModalOpen}
				handleClose={() => setImageUploadModalOpen(false)}
				handleSubmitFiles={handleImageUpload}
			/>

			<FigureSelectModal
				open={figureSelectModalOpen}
				handleClose={() => setFigureSelectModalOpen(false)}
				handleSetSelectedUrls={handleSetSelectedUrls}
				figureUrls={figureUrls}
				selectedUrls={editedChunk.figureUrls || []}
			/>
		</>
	)
}
