export const QUERY_KEYS = {
	// Admin related keys
	admin: {
		users: (orgId?: string) => ["users", orgId] as const,
		overview: (orgId?: string, date?: string) => ["adminOverview", orgId, date] as const,
		projects: (orgId?: string) => ["adminProjects", orgId] as const,
		billing: (orgId?: string) => ["adminBilling", orgId] as const,
	},

	// Super admin related keys
	superAdmin: {
		overview: ["superAdminOverview"] as const,
		organizations: ["superAdminOrganizations"] as const,
		billing: ["superAdminBilling"] as const,
	},

	document: (documentId: string) => ["document", documentId] as const,

	invalidity: {
		chartData: (
			projectId: string,
			selectedReferences?: string[],
			selectedClaims?: string[],
			selectedColors?: string[],
		) =>
			[
				"invalidityChartData",
				projectId,
				selectedReferences,
				selectedClaims,
				selectedColors,
			] as const,
		summaryData: (projectId: string) => ["invalidity", "summaryData", projectId] as const,
	},

	portfolio: {
		metadata: (portfolioId: string) => ["portfolio", "meatdata", portfolioId] as const,
		references: (portfolioId: string) =>
			["portfolio", "references", portfolioId] as const,
		overview: (portfolioId: string) => ["portfolio", "overview", portfolioId] as const,
		settings: (portfolioId: string) => ["portfolio", "settings", portfolioId] as const,
		context: (portfolioId: string) => ["portfolio", "context", portfolioId] as const,
	},

	project: {
		all: ["project"] as const,
		metadata: (projectId?: string) => ["project", "metadata", projectId],
		subject: (projectId?: string) => ["project", "subject", projectId],
		documents: (projectId?: string) => ["project", "documents", projectId],
		officeActions: (projectId?: string) => ["project", "officeActions", projectId],
		claims: (projectId?: string, collapsed?: boolean) => [
			"project",
			"claims",
			projectId,
			collapsed,
		],
		settings: (projectId?: string) => ["project", "settings", projectId],
		context: (projectId?: string) => ["project", "context", projectId],
		users: (id?: string) => ["project", "users", id],
		shareableUsers: (id?: string) => ["project", "shareableUsers", id],
	},

	sharing: {
		sharedUsers: (id?: string) => ["sharing", "sharedUsers", id],
		shareableUsers: (id?: string) => ["sharing", "shareableUsers", id],
	},

	projects: {
		active: () => ["projects", "active"],
		archived: () => ["projects", "archived"],
	},

	officeActionApplicationNumber: (projectId?: string) => [
		"officeActionApplicationNumber",
		projectId,
	],

	invaliditySettings: (projectId: string, portfolioId: string) => [
		"invaliditySettings",
		projectId,
		portfolioId,
	],

	personalProject: () => ["personalProject"] as const,
} as const

export const MUTATION_KEYS = {
	admin: {
		user: {
			create: () => ["admin", "user", "create"] as const,
			update: () => ["admin", "user", "update"] as const,
			delete: () => ["admin", "user", "delete"] as const,
			toggleStatus: () => ["admin", "user", "toggleStatus"] as const,
		},
	},

	document: {
		update: () => ["document", "update"] as const,
		chunk: {
			update: () => ["document", "chunk", "update"] as const,
			add: () => ["document", "chunk", "add"] as const,
			delete: () => ["document", "chunk", "delete"] as const,
		},
		metadata: {
			update: () => ["document", "metadata", "update"] as const,
		},
		delete: () => ["document", "delete"] as const,
		prune: () => ["document", "prune"] as const,
	},

	// invalidity: { // probably also restructure
	// 	chart: {
	// 		updateChunkToLimitation: () =>
	// 			["invalidity", "chart", "updateChunkToLimitation"] as const,
	// 		toggleVerifyCitation: () => ["invalidity", "chart", "toggleVerifyCitation"] as const,
	// 		pruneByColor: () => ["invalidity", "chart", "pruneByColor"] as const,
	// 		pruneByScore: () => ["invalidity", "chart", "pruneByScore"] as const,
	// 	},
	// },

	project: {
		create: () => ["project", "create"] as const,
		update: (projectId?: string) => ["project", "update", projectId] as const,
		updateDetails: (projectId?: string) =>
			["project", "updateDetails", projectId] as const,
		updateStatus: (projectId?: string) => ["project", "updateStatus", projectId] as const,
		rename: (projectId?: string) => ["project", "rename", projectId] as const,
		context: {
			update: () => ["project", "context", "update"] as const,
			generateFromId: () => ["project", "context", "generateFromId"] as const,
			generateFromText: () => ["project", "context", "generateFromText"] as const,
		},
		claims: {
			update: () => ["project", "claims", "update"] as const,
		},
		settings: {
			update: () => ["project", "settings", "update"] as const,
		},
		users: {
			add: () => ["project", "users", "add"] as const,
			remove: () => ["project", "users", "remove"] as const,
		},
		delete: () => ["project", "delete"] as const,
	},

	portfolio: {
		update: (portfolioId?: string) => ["portfolio", "update", portfolioId] as const,
		removeProjects: (portfolioId?: string) =>
			["portfolio", "removeProjects", portfolioId] as const,
		rename: (portfolioId?: string) => ["portfolio", "rename", portfolioId] as const,
		delete: (portfolioId?: string) => ["portfolio", "delete", portfolioId] as const,
		updateStatus: (portfolioId?: string) =>
			["portfolio", "updateStatus", portfolioId] as const,
	},

	organization: {
		create: () => ["organization", "create"] as const,
		update: () => ["organization", "update"] as const,
		delete: () => ["organization", "delete"] as const,
	},

	cover: {
		generate: () => ["cover", "generate"] as const,
	},
} as const
