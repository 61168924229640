/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import DocumentDetailLayout from "@/components/documents/DocumentDetailLayout"
import { useClaims, useProject } from "@/hooks"
import { PageName } from "@/types/pages"
import type React from "react"
import ProjectPage from "../../components/projects/ProjectPage"

const SubjectPage: React.FC = () => {
	const { projectSubject, isLoading, isError } = useProject()
	const { projectClaims, isLoading: isLoadingClaims } = useClaims()

	if (isLoadingClaims.projectClaims || isLoading.projectSubject) {
		return (
			<ProjectPage pageName={PageName.ProjectSubject}>
				<Loader />
			</ProjectPage>
		)
	}

	if (isError.project) {
		return (
			<ProjectPage pageName={PageName.ProjectSubject}>
				<div>Error loading project metadata</div>
			</ProjectPage>
		)
	}

	return (
		<ProjectPage pageName={PageName.ProjectSubject}>
			<div>
				<DocumentDetailLayout document={projectSubject} claims={projectClaims} />
			</div>
		</ProjectPage>
	)
}

export default SubjectPage
