import { api } from "@/api"
import type { ProjectDocumentMetadata } from "@/types"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"
import { useReferencesBase } from "./useReferencesBase"

export function useProjectDocuments(projectId: string) {
	const { deleteDocuments, pruneDocuments } = useReferencesBase()

	const {
		data: priorArtDocuments = [],
		isLoading,
		error,
	} = useQuery({
		queryKey: QUERY_KEYS.project.documents(projectId),
		queryFn: () => api.getProjectPriorArtDocuments(projectId as string),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Wrap the base mutation with project-specific params
	const deleteProjectDocuments = async (referenceIds: string[]) => {
		return deleteDocuments.mutateAsync({
			projectId,
			referenceIds,
		})
	}

	const pruneProjectDocuments = async (numDocuments: number, strict: boolean) => {
		return pruneDocuments.mutateAsync({
			projectId,
			numReferences: numDocuments,
			strict,
		})
	}

	return {
		priorArtDocuments: priorArtDocuments as ProjectDocumentMetadata[],
		isLoading,
		error,
		deleteDocuments: deleteProjectDocuments,
		pruneDocuments: pruneProjectDocuments,
	}
}

export default useProjectDocuments
