/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { TooltipProvider } from "@/components/ui/tooltip"
import { H4, Paragraph } from "@/components/ui/typography"
import ProtectedRoute from "@/routes/ProtectedRoute"
import { ThemeProvider } from "@/styled/ThemeProvider"
import { AuthProvider } from "@propelauth/react"
import React from "react"
import { isMobile } from "react-device-detect"
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"

// Admin Pages
import AdminBillingPage from "@/features/admin/admin/AdminBillingPage"
import AdminPage from "@/features/admin/admin/AdminPage"
import AdminUsersPage from "@/features/admin/admin/AdminUsersPage"
import SuperAdminBillingPage from "@/features/admin/superadmin/SuperAdminBillingPage"
import SuperAdminOrganizationDetailsPage from "@/features/admin/superadmin/SuperAdminOrganizationDetailsPage"
import SuperAdminOrganizationPage from "@/features/admin/superadmin/SuperAdminOrganizationPage"
import SuperAdminPage from "@/features/admin/superadmin/SuperAdminPage"

// Create Pages
import CreateProjectPage from "@/features/create/CreateProjectPage"

// Project Pages
import ApplicationDocumentsPage from "@/features/application/ApplicationDocumentsPage"
import InvalidityChartsPage from "@/features/charts/InvalidityChartsPage"
import ProjectChatPage from "@/features/chat/ProjectChatPage"
import ContextPage from "@/features/context/ContextPage"
import CoverPleadingPage from "@/features/cover/CoverPleadingPage"
import ProjectDocumentPage from "@/features/references/ProjectDocumentPage"
import ReferencesPage from "@/features/references/ReferencesPage"
import SubjectPage from "@/features/subject/SubjectPage"

// Portfolio Pages
import PortfolioCoverPleadingPage from "@/features/cover/PortfolioCoverPleadingPage"
import PortfolioOverviewPage from "@/features/portfolio/PortfolioOverviewPage"
import PortfolioSubjectsPage from "@/features/portfolio/PortfolioSubjectsPage"

import PageNotFound from "@/features/PageNotFound"
import UnauthorizedPage from "@/features/UnauthorizedPage"
// Misc. Pages
import LogInPage from "@/features/auth/LogInPage"
import HomePage from "@/features/home/HomePage"
import ProjectSearchPage from "@/features/search/ProjectSearchPage"
// User Documents Pages
import UserDocumentPage from "@/features/user-documents/UserDocumentPage"
import UserDocumentsChatPage from "@/features/user-documents/UserDocumentsChatPage"
import UserDocumentsPage from "@/features/user-documents/UserDocumentsPage"
import UserDocumentsSearchPage from "@/features/user-documents/UserDocumentsSearchPage"
import { usePostHogIdentify } from "./hooks/integrations/usePostHogIdentity"
import { useSentryReplay } from "./hooks/integrations/useSentryReplay"

import { PortfolioRoute } from "@/routes/PortfolioRoute"
import { ProjectRoute } from "@/routes/ProjectRoute"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { PostHogProvider } from "posthog-js/react"
import { useSetupApiAuth } from "./hooks/useApiAuth"

// Create QueryClient instance outside of component
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 1,
			refetchOnWindowFocus: false,
		},
	},
})

const AppContent: React.FC = () => {
	usePostHogIdentify()
	useSentryReplay()
	useSetupApiAuth()

	return (
		<Router>
			<Routes>
				{/* Auth Routes */}
				<Route path="/signin" element={<LogInPage />} />
				<Route path="/" element={<Navigate to="/signin" />} />
				<Route path="*" element={<Navigate to="/signin" />} />

				<Route element={<ProtectedRoute />}>
					{/* Admin Routes */}
					<Route path="/admin" element={<AdminPage />} />
					<Route path="/admin/users" element={<AdminUsersPage />} />
					<Route path="/admin/billing" element={<AdminBillingPage />} />

					{/* Super Admin Routes */}
					<Route path="/superadmin" element={<SuperAdminPage />} />
					<Route path="/superadmin/billing" element={<SuperAdminBillingPage />} />
					<Route path="/superadmin/organizations" element={<SuperAdminOrganizationPage />} />
					<Route
						path="/superadmin/organizations/:organizationId"
						element={<SuperAdminOrganizationDetailsPage />}
					/>

					{/* User Documents Routes */}
					<Route path="/documents" element={<UserDocumentsPage />} />
					<Route path="/documents/:documentId" element={<UserDocumentPage />} />
					<Route
						path="/documents/:documentId/search"
						element={<UserDocumentsSearchPage />}
					/>
					<Route path="/documents/:documentId/chat" element={<UserDocumentsChatPage />} />

					{/* Project Routes */}
					<Route element={<ProjectRoute />}>
						<Route path="/project/:projectId/subject" element={<SubjectPage />} />
						<Route path="/project/:projectId/context" element={<ContextPage />} />
						<Route path="/project/:projectId/references" element={<ReferencesPage />} />
						<Route
							path="/project/:projectId/references/:documentId"
							element={<ProjectDocumentPage />}
						/>
						<Route path="/project/:projectId/charts" element={<InvalidityChartsPage />} />
						<Route path="/project/:projectId/search" element={<ProjectSearchPage />} />
						<Route path="/project/:projectId/chat" element={<ProjectChatPage />} />
						<Route
							path="/project/:projectId/documents"
							element={<ApplicationDocumentsPage />}
						/>
						<Route path="/project/:projectId/cover" element={<CoverPleadingPage />} />
					</Route>

					{/* Portfolio Routes */}
					<Route element={<PortfolioRoute />}>
						<Route
							path="/portfolio/:portfolioId/subjects"
							element={<PortfolioSubjectsPage />}
						/>
						<Route path="/portfolio/:portfolioId/references" element={<ReferencesPage />} />
						<Route
							path="/portfolio/:portfolioId/overview"
							element={<PortfolioOverviewPage />}
						/>
						<Route
							path="/portfolio/:portfolioId/cover"
							element={<PortfolioCoverPleadingPage />}
						/>

						{/* Nested Project Routes within Portfolio */}
						{/* <Route element={<ProjectRoute />}> */}
						<Route
							path="/portfolio/:portfolioId/project/:projectId/subject"
							element={<SubjectPage />}
						/>
						<Route
							path="/portfolio/:portfolioId/project/:projectId/context"
							element={<ContextPage />}
						/>
						<Route
							path="/portfolio/:portfolioId/project/:projectId/references"
							element={<ReferencesPage />}
						/>
						<Route
							path="/portfolio/:portfolioId/project/:projectId/charts"
							element={<InvalidityChartsPage />}
						/>
						<Route
							path="/portfolio/:portfolioId/project/:projectId/search"
							element={<ProjectSearchPage />}
						/>
						<Route
							path="/portfolio/:portfolioId/project/:projectId/chat"
							element={<ProjectChatPage />}
						/>
						<Route
							path="/portfolio/:portfolioId/project/:projectId/documents"
							element={<ApplicationDocumentsPage />}
						/>
						<Route
							path="/portfolio/:portfolioId/project/:projectId/cover"
							element={<CoverPleadingPage />}
						/>
						{/* </Route> */}
					</Route>

					{/* Create Routes */}
					<Route path="/create-project" element={<CreateProjectPage />} />

					{/* Unauthorized Route */}
					<Route path="/project/:projectId/unauthorized" element={<UnauthorizedPage />} />
					<Route
						path="/portfolio/:portfolioId/unauthorized"
						element={<UnauthorizedPage />}
					/>
					<Route
						path="/portfolio/:portfolioId/unauthorized"
						element={<UnauthorizedPage />}
					/>

					{/* Home Route */}
					<Route path="/home" element={<HomePage />} />

					{/* Page Not Found Route */}
					<Route path="*" element={<PageNotFound />} />
				</Route>
			</Routes>
		</Router>
	)
}

const App: React.FC = () => {
	if (isMobile) {
		return (
			<div
				style={{
					width: "100vw",
					padding: 20,
					textAlign: "center",
					marginTop: 50,
				}}
			>
				<H4>&AI</H4>
				<Paragraph>Mobile use is not supported.</Paragraph>
				<Paragraph>Please access on a computer.</Paragraph>
			</div>
		)
	}

	return (
		<React.StrictMode>
			<PostHogProvider>
				<QueryClientProvider client={queryClient}>
					<AuthProvider authUrl={process.env.REACT_APP_AUTH_URL!}>
						<ThemeProvider defaultTheme="system" storageKey="ui-theme">
							<TooltipProvider>
								<AppContent />
							</TooltipProvider>
						</ThemeProvider>
					</AuthProvider>
					{process.env.NODE_ENV !== "production" && (
						<ReactQueryDevtools initialIsOpen={true} />
					)}
				</QueryClientProvider>
			</PostHogProvider>
		</React.StrictMode>
	)
}

export default App
