import { chartsApi } from "@/api/chartsApi"
import { useProjectAndPortfolioIds } from "@/hooks"
import { useProjectStore } from "@/store"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

function useInvalidityChartData() {
	const { selectedReferences, selectedColors, selectedLimitations } = useProjectStore()
	const { projectId } = useProjectAndPortfolioIds()

	const { data, isLoading, isError } = useQuery({
		queryKey: QUERY_KEYS.invalidity.chartData(
			projectId,
			selectedReferences,
			selectedLimitations,
			selectedColors,
		),
		queryFn: async () => {
			if (!projectId) return null
			return chartsApi.getInvalidityChartData(
				projectId,
				selectedReferences,
				selectedLimitations,
				selectedColors,
			)
		},
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		invalidityChartData: data,
		isLoadingChartData: isLoading,
		isErrorChartData: isError,
	}
}

export default useInvalidityChartData
