/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DataTable } from "@/components/table/DataTable"
import { useOfficeAction } from "@/hooks"
import type { OfficeAction } from "@/types"
import React, { useCallback, useState } from "react"
import { getCommonColumns } from "./components/ApplicationDocumentsTableColumns"

interface ApplicationDocumentsProps {
	documents: OfficeAction[]
	height?: string
	children?: React.ReactNode
	onRowClick?: (documentId: string) => void
	onDelete?: (officeActions: OfficeAction[]) => void
	onDeleteSelected?: () => void
}

/**
 * @description Table for displaying user uploaded files
 */
const ApplicationDocumentsTable: React.FC<ApplicationDocumentsProps> = ({
	documents,
	height,
	children,
	onRowClick,
}) => {
	// Project stores
	const { deleteOfficeAction } = useOfficeAction()

	// Local States
	const [_selectedRows, _setSelectedRows] = useState<OfficeAction[]>([])
	const [handleDeleteSelectedLoading, setHandleDeleteSelectedLoading] = useState(false)

	// Get columns based on document types present in the data
	const columns = [...getCommonColumns()]
	// if (documents.some((doc) => doc.type === DocumentType.OFFICE_ACTION)) {
	//   columns.push(...getOfficeActionColumns());
	// }

	// Table actions
	const handleDeleteSelected = async (selectedRows: OfficeAction[]) => {
		setHandleDeleteSelectedLoading(true)
		try {
			const selectedIds = selectedRows.map((doc) => doc.id)
			await deleteOfficeAction(selectedIds)
		} catch (error) {
			console.error("Error deleting office actions:", error)
		} finally {
			setHandleDeleteSelectedLoading(false)
		}
	}

	const handleRowClick = useCallback(
		(row: any) => {
			onRowClick?.(row.id)
		},
		[onRowClick],
	)

	// const handleReprocessSelected = async (
	//   selectedRows: ApplicationDocument[],
	//   isReprocess: boolean
	// ) => {
	//   const selectedIds = selectedRows.map((doc) => doc.id);
	//   try {
	//     await processOfficeActionClaimDetails(currentProject.id, selectedIds);
	//     setSelectedRows([]);
	//   } catch (error) {
	//     console.error("Error processing office action claims:", error);
	//   }
	// };

	// Handle row selection changes through DataTable's onRowSelection prop
	const handleRowSelection = (_selectedRows: OfficeAction[]) => {
		// do nothing
	}

	return (
		<>
			<DataTable
				columns={columns}
				data={documents}
				onRowClick={handleRowClick}
				height={height}
				showActions={true}
				enableRowDelete={true}
				onDeleteSelected={handleDeleteSelected}
				// onReprocessSelected={handleReprocessSelected}
				isDeleteSelectedLoading={handleDeleteSelectedLoading}
				showTag={false}
				showReprocess={true}
				showColumns={true}
				onRowSelection={handleRowSelection}
			>
				{children}
			</DataTable>
		</>
	)
}

export default React.memo(ApplicationDocumentsTable)
