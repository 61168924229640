import {
	type AddDocumentChunkRequest,
	type ChunkLocation,
	type DeleteDocumentChunkRequest,
	type DeleteDocumentsRequest,
	type DocumentStatus,
	type DocumentToProcess,
	type ElementStrengthsResponse,
	type GetElementStrengthsRequest,
	type GetOfficeActionsRequest,
	type GetPatentDetailsRequest,
	type PatentNumberOptions,
	type PortfolioDocument,
	type ProcessDocumentsRequest,
	type ProcessSettings,
	type ProjectDocument,
	type PruneReferencesRequest,
	type ReprocessDocumentsRequest,
	S3Operation,
	type SemanticSearchDocumentsRequest,
	type SemanticSearchResponse,
	type UpdateDocumentChunkRequest,
	type UpdateDocumentMetadataRequest,
	type UpdateDocumentRequest,
} from "@/types"
import { apiClient } from "./client"

export const documentApi = {
	async deleteReferences(
		projectId: string | null,
		portfolioId: string | null,
		referenceIds: string[],
	) {
		const payload: DeleteDocumentsRequest = {
			project_id: projectId,
			portfolio_id: portfolioId,
			reference_ids: referenceIds,
		}

		return await apiClient.post("post_delete_documents", payload)
	},

	async pruneReferences(projectId: string, numReferences?: number, strict?: boolean) {
		const params: PruneReferencesRequest = {
			project_id: projectId,
			num_references: numReferences,
			strict,
		}
		return await apiClient.get("get_references_to_prune", params)
	},

	async semanticSearchDocuments(
		projectId: string,
		searchQuery: string,
		documentIds: string[],
		searchMode: string,
	): Promise<SemanticSearchResponse> {
		const params: SemanticSearchDocumentsRequest = {
			project_id: projectId,
			text: searchQuery,
			documents_ids: documentIds,
			results: 50,
			search_mode: searchMode as "semantic" | "keyword",
		}

		return await apiClient.get("get_semantic_search_documents", params)
	},

	async getFullDocument(
		documentId: string,
		projectId: string | null,
		portfolioId: string | null,
	): Promise<ProjectDocument | PortfolioDocument> {
		const params = {
			document_id: documentId,
			...(projectId && { project_id: projectId }),
			...(portfolioId && { portfolio_id: portfolioId }),
		}
		return await apiClient.get("get_full_document", params)
	},

	async getElementStrengths(projectId: string): Promise<ElementStrengthsResponse> {
		const params: GetElementStrengthsRequest = {
			project_id: projectId,
		}
		return await apiClient.get("get_element_strengths", params)
	},

	async processDocuments(
		portfolioId: string | null, // Add portfolioId for projects with a portfolio
		projectIds: string[] | null,
		documents: DocumentToProcess[],
		settings: ProcessSettings,
	) {
		const payload: ProcessDocumentsRequest = {
			portfolio_id: portfolioId,
			project_ids: projectIds,
			documents,
			settings,
		}
		return await apiClient.post("process", payload)
	},

	async reprocessDocuments(
		portfolioId: string | null,
		projectIds: string[] | null,
		documentIds: string[],
		settings: ProcessSettings,
	) {
		const payload: ReprocessDocumentsRequest = {
			portfolio_id: portfolioId,
			project_ids: projectIds,
			document_ids: documentIds,
			settings: {
				chart: settings.chart ?? true,
				reparse: settings.reparse ?? false,
				citation_policy: settings.citation_policy,
				claims_in_charting: settings.claims_in_charting ?? false,
			},
		}
		return await apiClient.post("reprocess", payload)
	},

	async getPatentDetails(
		patentNumbers: string[],
		isEarlyApplication: boolean,
	): Promise<PatentNumberOptions[]> {
		const params: GetPatentDetailsRequest = {
			patent_numbers: patentNumbers,
			is_early_application: isEarlyApplication,
		}
		return await apiClient.get("get_patent_details", params)
	},

	async updateDocuments(
		documentIds: string[],
		status: DocumentStatus | null = null,
		title: string | null = null,
	) {
		const payload: UpdateDocumentRequest = {
			document_ids: documentIds,
			status,
			title,
		}
		return await apiClient.post("post_update_documents", payload)
	},

	async updateDocumentChunk({
		documentChunkId,
		text,
		location,
		figureUrls,
	}: {
		documentChunkId: string
		text?: string
		location?: ChunkLocation
		figureUrls?: string[]
	}) {
		const payload: UpdateDocumentChunkRequest = {
			document_chunk_id: documentChunkId,
			...(text && { text }),
			...(location && { location }),
			...(figureUrls && { figure_urls: figureUrls }),
		}
		return await apiClient.post("post_update_document_chunk", payload)
	},

	async addDocumentChunk(
		documentId: string,
		text: string,
		location: ChunkLocation,
		figureUrls: string[],
		// type: BlockType,
	) {
		const payload: AddDocumentChunkRequest = {
			document_id: documentId,
			text,
			location: location as Record<string, never>,
			figure_urls: figureUrls,
			type: "TEXT",
		}
		return await apiClient.post("post_add_document_chunk", payload)
	},

	async deleteDocumentChunk(documentChunkId: string) {
		const payload: DeleteDocumentChunkRequest = {
			document_chunk_id: documentChunkId,
		}
		return await apiClient.post("post_delete_document_chunk", payload)
	},

	async updateDocumentMetadata(
		projectId: string,
		portfolioId: string,
		documentIds: string[],
		options: {
			notes?: string | null
			addTags?: string[] | null
			setTags?: string[] | null
			nickname?: string | null
			documentSummary?: string | null
		},
	) {
		const payload: UpdateDocumentMetadataRequest = {
			...(projectId && { project_id: projectId }),
			...(portfolioId && { portfolio_id: portfolioId }),
			document_ids: documentIds,
			...(options.notes && { notes: options.notes }),
			...(options.addTags && { add_tags: options.addTags }),
			...(options.setTags && { set_tags: options.setTags }),
			...(options.nickname && { nickname: options.nickname }),
			...(options.documentSummary && { document_summary: options.documentSummary }),
		}
		return await apiClient.post("post_update_document_metadata", payload)
	},

	async getOfficeActions(projectId: string) {
		const params: GetOfficeActionsRequest = {
			project_id: projectId,
		}
		return await apiClient.get("get_office_actions", params)
	},

	async getSignedUrlForFile(
		s3Key: string,
		operation: S3Operation = S3Operation.GET,
		contentType = "application/pdf",
	) {
		const data = await apiClient.get("get_signed_url_for_file", {
			file_id: s3Key,
			operation: operation,
			content_type: contentType,
		})
		return data
	},

	async getPdfFile(url: string): Promise<{ data: Blob; status: number }> {
		return await apiClient.getBinary("get_pdf_file", { url })
	},
}
