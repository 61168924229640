/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { cn } from "@/lib/utils"
import { useProjectStore } from "@/store"
import type {
	PortfolioDocumentMetadata,
	ProjectDocumentMetadata,
} from "@/types/documents"
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import type React from "react"
import { memo, useCallback, useEffect, useState } from "react"

interface DocumentSelectProps {
	handleDocumentSelect: (selectedIds: string[]) => void
	documents: ProjectDocumentMetadata[] | PortfolioDocumentMetadata[]
	className?: string
}

/**
 * @description Reference select to control references displayed in the table
 * @param {function} handleReferenceSelect - Function to handle the selection of references.
 */
const DocumentSelect: React.FC<DocumentSelectProps> = ({
	handleDocumentSelect,
	className,
	documents,
}) => {
	const [open, setOpen] = useState(false)
	const { getDocumentName } = useDocumentNaming()

	const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([])

	const selectableDocumentIds = documents.map((doc) => doc.documentId)

	const { selectedReferences } = useProjectStore()

	useEffect(() => {
		if (selectedReferences) {
			setSelectedDocumentIds(selectedReferences)
		}
	}, [selectedReferences])

	const handleDocumentToggle = useCallback((id: string) => {
		setSelectedDocumentIds((prev) => {
			const newSelection = prev.includes(id)
				? prev.filter((item) => item !== id)
				: [...prev, id]
			return newSelection.slice(0, 3) // Limit to 3 selections
		})
	}, [])

	const handleClose = useCallback(() => {
		handleDocumentSelect(selectedDocumentIds)
		setOpen(false)
	}, [handleDocumentSelect, selectedDocumentIds])

	// UI Components
	const SelectablePriorArtRow = memo(
		({
			id,
			isSelected,
			onToggle,
			getDocumentName,
		}: {
			id: string
			isSelected: boolean
			onToggle: (id: string) => void
			getDocumentName: (id: string) => string
		}) => (
			<Button
				key={id}
				variant="ghost"
				className="w-full justify-start text-left"
				onClick={() => onToggle(id)}
			>
				<div className="flex items-center w-full">
					{isSelected && <CheckIcon className="mr-2 h-4 w-4 flex-shrink-0" />}
					<span className="truncate">{getDocumentName(id)}</span>
				</div>
			</Button>
		),
	)

	const DisplayedPriorArts = memo(
		({
			ids,
			getDocumentName,
		}: {
			ids: string[]
			getDocumentName: (id: string) => string
		}) => (
			<div className="flex items-center space-x-1 truncate">
				{ids.length ? (
					ids.map((id) => (
						<span key={id} className="text-secondary-foreground px-2 rounded border">
							{getDocumentName(id)}
						</span>
					))
				) : (
					<span>Select documents</span>
				)}
			</div>
		),
	)

	return (
		<Popover
			open={open}
			onOpenChange={(isOpen) => (isOpen ? setOpen(true) : handleClose())}
		>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					aria-expanded={open}
					aria-haspopup="listbox"
					aria-controls="reference-select-options"
					className={cn("justify-between flex-grow", className)}
				>
					<DisplayedPriorArts ids={selectedDocumentIds} getDocumentName={getDocumentName} />
					<ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>

			<PopoverContent
				className="p-0 w-auto min-w-[var(--radix-popover-trigger-width)] max-w-[300px]"
				align="start"
			>
				<ScrollArea className="h-[300px]">
					<div className="p-1">
						{selectableDocumentIds.map((id) => (
							<SelectablePriorArtRow
								key={id}
								id={id}
								isSelected={selectedDocumentIds.includes(id)}
								onToggle={handleDocumentToggle}
								getDocumentName={getDocumentName}
							/>
						))}
					</div>
				</ScrollArea>
			</PopoverContent>
		</Popover>
	)
}

export default DocumentSelect
