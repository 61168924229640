import type {
	DeletePortfolioRequest,
	GetPortfolioDocumentsRequest,
	GetPortfolioMatrixRequest,
	GetPortfolioMatrixResponse,
	GetPortfolioMetadataRequest,
	PortfolioDocumentMetadata,
	PortfolioMetadata,
	RemoveProjectsFromPortfolioRequest,
	UpdatePortfolioDetailsRequest,
} from "@/types"
import { apiClient } from "./client"

export const portfolioApi = {
	async updatePortfolioDetails(portfolioId: string, options: any) {
		const payload: UpdatePortfolioDetailsRequest = {
			portfolio_id: portfolioId,
			...options,
		}
		return await apiClient.post("post_update_portfolio_details", payload)
	},

	async getPortfolioMetadata(
		portfolioId: string,
		includeReferences = true,
	): Promise<PortfolioMetadata> {
		const params: GetPortfolioMetadataRequest = {
			portfolio_id: portfolioId,
			include_references: includeReferences,
		}
		return await apiClient.get("get_portfolio_metadata", params)
	},

	async removeProjectsFromPortfolio(portfolioId: string, projectIds: string[]) {
		const payload: RemoveProjectsFromPortfolioRequest = {
			portfolio_id: portfolioId,
			project_ids: projectIds,
		}
		return await apiClient.post("post_remove_projects_from_portfolio", payload)
	},

	// TODO mege into search
	async getPatentsForAssignee(
		assignee: string[],
		priorityDate: string,
		expiryDate: string,
		cpcCodes: string[],
		types: string[],
	) {
		return await apiClient.get("get_patents_for_assignee", {
			assignee,
			priority_date_start: priorityDate,
			priority_date_end: expiryDate,
			cpc_codes: cpcCodes,
			types,
			limit: 1000,
		})
	},

	async getPortfolioMatrix(
		portfolioId: string,
		assertedClaimsOnly: boolean,
	): Promise<GetPortfolioMatrixResponse> {
		const params: GetPortfolioMatrixRequest = {
			portfolio_id: portfolioId,
			asserted_claims_only: assertedClaimsOnly,
		}
		return await apiClient.get("get_portfolio_matrix", params)
	},

	async deletePortfolio(portfolioId: string) {
		const payload: DeletePortfolioRequest = {
			portfolio_id: portfolioId,
		}
		return await apiClient.post("post_delete_portfolio", payload)
	},

	async getPortfolioPriorArtRows(
		portfolioId: string,
	): Promise<PortfolioDocumentMetadata[]> {
		const params: GetPortfolioDocumentsRequest = {
			portfolio_id: portfolioId,
		}
		return await apiClient.get("get_portfolio_prior_art_rows", params)
	},
}
