/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ElementPill, ErrorMessage, Loader } from "@/components"
import UnprocessedDocumentsList from "@/components/documents/unprocessed/UnprocessedDocumentsList"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import {
	useElementStrengths,
	usePriorArtSearch,
	useProjectAndPortfolioIds,
	useUnprocessedDocuments,
} from "@/hooks"
import useProcessDocuments from "@/hooks/useProcessDocuments"
import { useAppStateStore } from "@/store"
import {
	DocumentRole,
	type ElementStrength,
	type ElementStrengthCategory,
	ProcessType,
	type ProjectDocumentMetadata,
	UnprocessedDocumentType,
} from "@/types"
import { useEffect, useState } from "react"

interface PatchReferencesModalProps {
	open: boolean
	handleClose: () => void
}

/**
 * @description Patch elemgaps modal
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 */
function PatchReferencesModal({ open, handleClose }: PatchReferencesModalProps) {
	const { processDocuments } = useProcessDocuments()
	const { getElementStrengths } = useElementStrengths()
	const { searchPriorArt } = usePriorArtSearch()

	// Global state from store
	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const { addErrorMessage } = useAppStateStore()
	const {
		addUnprocessedDocuments,
		unprocessedDocuments,
		removeUnprocessedDocument,
		updateSelectedKindCode,
	} = useUnprocessedDocuments()

	// Local state setup
	const [claims, setClaims] = useState<ElementStrengthCategory>({
		weak: [],
		strong: [],
	})
	const [isSourcesLoading, setIsSourcesLoading] = useState(false)
	// const [foundSources, setFoundSources] = useState<Patent[]>([]);
	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [error, setError] = useState(false)
	const [selectedReferences, setSelectedReferences] = useState<
		ProjectDocumentMetadata[]
	>([])
	const [selected, setSelected] = useState<ElementStrength[]>([])
	const [notSelected, setNotSelected] = useState<ElementStrength[]>([])
	const [priorArtSearchInstructions, setPriorArtSearchInstructions] = useState("")
	const [priorArtFilterInstructions, setPriorArtFilterInstructions] = useState("")

	// Reset modal state on open
	useEffect(() => {
		resetModal()
		// Fetch element strengths when modal opens
		if (projectId && open) {
			const fetchData = async () => {
				const response = await getElementStrengths(projectId)
				if (response.success && response.data) {
					const { claims } = response.data
					setClaims(claims || { weak: [], strong: [] })

					setSelected(claims.weak || [])
					setNotSelected(claims.strong || [])
				}
			}
			fetchData()
		}
	}, [open])

	// Set the selected and not selected elements when mode changes
	useEffect(() => {
		setSelected(claims.weak)
		setNotSelected(claims.strong)
	}, [claims])

	// Reset modal state
	const resetModal = () => {
		setHasSubmitted(false)
		setIsSourcesLoading(false)
		setError(false)
		setSelectedReferences([])
		setPriorArtSearchInstructions("")
		setPriorArtFilterInstructions("")
	}

	// Add references to project
	const handleAddReferences = async (_addAll = false) => {
		handleClose()

		// const numbersToAdd = addAll
		//   ? foundSources.map((source) => source.number)
		//   : selectedReferences.map((source) => source.number);

		await processDocuments(
			ProcessType.ADD_PRIOR_ART,
			portfolioId,
			projectId,
			unprocessedDocuments,
		)
	}

	// Searches for prior art based on the given keywords and search settings
	const handleSearchClick = async () => {
		setHasSubmitted(true)
		setIsSourcesLoading(true)

		const payload = {
			max_results: 10,
			claim_numbers: selected.map((item) => item.label),
		}

		const keywords: string[] = []
		// if (priorArtSearchInstructions)
		//   payload.search_instructions = priorArtSearchInstructions
		// if (priorArtFilterInstructions)
		//   payload.filter_instructions = priorArtFilterInstructions
		// payload.dedupe_family_id = false
		// payload.use_independent_claims = false
		const priorArtResponse = await searchPriorArt(projectId, keywords, payload)
		if (!priorArtResponse.success) {
			handleError()
			return
		}
		const priorArt = priorArtResponse.data

		if (priorArt) {
			addUnprocessedDocuments(
				DocumentRole.PRIOR_ART,
				UnprocessedDocumentType.PUBLISHED_PATENT,
				{
					patentDetails: priorArt,
				},
			)
		}
		setIsSourcesLoading(false)
	}

	// Handle error
	const handleError = () => {
		resetModal()
		addErrorMessage("Search failed.")
	}

	// Handle element selection
	const handleAddElement = (element: ElementStrength) => {
		const updatedSelected = [...selected, element]
		const updatedNotSelected = notSelected.filter((item) => item !== element)
		setSelected(updatedSelected)
		setNotSelected(updatedNotSelected)
	}

	const handleRemoveElement = (element: ElementStrength) => {
		const updatedSelected = selected.filter((item) => item !== element)
		const updatedNotSelected = [...notSelected, element]
		setSelected(updatedSelected)
		setNotSelected(updatedNotSelected)
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col min-w-[600px]">
				<DialogHeader>
					<DialogTitle className="flex items-center gap-2">Patch references</DialogTitle>

					{!hasSubmitted && (
						<p className="text-sm text-muted-foreground">
							Search for references that cover the selected claims or features in the set.
						</p>
					)}
				</DialogHeader>

				<div>
					{!hasSubmitted ? (
						<div className="flex-grow overflow-y-auto">
							<Table>
								<TableHeader>
									<TableRow>
										<TableHead>Selected</TableHead>
										<TableHead>Unselected</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									<TableRow>
										<TableCell className="align-top bg-muted">
											<div className="flex flex-wrap gap-1">
												{selected.map((element, index) => (
													<ElementPill
														key={index}
														handleClick={() => handleRemoveElement(element)}
														color={element.color}
														label={element.label}
														isSelected={true}
													/>
												))}
											</div>
										</TableCell>
										<TableCell className="align-top">
											<div className="flex flex-wrap gap-1">
												{notSelected.map((element, index) => (
													<ElementPill
														key={index}
														handleClick={() => handleAddElement(element)}
														color={element.color}
														label={element.label}
														isSelected={false}
													/>
												))}
											</div>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
							{/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <PriorArtSearchInstructions
                  instructions={priorArtSearchInstructions}
                  onChange={setPriorArtSearchInstructions}
                  isEditing={true}
                />
                <PriorArtFilterInstructions
                  instructions={priorArtFilterInstructions}
                  onChange={setPriorArtFilterInstructions}
                  isEditing={true}
                />
              </div> */}
						</div>
					) : (
						<>
							{isSourcesLoading ? (
								<Loader message="Finding references..." />
							) : (
								<div>
									<div>
										{unprocessedDocuments?.length > 0 ? (
											<div className="flex flex-col h-[calc(85vh-100px)]">
												<UnprocessedDocumentsList
													documents={unprocessedDocuments}
													onRemove={removeUnprocessedDocument}
													updateSelectedKindCode={updateSelectedKindCode}
												/>
											</div>
										) : (
											<div className="mt-4 flex flex-col items-center">
												<p>No references found. Try again.</p>
												<Button className="mt-2" variant="outline" onClick={resetModal}>
													Search Again
												</Button>
											</div>
										)}
									</div>
								</div>
							)}
						</>
					)}
				</div>

				{error && <ErrorMessage />}
				<DialogFooter>
					<>
						{!hasSubmitted && (
							<>
								<Button variant="outline" onClick={handleClose}>
									Cancel
								</Button>
								<Button onClick={handleSearchClick} disabled={selected?.length === 0}>
									Search
								</Button>
							</>
						)}
						{unprocessedDocuments?.length > 0 && (
							<>
								<Button
									onClick={() => resetModal()}
									aria-label="clear results"
									variant="outline"
								>
									Clear Results
								</Button>
								<Button
									onClick={() => handleAddReferences(true)}
									variant={selectedReferences.length === 0 ? "default" : "outline"}
									aria-label="add all"
								>
									Add All
								</Button>
								<Button
									onClick={() => handleAddReferences(false)}
									disabled={selectedReferences.length === 0}
									aria-label="add selected"
								>
									Add Selected
								</Button>
							</>
						)}
					</>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default PatchReferencesModal
