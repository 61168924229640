import { api } from "@/api"
import { useClearFeedbackDataByID } from "@/features/charts/hooks/useFeedbackData"
import { useProjectStore } from "@/store"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export function useReferencesBase() {
	const queryClient = useQueryClient()
	const { removeSelectedReferences, selectedReferences } = useProjectStore()
	const clearFeedbackChartDataForReferences = useClearFeedbackDataByID()

	const deleteReferences = useMutation({
		mutationKey: MUTATION_KEYS.document.delete(),
		mutationFn: async ({
			projectId,
			portfolioId,
			referenceIds,
		}: {
			projectId?: string
			portfolioId?: string
			referenceIds: string[]
		}) => {
			return api.deleteReferences(projectId, portfolioId, referenceIds)
		},
		onMutate: async (variables) => {
			const queryKeys = [
				QUERY_KEYS.project.documents(variables.projectId),
				QUERY_KEYS.portfolio.references(variables.portfolioId),
			]
			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys,
				updateFn: (oldData: any) =>
					oldData?.filter((ref: any) => !variables.referenceIds.includes(ref.id)),
			})

			return context
		},
		onError: (err, variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
		},
		onSuccess: (_, variables) => {
			// Still clear selected references and feedback data on success
			if (selectedReferences !== null) {
				removeSelectedReferences(variables.referenceIds)
			}
			clearFeedbackChartDataForReferences(variables.projectId, variables.referenceIds)
		},
	})

	const pruneReferences = useMutation({
		mutationKey: MUTATION_KEYS.document.prune(),
		mutationFn: async ({
			projectId,
			numReferences,
			strict,
		}: {
			projectId: string
			numReferences?: number
			strict?: boolean
		}) => {
			return api.pruneReferences(projectId, numReferences, strict)
		},
		onSuccess: (_, variables) => {
			// Invalidate because we don't know what changed
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.documents(variables.projectId),
			})
		},
	})

	return {
		deleteDocuments: deleteReferences,
		pruneDocuments: pruneReferences,
	}
}
