/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
	BulkAddModal,
	ClearConfirmationModal,
	GenerateContextModal,
	SearchResultsModal,
} from "@/features/context/components/ContextModals"
import { useFileUtils, usePortfolio, useProject, useSearchDocuments } from "@/hooks"
import { useAppStateStore, useAreProcessesPending, useProcessStore } from "@/store"
import {
	ProcessType,
	type ProjectContext,
	ProjectType,
	conflictingContextProcesses,
} from "@/types"
import { PageName } from "@/types/pages"
import { nanoid } from "nanoid"
import type React from "react"
import { useState } from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import {
	Features,
	ImportantFacts,
	PriorArtSearchInstructions,
	TermsAndDefinitions,
} from "./components/ContextSections"
import { GenerateContextDropdown } from "./components/GenerateContextDropdown"
import useProjectContext from "./hooks/useProjectContext"

const defaultContextData: ProjectContext = {
	expertKnowledge: "",
	termsAndDefinitions: {},
	importantFacts: [],
	priorArtSearchInstructions: "",
	priorArtSearchFilterInstructions: "",
	features: [],
}

/**
 * @description Context page
 */
const ContextPage: React.FC = () => {
	// Hooks
	const { uploadToS3 } = useFileUtils()
	const { semanticSearchDocuments } = useSearchDocuments()
	const {
		generatePatentContextFromId,
		generatePatentContextFromText,
		updateProjectContext,
		context,
	} = useProjectContext()

	const { projectId, subjectId, projectType } = useProject()
	const { portfolioId, portfolioType } = usePortfolio()
	// Stores
	const { addProcess, removeProcess } = useProcessStore()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()

	// State
	const [contextData, setContextData] = useState<ProjectContext | null>(null)
	const [isBulkAddModalOpen, setIsBulkAddModalOpen] = useState(false)
	const [bulkAddType, setBulkAddType] = useState<"facts" | "terms">("facts")
	const [bulkAddContent, setBulkAddContent] = useState("")
	const [showClearConfirmation, setShowClearConfirmation] = useState(false)
	const [searchResults, setSearchResults] = useState([])
	const [showSpinner, setShowSpinner] = useState(false)
	const [sentSearchQuery, setSentSearchQuery] = useState("")
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)
	const [isLoading, _setIsLoading] = useState(false)
	const [_dropdownOpen, _setDropdownOpen] = useState(false)
	const [originalContextData, setOriginalContextData] = useState<ProjectContext | null>(
		context ?? defaultContextData,
	)

	const isStandardsPortfolio = portfolioType === ProjectType.SEP

	const isGeneratingContext = useAreProcessesPending(
		[ProcessType.GENERATE_CONTEXT],
		projectId,
		portfolioId || null,
	)

	const isGenerateContextDisabled = useAreProcessesPending(
		conflictingContextProcesses,
		projectId,
		portfolioId || null,
	)

	const searchTerm = async (query: string) => {
		setSentSearchQuery(query)
		setShowSpinner(true)
		setIsSearchModalOpen(true)

		const response = await semanticSearchDocuments(
			projectId,
			query,
			[subjectId],
			"semantic",
		)

		if (response.success) {
			setSearchResults(response.data.sources)
		} else {
			addErrorMessage("Error searching for term.")
		}
		setShowSpinner(false)
	}

	const handleSearchResultsClose = () => {
		setIsSearchModalOpen(false)
		setSearchResults([])
		setSentSearchQuery("")
		setShowSpinner(false)
	}

	const isContextEmpty = () => {
		return (
			!context?.expertKnowledge &&
			Object.keys(context?.termsAndDefinitions ?? {})?.length === 0 &&
			context?.importantFacts?.length === 0 &&
			!context?.priorArtSearchInstructions &&
			!context?.priorArtSearchFilterInstructions &&
			context?.features?.length === 0
		)
	}

	const handleClearConfirmation = () => {
		setShowClearConfirmation(true)
	}

	const handleSave = async () => {
		try {
			await updateProjectContext(projectId, contextData)
			addSuccessMessage("Patent context saved successfully")
		} catch (error) {
			console.error("Error saving patent context:", error)
			addErrorMessage("Failed to save patent context. Please try again.")
		}
	}

	const handleClear = async () => {
		try {
			setContextData(defaultContextData)
			await updateProjectContext(projectId, defaultContextData)
			setIsEditing(false)
			addSuccessMessage("Patent context cleared successfully")
		} catch (_error) {
			addErrorMessage("Failed to clear patent context. Please try again.")
		}
	}

	const handleBulkAdd = () => {
		const items = bulkAddContent.split("\n").filter((item) => item.trim() !== "")

		if (bulkAddType === "facts") {
			setContextData({
				...contextData,
				importantFacts: [...contextData.importantFacts, ...items],
			})
		} else {
			const newTerms = { ...contextData.termsAndDefinitions }
			for (const item of items) {
				const [term, definition] = item.split(":")
				if (term && definition) {
					newTerms[term.trim()] = definition.trim()
				}
			}
			setContextData({
				...contextData,
				termsAndDefinitions: newTerms,
			})
		}

		setBulkAddContent("")
		setIsBulkAddModalOpen(false)
	}

	const [isEditing, setIsEditing] = useState(false)
	const [url, setUrl] = useState("")
	const [inputContext, setInputContext] = useState("")
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalType, setModalType] = useState<"url" | "document" | "input" | null>(null)
	const [files, setFiles] = useState<File[]>([])

	const handleAddContext = async (source: string) => {
		const key = nanoid()

		try {
			switch (source) {
				case "subject-patent":
					addProcess({
						id: key,
						type: ProcessType.GENERATE_CONTEXT,
						projectId: projectId,
						portfolioId: portfolioId ?? "",
					})
					await generatePatentContextFromId(projectId, subjectId)
					break
				case "document":
					setModalType("document")
					setIsModalOpen(true)
					break
				case "url":
					setModalType("url")
					setIsModalOpen(true)
					break
				case "input":
					setModalType("input")
					setIsModalOpen(true)
					break
			}
		} catch (error) {
			console.error("Error generating patent context:", error)
			addErrorMessage("Failed to generate patent context. Please try again.")
		} finally {
			removeProcess(key)
		}
	}

	const isValidUrl = (url: string) => {
		try {
			new URL(url)
			return true
		} catch {
			return false
		}
	}

	const handleUrlSubmit = () => {
		if (isValidUrl(url)) {
			setIsModalOpen(false)
			setUrl("")
		} else {
			addErrorMessage("Invalid URL. Please enter a valid URL.")
		}
	}

	const handleFileChange = (selectedFiles: File[]) => {
		setFiles(selectedFiles)
	}

	const handleFileUpload = async () => {
		const key = nanoid()
		addProcess({
			id: key,
			type: ProcessType.GENERATE_CONTEXT,
			projectId: projectId,
		})
		try {
			setIsModalOpen(false)
			// setIsGeneratingContext(true);
			// TODO: fix this for context documents, dummy rn
			const _response = await uploadToS3(files[0], projectId, "application/pdf")
			const document_ids = "00000"
			await generatePatentContextFromId(projectId, document_ids[0])
			await generatePatentContextFromId(projectId, document_ids[0])
		} catch (error) {
			console.error("Error generating patent context:", error)
			addErrorMessage("Failed to generate patent context. Please try again.")
		} finally {
			setFiles([])
			removeProcess(key)
			// setIsGeneratingContext(false);
		}
	}

	const handleInputSubmit = async () => {
		const key = nanoid()
		addProcess({
			id: key,
			type: ProcessType.GENERATE_CONTEXT,
			projectId: projectId,
		})
		try {
			setIsModalOpen(false)
			await generatePatentContextFromText(projectId, inputContext)
			await generatePatentContextFromText(projectId, inputContext)
			setInputContext("")
		} catch (error) {
			console.error("Error generating patent context:", error)
			addErrorMessage("Failed to generate patent context. Please try again.")
		} finally {
			removeProcess(key)
		}
	}

	const handleRemoveFile = (index: number) => {
		setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
	}

	// When entering edit mode, save original context data in case of a reset
	const handleEdit = () => {
		setOriginalContextData(context ?? defaultContextData)
		setContextData(context ?? defaultContextData)
		setIsEditing(true)
	}

	const handleReset = () => {
		if (originalContextData) {
			setContextData(originalContextData) // Revert to original context data
		}
		setIsEditing(false)
	}

	const handleSaveContext = () => {
		if (isEditing) {
			handleSave()
			setIsEditing(false)
		} else {
			handleEdit()
		}
	}

	return (
		<ProjectPage pageName={PageName.ProjectContext}>
			<div className="flex flex-col h-[calc(100vh-60px)]">
				<div className="flex justify-between items-center p-2 bg-background z-10 border-b">
					<div className="gap-2 flex flex-row">
						<GenerateContextDropdown
							isEditing={isEditing}
							isGeneratingContext={isGeneratingContext}
							isGenerateContextDisabled={isGenerateContextDisabled}
							onGenerateContext={handleAddContext}
						/>
					</div>
					<div className="space-x-2">
						{isEditing && (
							<Button onClick={handleReset} variant="outline" className="h-9">
								Reset
							</Button>
						)}
						{isEditing && !isContextEmpty() && (
							<Button
								disabled={isGeneratingContext}
								onClick={handleClearConfirmation}
								variant="outline"
								className="h-9"
							>
								Clear
							</Button>
						)}
						<Button
							disabled={isGeneratingContext || isGenerateContextDisabled}
							onClick={handleSaveContext}
							className="h-9"
						>
							{isEditing ? "Save Changes" : "Edit Context"}
						</Button>
					</div>
				</div>
				<ScrollArea className="flex-grow">
					<div className="p-2">
						{isLoading ? (
							<div className="mt-10">
								<Loader />
							</div>
						) : isGeneratingContext ? (
							<div className="mt-10">
								<Loader message="Generating context..." />
							</div>
						) : (
							<div className="grid grid-cols-2 gap-2 h-full">
								<TermsAndDefinitions
									terms={
										isEditing
											? (contextData?.termsAndDefinitions ?? {})
											: (context?.termsAndDefinitions ?? {})
									}
									onChange={(terms) =>
										setContextData({
											...contextData,
											termsAndDefinitions: terms as Record<string, string>,
										})
									}
									isEditing={isEditing}
									onBulkAdd={() => {
										setBulkAddType("terms")
										setIsBulkAddModalOpen(true)
									}}
									searchTerm={searchTerm}
									showSearchButton={projectType !== ProjectType.APP}
								/>
								<ImportantFacts
									facts={
										isEditing
											? (contextData?.importantFacts ?? [])
											: (context?.importantFacts ?? [])
									}
									onChange={(facts) =>
										setContextData({
											...contextData,
											importantFacts: facts,
										})
									}
									isEditing={isEditing}
									onBulkAdd={() => {
										setBulkAddType("facts")
										setIsBulkAddModalOpen(true)
									}}
									searchTerm={searchTerm}
									showSearchButton={projectType !== ProjectType.APP}
								/>
								{!isStandardsPortfolio && (
									<PriorArtSearchInstructions
										instructions={
											isEditing
												? contextData?.priorArtSearchInstructions
												: context?.priorArtSearchInstructions
										}
										onChange={(instructions) =>
											setContextData({
												...contextData,
												priorArtSearchInstructions: instructions,
											})
										}
										isEditing={isEditing}
									/>
								)}
								<Features
									features={
										isEditing ? (contextData?.features ?? []) : (context?.features ?? [])
									}
									onChange={(features) =>
										setContextData({
											...contextData,
											features: features,
										})
									}
									isEditing={isEditing}
									searchTerm={searchTerm}
									showSearchButton={projectType !== ProjectType.APP}
								/>
							</div>
						)}
					</div>
				</ScrollArea>
			</div>

			<GenerateContextModal
				isOpen={isModalOpen}
				onOpenChange={setIsModalOpen}
				modalType={modalType}
				url={url}
				setUrl={setUrl}
				files={files}
				handleFileChange={handleFileChange}
				handleRemoveFile={handleRemoveFile}
				inputContext={inputContext}
				setInputContext={setInputContext}
				handleUrlSubmit={handleUrlSubmit}
				handleFileUpload={handleFileUpload}
				handleInputSubmit={handleInputSubmit}
				isValidUrl={isValidUrl}
			/>
			<BulkAddModal
				isOpen={isBulkAddModalOpen}
				onOpenChange={setIsBulkAddModalOpen}
				bulkAddType={bulkAddType}
				bulkAddContent={bulkAddContent}
				setBulkAddContent={setBulkAddContent}
				onConfirm={handleBulkAdd}
			/>

			<ClearConfirmationModal
				isOpen={showClearConfirmation}
				onOpenChange={setShowClearConfirmation}
				onConfirm={handleClear}
			/>

			<SearchResultsModal
				isOpen={isSearchModalOpen}
				onOpenChange={handleSearchResultsClose}
				searchResults={searchResults}
				sentSearchQuery={sentSearchQuery}
				showSpinner={showSpinner}
			/>
		</ProjectPage>
	)
}

export default ContextPage
