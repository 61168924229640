import { Figure } from "@/components"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import ImageScrollBox from "@/components/images/ImageScrollBox"
import { Label } from "@/components/ui/label"

/**
 * @description Subject abstract and images component
 * @param {object} details - The details to display
 */
interface FiguresProps {
	figureUrls: string[]
	showTitle?: boolean
}

const Figures: React.FC<FiguresProps> = ({ figureUrls, showTitle = true }) => {
	return (
		<div>
			{figureUrls.length > 0 && (
				<div className="mb-4">
					{showTitle && <Label>Figures ({figureUrls.length})</Label>}
					<ImageScrollBox>
						<div className="flex gap-2 p-2">
							{figureUrls.map((imageUrl) => (
								<Figure
									key={imageUrl}
									imageUrl={imageUrl}
									imageName={imageUrl.split("/").pop() ?? "Image"}
								/>
							))}
						</div>
					</ImageScrollBox>
				</div>
			)}
		</div>
	)
}

export default Figures
