import type {
	AddUserToOrganizationRequest,
	AdminBillingData,
	AdminOverview,
	BillingCycleSummary,
	CreateOrganizationRequest,
	DeleteOrganizationRequest,
	DeleteUserRequest,
	DisableUserRequest,
	EnableUserRequest,
	GetUsersRequest,
	Organization,
	OrganizationPlan,
	OrganizationSummary,
	PortfolioSummary,
	ProjectSummary,
	StripeInvoice,
	UpdateOrganizationRequest,
	UpdateUserRoleRequest,
	User,
} from "@/types"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { apiClient } from "./client"

export const adminApi = {
	// User Management APIs
	async getUsers(organizationId: string): Promise<User[]> {
		const params: GetUsersRequest = {
			organization_id: organizationId,
		}
		return await apiClient.get("get_users", params)
	},

	async createUser(
		email: string,
		firstName: string,
		lastName: string,
		role: string,
		organizationId: string,
	) {
		const payload: AddUserToOrganizationRequest = {
			email: email,
			first_name: firstName,
			last_name: lastName,
			role: role,
			organization_id: organizationId,
		}
		return await apiClient.post("post_add_user_to_organization", payload)
	},

	async deleteUser(userId: string, organizationId: string) {
		const payload: DeleteUserRequest = {
			user_id: userId,
			organization_id: organizationId,
		}
		return await apiClient.post("post_delete_user", payload)
	},

	async updateUserRole(userId: string, role: string, organizationId: string) {
		const payload: UpdateUserRoleRequest = {
			user_id: userId,
			role: role,
			organization_id: organizationId,
		}
		return await apiClient.post("post_update_user_role", payload)
	},

	async disableUser(userId: string) {
		const payload: DisableUserRequest = {
			user_id: userId,
		}
		return await apiClient.post("post_disable_user", payload)
	},

	async enableUser(userId: string) {
		const payload: EnableUserRequest = {
			user_id: userId,
		}
		return await apiClient.post("post_enable_user", payload)
	},

	// Organization Management APIs
	async createOrganization(org: Partial<Organization>) {
		const payload: CreateOrganizationRequest = {
			name: org.name ?? null,
			passthrough_billing: org.passthroughBilling ?? null,
			client_numbers: org.clientNumbers ?? null,
			invoice_type: org.invoiceType ?? null,
			document_price: org.documentPrice ?? null,
			monthly_license_price: org.monthlyLicensePrice ?? null,
			yearly_license_price: org.yearlyLicensePrice ?? null,
			monthly_seat_price: org.monthlySeatPrice ?? null,
			yearly_seat_price: org.yearlySeatPrice ?? null,
			inv_project_price: org.invProjectPrice ?? null,
			pfa_project_price: org.pfaProjectPrice ?? null,
			app_project_price: org.appProjectPrice ?? null,
			max_seats: org.maxSeats ?? null,
			documents_added_price: org.documentsAddedPrice ?? null,
			documents_exported_price: org.documentsExportedPrice ?? null,
			unique_documents_pricing: org.uniqueDocumentsPricing ?? null,
		}
		return await apiClient.post("post_create_organization", payload)
	},

	async deleteOrganization(organizationId: string, organizationName: string) {
		const payload: DeleteOrganizationRequest = {
			organization_id: organizationId,
			organization_name: organizationName,
		}
		return await apiClient.post("post_delete_organization", payload)
	},

	async updateOrganization(organizationId: string, org: Partial<Organization>) {
		const payload: UpdateOrganizationRequest = {
			id: organizationId,
			name: org.name ?? null,
			passthrough_billing: org.passthroughBilling ?? null,
			client_numbers: org.clientNumbers ?? null,
			invoice_type: org.invoiceType ?? null,
			document_price: org.documentPrice ?? null,
			monthly_license_price: org.monthlyLicensePrice ?? null,
			yearly_license_price: org.yearlyLicensePrice ?? null,
			monthly_seat_price: org.monthlySeatPrice ?? null,
			yearly_seat_price: org.yearlySeatPrice ?? null,
			inv_project_price: org.invProjectPrice ?? null,
			pfa_project_price: org.pfaProjectPrice ?? null,
			app_project_price: org.appProjectPrice ?? null,
			max_seats: org.maxSeats ?? null,
			documents_added_price: org.documentsAddedPrice ?? null,
			documents_exported_price: org.documentsExportedPrice ?? null,
			unique_documents_pricing: org.uniqueDocumentsPricing ?? null,
		}
		return await apiClient.post("post_update_organization", payload)
	},

	async getAllOrganizations(): Promise<Organization[]> {
		return await apiClient.get("get_all_organizations")
	},

	// Admin Organization APIs
	async getAdminOverviewForOrg(
		orgId: string,
		year: number | null = null,
		month: number | null = null,
	): Promise<AdminOverview> {
		const params: any = {
			organization_id: orgId,
		}
		if (year && month) {
			params.year = year
			params.month = month
		}
		return await apiClient.get("get_admin_overview_for_org", params)
	},

	async getAdminProjectsForOrg(
		orgId: string,
	): Promise<Array<PortfolioSummary | ProjectSummary>> {
		return await apiClient.get("get_admin_projects_for_org", {
			organization_id: orgId,
		})
	},

	async getAdminBillingForOrg(orgId: string): Promise<AdminBillingData> {
		const response = (await apiClient.get("get_admin_billing_for_org", {
			organization_id: orgId,
		})) as [StripeInvoice[], OrganizationPlan, BillingCycleSummary]

		const [invoices, plan, cycleSummary] = response
		return {
			invoices,
			plan,
			cycleSummary,
		}
	},

	// Superadmin APIs
	async getSuperAdminOverview(): Promise<AdminOverview> {
		return await apiClient.get("get_superadmin_overview")
	},

	async getSuperAdminOrganizations(): Promise<OrganizationSummary[]> {
		return await apiClient.get("get_superadmin_organizations")
	},

	async getSuperAdminBilling(): Promise<StripeInvoice[]> {
		return await apiClient.get("get_superadmin_billing")
	},

	// Utility APIs
	async createUserPostgres() {
		return await apiClient.post("post_create_user_postgres")
	},

	async getUserPostgres(): Promise<boolean> {
		return await apiClient.get("get_user_in_postgres")
	},

	async getUserHash(): Promise<string> {
		return await apiClient.get("get_user_hash")
	},

	async getAdminOverviewDetailsForOrgByMonth(
		orgId: string,
		date: string,
	): Promise<AdminOverview> {
		return await apiClient.get("get_admin_overview_details_for_org_by_month", {
			organization_id: orgId,
			year_month: date,
		})
	},
}
