/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { InfoPopover, OpenInGooglePatentButton } from "@/components"
import { DataTable } from "@/components/table/DataTable"
import { TableTags } from "@/components/table/TableTags"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Spinner } from "@/components/ui/spinner"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useNavigationState, usePortfolio, useProjectAndPortfolioIds } from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { useAreProcessesPending } from "@/store"
import {
	DocumentStatus,
	DocumentType,
	type ParentDocumentMetadata,
	conflictingReferenceProcesses,
} from "@/types"
import { formatPatentNumber } from "@/utils/projectUtils"
import type { ColumnDef, Row } from "@tanstack/react-table"
import he from "he"
import { MoreVertical } from "lucide-react"
import { DateTime } from "luxon"
import type React from "react"
import { type ChangeEvent, type KeyboardEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

type CustomColumnDef<TData, TValue = unknown> = ColumnDef<TData, TValue> & {
	hidden?: boolean
}

type FilterOption = { label: string; value: string }
type Filters = {
	type?: { label: string; values: FilterOption[] }
	prefix?: { label: string; values: FilterOption[] }
	tags?: { label: string; values: FilterOption[] }
}

interface DocumentMetadataTableProps {
	documents: ParentDocumentMetadata[]
	onDeleteRow?: (document: ParentDocumentMetadata) => void
	onRowSelection?: (document: ParentDocumentMetadata[]) => void

	height?: string
	children?: React.ReactNode
	enableRowDelete?: boolean
	isDeleteSelectedLoading?: boolean
	onReprocessSelected?: (
		references: ParentDocumentMetadata[],
		isReprocess: boolean,
	) => void
	onDismissSelected?: (documents: ParentDocumentMetadata[]) => void
	onDeleteSelected?: (selectedDocuments: ParentDocumentMetadata[]) => void
	onSaveName?: (
		document: ParentDocumentMetadata,
		newName: string,
		isNickname: boolean,
	) => void
	onSaveNote?: (document: ParentDocumentMetadata, newNote: string) => void
	onTagUpdate?: (
		selectedRows: ParentDocumentMetadata[],
		addTags: string[],
		deleteTags: string[],
	) => void
	onSelectProject?: (document: ParentDocumentMetadata) => void
	onRowClick?: (document: ParentDocumentMetadata) => void
	isReference?: boolean
	isPortfolio?: boolean
	isSearch?: boolean
	title?: string
	inUserDocuments?: boolean
}

const DocumentMetadataTable: React.FC<DocumentMetadataTableProps> = ({
	documents,
	onDeleteRow,
	onRowSelection,
	height,
	children,
	enableRowDelete,
	onDeleteSelected,
	isSearch,
	isDeleteSelectedLoading = false,
	onReprocessSelected,
	onDismissSelected,
	onTagUpdate,
	onSelectProject,
	onSaveName,
	onSaveNote,
	onRowClick,
	isReference = false,
	inUserDocuments = false,
	title,
}) => {
	const { getDocumentName } = useDocumentNaming()
	const navigate = useNavigate()
	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const { portfolio } = usePortfolio()

	const checkboxStart = !isSearch
	const enableRowActions = !isSearch
	const showTableActions = true
	const enableRename = !isSearch
	const showPortfolio = !projectId && portfolioId
	const showSubjects = showPortfolio && isReference
	const showReferenceEditCols = isReference && !isSearch
	const showName = !isSearch

	const handleRowSelection = (selectedRows: ParentDocumentMetadata[]) => {
		if (onRowSelection) {
			onRowSelection(selectedRows)
		}
	}

	const { isDocumentsPage } = useNavigationState()

	const isReferenceDisabled = useAreProcessesPending(
		conflictingReferenceProcesses,
		projectId || null,
		portfolioId || null,
	)

	const [editingNoteRefId, setEditingNoteRefId] = useState<string>("")
	const [editingNameRefId, setEditingNameRefId] = useState<string>("")
	const [noteContent, setNoteContent] = useState<string>("")
	const [newName, setNewName] = useState<string>("")

	const handleRemoveRow = (reference: ParentDocumentMetadata) => {
		if (reference && onDeleteRow) {
			onDeleteRow(reference)
		}
	}

	const handleEditNotes = (document: ParentDocumentMetadata) => {
		if (document) {
			setEditingNoteRefId(document.documentId)
			setNoteContent(document.notes || "")
		}
	}

	const handleSaveNote = async (document: ParentDocumentMetadata, newNote: string) => {
		await onSaveNote?.(document, newNote)
		setEditingNoteRefId("")
		setNoteContent("")
	}

	const handleSaveName = async (
		document: ParentDocumentMetadata,
		newName: string,
		isNickname: boolean,
	) => {
		await onSaveName?.(document, newName, isNickname)
		setEditingNameRefId("")
		setNewName("")
	}

	const handleViewDetails = async (reference: ParentDocumentMetadata) => {
		navigate(`/project/${projectId}/references/${reference.documentId}`)
	}

	const [allTags, setAllTags] = useState<string[]>([])
	const [selectedTags, setSelectedTags] = useState<string[]>([])

	useEffect(() => {
		const tags = new Set<string>()
		for (const ref of documents) {
			if (Array.isArray(ref.tags)) {
				for (const tag of ref.tags) {
					tags.add(tag)
				}
			}
		}
		setAllTags(Array.from(tags))
	}, [documents])

	const handleEditName = (document: ParentDocumentMetadata) => {
		setEditingNameRefId(document.documentId)
		setNewName(document.nickname || getDocumentName(document.documentId)) // Initialize with existing nickname
	}

	// Sort functions for title and name
	const _multiLingualSort = (
		rowA: Row<ParentDocumentMetadata>,
		rowB: Row<ParentDocumentMetadata>,
		columnId: string,
	) => {
		const collator = new Intl.Collator(["ko", "ja", "zh", "en"], {
			sensitivity: "base",
			caseFirst: "false",
		})

		if (columnId === "title") {
			const valueA = (rowA.original.document?.title || "") as string
			const valueB = (rowB.original.document?.title || "") as string
			return collator.compare(valueA, valueB)
		}

		const valueA = rowA.getValue(columnId) as string
		const valueB = rowB.getValue(columnId) as string
		return collator.compare(valueA, valueB)
	}

	const nicknameSort = (
		rowA: Row<ParentDocumentMetadata>,
		rowB: Row<ParentDocumentMetadata>,
	) => {
		const collator = new Intl.Collator(["ko", "ja", "zh", "en"], {
			sensitivity: "base",
			caseFirst: "false",
		})

		const valueA = getDocumentName(rowA.original.documentId)
		const valueB = getDocumentName(rowB.original.documentId)

		return collator.compare(valueA, valueB)
	}

	const checkboxColumn = {
		id: "select",
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllPageRowsSelected()}
				onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
				aria-label="Select all"
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={(value) => row.toggleSelected(!!value)}
				aria-label="Select row"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	}

	const statusTooltipContent = (
		<>
			<p className="text-sm text-muted-foreground">
				<b>Processed</b>: No action needed.
			</p>
			<p className="text-sm text-muted-foreground">
				<b>Reprocess</b>: Click "Resolve Statuses" to reprocess with updated algorithms.
			</p>
			<p className="text-sm text-muted-foreground">
				<b>Rechart</b>: Click "Resolve Statuses" to rechart with updated algorithms or
				context.
			</p>
			<p className="text-sm text-muted-foreground">
				<b>Reupload</b>: Delete and reupload the document.
			</p>
		</>
	)

	const [editingTitleRefId, setEditingTitleRefId] = useState<string>("")
	const [titleContent, setTitleContent] = useState<string>("")

	const _handleEditTitle = (document: ParentDocumentMetadata) => {
		if (document) {
			setEditingTitleRefId(document.documentId)
			setTitleContent(he.decode(document.document?.title || ""))
		}
	}

	const handleSaveTitle = async (document: ParentDocumentMetadata, newTitle: string) => {
		await onSaveName?.(document, newTitle, false)
		setEditingTitleRefId("")
		setTitleContent("")
	}

	const columns: CustomColumnDef<ParentDocumentMetadata>[] = [
		...(enableRowActions && isReference && checkboxStart ? [checkboxColumn] : []),

		// Move the status column here (as per previous modification)
		...(showReferenceEditCols
			? [
					{
						id: "status",
						accessorKey: "status",
						header: () => (
							<div className="flex items-center gap-2">
								Status
								<InfoPopover content={statusTooltipContent} />
							</div>
						),
						cell: ({ row }) => {
							const document: ParentDocumentMetadata = row.original
							let statusColor = ""
							let statusText = ""
							let tooltipText = ""
							switch (document.document?.status) {
								case DocumentStatus.PROCESSED:
								case DocumentStatus.UPLOADED:
									statusColor = "bg-green-500"
									statusText =
										document.document?.status === DocumentStatus.PROCESSED
											? "Processed"
											: "Uploaded"
									tooltipText = "This document has been processed"
									break
								case DocumentStatus.REPROCESS:
								case DocumentStatus.RECHART:
									statusColor = "bg-yellow-500"
									statusText =
										document.document?.status === DocumentStatus.REPROCESS
											? "Reprocess"
											: "Rechart"
									tooltipText =
										document.document?.status === DocumentStatus.REPROCESS
											? "Our algorithm has been updated since this document was processed. Reprocess for updated content."
											: "Context has been updated since this document was charted."
									break
								case DocumentStatus.REUPLOAD:
									statusColor = "bg-red-500"
									statusText = "Reupload"
									tooltipText = "Delete this document and reupload the file"
									break
								case DocumentStatus.PROCESSING:
									statusColor = "bg-blue-500"
									statusText = "Processing"
									// tooltipText = "This document is pending processing";
									break
								case DocumentStatus.DB_ERROR:
									statusColor = "bg-red-500"
									statusText = "DB Error"
									tooltipText = "There was an error with this document"
									break
								case DocumentStatus.QUEUED:
									statusColor = "bg-gray-500"
									statusText = "Queued"
									tooltipText = "This document is queued for processing"
									break
								default:
									statusColor = "bg-gray-500"
									statusText = "—"
									tooltipText = "Unknown status"
							}

							return (
								<Tooltip>
									<TooltipTrigger>
										<Badge variant="outline" className="flex items-center gap-2">
											{document.document?.status === DocumentStatus.PROCESSING ? (
												<Spinner className="h-3 w-3" />
											) : (
												<div className={`w-2 h-2 rounded-full ${statusColor}`} />
											)}
											{statusText}
										</Badge>
									</TooltipTrigger>
									<TooltipContent>{tooltipText}</TooltipContent>
								</Tooltip>
							)
						},
					},
				]
			: []),

		...(showSubjects
			? [
					{
						id: "subjects",
						accessorKey: "subjects",
						header: "Subjects",
						cell: ({ row }) => {
							const document: ParentDocumentMetadata = row.original

							const projectIds = document.document?.projectIds
							if (!projectIds?.length) return "—"

							// Check if all portfolio projects are included
							const isAllProjects =
								portfolio?.projects?.length === projectIds.length &&
								portfolio.projects.every((project) => projectIds.includes(project.id))

							if (isAllProjects) {
								return (
									<Badge variant="outline" className="mr-1 mb-1 cursor-pointer">
										All
									</Badge>
								)
							}

							return (
								<div className="flex flex-wrap gap-1">
									{portfolio?.projects
										?.filter((project) => projectIds.includes(project.id))
										.map((project) => (
											<Badge
												key={project.id}
												variant="outline"
												className="cursor-pointer whitespace-nowrap"
												onClick={() =>
													onSelectProject?.({
														projectId: project.id,
													})
												}
											>
												{project.name}
											</Badge>
										))}
								</div>
							)
						},
					},
				]
			: []),
		...(showName
			? [
					{
						id: "nickname",
						accessorKey: "nickname",
						header: "Name",
						sortingFn: nicknameSort,
						enableSorting: true,
						cell: ({ row }) => {
							const document: ParentDocumentMetadata = row.original
							return (
								<>
									{enableRename ? (
										<div className="w-full min-w-[6rem]">
											{" "}
											{/* Increased minimum width */}
											{editingNameRefId === document.documentId ? (
												<Input
													value={newName}
													onChange={(e: ChangeEvent<HTMLInputElement>) => setNewName(e.target.value)}
													onBlur={() => handleSaveName(document, newName, true)}
													onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
														if (e.key === "Enter") handleSaveName(document, newName, true)
													}}
													autoFocus
												/>
											) : (
												<span
													onClick={() => isReference && handleEditName(document)}
													className={isReference ? "cursor-pointer" : ""}
												>
													{getDocumentName(document.documentId) || "—"}
												</span>
											)}
										</div>
									) : (
										<div className="min-w-[12rem]">
											{getDocumentName(document.documentId) || "—"}
										</div>
									)}
								</>
							)
						},
					},
				]
			: []),

		...(showReferenceEditCols
			? [
					{
						id: "tags",
						accessorKey: "tags",
						header: "Tags",
						cell: ({ row }) => {
							const document: ParentDocumentMetadata = row.original
							const tags = Array.isArray(document.tags) ? document.tags : []

							return (
								<TableTags
									tagOptions={allTags}
									selectedTags={tags}
									setSelectedTags={(newTags) => {
										setSelectedTags(newTags)
									}}
									onClose={() => {
										onTagUpdate(
											[document],
											null, // add tags
											selectedTags, // set tags
										)
									}}
									onApply={(newTags) => {
										onTagUpdate(
											[document],
											null, // add tags
											newTags, // setTags
										)
									}}
								>
									<div className="w-full h-full">
										{tags.length > 0 ? (
											<div className="flex flex-wrap gap-1">
												{tags.map((tag) => (
													<Badge variant="outline" key={tag} className="whitespace-nowrap">
														{tag}
													</Badge>
												))}
											</div>
										) : (
											<div className="w-full h-full items-center justify-center">—</div>
										)}
									</div>
								</TableTags>
							)
						},
					},
				]
			: []),
		{
			id: "number",
			accessorKey: "number",
			header: "Number",
			cell: ({ row }) => {
				const document = row.original

				return document.document?.patent?.number ? (
					<div>
						{formatPatentNumber(document.document.patent.number)}
						<OpenInGooglePatentButton sourceNumber={document.document.patent.number} />
					</div>
				) : (
					"—"
				)
			},
		},

		{
			id: "title",
			accessorKey: "title",
			header: "Title",
			// sortingFn: multiLingualSort,
			cell: ({ row }) => {
				const document: ParentDocumentMetadata = row.original
				return (
					<div
						onClick={() => {
							// if (isReference && document.type !== "PATENT") {
							//   handleEditTitle(document);
							// } else {
							onRowClick?.(document)
							// }
						}}
						className="w-96 max-w-xl overflow-hidden text-ellipsis"
					>
						{editingTitleRefId === document.documentId ? (
							<Input
								value={titleContent}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setTitleContent(e.target.value)}
								onBlur={() => handleSaveTitle(document, titleContent)}
								onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
									if (e.key === "Enter") handleSaveTitle(document, titleContent)
								}}
								className="w-full"
								autoFocus
							/>
						) : (
							<span title={he.decode(document.document?.title || "—")}>
								{he.decode(document.document?.title || "—")}
							</span>
						)}
					</div>
				)
			},
			// enableSorting: true,
		},
		{
			id: "createdAt",
			accessorKey: "createdAt",
			header: "Created",
			enableSorting: true,
			cell: ({ row }) => {
				const document: ParentDocumentMetadata = row.original
				return document.createdAt && document.document?.type !== DocumentType.PATENT
					? DateTime.fromISO(document.createdAt, { zone: "utc" }).toFormat("MM/dd/yyyy")
					: "—"
			},
		},
		{
			id: "publicationDate",
			accessorKey: "publicationDate",
			header: "Publication",
			enableSorting: true,
			cell: ({ row }) => {
				const document: ParentDocumentMetadata = row.original
				return document.document?.patent?.publicationDate &&
					document.document?.patent?.publicationDate !== document.createdAt
					? DateTime.fromISO(document.document?.patent?.publicationDate, {
							zone: "utc",
						}).toFormat("MM/dd/yyyy")
					: "—"
			},
		},
		{
			id: "priorityDate",
			accessorKey: "priorityDate",
			header: "Priority",
			enableSorting: true,
			hidden: false,
			cell: ({ row }) => {
				const document: ParentDocumentMetadata = row.original
				return document.document?.patent?.priorityDate ||
					document.document?.patent?.filingDate
					? DateTime.fromISO(
							document.document?.patent?.priorityDate || document.document?.patent?.filingDate,
							{
								zone: "utc",
							},
						).toFormat("MM/dd/yyyy")
					: "—"
			},
			sortingFn: (rowA, rowB) => {
				const dateA =
					rowA.original.document?.patent?.priorityDate ||
					rowA.original.document?.patent?.filingDate ||
					""
				const dateB =
					rowB.original.document?.patent?.priorityDate ||
					rowB.original.document?.patent?.filingDate ||
					""
				return dateA.localeCompare(dateB)
			},
		},
		// {
		//   id: "inventors",
		//   accessorKey: "inventors",
		//   header: "Inventors",
		//   cell: ({ row }) => {
		//     const document: ParentDocumentMetadata = row.original
		//     const inventors = document.document?.patent?.inventors;
		//     if (!Array.isArray(inventors) || inventors.length === 0) {
		//       return "—";
		//     }
		//     return (
		//       <Tooltip>
		//         <TooltipTrigger className="text-left">
		//           {inventors[0]}
		//           {inventors.length > 1 && `, +${inventors.length - 1}`}
		//         </TooltipTrigger>
		//         <TooltipContent>{inventors.join(", ")}</TooltipContent>
		//       </Tooltip>
		//     );
		//   },
		// },
		// {
		//   id: "assignee",
		//   accessorKey: "assignee",
		//   header: "Assignee",
		//   cell: ({ row }) => row.original.assignee || "—",
		// },
		{
			id: "type",
			accessorKey: "type",
			header: "Type",
			cell: ({ row }) => {
				const document: ParentDocumentMetadata = row.original
				return document.document?.type === DocumentType.PATENT ? "Patent" : "Non-Patent"
			},
			enableHiding: true,
			enableSorting: !isSearch,
			hidden: true,
		},

		// {
		//   id: "prefix",
		//   accessorKey: "prefix",
		//   header: "Country",
		//   cell: ({ row }) => {
		//     const document = row.original;
		//     return document.prefix || "—";
		//   },
		//   enableHiding: true,
		//   enableSorting: true,
		//   hidden: true,
		// },
	]

	if (showReferenceEditCols) {
		columns.push({
			id: "note",
			accessorKey: "note",
			header: "Notes",
			// enableSorting: true,
			// sortingFn: multiLingualSort,
			cell: ({ row }) => {
				const document = row.original
				return (
					<div onClick={() => handleEditNotes(document)}>
						{editingNoteRefId === document.documentId ? (
							<Input
								value={noteContent}
								onChange={(e: ChangeEvent<HTMLInputElement>) => setNoteContent(e.target.value)}
								onBlur={() => handleSaveNote(document, noteContent)}
								onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
									if (e.key === "Enter") handleSaveNote(document, noteContent)
								}}
								className="w-full"
								autoFocus
							/>
						) : (
							<span>{document.notes || "—"}</span>
						)}
					</div>
				)
			},
		})
	}

	if (!checkboxStart) {
		columns.push(checkboxColumn)
	}

	if (enableRowActions) {
		columns.push({
			id: "actions",
			enableHiding: false,
			cell: ({ row }) => {
				const document = row.original
				return (
					<div className="clickable-element" onClick={(e) => e.stopPropagation()}>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="ghost" className="h-8 w-8 p-0">
									<MoreVertical className="h-4 w-4" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuItem onClick={() => handleViewDetails(document)}>
									View details
								</DropdownMenuItem>
								{!isDocumentsPage && (
									<DropdownMenuItem onClick={() => handleEditName(document)}>
										Rename
									</DropdownMenuItem>
								)}
								{showReferenceEditCols && (
									<DropdownMenuItem onClick={() => handleEditNotes(document)}>
										Edit notes
									</DropdownMenuItem>
								)}
								{handleRemoveRow && (
									<DropdownMenuItem
										onClick={() => handleRemoveRow(document)}
										disabled={isReferenceDisabled}
									>
										Remove
									</DropdownMenuItem>
								)}
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				)
			},
		})
	}

	const filters: Filters = {
		// prefix: {
		//   label: "Country",
		//   values: Array.from(
		//     new Set(references?.map((ref) => ref.prefix).filter(Boolean)),
		//   ).map((prefix) => ({
		//     label: prefix,
		//     value: prefix,
		//   })),
		// },

		...(isSearch || !isReference
			? {}
			: {
					tags: {
						label: "Tags",
						values: documents
							.flatMap((ref) => ref.tags || [])
							.filter(Boolean)
							.map((tag) => ({ label: tag, value: tag })),
					},
					type: {
						label: "Type",
						values: [
							{ label: "Patent", value: DocumentType.PATENT },
							{ label: "Non-Patent", value: DocumentType.REFERENCE },
						],
					},
				}),
	}

	return (
		<>
			<DataTable
				columns={columns}
				data={documents}
				onRowSelection={handleRowSelection}
				height={height}
				showActions={showTableActions}
				onDeleteSelected={onDeleteSelected}
				initialFilters={filters}
				enableRowDelete={enableRowDelete}
				onTagApply={onTagUpdate}
				showTag={!inUserDocuments}
				showReprocess={!inUserDocuments}
				showRechart={!inUserDocuments}
				isModal={isSearch}
				isDeleteSelectedLoading={isDeleteSelectedLoading}
				onReprocessSelected={onReprocessSelected}
				onRowClick={onRowClick}
				onDismissSelected={onDismissSelected}
				title={title}
			>
				{children}
			</DataTable>
		</>
	)
}

export default DocumentMetadataTable
