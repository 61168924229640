import { apiClient } from "./client"

interface ChatRequestPayload {
	query: string
	project_id: string
	document_ids: string[]
	session_id?: string
}

/**
 * Handles streaming chat responses from the API
 */
export const streamChatResponse = async (
	payload: ChatRequestPayload,
	signal: AbortSignal,
	onSessionId: (sessionId: string) => void,
	onChunk: (text: string) => void,
	onComplete: () => void,
) => {
	let accumulatedText = ""
	let sessionCaptured = false
	const sessionIdRegex = /<SESSION_ID>([0-9a-f-]{36})<\/SESSION_ID>/i

	try {
		await apiClient.stream(
			"post_query_chatbot",
			payload,
			{}, // params
			{ signal }, // fetch config (to allow abort)
			(rawChunk) => {
				// If user has stopped streaming, abort.
				if (signal.aborted) return

				let chunk = rawChunk

				// Capture session ID if present (only once)
				if (!sessionCaptured) {
					const match = sessionIdRegex.exec(chunk as string)
					if (match) {
						onSessionId(match[1])
						sessionCaptured = true
						// Remove session ID tag from our visible text
						chunk = (chunk as string).replace(sessionIdRegex, "")
					}
				}

				// Accumulate text so the UI sees the entire response so far
				accumulatedText += chunk

				onChunk(accumulatedText as string)
			},
		)

		// Once the stream completes, invoke onComplete
		onComplete()
	} catch (error) {
		// If the request wasn't manually aborted, log & rethrow
		if (!signal.aborted) {
			console.error("Streaming error:", error)
			throw error
		}
	}
}
