/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import ChatComponent from "@/features/chat/components/ChatComponent"
import { useProject } from "@/hooks"
import { PageName } from "@/types/pages"
import type React from "react"
import ProjectPage from "../../components/projects/ProjectPage"
/**
 * @description Project-level chat page
 */
const ProjectChatPage: React.FC = () => {
	const { projectId, projectPriorArtDocuments, subjectId, priorArtIds, isLoading } =
		useProject()

	const projectIdArray = [subjectId, ...(Array.isArray(priorArtIds) ? priorArtIds : [])]

	return (
		<ProjectPage pageName={PageName.Chat}>
			{isLoading.projectReferences || isLoading.project ? (
				<div className="flex justify-center items-center flex-col mt-12">
					<Loader message="Loading..." />
				</div>
			) : (
				<ChatComponent
					projectId={projectId}
					disabledBool={false}
					documents={projectPriorArtDocuments}
				/>
			)}
		</ProjectPage>
	)
}

export default ProjectChatPage
