import { cn } from "@/lib/utils"
import { useState } from "react"

interface LocationRangeInputProps {
	label: string
	fromValue: number | null
	toValue: number | null
	onFromChange: (value: number | null) => void
	onToChange: (value: number | null) => void
	className?: string
	error?: string
	onBlur?: () => void
}

export function LocationRangeInput({
	label,
	fromValue,
	toValue,
	onFromChange,
	onToChange,
	onBlur,
	className,
	error,
}: LocationRangeInputProps) {
	const [isFocused, setIsFocused] = useState(false)

	const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		if (value === "") {
			onFromChange(null)
			return
		}
		const numValue = Number.parseInt(value)
		if (!Number.isNaN(numValue)) {
			onFromChange(numValue)
		}
	}

	const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		if (value === "") {
			onToChange(null)
			return
		}
		const numValue = Number.parseInt(value)
		if (!Number.isNaN(numValue)) {
			onToChange(numValue)
		}
	}

	return (
		<div className="flex flex-col">
			<span className="text-xs text-muted-foreground mb-1">{label}</span>
			<div className="relative">
				<div
					className={cn(
						"flex items-center justify-between h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors",
						isFocused && "ring-1 ring-ring",
						error && "border-red-500",
						className,
					)}
				>
					<div className="flex-1 flex justify-center">
						<input
							type="number"
							value={fromValue ?? ""}
							onChange={handleFromChange}
							onFocus={() => setIsFocused(true)}
							onBlur={onBlur}
							className="w-[40px] border-none focus:outline-none bg-transparent text-sm text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
							placeholder="From"
						/>
					</div>
					<span className="flex-none text-sm text-muted-foreground px-2">-</span>
					<div className="flex-1 flex justify-center">
						<input
							type="number"
							value={toValue ?? ""}
							onChange={handleToChange}
							onFocus={() => setIsFocused(true)}
							onBlur={onBlur}
							className="w-[40px] border-none focus:outline-none bg-transparent text-sm text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
							placeholder="To"
						/>
					</div>
				</div>
				{error && <p className="mt-1 text-xs text-red-500">{error}</p>}
			</div>
		</div>
	)
}

interface LocationInputProps {
	label: string
	value: string
	onChange: (value: string) => void
	className?: string
	error?: string
	onBlur?: () => void
}

export function LocationInput({
	label,
	value,
	onChange,
	className,
	error,
	onBlur,
}: LocationInputProps) {
	const [isFocused, setIsFocused] = useState(false)

	return (
		<div className="flex flex-col">
			<span className="text-xs text-muted-foreground mb-1">{label}</span>
			<div className="relative">
				<div
					className={cn(
						"flex items-center h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors",
						isFocused && "ring-1 ring-ring",
						error && "border-red-500",
						className,
					)}
				>
					<input
						type="text"
						value={value}
						onChange={(e) => onChange(e.target.value)}
						onFocus={() => setIsFocused(true)}
						onBlur={onBlur}
						className="w-full border-none focus:outline-none bg-transparent text-sm"
					/>
				</div>
				{error && <p className="mt-1 text-xs text-red-500">{error}</p>}
			</div>
		</div>
	)
}
