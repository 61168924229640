/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { UnprocessedDocument } from "@/types"
import { FileIcon } from "@radix-ui/react-icons"
import type React from "react"

interface UnprocessedReferenceProps {
	document: UnprocessedDocument
}

/**
 * A card component that displays detailed information of a patent.
 *
 * @param {PatentDetailCardProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered component.
 */
const UnprocessedDocumentCard: React.FC<UnprocessedReferenceProps> = ({ document }) => {
	return (
		<div className="flex items-center gap-4">
			<FileIcon className="text-primary h-5 w-5" />
			<p className="text-sm font-semibold">{document.file.name}</p>
		</div>
	)
}

export default UnprocessedDocumentCard
