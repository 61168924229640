import type { components, operations } from "schema"
import type { User } from "./admin"
import type { KeysToCamelCase } from "./utils"

export const PATENT_TYPES = ["Application", "Issued Asset", "International"]
export const PATENT_STATUSES = ["Active", "Inactive"]
export const SUPPORTED_JURISDICTIONS = ["US", "EP", "KR", "JP", "DE"]

export type Project = KeysToCamelCase<components["schemas"]["Project"]>

export type ProjectTypeSchema = components["schemas"]["ProjectType"]
export const ProjectType = {
	SEP: "SEP",
	INV: "INV",
	APP: "APP",
	PERSONAL: "PERSONAL",
} as const satisfies Record<string, ProjectTypeSchema>
export type ProjectType = (typeof ProjectType)[keyof typeof ProjectType]

export type ProjectStatusSchema = components["schemas"]["ProjectStatus"]
export const ProjectStatus = {
	ACTIVE: "ACTIVE",
	ARCHIVED: "ARCHIVED",
} as const satisfies Record<string, ProjectStatusSchema>
export type ProjectStatus = (typeof ProjectStatus)[keyof typeof ProjectStatus]

export type FigurePlacementSchema = components["schemas"]["FigurePlacement"]
export const FigurePlacement = {
	EMBEDDED: "EMBEDDED",
	GROUPED: "GROUPED",
	OMITTED: "OMITTED",
} as const satisfies Record<string, FigurePlacementSchema>
export type FigurePlacement = (typeof FigurePlacement)[keyof typeof FigurePlacement]

export type ClaimNumberStyleSchema = components["schemas"]["ClaimNumberStyle"]
export const ClaimNumberStyle = {
	DECIMAL: "DECIMAL",
	LEGAL: "LEGAL",
} as const satisfies Record<string, ClaimNumberStyleSchema>
export type ClaimNumberStyle = (typeof ClaimNumberStyle)[keyof typeof ClaimNumberStyle]

export type InvaliditySettings = KeysToCamelCase<
	components["schemas"]["InvaliditySettings"]
>

export type Claim = KeysToCamelCase<components["schemas"]["Claim"]>
export type ClaimLimitation = KeysToCamelCase<components["schemas"]["ClaimLimitation"]>

export type ClaimEdit = KeysToCamelCase<components["schemas"]["ClaimEdit"]>
export type ClaimLimitationEdit = KeysToCamelCase<
	components["schemas"]["ClaimLimitationEdit"]
>

export type ProjectMetadata = KeysToCamelCase<components["schemas"]["ProjectMetadata"]>

export type GetProjectClaimsResponse = KeysToCamelCase<
	operations["get_project_claims_get_project_claims_get"]["responses"]["200"]["content"]["application/json"]
>

export type ProjectContext = KeysToCamelCase<components["schemas"]["PatentContext"]>

// export type PortfolioProjectData = KeysToCamelCase<
//   components["schemas"]["PortfolioProjectData"]
// >
export type PortfolioMetadata = KeysToCamelCase<
	components["schemas"]["PortfolioMetadata"]
>

export type ProjectListItem = PortfolioMetadata | ProjectMetadata
export type GetPortfolioMatrixResponse = KeysToCamelCase<
	operations["get_portfolio_matrix_get_portfolio_matrix_get"]["responses"]["200"]["content"]["application/json"]
>

export type PortfolioMatrixDocument = KeysToCamelCase<
	components["schemas"]["PortfolioMatrixDocument"]
>
export type PortfolioMatrixColumn = KeysToCamelCase<
	components["schemas"]["PortfolioMatrixColumn"]
>

export interface ChunkLocation {
	paragraphs?: number[]
	pages?: number[]
	lines?: number[]
	columns?: number[]
	claimSection?: string | null
	section?: string | null
}

export interface Message {
	text?: string
	sender: string
	loading?: boolean
}

export interface Element {
	color: string
	label: string
	language: string
}

export interface ReferenceParagraphCitation {
	referenceExtracted: string
	referenceNickname: string
	referenceNumber: string
	paragraphNumber: string
	paragraphId: string
	paragraphText: string
	cosineSimilarityScore: number
	color: ChartColor
}

export interface ProjectUser {
	userId: string
	user: User
	projectId: string
	project: Project
}

export enum ParentType {
	PROJECT = "PROJECT",
	PORTFOLIO = "PORTFOLIO",
}

export enum ChartColor {
	GREEN = "#C1E1A6",
	YELLOW = "#FFDE7D",
	RED = "#F5A19B",
	GRAY = "#D3D3D3",
}

export enum ClaimStatusColor {
	RED = "#f14a4a",
	YELLOW = "#ffd561",
	BLUE = "#61bdff",
}

export enum ClaimStatus {
	REJECTED = "rejected",
	OBJECTED = "objected",
	ALLOWED = "allowed",
	WITHDRAWN = "withdrawn",
}

export enum CreateProjectStep {
	DETAILS = "DETAILS",
	SUBJECT = "SUBJECT",
	PRIOR_ART = "PRIOR_ART",
	CONTEXT = "CONTEXT",
	REVIEW = "REVIEW",
	OFFICE_ACTION = "OFFICE_ACTION",
}

export function isPortfolioMetadata(
	project: ProjectListItem,
): project is PortfolioMetadata {
	return "projects" in project
}
