import {
	type AlertMessage,
	type LoadingGroup,
	type LoadingGroupItem,
	type LoadingMessage,
	StatusType,
} from "@/types"
import { create } from "zustand"

interface AppState {
	loadingGroups: LoadingGroup[]
	statusMessages: AlertMessage[]
	loadingMessages: LoadingMessage[]
	isStoreEmpty: boolean
	updateIsStoreEmpty: (isStoreEmpty: boolean) => void
	addErrorMessage: (message: string) => void
	addLoadingGroupItem: (
		groupKey: string,
		processType: string,
		itemName: string,
		status?: StatusType,
		detail?: string,
		displayName?: string,
	) => void
	removeLoadingGroup: (groupKey: string, processType: string) => void
	updateLoadingGroupItem: (
		groupKey: string,
		processType: string,
		itemName: string,
		status: StatusType,
		detail?: string,
	) => void
	clearLoadingGroups: () => void
	addSuccessMessage: (message: string) => void
	removeStatusMessage: (key: string) => void
	addLoadingMessage: (message: string, key: string) => void
	addWarningMessage: (message: string) => void
	removeLoadingMessage: (key: string) => void
	addLoadingGroup: (
		key: string,
		name: string,
		processType: string,
		messages?: LoadingGroupItem[],
	) => void
}

const initialState: Omit<
	AppState,
	| "updateIsStoreEmpty"
	| "addErrorMessage"
	| "addSuccessMessage"
	| "removeStatusMessage"
	| "updateCreateProjectSpinnerText"
	| "updateCreateProjectError"
	| "addLoadingMessage"
	| "removeLoadingMessage"
	| "addLoadingGroupItem"
	| "removeLoadingGroup"
	| "addLoadingGroup"
	| "updateLoadingGroupItem"
	| "clearLoadingGroups"
	| "addWarningMessage"
> = {
	loadingMessages: [],
	statusMessages: [],
	isStoreEmpty: true,
	loadingGroups: [],
}

const useAppStateStore = create<AppState>((set) => ({
	...initialState,

	updateIsStoreEmpty: (isStoreEmpty: boolean) => set(() => ({ isStoreEmpty })),

	addErrorMessage: (message: string) =>
		set((state) => ({
			statusMessages: [
				...state.statusMessages,
				{
					message,
					key: new Date().getTime().toString(),
					severity: StatusType.ERROR,
				},
			],
		})),

	addSuccessMessage: (message: string) =>
		set((state) => ({
			statusMessages: [
				...state.statusMessages,
				{
					message,
					key: new Date().getTime().toString(),
					severity: StatusType.SUCCESS,
				},
			],
		})),

	removeStatusMessage: (key: string) =>
		set((state) => ({
			statusMessages: state.statusMessages.filter((msg) => msg.key !== key),
		})),

	addLoadingMessage: (message: string, key: string) =>
		set((state) => ({
			loadingMessages: [...state.loadingMessages, { message, key }],
		})),

	removeLoadingMessage: (key: string) =>
		set((state) => ({
			loadingMessages: state.loadingMessages.filter((msg) => msg.key !== key),
		})),

	addLoadingGroup: (
		key: string,
		name: string,
		processType: string,
		messages?: LoadingGroupItem[],
	) =>
		set((state) => {
			const existingGroup = state.loadingGroups.find(
				(group) => group.key === key && group.processType === processType,
			)

			if (existingGroup) {
				// If the group already exists, don't add a duplicate
				return state
			}

			return {
				loadingGroups: [
					...state.loadingGroups,
					{ key, name, processType, messages: messages || [] },
				],
			}
		}),

	removeLoadingGroup: (groupKey: string, processType: string) =>
		set((state) => ({
			loadingGroups: state.loadingGroups.filter(
				(group) => !(group.key === groupKey && group.processType === processType),
			),
		})),

	addLoadingGroupItem: (
		groupKey: string,
		processType: string,
		itemName: string,
		status?: StatusType,
		detail?: string,
		displayName?: string,
	) =>
		set((state) => ({
			loadingGroups: state.loadingGroups.map((group) =>
				group.key === groupKey && group.processType === processType
					? {
							...group,
							messages: [
								...group.messages,
								{ name: itemName, displayName: displayName, status, detail },
							],
						}
					: group,
			),
		})),

	updateLoadingGroupItem: (
		groupKey: string,
		processType: string,
		itemName: string,
		status: StatusType,
		detail?: string,
		displayName?: string,
	) =>
		set((state) => ({
			loadingGroups: state.loadingGroups.map((group) =>
				group.key === groupKey && group.processType === processType
					? {
							...group,
							messages: group.messages.map((msg) =>
								msg.name === itemName
									? {
											...msg,
											status,
											detail,
											displayName: displayName || msg.displayName,
										}
									: msg,
							),
						}
					: group,
			),
		})),

	clearLoadingGroups: () => set(() => ({ loadingGroups: [] })),

	addWarningMessage: (message: string) =>
		set((state) => ({
			statusMessages: [
				...state.statusMessages,
				{
					message,
					key: new Date().getTime().toString(),
					severity: StatusType.WARNING,
				},
			],
		})),
}))

export default useAppStateStore
