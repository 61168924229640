/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { AddDocumentsModal, Loader } from "@/components"
import { Button } from "@/components/ui/button"
import { H4 } from "@/components/ui/typography"
import { useOfficeAction } from "@/hooks"
import { DocumentRole } from "@/types"
import type { OfficeAction } from "@/types"
import { PageName } from "@/types/pages"
import type React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import ApplicationDocumentsTable from "./ApplicationDocumentsTable"
import OfficeActionDocument from "./OfficeActionDocument"
import DocumentsBreadcrumb from "./components/DocumentsBreadcrumb"
import { ExportButton } from "./components/DraftingButtons"

/**
 * @description Application Documents page
 */
const ApplicationDocumentsPage: React.FC = () => {
	// Local and project states
	const { officeActions } = useOfficeAction()
	const [selectedDocId, setSelectedDocId] = useState<string | null>(null)
	const [isLoading, _setLoading] = useState<boolean>(false)
	const [showUploadFilesModal, setShowUploadFilesModal] = useState(false)

	const [isExporting, setIsExporting] = useState(false)

	// Navigation handling (for browser back/forwards)
	const handleRowClick = useCallback((documentId: string) => {
		window.history.pushState({ view: "document", id: documentId }, "")
		setSelectedDocId(documentId)
	}, [])

	const handleBackToDocuments = useCallback(() => {
		window.history.back()
	}, [])

	const handlePopState = useCallback((event: PopStateEvent) => {
		if (event.state?.view === "document" && event.state?.id) {
			setSelectedDocId(event.state.id)
		} else {
			setSelectedDocId(null)
		}
	}, [])

	// History management effects (for browser back/forwards)
	function initializeHistory() {
		if (!window.history.state) {
			window.history.replaceState({ view: "documents" }, "")
		}
	}

	useEffect(() => {
		initializeHistory()
		window.addEventListener("popstate", handlePopState)
		return () => {
			window.removeEventListener("popstate", handlePopState)
		}
	}, [handlePopState])

	function cleanupHistory() {
		if (window.history.state?.view === "document") {
			window.history.pushState({ view: "documents" }, "")
		}
	}

	useEffect(() => {
		return cleanupHistory
	}, [])

	// Tracking documents and selected documents
	const tableDocuments = officeActions ?? []
	const selectedDocument = useMemo(
		() => (selectedDocId ? tableDocuments.find((doc) => doc.id === selectedDocId) : null),
		[selectedDocId, tableDocuments],
	)

	// Export button
	const handleExport = async () => {
		setIsExporting(true)
		try {
			// TODO: fix this
			// await exportOfficeActionDraftToWord(
			//   selectedDocument as OfficeAction,
			//   currentProject.subject as Document,
			// );
		} finally {
			setIsExporting(false)
		}
	}

	const hasContentToExport = (document: OfficeAction): boolean => {
		if (!document?.claimDetails) {
			return false
		}

		// const hasContent = document.claimDetails.some((claim) =>
		//   claim.reasons.some((reason) => {
		//     const hasArguments = reason.arguments?.length > 0
		//     const hasAmendments = reason.amendments?.length > 0
		//     return hasArguments || hasAmendments
		//   }),
		// )

		return true
	}

	const renderDocumentContent = useMemo(() => {
		if (!selectedDocument) {
			return null
		}

		const officeAction = selectedDocument as OfficeAction
		const showExportButton = hasContentToExport(officeAction)

		return (
			<>
				<div className="flex justify-between items-center">
					<DocumentsBreadcrumb
						onBackToDocuments={handleBackToDocuments}
						documentType="Office Action"
					/>
					{showExportButton && (
						<ExportButton onExport={handleExport} isExporting={isExporting} />
					)}
				</div>
				<OfficeActionDocument document={officeAction} />
			</>
		)
	}, [
		selectedDocument,
		handleBackToDocuments,
		handleExport,
		isExporting,
		// Track the entire document structure
		selectedDocument,
	])

	// Upload file modal
	// const handleContextAddClick = async (selectedFiles: File[]) => {
	//   if (selectedFiles && selectedFiles.length > 0) {
	//     try {
	//       // setIsProcessing(true);
	//       await processOfficeAction(selectedFiles[0], currentProject.id);
	//       await fetchOfficeActions();
	//     } catch (error) {
	//       console.error("Error uploading office action:", error);
	//     } finally {
	//       // setIsProcessing(false);
	//       setShowUploadFilesModal(false);
	//     }
	//   }
	// };

	// Main content render
	const renderContent = () => {
		if (isLoading) {
			return (
				<div className="flex justify-center items-center flex-col">
					<Loader />
				</div>
			)
		}

		// If no documents, show empty state
		if (!tableDocuments?.length) {
			return (
				<div className="flex justify-center items-center flex-col mt-12">
					<H4>This project has no office actions.</H4>
					{/* <p>Documents you upload about your application will appear here.</p> */}
					<div className="flex flex-row gap-2">
						<Button
							className="mt-3"
							variant="outline"
							onClick={() => setShowUploadFilesModal(true)}
						>
							Upload Office Action
						</Button>
						<AddDocumentsModal
							open={showUploadFilesModal}
							handleClose={() => setShowUploadFilesModal(false)}
							buttonLabel="Add Office Action"
							showProcessSettings={false}
							maxFiles={1}
							documentRole={DocumentRole.OFFICE_ACTION}
						/>
					</div>
				</div>
			)
		}

		// If a document is selected, show document
		if (selectedDocId && renderDocumentContent) {
			return <div className="p-2">{renderDocumentContent}</div>
		}

		// Otherwise, show the document table
		return (
			<ApplicationDocumentsTable
				documents={tableDocuments as OfficeAction[]}
				onRowClick={handleRowClick}
			/>
		)
	}

	return (
		<ProjectPage pageName={PageName.ProjectApplicationDocuments}>
			{renderContent()}
		</ProjectPage>
	)
}
export default ApplicationDocumentsPage
