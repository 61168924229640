/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DataTable } from "@/components/table/DataTable"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { ClickableCell } from "@/components/ui/table"
import { H4 } from "@/components/ui/typography"
import useUserProjects from "@/hooks/useUserProjects"
import {
	type PortfolioMetadata,
	type ProjectMetadata,
	ProjectStatus,
	ProjectType,
	isPortfolioMetadata,
} from "@/types"
import type { ProjectListItem } from "@/types"
import { ArchiveIcon, DotsVerticalIcon } from "@radix-ui/react-icons"
import type { ColumnDef } from "@tanstack/react-table"
import type React from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import ArchivedProjectsModal from "./ArchivedProjectsModal"
import DeleteProjectModal from "./DeleteProjectModal"

interface ProjectsTableProps {
	tableType: ProjectStatus
	handleClickProject?: (project: ProjectListItem) => Promise<void>
	isInArchive: boolean
	height: string
	projects: ProjectListItem[]
}

/**
 * @description Projects table
 *
 * @param {ProjectStatus} tableType - The type of table to display.
 * @param {function} handleClickProject - Function to handle the click of a project.
 * @param {boolean} isInArchive - Whether the projects are in the archive.
 * @param {string} height - The height of the table.
 */
const ProjectsTable: React.FC<ProjectsTableProps> = ({
	tableType,
	handleClickProject,
	isInArchive,
	height,
	projects,
}) => {
	const navigate = useNavigate()
	const {
		deleteProject,
		deletePortfolio,
		archivedProjects,
		isLoading,
		updateProjectStatus,
		updatePortfolioStatus,
	} = useUserProjects()

	const [showArchiveModal, setShowArchiveModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [projectToDelete, setProjectToDelete] = useState<ProjectListItem | null>(null)

	// Add this new function to sort projects by date
	const _sortProjectsByDate = (projects: ProjectListItem[]) => {
		return [...projects].sort((a, b) => {
			return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
		})
	}

	const handleMenuItemClick = (action: string, project: ProjectListItem) => {
		if (action === "archive") {
			handleArchiveProject(project)
		} else if (action === "delete") {
			setProjectToDelete(project)
			setShowDeleteModal(true)
		}
	}

	const onProjectCellClick = (project: ProjectListItem) => {
		handleClickProject(project)
	}

	const handleArchiveProject = async (selectedProject: ProjectListItem) => {
		if (isInArchive) {
			if (!isPortfolioMetadata(selectedProject)) {
				await updateProjectStatus({
					projectId: selectedProject?.id,
					status: ProjectStatus.ACTIVE,
				})
			} else {
				await updatePortfolioStatus({
					portfolioId: selectedProject?.id,
					status: ProjectStatus.ACTIVE,
				})
			}
		} else {
			if (!isPortfolioMetadata(selectedProject)) {
				await updateProjectStatus({
					projectId: selectedProject.id,
					status: ProjectStatus.ARCHIVED,
				})
			} else {
				await updatePortfolioStatus({
					portfolioId: selectedProject?.id,
					status: ProjectStatus.ARCHIVED,
				})
			}
		}
	}

	const handleDeleteProject = async () => {
		if (isPortfolioMetadata(projectToDelete)) {
			await deletePortfolio(projectToDelete?.id)
		} else {
			await deleteProject(projectToDelete?.id)
		}

		setProjectToDelete(null)
	}

	const handleArchiveClose = () => {
		setShowArchiveModal(false)
	}

	// Define the columns for the table
	const columns: ColumnDef<ProjectMetadata | PortfolioMetadata>[] = [
		{
			accessorKey: "type",
			header: "Type",
			cell: ({ row }) => {
				const project = row.original
				return (
					<ClickableCell onClick={() => onProjectCellClick(project)}>
						<Badge
							variant="outline"
							className="py-1 hover:cursor-pointer"
							onClick={() => onProjectCellClick(project)}
						>
							{project.type === ProjectType.INV
								? "Invalidity"
								: project.type === ProjectType.APP
									? "Application"
									: project.type === ProjectType.SEP
										? "Standards Portfolio"
										: "Invalidity Portfolio"}
						</Badge>
					</ClickableCell>
				)
			},
		},
		{
			accessorKey: "name",
			header: "Name",
			cell: ({ row }) => {
				const project = row.original
				return (
					<ClickableCell onClick={() => onProjectCellClick(project)}>
						{project.name}
					</ClickableCell>
				)
			},
		},
		{
			accessorKey: "createdBy",
			header: "Owner",
			cell: ({ row }) => (
				<ClickableCell onClick={() => onProjectCellClick(row.original)}>
					{row.original.createdBy}
				</ClickableCell>
			),
		},
		{
			accessorKey: "updatedAt",
			header: "Last modified",
			cell: ({ row }) => {
				const project = row.original
				const localDate = new Date(project.updatedAt)
				const today = new Date()
				const yesterday = new Date(today)
				yesterday.setDate(yesterday.getDate() - 1)

				const timeFormatter = new Intl.DateTimeFormat(undefined, {
					hour: "numeric",
					minute: "2-digit",
					hour12: true,
				})

				const dateFormatter = new Intl.DateTimeFormat(undefined, {
					year: "numeric",
					month: "short",
					day: "numeric",
				})

				const formattedTime = timeFormatter.format(localDate)

				let dateString = ""
				if (localDate.toDateString() === today.toDateString()) {
					dateString = `Today at ${formattedTime}`
				} else if (localDate.toDateString() === yesterday.toDateString()) {
					dateString = `Yesterday at ${formattedTime}`
				} else {
					dateString = dateFormatter.format(localDate)
				}
				return (
					<ClickableCell onClick={() => onProjectCellClick(project)}>
						{dateString}
					</ClickableCell>
				)
			},
		},

		{
			id: "actions",
			cell: ({ row }) => {
				const project = row.original
				return (
					<ClickableCell onClick={() => onProjectCellClick(row.original)}>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="ghost" className="h-8 w-8 p-0">
									<span className="sr-only">Open menu</span>
									<DotsVerticalIcon className="h-4 w-4" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuItem
									onClick={(e) => {
										e.stopPropagation()
										handleMenuItemClick("archive", project)
									}}
								>
									{project.status === "ARCHIVED" ? "Unarchive" : "Archive"}
								</DropdownMenuItem>
								{project.createdBy === "me" && (
									<DropdownMenuItem
										onClick={(e) => {
											e.stopPropagation()
											handleMenuItemClick("delete", project)
										}}
									>
										Delete
									</DropdownMenuItem>
								)}
							</DropdownMenuContent>
						</DropdownMenu>
					</ClickableCell>
				)
			},
		},
	]

	return (
		<>
			{projects?.length === 0 ? (
				<div className="flex flex-col items-center justify-center p-8 text-center">
					{!isInArchive && (
						<>
							<H4 className="mb-4">
								You have{" "}
								{Array.isArray(archivedProjects) && archivedProjects.length > 0
									? "no active"
									: "no"}{" "}
								projects.
							</H4>
							<div className="flex gap-4">
								<Button onClick={() => navigate("/create-project")}>Create Project</Button>
								{Array.isArray(archivedProjects) && archivedProjects.length > 0 && (
									<Button variant="outline" onClick={() => setShowArchiveModal(true)}>
										View Archived Projects
									</Button>
								)}
							</div>
						</>
					)}
				</div>
			) : (
				<DataTable
					columns={columns}
					data={projects}
					enableRowDelete={!isInArchive}
					onDeleteSelected={!isInArchive ? handleDeleteProject : undefined}
					showActions={!isInArchive}
					showFilter={true}
					showSort={true}
					showSearch={true}
					showColumns={false}
					height={height}
					showReprocess={true}
					showRechart={true}
					title={tableType === ProjectStatus.ACTIVE ? "Projects" : undefined}
				>
					{Array.isArray(archivedProjects) && archivedProjects.length > 0 && (
						<Button variant="outline" size="icon" onClick={() => setShowArchiveModal(true)}>
							<ArchiveIcon className="h-4 w-4" />
						</Button>
					)}
				</DataTable>
			)}
			<ArchivedProjectsModal open={showArchiveModal} handleClose={handleArchiveClose} />
			<DeleteProjectModal
				open={showDeleteModal}
				handleClose={() => setShowDeleteModal(false)}
				handleDelete={handleDeleteProject}
				name={projectToDelete ? projectToDelete.name : ""}
			/>
		</>
	)
}

export default ProjectsTable
