/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import UnprocessedDocumentsList from "@/components/documents/unprocessed/UnprocessedDocumentsList"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"
import type { UnprocessedDocument } from "@/types"
import { useCreateProjectContext } from "../CreateProjectContext"

interface DocumentListProps {
	documents: UnprocessedDocument[]
	title: string
}

/**
 * @description Component for displaying a list of documents
 * @param {Object} props
 * @param {UnprocessedDocument[]} documents - The documents to display
 * @param {string} title - The title of the list
 */
const DocumentList = ({ documents, title }: DocumentListProps) => {
	const { removeUnprocessedDocument, updateSelectedKindCode } = useCreateProjectContext()
	if (documents.length === 0) return null

	return (
		<div className="my-4">
			<Separator />
			<Label className="mt-4 mb-4">
				{title} ({documents.length})
			</Label>
			<UnprocessedDocumentsList
				documents={documents}
				onRemove={removeUnprocessedDocument}
				updateSelectedKindCode={updateSelectedKindCode}
			/>
		</div>
	)
}

export default DocumentList
