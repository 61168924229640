/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { useInvalidityChart } from "@/features/charts/hooks"
import { useClaims } from "@/hooks"
import type React from "react"
import { useState } from "react"

interface CopyCitationsModalProps {
	open: boolean
	handleClose: () => void
	documentId: string
	mode: "append" | "replace"
	claimLimitationId: string
	claimNumber: string
}

/**
 * @description Prune invalidity charts by citation score
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const CopyCitationsModal: React.FC<CopyCitationsModalProps> = ({
	open,
	handleClose,
	documentId,
	mode,
	claimLimitationId,
	claimNumber,
}) => {
	const { copyChunkToLimitations, isCopyingChunkToLimitations } = useInvalidityChart()
	const { limitations } = useClaims()

	const [isLoading, setIsLoading] = useState(false)
	const [sourceLimitationId, setSourceLimitationId] = useState<string | null>(null)

	const resetModal = () => {
		setSourceLimitationId(null)
		setIsLoading(false)
	}

	const onClose = () => {
		resetModal()
		handleClose()
	}

	const handleSave = async () => {
		setIsLoading(true)
		const deleteExisting = mode === "replace"
		copyChunkToLimitations({
			targetLimitationId: claimLimitationId,
			sourceLimitationId: sourceLimitationId,
			documentId: documentId,
			replace: deleteExisting,
		})

		onClose()
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>
						{mode === "append"
							? `Append citations to claim ${claimNumber}`
							: `Replace citations for claim ${claimNumber}`}
					</DialogTitle>
					<DialogDescription>
						{mode === "append"
							? `Append citations to claim ${claimNumber} from another claim.`
							: `Replace citations for claim ${claimNumber} with citations from another claim. Note that replacing citations will overwrite verified citations.`}
					</DialogDescription>
				</DialogHeader>

				<div className="flex items-center gap-2">
					<Select
						value={sourceLimitationId ?? ""}
						onValueChange={(value) => setSourceLimitationId(value)}
						disabled={isLoading}
					>
						<SelectTrigger className="w-[300px]">
							<SelectValue placeholder="Select claim" />
						</SelectTrigger>
						<SelectContent>
							{limitations.map((limitation) => (
								<SelectItem key={limitation.id} value={limitation.id}>
									{limitation.label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				{/* <div className="flex gap-2 flex-col mt-2">
					<Label>Apply to </Label>
					<RadioGroup
						value={applyToAllReferences ? "all" : "selected"}
						onValueChange={(value) => setApplyToAllReferences(value === "all")}
						className="flex space-x-4"
					>
						<RadioGroupItemWithText value="selected" label="Selected document only" />
						<RadioGroupItemWithText value="all" label="All documents" />
					</RadioGroup>
				</div> */}

				<DialogFooter>
					<div className="flex items-center justify-end gap-2">
						<Button variant="outline" onClick={() => onClose()} disabled={isLoading}>
							Cancel
						</Button>
						<LoadingButton
							variant="default"
							onClick={handleSave}
							loading={isCopyingChunkToLimitations}
							disabled={isCopyingChunkToLimitations}
						>
							{isCopyingChunkToLimitations
								? mode === "append"
									? "Appending..."
									: "Replacing..."
								: mode === "append"
									? "Append"
									: "Replace"}
						</LoadingButton>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default CopyCitationsModal
