import { api } from "@/api"
import { useProjectStore } from "@/store"
import type React from "react"
import { useEffect, useState } from "react"
import { Navigate, Outlet, useParams } from "react-router-dom"

export const ProjectRoute: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>()
	const { resetProjectStore } = useProjectStore()
	const [isLoading, setIsLoading] = useState(true)
	const [hasAccess, setHasAccess] = useState(false)

	useEffect(() => {
		let isMounted = true
		resetProjectStore()

		async function checkAccess() {
			try {
				if (!projectId) {
					setIsLoading(false)
					return
				}
				const hasPermission = await api.getUserProjectAccessCheck(projectId)
				if (isMounted) {
					setHasAccess(hasPermission)
					setIsLoading(false)
				}
			} catch (error) {
				if (process.env.NODE_ENV !== "production") {
					console.error("Error checking access:", error)
				}
				if (isMounted) {
					setHasAccess(false)
					setIsLoading(false)
				}
			}
		}

		checkAccess()
		return () => {
			isMounted = false
		}
	}, [projectId, resetProjectStore])

	if (isLoading) {
		return null
	}

	if (!hasAccess) {
		return <Navigate to={`/project/${projectId}/unauthorized`} replace />
	}

	return <Outlet />
}
