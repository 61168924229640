/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import { api } from "@/api"
import { useAppStateStore } from "@/store"
import { invalidateUserProjectRelatedQueries } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useUserProjects = () => {
	const queryClient = useQueryClient()
	const { addErrorMessage } = useAppStateStore()

	const activeProjects = useQuery({
		queryKey: QUERY_KEYS.projects.active(),
		queryFn: () => api.getProjectList(false),
		...DEFAULT_QUERY_OPTIONS,
	})

	const archivedProjects = useQuery({
		queryKey: QUERY_KEYS.projects.archived(),
		queryFn: () => api.getProjectList(true),
		...DEFAULT_QUERY_OPTIONS,
	})

	const deleteProjectMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.delete(),
		mutationFn: (projectId: string) => api.deleteProject(projectId),
		onMutate: async (projectId) => {
			return handleOptimisticUpdate({
				queryClient,
				queryKeys: [QUERY_KEYS.projects.active(), QUERY_KEYS.projects.archived()],
				updateFn: (oldData) => {
					if (!oldData || !Array.isArray(oldData)) return oldData
					return oldData.filter((p: any) => p.id !== projectId)
				},
			})
		},
		onError: (_error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			addErrorMessage("Error deleting project")
		},
		onSuccess: () => {
			invalidateUserProjectRelatedQueries(queryClient)
		},
	})

	const deletePortfolioMutation = useMutation({
		mutationKey: MUTATION_KEYS.portfolio.delete(),
		mutationFn: (portfolioId: string) => api.deletePortfolio(portfolioId),
		onMutate: async (portfolioId) => {
			return handleOptimisticUpdate({
				queryClient,
				queryKeys: [QUERY_KEYS.projects.active(), QUERY_KEYS.projects.archived()],
				updateFn: (oldData) => {
					if (!oldData || !Array.isArray(oldData)) return oldData
					return oldData.filter((p: any) => p.id !== portfolioId)
				},
			})
		},
		onError: (_error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			addErrorMessage("Error deleting portfolio")
		},
		onSuccess: () => {
			invalidateUserProjectRelatedQueries(queryClient)
		},
	})

	/**
	 * @description Shared optimistic update logic for status changes
	 */
	const handleStatusOptimisticUpdate = ({
		id,
		status,
		type,
	}: {
		id: string
		status: string | boolean
		type: "project" | "portfolio"
	}) => {
		return handleOptimisticUpdate({
			queryClient,
			queryKeys: [QUERY_KEYS.projects.active(), QUERY_KEYS.projects.archived()],
			updateFn: (oldData) => {
				if (!oldData) return oldData

				if (Array.isArray(oldData)) {
					if (type === "portfolio") {
						const portfolioToMove = oldData.find((p: any) => p.id === id)
						if (portfolioToMove) {
							return oldData.map((p: any) =>
								p.id === id ? { ...p, status: status === "ARCHIVED" } : p,
							)
						}
					} else {
						// Project logic
						const sourceList =
							status === "ARCHIVED" ? activeProjects.data : archivedProjects.data
						const projectToMove =
							Array.isArray(sourceList) && sourceList.find((p: any) => p.id === id)

						if (projectToMove) {
							const otherProjects = oldData.filter((p: any) => p.id !== id)
							return [...otherProjects, { ...projectToMove, status }]
						}
					}
					return oldData.filter((p: any) => p.id !== id)
				}
				return oldData
			},
		})
	}

	const updateProjectStatusMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.updateStatus(),
		mutationFn: ({ projectId, status }: { projectId: string; status: string }) => {
			const payload = {
				project_id: projectId,
				status: status,
			}
			return api.updateProjectDetails(projectId, payload)
		},
		onMutate: async ({ projectId, status }) => {
			return handleStatusOptimisticUpdate({
				id: projectId,
				status: !!status,
				type: "project",
			})
		},
		onError: (_error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			addErrorMessage("Error toggling project status")
		},
		onSuccess: () => {
			invalidateUserProjectRelatedQueries(queryClient)
		},
	})

	const updatePortfolioStatusMutation = useMutation({
		mutationKey: MUTATION_KEYS.portfolio.updateStatus(),
		mutationFn: ({ portfolioId, status }: { portfolioId: string; status: string }) => {
			const payload = {
				portfolio_id: portfolioId,
				status: status,
			}
			return api.updatePortfolioDetails(portfolioId, payload)
		},
		onMutate: async ({ portfolioId, status }) => {
			return handleStatusOptimisticUpdate({
				id: portfolioId,
				status,
				type: "portfolio",
			})
		},
		onError: (_error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			addErrorMessage("Error updating portfolio status")
		},
		onSuccess: () => {
			invalidateUserProjectRelatedQueries(queryClient)
		},
	})

	return {
		// Query data
		activeProjects: activeProjects.data,
		archivedProjects: archivedProjects.data,

		// Loading states
		isLoading: {
			activeProjects: activeProjects.isLoading,
			archivedProjects: archivedProjects.isLoading,
		},

		// Error states
		isError: {
			activeProjects: activeProjects.isError,
			archivedProjects: archivedProjects.isError,
		},

		// Mutation functions
		deleteProject: deleteProjectMutation.mutateAsync,
		deletePortfolio: deletePortfolioMutation.mutateAsync,
		updateProjectStatus: updateProjectStatusMutation.mutateAsync,
		updatePortfolioStatus: updatePortfolioStatusMutation.mutateAsync,
	}
}

export default useUserProjects
