import { useLocation } from "react-router-dom"

export function useNavigationState() {
	const location = useLocation()

	return {
		// Home
		isHomePage: location.pathname === "/home",

		// Documents
		isDocumentsPage: location.pathname.includes("/documents"),

		// Admin sections
		isAdminPage: location.pathname.includes("/admin"),
		isSuperAdminPage: location.pathname.includes("/superadmin"),

		// Project related
		isCreateProjectPage: location.pathname.includes("/create"),
		isProjectPage: location.pathname.includes("project"),
		isPortfolioPage:
			location.pathname.includes("portfolio") && !location.pathname.includes("project"),
		isParentPortfolio: location.pathname.includes("portfolio"),
	}
}

export default useNavigationState
