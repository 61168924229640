import { memo } from "react"

interface OfficeActionSectionProps {
	children: React.ReactNode
}

export const OfficeActionSectionContainer = memo(
	({ children }: OfficeActionSectionProps) => {
		return (
			<div className="space-y-0.5 border border-gray-200 rounded-md px-[1vw] py-3">
				{children}
			</div>
		)
	},
)

OfficeActionSectionContainer.displayName = "OfficeActionSection"
