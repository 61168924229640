import { api } from "@/api"
import { useApi, useProjectAndPortfolioIds } from "@/hooks"
import type { ChunkLocation } from "@/types"
import { MUTATION_KEYS } from "@/utils"
import { invalidateDocumentRelatedQueries } from "@/utils/query/invalidation"
import { useMutation, useQueryClient } from "@tanstack/react-query"

type UpdateChunkPayload = {
	documentId: string
	documentChunkId: string
	text?: string
	location?: ChunkLocation
	figureUrls?: string[]
}

const useDocumentChunk = () => {
	const queryClient = useQueryClient()
	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const { handleError } = useApi()

	const updateDocumentChunk = useMutation({
		mutationKey: MUTATION_KEYS.document.chunk.update(),
		mutationFn: async ({
			documentId,
			documentChunkId,
			text,
			location,
			figureUrls,
		}: UpdateChunkPayload) => {
			return api.updateDocumentChunk({ documentChunkId, text, location, figureUrls })
		},
		onError: (error) => {
			handleError(error, "Failed to update document chunk")
		},
		onSuccess: (_, variables) => {
			invalidateDocumentRelatedQueries(queryClient, variables.documentId, projectId)
		},
	})

	const addDocumentChunk = useMutation({
		mutationKey: MUTATION_KEYS.document.chunk.add(),
		mutationFn: async ({
			documentId,
			text,
			location,
			figureUrls,
			// type,
		}: {
			documentId: string
			text: string
			location: ChunkLocation
			figureUrls: string[]
			// type: BlockType
		}) => {
			return api.addDocumentChunk(documentId, text, location, figureUrls)
		},
		onError: (error) => {
			handleError(error, "Failed to add document chunk")
		},
		onSuccess: (_, variables) => {
			invalidateDocumentRelatedQueries(queryClient, variables.documentId, projectId)
		},
	})

	const deleteDocumentChunk = useMutation({
		mutationKey: MUTATION_KEYS.document.chunk.delete(),
		mutationFn: async ({
			documentId,
			documentChunkId,
		}: {
			documentId: string
			documentChunkId: string
		}) => {
			return api.deleteDocumentChunk(documentChunkId)
		},
		onError: (error) => {
			handleError(error, "Failed to delete document chunk")
		},
		onSuccess: (_, variables) => {
			invalidateDocumentRelatedQueries(queryClient, variables.documentId, projectId)
		},
	})

	return {
		updateDocumentChunk: updateDocumentChunk.mutate,
		addDocumentChunk: addDocumentChunk.mutate,
		deleteDocumentChunk: deleteDocumentChunk.mutate,
	}
}

export default useDocumentChunk
