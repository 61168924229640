/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useCreateProjectContext } from "../CreateProjectContext"
import DocumentList from "./DocumentList"
import ProjectDetailsForm from "./ProjectDetailsForm"

/**
 * @description Project review step displayed in create project flow
 */
const ReviewStep: React.FC = () => {
	const {
		unprocessedSubjectDocuments,
		unprocessedPriorArtDocuments,
		unprocessedContextDocuments,
		unprocessedOfficeActionDocuments,
	} = useCreateProjectContext()

	return (
		<div className="space-y-6">
			<ProjectDetailsForm />

			<div className="space-y-6">
				<DocumentList documents={unprocessedOfficeActionDocuments} title="Office Actions" />
				<DocumentList documents={unprocessedSubjectDocuments} title="Subjects" />
				<DocumentList documents={unprocessedPriorArtDocuments} title="References" />
				<DocumentList documents={unprocessedContextDocuments} title="Context" />
			</div>
		</div>
	)
}

export default ReviewStep
