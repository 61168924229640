/*
 * Copyright AndAI, Inc. 2025.
 * All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import { InfoPopover } from "@/components"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import type React from "react"
import { useCreateProjectContext } from "../CreateProjectContext"

/**
 * @description Component for displaying the project details form (includes project name and client matter ID)
 */
const ProjectDetailsForm: React.FC = () => {
	const {
		projectName,
		updateProjectName,
		clientNumber,
		updateClientNumber,
		isProjectCreationInProgress,
	} = useCreateProjectContext()

	const clientNumberContent =
		"If a client matter ID is provided, this project or portfolio will be added to a client-specific invoice provided for ease of billing."

	return (
		<div className="flex flex-row gap-4">
			<div className="flex-1">
				<Label htmlFor="project-name">Project Name</Label>
				<Input
					id="project-name"
					className="mt-1"
					placeholder="Required"
					disabled={isProjectCreationInProgress}
					value={projectName || ""}
					onChange={(e) => updateProjectName(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault()
						}
					}}
				/>
			</div>

			<div className="flex-1">
				<div className="flex items-center justify-start">
					<Label htmlFor="client-number" className="mr-1 flex items-center">
						Client Matter ID
						<InfoPopover content={clientNumberContent} className="ml-1" />
					</Label>
				</div>
				<Input
					id="client-number"
					className="mt-1"
					placeholder="Optional"
					disabled={isProjectCreationInProgress}
					value={isProjectCreationInProgress && clientNumber === "" ? "None" : clientNumber}
					onChange={(e) => updateClientNumber(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault()
						}
					}}
				/>
			</div>
		</div>
	)
}

export default ProjectDetailsForm
