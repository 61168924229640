import type { ClaimLimitation as LimitationType } from "@/types"
import DOMPurify from "dompurify"
import type React from "react"
interface ClaimLimitationProps {
	limitation: LimitationType
	isClaimIndependent: boolean
}

const ClaimLimitation: React.FC<ClaimLimitationProps> = ({
	limitation,
	isClaimIndependent,
}) => {
	// Calculate indentation based on number of dots in label
	const indentLevel = limitation.label?.split(".").length - 1 || 0
	const indentClass =
		indentLevel === 1
			? "ml-8"
			: indentLevel === 2
				? "ml-16"
				: indentLevel === 3
					? "ml-24"
					: indentLevel >= 4
						? "ml-32"
						: "" // max indent at level 4+

	const displayLabel = limitation.label?.includes(".") ? "" : limitation.label
	return (
		<div className={`mt-1 ${indentClass}`}>
			<div className="flex items-start gap-4">
				<div className="text-gray-600 whitespace-nowrap">
					{displayLabel ? limitation.label : ""}
				</div>
				<div
					className={!isClaimIndependent ? "text-gray-600" : ""}
					// biome-ignore lint/security/noDangerouslySetInnerHtml: we sanitize below
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(limitation.html),
					}}
				/>
			</div>
		</div>
	)
}

export default ClaimLimitation
