import { api } from "@/api"
import { useProjectAndPortfolioIds } from "@/hooks"
import type { ChunkLocation, DocumentStatus, ProjectDocumentMetadata } from "@/types"
import { invalidateDocumentReferences } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import {
	type QueryClient,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query"

/* -------------------------
   Helper Interfaces
------------------------- */
type BasePayload = {
	documentIds: string[]
}

type UpdateDocumentsPayload = BasePayload & {
	status?: DocumentStatus
	title?: string
}

type UpdateMetadataPayload = BasePayload & {
	notes?: string
	nickname?: string
	addTags?: string[]
	setTags?: string[]
	[key: string]: any // for future metadata fields
}

type UpdateChunkPayload = {
	documentChunkId: string
	text?: string
	location?: ChunkLocation
}

/* -------------------------
   Helper Functions
------------------------- */
function invalidateDocumentQueries(
	queryClient: QueryClient,
	projectId?: string,
	portfolioId?: string,
) {
	invalidateDocumentReferences(queryClient, projectId)
	queryClient.invalidateQueries({
		queryKey: [
			QUERY_KEYS.document,
			QUERY_KEYS.project.documents(projectId),
			QUERY_KEYS.portfolio.references(portfolioId),
		],
	})
}

function handleOptimisticUpdate(
	queryClient: QueryClient,
	projectId: string | undefined,
	portfolioId: string | undefined,
	documentIds: string[],
	updateFn: (ref: ProjectDocumentMetadata) => ProjectDocumentMetadata,
) {
	const projectQueryKey = QUERY_KEYS.project.documents(projectId)
	const portfolioQueryKey = QUERY_KEYS.portfolio.references(portfolioId)

	const previousProjectReferences =
		queryClient.getQueryData<ProjectDocumentMetadata[]>(projectQueryKey)
	const previousPortfolioReferences =
		queryClient.getQueryData<ProjectDocumentMetadata[]>(portfolioQueryKey)

	// Project references
	if (!portfolioId) {
		queryClient.setQueryData<ProjectDocumentMetadata[] | undefined>(
			projectQueryKey,
			(oldData) => {
				if (!oldData) return oldData
				return oldData.map((doc) =>
					documentIds.includes(doc.documentId) ? updateFn(doc) : doc,
				)
			},
		)
	}

	// Portfolio references
	if (portfolioId) {
		queryClient.setQueryData<ProjectDocumentMetadata[] | undefined>(
			portfolioQueryKey,
			(oldData) => {
				if (!oldData) return oldData
				return oldData.map((doc) =>
					documentIds.includes(doc.documentId) ? updateFn(doc) : doc,
				)
			},
		)
	}

	return { previousProjectReferences, previousPortfolioReferences }
}

function handleOptimisticRollback(
	queryClient: QueryClient,
	projectId: string | undefined,
	portfolioId: string | undefined,
	previousProjectReferences?: ProjectDocumentMetadata[],
	previousPortfolioReferences?: ProjectDocumentMetadata[],
) {
	if (!portfolioId && previousProjectReferences) {
		queryClient.setQueryData(
			QUERY_KEYS.project.documents(projectId),
			previousProjectReferences,
		)
	}
	if (portfolioId && previousPortfolioReferences) {
		queryClient.setQueryData(
			QUERY_KEYS.portfolio.references(portfolioId),
			previousPortfolioReferences,
		)
	}
}

function invalidateQueriesWithId(queryClient: QueryClient, id: string) {
	queryClient.invalidateQueries({
		predicate: (query) => {
			// Check if the query key is an array and contains the id
			return (
				Array.isArray(query.queryKey) &&
				query.queryKey.some((key) => typeof key === "string" && key.includes(id))
			)
		},
	})
}

/* -------------------------
   Main Hook
------------------------- */
const useDocument = (documentId?: string) => {
	const queryClient = useQueryClient()
	const { projectId, portfolioId } = useProjectAndPortfolioIds()

	// Single doc query
	const documentQuery = useQuery({
		queryKey: QUERY_KEYS.document(documentId),
		queryFn: async () => await api.getFullDocument(documentId, projectId, portfolioId),
		enabled: !!documentId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// 1) Single mutation for updating documents (status, title, etc.)
	const updateDocuments = useMutation({
		mutationKey: MUTATION_KEYS.document.update(),
		mutationFn: async ({ documentIds, ...fields }: UpdateDocumentsPayload) => {
			return api.updateDocuments(documentIds, fields.status ?? null, fields.title ?? null)
		},
		onMutate: async ({ documentIds, ...fields }) => {
			return handleOptimisticUpdate(
				queryClient,
				projectId,
				portfolioId,
				documentIds,
				(ref) => ({ ...ref, ...fields }),
			)
		},
		onError: (error, variables, context) => {
			handleOptimisticRollback(
				queryClient,
				projectId,
				portfolioId,
				context?.previousProjectReferences,
				context?.previousPortfolioReferences,
			)
		},
		onSuccess: () => {
			invalidateDocumentQueries(queryClient, projectId, portfolioId)
		},
	})

	// 2) Single mutation for updating metadata (notes, nickname, tags, etc.)
	const updateDocumentMetadata = useMutation({
		mutationKey: MUTATION_KEYS.document.metadata.update(),
		mutationFn: async ({ documentIds, ...metadata }: UpdateMetadataPayload) => {
			return api.updateDocumentMetadata(projectId, portfolioId, documentIds, metadata)
		},
		onMutate: async ({ documentIds, ...metadata }) => {
			return handleOptimisticUpdate(
				queryClient,
				projectId,
				portfolioId,
				documentIds,
				(ref) => {
					const updatedDoc = { ...ref }
					if (metadata.addTags) {
						updatedDoc.tags = [...(ref.tags || []), ...metadata.addTags]
					} else if (metadata.setTags) {
						updatedDoc.tags = metadata.setTags
					}
					return { ...updatedDoc, ...metadata }
				},
			)
		},
		onError: (error, variables, context) => {
			handleOptimisticRollback(
				queryClient,
				projectId,
				portfolioId,
				context?.previousProjectReferences,
				context?.previousPortfolioReferences,
			)
		},
		onSuccess: () => {
			invalidateDocumentQueries(queryClient, projectId, portfolioId)
		},
	})

	return {
		document: documentQuery.data,
		isLoading: documentQuery.isLoading,
		isError: documentQuery.isError,
		refetch: documentQuery.refetch,

		updateDocuments: updateDocuments.mutate,
		updateDocumentMetadata: updateDocumentMetadata.mutate,
	}
}

export default useDocument
