import type { InvaliditySummaryRow } from "@/types"
import type { UpdateClaimsRequest } from "@/types/request"
import { apiClient } from "./client"

export const claimApi = {
	async updateClaimLimitation(
		claimLimitationId: string,
		options?: {
			commentary?: string
			groups?: number[]
			text?: string
			html?: string
			label?: string
		},
	): Promise<InvaliditySummaryRow[]> {
		const payload = {
			claim_limitation_id: claimLimitationId,
			...(options || {}),
		}

		return await apiClient.post("post_update_claim_limitation", payload)
	},

	async updateClaims(payload: UpdateClaimsRequest) {
		return await apiClient.post("post_update_claims", payload)
	},
}
