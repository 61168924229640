import { DocumentChunkComponent } from "@/components/documents/sections/body/DocumentChunk"
import { Badge } from "@/components/ui/badge"
import { type ChunkToLimitation, DocumentChunkMode } from "@/types"
import type React from "react"
import InvalidityCitationActions from "./InvalidityCitationActions"

interface InvalidityTableCitationProps {
	citation: ChunkToLimitation
	setShowNoMoreCitations: (show: boolean) => void
}

export const InvalidityTableCitation: React.FC<InvalidityTableCitationProps> = ({
	citation,
	setShowNoMoreCitations,
}) => {
	return (
		<div className="my-4">
			<InvalidityCitationActions
				citation={citation}
				showColor={true}
				showRemove={true}
				showVerify={true}
				showFeedback={true}
			>
				<DocumentChunkComponent
					chunk={citation.documentChunk}
					showFigures={true}
					mode={DocumentChunkMode.CITATION}
					selectedLimitationId={citation.claimLimitationId}
				/>

				{citation.iprs && citation.iprs.length > 0 && (
					<Badge className="mb-2">
						Cited in IPR(s): {Array.from(new Set(citation.iprs)).join(", ")}
					</Badge>
				)}
			</InvalidityCitationActions>
		</div>
	)
}

export default InvalidityTableCitation
