import { Button } from "@/components/ui/button"
import { CheckboxWithText } from "@/components/ui/checkbox"
import { Label } from "@/components/ui/label"
import type { ClaimEdit, ClaimLimitationEdit } from "@/types"
import LimitationEditor from "./LimitationEditor"

interface ClaimEditorProps {
	claim: ClaimEdit
	onUpdate: (claim: ClaimEdit) => void
	onDelete: () => void
	onUpdateLimitation: (params: {
		claimLimitationId: string
		options: ClaimLimitationEdit
	}) => void
	onDeleteLimitation: (limitationId: string) => void
	onAddLimitation: (limitation: ClaimLimitationEdit) => void
	isLoading: {
		claim: boolean
		limitation: boolean
	}
}

export function ClaimEditor({
	claim,
	onUpdate,
	onDelete,
	onUpdateLimitation,
	onDeleteLimitation,
	onAddLimitation,
	isLoading,
}: ClaimEditorProps) {
	const handleUpdate = (updates: Partial<ClaimEdit>) => {
		onUpdate({ ...claim, ...updates })
	}

	const handleAddLimitation = () => {
		const limitations = claim.limitations || []
		const lastLimitation = limitations[limitations.length - 1]

		const newLabel = lastLimitation
			? `${lastLimitation.label.split(".")[0]}.${
					Number.parseInt(lastLimitation.label.split(".")[1] || "0") + 1
				}`
			: `${claim.number}.1`

		const newLimitation: ClaimLimitationEdit = {
			id: crypto.randomUUID(),
			label: newLabel,
			text: "",
			claimId: claim.id,
			groups: [],
			commentary: null,
		}

		onAddLimitation(newLimitation)
	}

	const handleDeleteLimitation = (limitationId: string) => {
		onDeleteLimitation(limitationId)
	}

	return (
		<div className="space-y-4 px-4 py-3 mt-2 border rounded-lg">
			<div className="flex justify-between">
				<div className="flex flex-row gap-2 items-center">
					<Label>Claim {claim.number}</Label>
					<CheckboxWithText
						label="Asserted"
						checked={claim.isAsserted}
						onCheckedChange={(checked) => handleUpdate({ isAsserted: checked as boolean })}
					/>
					<CheckboxWithText
						label="Independent"
						checked={claim.isIndependent}
						onCheckedChange={(checked) => handleUpdate({ isIndependent: checked as boolean })}
					/>
				</div>

				<div className="flex gap-2">
					<Button variant="destructive" size="sm" onClick={onDelete}>
						Delete
					</Button>
				</div>
			</div>

			<div className="space-y-2">
				{(claim.limitations || []).map((limitation) => (
					<LimitationEditor
						key={limitation.id}
						limitation={limitation}
						onUpdate={(updatedLimitation) =>
							onUpdateLimitation({
								claimLimitationId: limitation.id,
								options: updatedLimitation,
							})
						}
						onDelete={() => handleDeleteLimitation(limitation.id)}
						isLoading={isLoading.limitation}
					/>
				))}
				<Button variant="outline" onClick={handleAddLimitation}>
					Add Limitation
				</Button>
			</div>
		</div>
	)
}

export default ClaimEditor
