/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
	CopyToClipboardButton,
	DocumentViewer,
	Figure,
	HighlightedText,
} from "@/components"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { cn } from "@/lib/utils"
import type { CitationSearchResult } from "@/types"
import { EyeOpenIcon } from "@radix-ui/react-icons"
import { useState } from "react"

interface SearchResultsTableProps {
	searchResults: CitationSearchResult[]
	sentSearchQuery: string
	height?: string
}

/**
 * @description Search results table component
 */
export default function SearchResultsTable({
	searchResults,
	sentSearchQuery,
	height,
}: SearchResultsTableProps) {
	const { getDocumentName } = useDocumentNaming()

	// Local state setup
	const [showPreview, setShowPreview] = useState<boolean>(false)
	const [patentId, setPatentId] = useState<string | null>(null)
	const [_, setCitationText] = useState<string | null>(null)

	const handlePreviewClick = async (refId: string, text: string) => {
		if (text.startsWith('"') && text.endsWith('"')) {
			const strippedText = text.substring(1, text.length - 1)
			setCitationText(strippedText)
		} else {
			setCitationText(text)
		}
		setPatentId(refId)
		setShowPreview(true)
	}

	const handleClosePreview = () => {
		setPatentId(null)
		setCitationText(null)
		setShowPreview(false)
	}

	return (
		<>
			{searchResults.length > 0 ? (
				<ScrollArea
					className={cn("flex-grow border rounded-md mx-2 overflow-x-auto", height)}
				>
					<Table className="px-4">
						<TableHeader>
							<TableRow>
								<TableHead>Document</TableHead>
								<TableHead>Location</TableHead>
								<TableHead>Text</TableHead>
								<TableHead>Actions</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{searchResults.map((result, index) => {
								return (
									<TableRow key={result.id}>
										<TableCellCondensed>{getDocumentName(result.referenceId)}</TableCellCondensed>
										<TableCellCondensed className="whitespace-nowrap">
											{result.location?.page && <div>Page {result.location.page}</div>}
											{result.location?.claimSection && (
												<div>Claim {result.location.claimSection}</div>
											)}
											{result.location?.paragraphs && (
												<div>
													{result.location.paragraphs[0] === result.location.paragraphs[1]
														? `Paragraph ${result.location.paragraphs[0]}`
														: `Paragraphs ${result.location.paragraphs[0]}-${result.location.paragraphs[1]}`}
												</div>
											)}
											{result.location?.columns && (
												<div>
													{result.location.columns[0] === result.location.columns[1]
														? `Column ${result.location.columns[0]}`
														: `Columns ${result.location.columns[0]}-${result.location.columns[1]}`}
												</div>
											)}
											{result.location?.lines && (
												<div>
													{result.location.lines[0] === result.location.lines[1]
														? `Line ${result.location.lines[0]}`
														: `Lines ${result.location.lines[0]}-${result.location.lines[1]}`}
												</div>
											)}
										</TableCellCondensed>
										<TableCellCondensed>
											<div className="flex items-center">
												<div
													className="w-4 flex-shrink-0 mr-3 self-stretch min-h-[45px] rounded"
													style={{
														backgroundColor: result.color || "lightgray",
													}}
												/>
												<div>
													<div className="mb-2.5">
														<HighlightedText
															text={result.text}
															highlight={sentSearchQuery}
															highlightColor="#e1e9ff"
														/>
													</div>
													{result.figureUrls?.map((imageUrl) => (
														<div key={imageUrl} className="mt-2">
															<Figure imageUrl={imageUrl} imageName={imageUrl} />
														</div>
													))}
												</div>
											</div>
										</TableCellCondensed>
										<TableCellCondensed>
											<div className="flex items-center space-x-2">
												<Tooltip>
													<TooltipTrigger asChild>
														<CopyToClipboardButton index={index} text={result.text} />
													</TooltipTrigger>
													<TooltipContent>
														<p>Copy to Clipboard</p>
													</TooltipContent>
												</Tooltip>

												<Tooltip>
													<TooltipTrigger asChild>
														<Button
															variant="ghost"
															size="icon"
															onClick={() => handlePreviewClick(result.referenceId, result.text)}
														>
															<EyeOpenIcon className="h-4 w-4" />
														</Button>
													</TooltipTrigger>
													<TooltipContent>
														<p>View in Document</p>
													</TooltipContent>
												</Tooltip>
											</div>
										</TableCellCondensed>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</ScrollArea>
			) : (
				<div className="text-center p-8">
					<h3 className="text-lg font-semibold mb-2">No results found.</h3>
					<p>Expand your search to include more sources or try a different search query.</p>
				</div>
			)}
			<DocumentViewer
				open={showPreview}
				handleClose={handleClosePreview}
				documentId={patentId}
				startInChartMode={false}
			/>
		</>
	)
}
