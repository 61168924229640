/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { useMemo } from "react"
import styled from "styled-components"

const HighlightSpan = styled.span<{ color: string }>`
  background-color: ${(props) => props.color};
`

/**
 * @description Highlighted text component
 * @param {string} text - The text to display
 * @param {string} highlight - The text to highlight
 * @param {string} highlightColor - The color of the highlighted text
 */
interface HighlightedTextProps {
	text: string
	highlight: string
	highlightColor: string
}

function HighlightedText({ text, highlight, highlightColor }: HighlightedTextProps) {
	const { regex, parts } = useMemo(() => {
		const safeHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // Escape regex special characters
		const regex = new RegExp(`(${safeHighlight})`, "gi")
		const parts = text?.split(regex)
		return { regex, parts }
	}, [text, highlight])

	return (
		<React.Fragment>
			{parts?.filter(Boolean).map((part, i) =>
				regex?.test(part) ? (
					<HighlightSpan color={highlightColor} key={i}>
						{part}
					</HighlightSpan>
				) : (
					<span key={i}>{part}</span>
				),
			)}
		</React.Fragment>
	)
}

export default HighlightedText
