import { api } from "@/api"
import { useNavigationState } from "@/hooks"
import type { UserMetadata } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useProjectAndPortfolioIds } from "./useProjectAndPortfolioIds"

/**
 * @description Hook for handling project/portfolio sharing operations
 */
const useSharing = () => {
	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const { isParentPortfolio } = useNavigationState()
	const queryClient = useQueryClient()

	const effectiveId = isParentPortfolio ? portfolioId : projectId

	const invalidateSharingQueries = () => {
		queryClient.invalidateQueries({
			queryKey: QUERY_KEYS.sharing.sharedUsers(effectiveId),
		})
		queryClient.invalidateQueries({
			queryKey: QUERY_KEYS.sharing.shareableUsers(effectiveId),
		})
	}

	// Query for getting current project users
	const projectUsers = useQuery({
		queryKey: QUERY_KEYS.sharing.sharedUsers(effectiveId),
		queryFn: () => api.getProjectUsers(effectiveId, isParentPortfolio),
		enabled: !!effectiveId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Query for getting users that can be shared with
	const shareableUsers = useQuery({
		queryKey: QUERY_KEYS.sharing.shareableUsers(effectiveId),
		queryFn: () => api.getShareableUsers(effectiveId, isParentPortfolio),
		enabled: !!effectiveId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Add user mutation
	const addUsersMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.users.add(),
		mutationFn: ({ userIds }: { userIds: string[] }) =>
			isParentPortfolio
				? api.addUsersToPortfolio(effectiveId, userIds)
				: api.addUsersToProject(effectiveId, userIds),
		onMutate: async ({ userIds }) => {
			const queryKeys = [
				QUERY_KEYS.sharing.sharedUsers(effectiveId),
				QUERY_KEYS.sharing.shareableUsers(effectiveId),
			]

			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys,
				updateFn: (oldData: UserMetadata[] | undefined) => {
					if (!oldData) return oldData

					if (queryKeys[0].includes("sharedUsers")) {
						return [
							...oldData,
							...(shareableUsers.data?.filter((u) => userIds.includes(u.id)) ?? []),
						]
					}
					return oldData.filter((u) => !userIds.includes(u.id))
				},
			})

			return context
		},
		onError: (_, __, context) => {
			if (context) revertOptimisticUpdates(queryClient, context)
		},
		onSuccess: () => {
			invalidateSharingQueries()
		},
	})

	// Remove user mutation
	const removeUsersMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.users.remove(),
		mutationFn: ({ userIds }: { userIds: string[] }) =>
			isParentPortfolio
				? api.removeUsersFromPortfolio(effectiveId, userIds)
				: api.removeUsersFromProject(effectiveId, userIds),
		onMutate: async ({ userIds }) => {
			const queryKeys = [
				QUERY_KEYS.sharing.sharedUsers(effectiveId),
				QUERY_KEYS.sharing.shareableUsers(effectiveId),
			]

			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys,
				updateFn: (oldData: UserMetadata[] | undefined) => {
					if (!oldData) return oldData

					// If we're updating shared users
					if (queryKeys[0].includes("sharedUsers")) {
						return oldData.filter((u: any) => !userIds.includes(u.id))
					}
					// If we're updating shareable users
					return [
						...oldData,
						...(projectUsers.data?.filter((u) => userIds.includes(u.id)) ?? []),
					]
				},
			})

			return context
		},
		onError: (_, __, context) => {
			if (context) revertOptimisticUpdates(queryClient, context)
		},
		onSuccess: () => {
			invalidateSharingQueries()
		},
	})

	return {
		// Query data
		projectUsers: projectUsers.data,
		shareableUsers: shareableUsers.data,

		// Loading states
		isLoading: {
			projectUsers: projectUsers.isLoading,
			shareableUsers: shareableUsers.isLoading,
			addUser: addUsersMutation.isPending,
			removeUser: removeUsersMutation.isPending,
		},

		// Error states
		isError: {
			projectUsers: projectUsers.isError,
			shareableUsers: shareableUsers.isError,
			addUser: addUsersMutation.isError,
			removeUser: removeUsersMutation.isError,
		},

		// Mutation functions
		addUsers: addUsersMutation.mutate,
		removeUsers: removeUsersMutation.mutate,

		// Refetch functions
		refetch: {
			projectUsers: projectUsers.refetch,
			shareableUsers: shareableUsers.refetch,
		},
	}
}

export default useSharing
