import { Button } from "@/components/ui/button"
import { type UnprocessedDocument, UnprocessedDocumentType } from "@/types"
import { Cross2Icon } from "@radix-ui/react-icons"
import type React from "react"
import UnprocessedDocumentCard from "./UnprocessedDocumentCard"
import UnprocessedPatentCard from "./UnprocessedPatentCard"

interface UnprocessedDocumentListItemProps {
	document: UnprocessedDocument
	onRemove: (documentId: string) => void
	updateSelectedKindCode: (documentId: string, kindCode: string) => void
}

const UnprocessedDocumentListItem: React.FC<UnprocessedDocumentListItemProps> = ({
	document,
	onRemove,
	updateSelectedKindCode,
}) => {
	const renderDocumentContent = () => {
		switch (document.type) {
			case UnprocessedDocumentType.PUBLISHED_PATENT:
			case UnprocessedDocumentType.UNPUBLISHED_PATENT:
				return (
					<UnprocessedPatentCard
						document={document}
						updateSelectedKindCode={updateSelectedKindCode}
					/>
				)
			case UnprocessedDocumentType.REFERENCE:
			case UnprocessedDocumentType.OFFICE_ACTION:
				return <UnprocessedDocumentCard document={document} />
			default:
				return <div>Unsupported document type: {document.type}</div>
		}
	}

	return (
		<div className="border border-gray-200 rounded-lg px-4 py-2">
			<div className="flex items-center gap-4 justify-between">
				<div className="flex flex-col gap-2">
					{renderDocumentContent()}
					{document.warning && <p className="text-sm text-red-500">{document.warning}</p>}
				</div>
				<Button
					variant="ghost"
					size="icon"
					className=" h-6 w-6"
					onClick={() => onRemove(document.id)}
				>
					<Cross2Icon className="h-4 w-4" />
				</Button>
			</div>
		</div>
	)
}

export default UnprocessedDocumentListItem
