/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import useCoverPleading from "@/features/cover/hooks/useCoverPleading"
import { useDocument, useProjectAndPortfolioIds } from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { useAppStateStore } from "@/store"
import { type ParentDocumentMetadata, ParentType } from "@/types"
import { Pencil1Icon } from "@radix-ui/react-icons"
import { RefreshCwIcon } from "lucide-react"
import type React from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
interface DocumentSummaryProps {
	parentToDocument: ParentDocumentMetadata
	parentType: ParentType
}

const DocumentSummary: React.FC<DocumentSummaryProps> = ({
	parentToDocument,
	parentType,
}) => {
	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const navigate = useNavigate()
	const { addErrorMessage } = useAppStateStore()
	const { generateSummaries, isGeneratingSummaries } = useCoverPleading()
	const [editedSummary, setEditedSummary] = useState("")
	const { updateDocumentMetadata } = useDocument()
	const { getDocumentPortfolioName, getDocumentName } = useDocumentNaming()

	const [documentViewerCitationText, setDocumentViewerCitationText] = useState<
		string | null
	>(null)
	const [isEditing, setIsEditing] = useState(false)
	const [showDocumentViewer, setShowDocumentViewer] = useState(false)
	const CITATION_REGEX =
		/(?:[A-Za-z]+\s+'\d{3}\s+at\s+[^.]+)|(?:\[[^\]]+\](?:,\s*\[[^\]]+\])*)/g

	const handleEditClick = (summary: string) => {
		setIsEditing(true)
		setEditedSummary(summary)
	}

	const handleSaveSummary = async () => {
		if (!isEditing) return
		try {
			await updateDocumentMetadata({
				projectId: projectId,
				portfolioId: portfolioId || "",
				documentIds: [parentToDocument.documentId],
				documentSummary: editedSummary,
			})
		} catch (error) {
			console.error("Error saving summary:", error)
			addErrorMessage("An error occurred while saving the summary. Please try again.")
		} finally {
			setIsEditing(false)
			setEditedSummary("")
		}
	}

	const handleCitationClick = async (e: React.MouseEvent, text: string) => {
		e.preventDefault()
		// First remove quotes if they exist
		const strippedText =
			text.startsWith('"') && text.endsWith('"')
				? text.substring(1, text.length - 1)
				: text

		setDocumentViewerCitationText(strippedText)
	}

	const formatSummaryText = (text: string, referenceId: string) => {
		const parts = []
		let currentPosition = 0

		// Find all quotes in the entire text
		const quoteMatches = Array.from(text.matchAll(/"([^"]+)"/g))
		const citationMatches = text.match(CITATION_REGEX)

		quoteMatches.forEach((quoteMatch, index) => {
			// Add text before the quote if any
			if (quoteMatch.index > currentPosition) {
				parts.push({
					type: "text",
					content: text.slice(currentPosition, quoteMatch.index).trim(),
				})
			}

			// Add the quote as a single unit, regardless of sentence count
			parts.push({
				type: "quote",
				content: quoteMatch[0],
				strippedContent: quoteMatch[1],
				citation: citationMatches?.[index] || null,
				index: index,
			})

			currentPosition = quoteMatch.index + quoteMatch[0].length
		})

		// Add any remaining text
		if (currentPosition < text.length) {
			parts.push({
				type: "text",
				content: text.slice(currentPosition).trim(),
			})
		}

		return (
			<span>
				{parts.map((part, partIndex) => {
					if (part.type === "quote") {
						return (
							<span
								key={`quote-${partIndex}`}
								className="hover:bg-blue-100 cursor-pointer"
								data-quote-group={`${referenceId}-${partIndex}`}
								onClick={(e) => handleCitationClick(e, part.strippedContent)}
							>
								{part.content}{" "}
							</span>
						)
					}
					return <span key={`text-${partIndex}`}>{part.content} </span>
				})}
			</span>
		)
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault()
			handleSaveSummary()
		}
	}

	const handleReset = () => {
		setIsEditing(false)
		setEditedSummary("")
	}

	const handleTitleClick = () => {
		navigate(`/project/${projectId}/references/${parentToDocument.documentId}`)
	}

	const handleGenerateSummary = async () => {
		const parentId = parentType === ParentType.PORTFOLIO ? portfolioId : projectId
		await generateSummaries({
			documentIds: [parentToDocument.documentId],
			id: parentId,
			parent: parentType,
			temperature: 1.0,
		})
	}

	return (
		<>
			<div key={parentToDocument.documentId} className="px-4 py-3 border rounded-md">
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-2">
						<Label
							className="mb-0 hover:cursor-pointer hover:text-blue-600"
							onClick={handleTitleClick}
						>
							{parentType === ParentType.PORTFOLIO
								? getDocumentPortfolioName(parentToDocument.documentId)
								: getDocumentName(parentToDocument.documentId)}
						</Label>

						{isEditing ? (
							<>
								<Button variant="outline" onClick={handleReset} className="h-9">
									Reset
								</Button>
								<Button className="h-9" onClick={handleSaveSummary}>
									Save
								</Button>
							</>
						) : (
							<Button
								variant="ghost"
								size="icon"
								onClick={() => handleEditClick(parentToDocument.documentSummary)}
								disabled={isGeneratingSummaries}
							>
								<Pencil1Icon className="h-4 w-4" />
							</Button>
						)}
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger asChild>
									<Button
										variant="ghost"
										size="icon"
										onClick={handleGenerateSummary}
										disabled={isGeneratingSummaries || isEditing}
									>
										<RefreshCwIcon className="h-4 w-4" />
									</Button>
								</TooltipTrigger>
								<TooltipContent>Regenerate summary</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</div>
				</div>
				{isEditing ? (
					<AutosizeTextarea
						value={editedSummary}
						onChange={(e) => setEditedSummary(e.target.value)}
						onKeyDown={handleKeyDown}
						className="w-full mt-2"
					/>
				) : isGeneratingSummaries ? (
					<Loader message="Regenerating summary..." />
				) : (
					<p className="text-sm">
						{formatSummaryText(parentToDocument.documentSummary, parentToDocument.documentId)}
					</p>
				)}
			</div>
		</>
	)
}

export default DocumentSummary
