import { Button } from "@/components/ui/button"
import { formatPatentNumber } from "@/utils/projectUtils"
import { ExternalLinkIcon } from "@radix-ui/react-icons"
import type React from "react"

interface PatentNumberDisplayProps {
	finalIdString: string
}

const PatentNumberDisplay: React.FC<PatentNumberDisplayProps> = ({ finalIdString }) => {
	return (
		<div className="flex items-center gap-1 bg-[#BBD6FF] text-black whitespace-nowrap px-1 py-0.75 pl-3 rounded-full h-10">
			<span className="text-md">{formatPatentNumber(finalIdString)}</span>
			<Button
				variant="ghost"
				size="icon"
				asChild
				className="text-black hover:bg-primary-foreground/10 flex-shrink-0"
			>
				<a
					href={`https://patents.google.com/patent/${finalIdString}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<ExternalLinkIcon className="h-4 w-4" />
				</a>
			</Button>
		</div>
	)
}

export default PatentNumberDisplay
