import { api } from "@/api"
import { usePortfolio } from "@/hooks"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"
import { useReferencesBase } from "./useReferencesBase"

export function usePortfolioDocuments(portfolioId: string) {
	const { deleteDocuments, pruneDocuments } = useReferencesBase()
	const { portfolio } = usePortfolio()

	const {
		data: priorArtDocuments = [],
		isLoading,
		error,
	} = useQuery({
		queryKey: QUERY_KEYS.portfolio.references(portfolioId),
		queryFn: () => api.getPortfolioPriorArtRows(portfolioId),
		enabled: !!portfolioId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const deletePortfolioDocuments = async (referenceIds: string[]) => {
		deleteDocuments.mutateAsync({
			portfolioId: portfolio.id,
			referenceIds,
		})
	}

	const prunePortfolioDocuments = async (numDocuments: number, strict: boolean) => {
		if (!portfolio?.projects) return

		await Promise.all(
			portfolio.projects.map((project) =>
				pruneDocuments.mutateAsync({
					projectId: project.id,
					numReferences: numDocuments,
					strict,
				}),
			),
		)
	}

	const _subjectIds = portfolio?.projects?.map((project) => project.subjectId) || []

	return {
		priorArtDocuments: priorArtDocuments,
		isLoading,
		error,
		deleteDocuments: deletePortfolioDocuments,
		pruneDocuments: prunePortfolioDocuments,
	}
}

export default usePortfolioDocuments
