import { create } from "zustand"

interface ProjectStore {
	selectedReferences: any[]
	selectedLimitations: any[]
	selectedColors: string[]
	showAssertedClaims: boolean
	draftingClaimID: string | null

	updateSelectedReferences: (details: any[]) => void
	addSelectedReferences: (refDetails: any) => void
	removeSelectedReferences: (refIds: string[]) => void
	updateSelectedLimitations: (selectedLimitations: any[]) => void
	updateSelectedColors: (colors: string[]) => void
	updateDraftingClaimID: (claimID: string | null) => void
	updateShowAssertedClaims: (showAssertedClaims: boolean) => void
	resetProjectStore: () => void
}

const initialState: Omit<
	ProjectStore,
	| "updateSelectedReferences"
	| "addSelectedReferences"
	| "removeSelectedReferences"
	| "updateSelectedDocumentIds"
	| "updateSelectedLimitations"
	| "updateSelectedColors"
	| "updateCurrentSubject"
	| "updateDraftingClaimID"
	| "updateShowAssertedClaims"
	| "resetProjectStore"
> = {
	selectedReferences: [],
	selectedLimitations: [],
	selectedColors: [],
	draftingClaimID: null,
	showAssertedClaims: false,
}

const useProjectStore = create<ProjectStore>((set) => ({
	...initialState,

	updateSelectedReferences: (details: any[]) =>
		set(() => ({ selectedReferences: details })),

	addSelectedReferences: (refDetails: any) =>
		set((state) => ({
			selectedReferences: [...state.selectedReferences, refDetails],
		})),

	removeSelectedReferences: (refIds: string[]) =>
		set((state) => ({
			selectedReferences: state.selectedReferences.filter((details) =>
				refIds.includes(details.id),
			),
		})),

	updateSelectedLimitations: (selectedLimitations: any[]) =>
		set(() => ({ selectedLimitations })),

	updateSelectedColors: (colors: string[]) => set(() => ({ selectedColors: colors })),

	updateDraftingClaimID: (claimID: string | null) =>
		set(() => ({ draftingClaimID: claimID })),

	updateShowAssertedClaims: (showAssertedClaims: boolean) =>
		set(() => ({ showAssertedClaims })),

	resetProjectStore: () => set(() => ({ ...initialState })),
}))

export default useProjectStore
