/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import {
	AdminPageLayout,
	CurrentBillingDetailsCard,
	InvoicesTable,
	NoAdminAccessMessage,
	PlanDetailsCard,
} from "@/features/admin/components"
import { useUserStore } from "@/store"
import { RoleType } from "@/types"
import { PageName } from "@/types/pages"
import { useEffect, useMemo, useState } from "react"
import { useAdminBilling } from "../hooks/useAdminBilling"

/**
 * @description Admin page component
 */
function AdminBillingPage() {
	const { organizationId, role } = useUserStore()
	const { adminBilling, isLoading } = useAdminBilling(organizationId)

	const [error, setError] = useState(false)

	useEffect(() => {
		if (!adminBilling && !isLoading) {
			setError(true)
		}
	}, [adminBilling, isLoading])

	const isAdmin = useMemo(
		() => role === RoleType.ADMIN || role === RoleType.OWNER,
		[role],
	)

	return (
		<AdminPageLayout isSuperAdmin={false} page={PageName.AdminBilling}>
			{isAdmin ? (
				<div className="mt-3 space-y-8">
					{isLoading ? (
						<Loader />
					) : error ? (
						<p className="text-destructive">Error fetching organization details.</p>
					) : (
						<div className="space-y-4">
							<div className="flex gap-3">
								<PlanDetailsCard plan={adminBilling?.plan} />
								<CurrentBillingDetailsCard cycleSummary={adminBilling?.cycleSummary} />
							</div>
							{adminBilling?.invoices?.length > 0 && (
								<InvoicesTable invoices={adminBilling.invoices} />
							)}
						</div>
					)}
				</div>
			) : (
				<NoAdminAccessMessage />
			)}
		</AdminPageLayout>
	)
}

export default AdminBillingPage
