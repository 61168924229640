/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import type { Metric } from "web-vitals"

type ReportHandler = (metric: Metric) => void

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		import("web-vitals").then(({ onCLS, onINP, onFCP, onLCP, onTTFB }) => {
			onCLS(onPerfEntry)
			onINP(onPerfEntry)
			onFCP(onPerfEntry)
			onLCP(onPerfEntry)
			onTTFB(onPerfEntry)
		})
	}
}

export default reportWebVitals
