import type { AnalyzeSection112Request, UpdateSection112Request } from "@/types"
import { apiClient } from "./client"

export const vectorApi = {
	async analyzeSection112(projectId: string) {
		const payload: AnalyzeSection112Request = {
			project_id: projectId,
		}
		return await apiClient.post("post_analyze_section_112", payload)
	},

	async updateSection112(
		projectId: string,
		subjectId: string,
		new_section: Array<Record<string, string>>,
	) {
		const payload: UpdateSection112Request = {
			project_id: projectId,
			subject_id: subjectId,
			enablement: new_section,
			indefiniteness: new_section,
			written_description: new_section,
		}
		return await apiClient.post("post_update_section_112", payload)
	},
}
