import { TableBody } from "@/components/ui/table"
import { usePortfolio } from "@/hooks"
import type React from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { PortfolioMatrixRow } from "./PortfolioMatrixRow"

interface PortfolioMatrixBodyProps {
	patentIds: string[]
	hideEmptyRows: boolean
}

const PortfolioMatrixBody: React.FC<PortfolioMatrixBodyProps> = ({
	patentIds,
	hideEmptyRows,
}) => {
	const navigate = useNavigate()
	const { portfolio, portfolioId, portfolioMatrix } = usePortfolio()

	const [isNavigating, setIsNavigating] = useState(false)
	const [expandedSubjectId, setExpandedSubjectId] = useState<string | null>(null)

	const handleRowToggle = async (subjectId: string) => {
		const isCurrentlyExpanded = expandedSubjectId === subjectId
		// Check if all cells in the row are empty
		const column = portfolioMatrix.find((col) => col.subject.documentId === subjectId)
		const allCellsAreEmpty = column?.priorArts.every(
			(priorArt) => priorArt?.color === "NONE" || !priorArt,
		)
		if (allCellsAreEmpty) {
			return
		}

		setExpandedSubjectId(isCurrentlyExpanded ? null : subjectId)
	}

	const handleCellClick = async (subjectId: string, referenceId: string) => {
		if (!portfolio?.projects || isNavigating) {
			console.warn("No projects found in portfolio or navigation in progress")
			return
		}

		const selectedProject = portfolio.projects.find((p) => p.subjectId === subjectId)
		const projectId = selectedProject?.id

		if (projectId) {
			try {
				setIsNavigating(true)
				navigate(`/portfolio/${portfolioId}/project/${projectId}/charts`)
			} catch (error) {
				console.error("Error loading project metadata:", error)
			} finally {
				setIsNavigating(false)
			}
		}
	}

	return (
		<TableBody>
			{portfolioMatrix.map((column) => (
				<PortfolioMatrixRow
					key={column.subject.documentId}
					column={column}
					patentIds={patentIds}
					onCellClick={handleCellClick}
					onRowToggle={handleRowToggle}
					isExpanded={expandedSubjectId === column.subject.documentId}
					hideEmptyRows={hideEmptyRows}
				/>
			))}
		</TableBody>
	)
}

export default PortfolioMatrixBody
