import { apiClient } from "@/api/client"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useAppStateStore } from "@/store"
import { EnvironmentTypes } from "@/types"
import { useAuthInfo, useLogoutFunction } from "@propelauth/react"
import axios, { type AxiosResponse } from "axios"
import { useEffect } from "react"

/**
 * @description Hook to handle the API requests
 */
const useApi = () => {
	const { accessToken, isLoggedIn } = useAuthInfo()
	const logout = useLogoutFunction()
	const { addErrorMessage } = useAppStateStore()

	const apiInstance = axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
		withCredentials: true,
		timeout: 300000,
	})

	// Interceptor to add the access token to each request
	apiInstance.interceptors.request.use((config) => {
		if (isLoggedIn && accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`
		}
		return config
	})

	// Handle 401 errors
	apiInstance.interceptors.response.use(
		(response: AxiosResponse) => response,
		async (error) => {
			if (error.response?.status === 401) {
				// If we get a 401, log the user out, their session has expired
				await logout(true)
				addErrorMessage("Your session has expired. Please sign in again.")
			}
			return Promise.reject(error)
		},
	)

	useEffect(() => {
		if (accessToken) {
			apiClient.setAuthToken(accessToken)
		}
	}, [accessToken])

	function joinUrl(baseUrl: string, urlPath: string): string {
		if (!baseUrl.endsWith("/")) {
			baseUrl += "/"
		}
		if (urlPath.startsWith("/")) {
			urlPath = urlPath.substring(1)
		}
		return baseUrl + urlPath
	}

	/**
	 * @description Makes a POST request to the API with streaming
	 * @param {string} url - The URL to make the request to
	 * @param {object} body - The body of the request
	 * @param {object} params - The parameters to send with the request
	 * @param {object} config - The configuration for the request
	 */
	const postStreamRequest = async (
		url: string,
		body: object = {},
		params: object = {},
		config: RequestInit = {},
	) => {
		return apiClient.stream(url, body, params, config)
	}

	const handleError = (error: any, defaultMessage: string) => {
		if (process.env.NODE_ENV !== EnvironmentTypes.PROD) {
			console.error(defaultMessage, error)
		}

		let _errorMessage = defaultMessage
		let errorStatus
		let errorDetail

		if (axios.isAxiosError(error)) {
			// Handle Axios errors
			errorStatus = error.response?.status
			errorDetail = error.response?.data?.detail || error.response?.data?.error
			_errorMessage = errorDetail || error.response?.statusText || defaultMessage
		} else if (error instanceof Error) {
			// Handle fetch API errors
			const match = error.message.match(/HTTP error! Status: (\d+)/)
			if (match) {
				errorStatus = Number.parseInt(match[1], 10)
				_errorMessage = `HTTP error! Status: ${errorStatus}`
			} else {
				_errorMessage = error.message || defaultMessage
			}
		}

		console.error("Error in handleError:", error)
		throw error

		// const response = {
		//   success: false,
		//   message: errorMessage,
		//   error,
		//   status: errorStatus,
		//   detail: errorDetail,
		// };
		// return response;
	}

	return {
		postStreamRequest,
		handleError,
	}
}

export default useApi
