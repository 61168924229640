/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { cn } from "@/lib/utils"
import type { UnprocessedDocument } from "@/types"
import type React from "react"
import UnprocessedDocumentListItem from "./UnprocessedDocumentListItem"

interface UnprocessedDocumentsListProps {
	documents: UnprocessedDocument[]
	onRemove: (documentId: string) => void
	updateSelectedKindCode: (documentId: string, kindCode: string) => void
}

/**
 * @description Displays a list of documents to process, used in create flow and add/upload files modals
 * @param {UnprocessedDocument[]} documents - The list of documents to display.
 */
const UnprocessedDocumentsList: React.FC<UnprocessedDocumentsListProps> = ({
	documents,
	onRemove,
	updateSelectedKindCode,
}) => {
	return (
		<div className={cn("overflow-y-auto overflow-x-auto space-y-2")}>
			{documents.map((document) => {
				return (
					<UnprocessedDocumentListItem
						document={document}
						onRemove={onRemove}
						key={document.id}
						updateSelectedKindCode={updateSelectedKindCode}
					/>
				)
			})}
		</div>
	)
}

export default UnprocessedDocumentsList
