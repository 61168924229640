import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { Cross2Icon, EyeOpenIcon, HandIcon } from "@radix-ui/react-icons"
import {
	AlignVerticalSpaceAround,
	BadgeCheckIcon,
	CopyPlusIcon,
	EditIcon,
	PenLineIcon,
	PlusIcon,
	ReplaceIcon,
	SearchIcon,
	ThumbsDownIcon,
	ThumbsUpIcon,
} from "lucide-react"

interface HelpItemProps {
	icon: React.ReactNode
	label: string
	description: string
}

const HelpItem: React.FC<HelpItemProps> = ({ icon, label, description }) => {
	return (
		<div className="space-y-1">
			<div className="flex items-center gap-2">
				{icon}
				<p className="font-medium ml-2 text-sm text-gray-900">{label}</p>
			</div>
			<p className="text-sm pl-8 text-gray-700">{description}</p>
		</div>
	)
}

interface InvalidityTableHelpModalProps {
	isOpen: boolean
	onClose: () => void
}

const InvalidityTableHelpModal: React.FC<InvalidityTableHelpModalProps> = ({
	isOpen,
	onClose,
}) => {
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="max-w-5xl">
				<DialogHeader>
					<DialogTitle className="text-gray-900">Invalidity Table Actions</DialogTitle>
				</DialogHeader>
				<div className="space-y-6">
					<Label className="text-gray-900 font-semibold">Table Actions</Label>
					<div className="grid grid-cols-2 gap-x-8 gap-y-4">
						<HelpItem
							icon={<AlignVerticalSpaceAround className="h-4 w-4" />}
							label="Edit table header/footer"
							description="Edit the header and footer format that will appear at the start and end of the cells for the entire table."
						/>
						<HelpItem
							icon={<BadgeCheckIcon className="h-4 w-4" />}
							label="Verify citations"
							description="Toggle to verify/unverify all table citations. You have the option to protect verified citations from being removed."
						/>
						<HelpItem
							icon={<EyeOpenIcon className="h-4 w-4" />}
							label="View full document"
							description="Open the complete reference document."
						/>
						<HelpItem
							icon={<HandIcon className="h-4 w-4" />}
							label="Pull IPR Proceedings"
							description="Automatically fetch and fill in IPR proceeding information."
						/>
					</div>

					<Label className="text-gray-900 font-semibold">Row Actions</Label>
					<div className="grid grid-cols-2 gap-x-8 gap-y-4">
						<HelpItem
							icon={<SearchIcon className="h-4 w-4" />}
							label="Find more citations"
							description="Searches to find more relevant citations for the current element. Positive and negative feedback on citations will guide the search."
						/>
						<HelpItem
							icon={<PlusIcon className="h-4 w-4" />}
							label="Add citations"
							description="Add citations directly from the reference document."
						/>
						<HelpItem
							icon={<CopyPlusIcon className="h-4 w-4" />}
							label="Append evidence"
							description="Append citations from another element to the current element, for the current reference or to all references in the project."
						/>
						<HelpItem
							icon={<ReplaceIcon className="h-4 w-4" />}
							label="Replace evidence"
							description="Overwrite the citations with evidence from another element, for the current reference or to all references in the project."
						/>
						<HelpItem
							icon={<PenLineIcon className="h-4 w-4" />}
							label="Cell header/footer"
							description="Customize the header and footer text that will appear at the start and end of this cell in the exported documents."
						/>
					</div>

					<Label className="text-gray-900 font-semibold">Citation Actions</Label>
					<div className="grid grid-cols-2 gap-x-8 gap-y-4">
						<HelpItem
							icon={<EditIcon className="h-4 w-4" />}
							label="Edit citation"
							description="Right click to edit the citation text, location, or images."
						/>
						<HelpItem
							icon={<EyeOpenIcon className="h-4 w-4" />}
							label="View in reference document"
							description="Right click to open the complete reference document at the citation location."
						/>
						<HelpItem
							icon={<ThumbsUpIcon className="h-4 w-4" />}
							label="Positive feedback"
							description="Click to indicate that the citation is relevant. If you search for more citations, positive feedback will help guide the search."
						/>
						<HelpItem
							icon={<ThumbsDownIcon className="h-4 w-4" />}
							label="Negative feedback"
							description="Click to indicate that the citation is irrelevant. If you search for more citations, negative feedback will help guide the search."
						/>
						<HelpItem
							icon={<BadgeCheckIcon className="h-4 w-4" />}
							label="Verify citation"
							description="Click to verify the citation. You have the option to protect verified citations from being removed."
						/>
						<HelpItem
							icon={<Cross2Icon className="h-4 w-4" />}
							label="Remove citation"
							description="Remove the citation from the table. The citations will appear in a 'Removed citations' section at the bottom of the cell."
						/>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default InvalidityTableHelpModal
