import { api } from "@/api"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import { useProjectDocuments } from "@/hooks/references/useProjectDocuments"
import type { ProjectMetadata } from "@/types"
import { invalidateProjectNameRelatedQueries } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useAuthInfo } from "@propelauth/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useProjectAndPortfolioIds } from "./useProjectAndPortfolioIds"
/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useProject = () => {
	const queryClient = useQueryClient()
	const { user } = useAuthInfo()
	const { handleError } = useApi()
	const { projectId, portfolioId } = useProjectAndPortfolioIds()
	const { priorArtDocuments, isLoading: referencesLoading } =
		useProjectDocuments(projectId)

	const project = useQuery({
		queryKey: QUERY_KEYS.project.metadata(projectId),
		queryFn: () => api.getProjectMetadata(projectId as string),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const projectSubject = useQuery({
		queryKey: QUERY_KEYS.project.subject(projectId),
		queryFn: () =>
			api.getProjectSubject(projectId as string, project.data?.subjectId as string),
		enabled: !!projectId && !!project.data?.subjectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const updateProjectDetailsMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.updateDetails(),
		mutationFn: ({ projectId, options }: { projectId: string; options: any }) => {
			const payload: { [key: string]: any } = {
				project_id: projectId,
			}

			// Append additional options to payload if they exist
			for (const key of Object.keys(options)) {
				if (options[key]) {
					payload[key] = options[key]
				}
			}

			return api.updateProjectDetails(projectId, payload)
		},
		onSuccess: (data, variables) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.metadata(variables.projectId),
			})
		},
		onError: (error) => {
			handleError(error, "Error updating project details")
		},
	})

	const renameProjectMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.rename(),
		mutationFn: ({ name }: { name: string }) => {
			const payload: { [key: string]: any } = {
				project_id: projectId,
				name: name,
			}

			return api.updateProjectDetails(projectId, payload)
		},
		onMutate: async ({ name }) => {
			// Cancel any outgoing refetches
			await queryClient.cancelQueries({
				queryKey: QUERY_KEYS.project.metadata(projectId),
			})

			// Snapshot the previous value
			const previousMetadata = queryClient.getQueryData<ProjectMetadata>(
				QUERY_KEYS.project.metadata(projectId),
			)

			// Optimistically update the metadata
			queryClient.setQueryData<ProjectMetadata>(
				QUERY_KEYS.project.metadata(projectId),
				(old) => (old ? { ...old, name } : old),
			)

			// Return context with the snapshotted value
			return { previousMetadata }
		},
		onError: (error, variables, context) => {
			// Revert to the previous value on error
			if (context?.previousMetadata) {
				queryClient.setQueryData(
					QUERY_KEYS.project.metadata(projectId),
					context.previousMetadata,
				)
			}
			handleError(error, "Error updating project details")
		},
		onSuccess: (data, variables) => {
			invalidateProjectNameRelatedQueries(queryClient, portfolioId)
		},
	})

	// Derive subjectId and referenceIds
	const subjectId = projectSubject?.data?.document?.id
	const priorArtIds = priorArtDocuments?.map((ref) => ref.documentId) ?? []
	const allDocumentIds = [subjectId, ...priorArtIds]

	const isProjectOwner = project.data?.createdBy === user?.userId

	return {
		// Query data
		project: project.data,
		projectPriorArtDocuments: priorArtDocuments,
		projectSubject: projectSubject.data,

		// Loading states
		isLoading: {
			project: project.isLoading,
			projectReferences: referencesLoading,
			projectSubject: projectSubject.isLoading,
		},

		// Error states
		isError: {
			project: project.isError,
			projectSubject: projectSubject.isError,
		},

		// Mutation functions
		updateProjectDetails: updateProjectDetailsMutation.mutate,
		renameProject: renameProjectMutation.mutate,
		// updateClaims: updateClaimsMutation.mutate,

		// Add refetch functions to allow manual fetching when needed
		refetch: {
			// projectReferences: projectReferences.refetch,
			projectSubject: projectSubject.refetch,
		},

		// newly exposed derived fields
		subjectId,
		priorArtIds,
		allDocumentIds,
		projectId: projectId,
		projectName: project.data?.name,
		projectType: project.data?.type,
		isProjectOwner,
	}
}

export default useProject
