/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { usePlatformUtils } from "@/hooks"
import { RedirectToLogin } from "@propelauth/react"

/**
 * @description Log in page redirects to PropelAuth
 */
function LogInPage() {
	const { usePageTitle } = usePlatformUtils()
	usePageTitle("Sign In")
	return (
		<RedirectToLogin
			postLoginRedirectUrl={"/home"} // Redirect to the home page after login
		/>
	)
}

export default LogInPage
