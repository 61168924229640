import { api } from "@/api"
import { useApi, useNavigationState, usePortfolio, useProject } from "@/hooks"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useAppStateStore, useProcessStore } from "@/store"
import {
	type ApiResponse,
	CitationPolicy,
	DocumentRole,
	type DocumentToProcess,
	DocumentType,
	type ProcessSettings,
	ProcessType,
	type ProjectDocumentMetadata,
	StatusType,
	type UnprocessedDocument,
	UnprocessedDocumentType,
} from "@/types"
import { QUERY_KEYS } from "@/utils/query/keys"
import { useQueryClient } from "@tanstack/react-query"
import { nanoid } from "nanoid"
import { useCallback } from "react"
import useDocumentNaming from "./useDocumentNaming"
import useFileUtils from "./useFileUtils"
const docStatusMapping = {
	PROCESSED: StatusType.SUCCESS,
	RECHART: StatusType.ERROR,
	RELOAD: StatusType.ERROR,
	UPLOADED: StatusType.SUCCESS,
}

export const docRoleToProcessType = {
	[DocumentRole.PRIOR_ART]: ProcessType.ADD_PRIOR_ART,
	[DocumentRole.SUBJECT]: ProcessType.ADD_SUBJECT,
} as const

/**
 * @description Hook for handling document processing and reprocessing
 */
const useProcessDocuments = () => {
	const { handleError } = useApi()
	const { projectId, projectName } = useProject()
	const { portfolioId, portfolioName } = usePortfolio()
	const { updateLoadingGroupItem, addLoadingGroupItem, addLoadingGroup } =
		useAppStateStore()
	const { addProcess, removeProcess } = useProcessStore()
	const { getUploadedFileKeysAndUrls } = useFileUtils()
	const { getDocumentName } = useDocumentNaming()
	const queryClient = useQueryClient()
	const { isPortfolioPage } = useNavigationState()

	const invalidateReferences = () => {
		if (isPortfolioPage) {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.portfolio.references(portfolioId),
			})
		} else {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.documents(projectId),
			})
		}
	}

	const processDocuments = async (
		processType: ProcessType,
		portfolioId?: string, // Add portfolioId for projects within a portfolio
		projectId?: string,
		documents: UnprocessedDocument[] = [],
		chart = true,
		claimsInCharting = false,
	): Promise<ApiResponse> => {
		//  Start process first
		const processId = nanoid()
		addProcess({
			id: processId,
			type: processType,
			projectId: projectId ?? null,
			portfolioId: portfolioId ?? null,
		})

		addLoadingGroup(processId, projectName ?? portfolioName, processType)
		for (const doc of documents) {
			addLoadingGroupItem(
				processId,
				processType,
				doc.s3_key,
				StatusType.PROCESSING,
				"",
				doc.patentDetails?.number ?? doc.file?.name,
			)
		}

		// Upload files before showing loading groups (tbd?)
		const documentsToProcess: DocumentToProcess[] =
			await convertToDocumentsToProcess(documents)

		// Try processing the documents
		try {
			const processResponse: any = await api.processDocuments(
				portfolioId || null,
				projectId ? [projectId] : null,
				documentsToProcess,
				{
					chart: chart,
					claims_in_charting: claimsInCharting,
					citation_policy: CitationPolicy.APPEND,
					reparse: false,
				},
			)

			const documents = processResponse.documents

			// Update document statuses
			for (const document of documents) {
				updateLoadingGroupItem(
					processId,
					processType,
					document.document_key,
					docStatusMapping[document.status],
					"",
				)
			}

			// Replace refetchReferences with invalidateReferences
			await invalidateReferences()
			removeProcess(processId)

			return {
				success: true,
				data: processResponse.data,
				status: processResponse.status,
			}
		} catch (error) {
			removeProcess(processId)
			return handleError(error, "Failed to process documents")
		}
	}

	const reprocessDocuments = async (
		documents: ProjectDocumentMetadata[] | string[],
		reparse = false,
		citationPolicy: CitationPolicy = CitationPolicy.APPEND,
	): Promise<ApiResponse> => {
		// Check the type and extract IDs accordingly
		const documentIds =
			Array.isArray(documents) && documents.length > 0
				? typeof documents[0] === "string"
					? (documents as string[])
					: (documents as ProjectDocumentMetadata[]).map((doc) => doc.documentId)
				: []

		//  Start processing first
		const processType = reparse ? ProcessType.REPROCESS : ProcessType.RECHART
		const processId = nanoid()

		addProcess({
			id: processId,
			type: processType,
			projectId: projectId ?? null,
			portfolioId: portfolioId ?? null,
		})

		// Set up loading groups
		addLoadingGroup(processId, projectName ?? portfolioName, processType)
		for (const documentId of documentIds) {
			addLoadingGroupItem(
				processId,
				processType,
				documentId,
				StatusType.PROCESSING,
				"",
				getDocumentName(documentId),
			)
		}
		try {
			// Update references' temporary frontend state to processing
			const reprocessResponse: any = await postReprocessRequest(
				portfolioId || null,
				projectId ? [projectId] : null,
				documentIds,
				{
					chart: true,
					reparse: reparse,
					citation_policy: citationPolicy,
					claims_in_charting: false,
				},
			)

			const documents = reprocessResponse.documents

			for (const document of documents) {
				updateLoadingGroupItem(
					processId,
					processType,
					document.id,
					docStatusMapping[document.status],
					"",
				)
			}
			// Replace refetchReferences with invalidateReferences
			await invalidateReferences()
			removeProcess(processId)
			return {
				success: true,
				data: reprocessResponse.data,
				status: reprocessResponse.status,
			}
		} catch (error) {
			removeProcess(processId)
			return handleError(error, "Failed to add references to the project")
		}
	}

	// This is a helper function, please use exported functions reprocessDocuments, processProjectCreation
	const postReprocessRequest = async (
		portfolioId: string | null,
		projectIds: string[] | null,
		documentIds: string[],
		settings: ProcessSettings,
	) => {
		return await api.reprocessDocuments(
			portfolioId || null,
			projectIds || null,
			documentIds,
			settings,
		)
	}

	const mapUnprocessedDocumentTypeToDocumentType = (
		inputType: UnprocessedDocumentType,
	): DocumentType => {
		switch (inputType) {
			case UnprocessedDocumentType.PUBLISHED_PATENT:
			case UnprocessedDocumentType.UNPUBLISHED_PATENT:
				return DocumentType.PATENT
			case UnprocessedDocumentType.STANDARD:
				return DocumentType.STANDARD
			case UnprocessedDocumentType.REFERENCE:
				return DocumentType.REFERENCE
			case UnprocessedDocumentType.OFFICE_ACTION:
				return DocumentType.OFFICE_ACTION
		}
	}

	const convertToDocumentsToProcess = useCallback(
		async (documents: UnprocessedDocument[]): Promise<DocumentToProcess[]> => {
			const processedDocs = await Promise.all(
				documents.map(async (doc) => {
					// Create base document with required fields
					const baseDocument: DocumentToProcess = {
						doc_type: mapUnprocessedDocumentTypeToDocumentType(doc.type),
						doc_role: doc.role as DocumentRole,
					}

					// Add additional fields based on document type
					switch (doc.type) {
						case UnprocessedDocumentType.PUBLISHED_PATENT:
							return {
								...baseDocument,
								publication_number: doc.patentDetails?.number,
							}

						case UnprocessedDocumentType.UNPUBLISHED_PATENT:
							return {
								...baseDocument,
								unpublished_patent: {
									...doc.unpublishedPatent,
									claims: doc.unpublishedPatent.claims.map((claim) => ({
										text: claim,
									})),
								},
							}

						case UnprocessedDocumentType.STANDARD:
						case UnprocessedDocumentType.REFERENCE:
						case UnprocessedDocumentType.OFFICE_ACTION:
							if (doc.file) {
								// Get signed URL and upload to S3
								const fileKeys = await getUploadedFileKeysAndUrls([doc.file])
								const s3Key = fileKeys[doc.file.name].s3_key

								return {
									...baseDocument,
									s3_key: s3Key,
									file_name: doc.file.name,
								}
							}
							return baseDocument

						default:
							return baseDocument
					}
				}),
			)

			return processedDocs as DocumentToProcess[]
		},
		[mapUnprocessedDocumentTypeToDocumentType, getUploadedFileKeysAndUrls],
	)

	return {
		processDocuments,
		reprocessDocuments,
		convertToDocumentsToProcess,
	}
}

export default useProcessDocuments
