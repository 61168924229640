/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { api } from "@/api"
import { useApi } from "@/hooks"
import type { PatentNumberOptions } from "@/types"

const usePatentDetails = () => {
	const { handleError } = useApi()
	/**
	 * @description Gets the details for a patent or application
	 * @param {string[]} patentNumbers - The numbers of the patents to get details for
	 * @param {boolean} isEarlyApplication - Flag for early applications
	 * @returns Result object with success status and data or error message.
	 */
	const getPatentDetails = async (
		patentNumbers: string[],
		isEarlyApplication = false,
	): Promise<any> => {
		try {
			const details: any = await api.getPatentDetails(patentNumbers, isEarlyApplication)

			// TODO set this on BE in response?
			details.forEach((patent: PatentNumberOptions) => {
				if (patent.details) {
					const kindCodes = Object.keys(patent.details)
					if (kindCodes.length > 0) {
						patent.selectedKindCode = kindCodes[0]
					}
				}
			})

			return details
		} catch (error) {
			return handleError(error, "Error fetching patent details")
		}
	}

	return {
		getPatentDetails,
	}
}

export default usePatentDetails
