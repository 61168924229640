/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DocumentViewer, Loader } from "@/components"
import { Button } from "@/components/ui/button"
import { LoadingButton } from "@/components/ui/loading-button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { H4 } from "@/components/ui/typography"
import useCoverPleading from "@/features/cover/hooks/useCoverPleading"
import { useProject } from "@/hooks"
import { ParentType } from "@/types"
import { PageName } from "@/types/pages"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import ProjectPage from "../../components/projects/ProjectPage"
import DocumentSummary from "./components/DocumentSummary"

const CoverPleadingPage: React.FC = () => {
	const {
		projectId,
		project,
		allDocumentIds,
		projectPriorArtDocuments,
		projectSubject,
		isLoading,
	} = useProject()
	const { analyzeSection112, getProjectSummaryExport, generateSummaries } =
		useCoverPleading()

	const [downloadIsLoading, setDownloadIsLoading] = useState(false)
	const [selectedView, _setSelectedView] = useState<"references" | "invalidity">(
		"references",
	)
	const [showDocumentViewer, setShowDocumentViewer] = useState<boolean>(false)
	const [documentViewerDocument, setDocumentViewerDocument] = useState<any>(null)
	const [documentViewerCitationText, setDocumentViewerCitationText] = useState<
		string | null
	>(null)
	const [isGeneratingSummaries, setIsGeneratingSummaries] = useState<boolean>(false)
	const [isAnalyzingSection112, setIsAnalyzingSection112] = useState(false)

	const navigate = useNavigate()

	const handleDownloadCoverPleading = async () => {
		setDownloadIsLoading(true)
		await getProjectSummaryExport(projectId)
		setDownloadIsLoading(false)
	}

	const handleGenerateSummaries = async () => {
		setIsGeneratingSummaries(true)
		await generateSummaries({
			documentIds: allDocumentIds,
			id: projectId,
			parent: ParentType.PROJECT,
			temperature: 0.0,
		})
		setIsGeneratingSummaries(false)
	}

	const handleAnalyzeSection112 = async () => {
		setIsAnalyzingSection112(true)
		await analyzeSection112(projectId)
		setIsAnalyzingSection112(false)
	}

	// const section112Types = [
	//   { type: "indefiniteness", claims: projectSubject?.document?.indefiniteness },
	//   { type: "enablement", claims: projectSubject?.document?.enablement },
	//   {
	//     type: "written_description",
	//     claims: projectSubject?.document?.writtenDescription,
	//   },
	// ] as const

	// const allSection112TablesEmpty = section112Types.every(
	//   ({ claims }) => !claims || claims.length === 0,
	// )

	return (
		<ProjectPage pageName={PageName.ProjectCover}>
			{isLoading.project || isLoading.projectReferences ? (
				<div className="mt-10">
					<Loader />
				</div>
			) : (
				<div className="flex flex-col">
					{projectPriorArtDocuments && projectPriorArtDocuments.length > 0 ? (
						<>
							<div className="border-b p-2 flex justify-between items-center">
								{/* <div className="flex items-center gap-4">
                  <Select
                    value={selectedView}
                    onValueChange={(value: "references" | "invalidity") =>
                      setSelectedView(value)
                    }
                  >
                    <SelectTrigger className="w-[200px] h-9">
                      <SelectValue placeholder="Select view" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="references">Reference Summaries</SelectItem>
                      <SelectItem value="invalidity">Invalidity Under § 112</SelectItem>
                    </SelectContent>
                  </Select>
                </div> */}

								<div className="flex gap-2">
									{selectedView === "invalidity" && (
										<LoadingButton
											className="h-9"
											variant="outline"
											onClick={() => handleAnalyzeSection112()}
											loading={isAnalyzingSection112}
										>
											{isAnalyzingSection112 ? "Analyzing..." : "Analyze"}
										</LoadingButton>
									)}
									{selectedView === "references" && (
										<LoadingButton
											className="h-9"
											variant="outline"
											onClick={() => handleGenerateSummaries()}
											loading={isGeneratingSummaries}
										>
											{isGeneratingSummaries ? "Generating..." : "Generate Summaries"}
										</LoadingButton>
									)}
									{/* {project?.summaries &&
                    project?.indefiniteness &&
                    project?.enablement &&
                    project?.writtenDescription && (
                      <LoadingButton
                        className="h-9"
                        onClick={() => handleDownloadCoverPleading()}
                        loading={downloadIsLoading}
                      >
                        {downloadIsLoading ? "Downloading..." : "Download"}
                      </LoadingButton>
                    )} */}
								</div>
							</div>

							<div className="flex-1">
								<ScrollArea className="h-[calc(100vh-105px)]">
									{selectedView === "references" ? (
										isGeneratingSummaries ? (
											<div className="flex justify-center items-center h-full">
												<Loader />
											</div>
										) : (
											<div className="grid grid-cols-1 gap-2 p-2">
												{projectPriorArtDocuments.map((parentToDocument) => (
													<DocumentSummary
														key={parentToDocument.documentId}
														parentToDocument={parentToDocument}
														parentType={ParentType.PROJECT}
													/>
												))}
											</div>
										)
									) : (
										<div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-2 items-start">
											{/* {!allSection112TablesEmpty &&
                        section112Types.map(({ type, claims }) => (
                          <Section112Table key={type} type={type} claims={claims} />
                        ))} */}
										</div>
									)}
								</ScrollArea>
							</div>
						</>
					) : (
						<div className="flex justify-center items-center flex-col mt-12">
							<H4>This project has no references.</H4>
							<Button
								className="mt-3"
								variant="outline"
								onClick={() => navigate(`/project/${projectId}/references`)}
							>
								Add references
							</Button>
						</div>
					)}
				</div>
			)}
			<DocumentViewer
				open={showDocumentViewer}
				handleClose={() => {
					setShowDocumentViewer(false)
					setDocumentViewerDocument(null)
					setDocumentViewerCitationText(null)
				}}
				documentId={documentViewerDocument?.id}
				startInChartMode={false}
			/>
		</ProjectPage>
	)
}

export default CoverPleadingPage
