import { Button } from "@/components/ui/button"
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable"
import { H4 } from "@/components/ui/typography"
import {
	type Claim,
	DocumentType,
	type PortfolioDocument,
	type ProjectDocument,
} from "@/types"
import type React from "react"
import { useEffect, useMemo, useState } from "react"
import ClaimList from "../claims/ClaimList"
import PdfViewer from "../pdf/PdfDisplay"
import PatentAbstract from "./patents/PatentAbstract"
import PatentMetadata from "./patents/PatentMetadata"
import PatentNumberDisplay from "./patents/PatentNumberDisplay"
import DocumentDates from "./sections/DocumentDates"
import Figures from "./sections/Figures"
import DocumentBody from "./sections/body/DocumentBody"

interface DocumentDetailLayoutProps {
	document: ProjectDocument | PortfolioDocument
	startDisplayPDF?: boolean
	onChangePDFVisibility?: (visible: boolean) => void
	claims?: Claim[]
	citationText?: string
	selectedDocumentChunkId?: string
	highlightedRef?: React.RefObject<HTMLDivElement | HTMLTableRowElement>
}

const DocumentDetailLayout: React.FC<DocumentDetailLayoutProps> = ({
	document,
	startDisplayPDF = false,
	onChangePDFVisibility,
	claims,
	citationText,
	selectedDocumentChunkId,
	highlightedRef,
}) => {
	const doc = document.document

	const isPatent = useMemo(() => {
		return doc.type === DocumentType.PATENT
	}, [doc.type])

	const [showPdf, setShowPdf] = useState<boolean>(startDisplayPDF || !isPatent)
	const [leftPanelSize, setLeftPanelSize] = useState<number>(50)
	const [selectedChunkId, setSelectedChunkId] = useState<string | null>(
		selectedDocumentChunkId,
	)
	const [isEditing, setIsEditing] = useState<boolean>(false)

	const effectiveClaims = useMemo(() => {
		return isPatent ? claims || doc.patent.claims : []
	}, [isPatent, claims, doc.patent.claims])

	const figureUrls = useMemo(() => {
		return doc.type === DocumentType.PATENT
			? doc.patent.figureUrls
			: doc.reference?.figureUrls || []
	}, [doc.type, doc.patent.figureUrls, doc.reference?.figureUrls])

	function handleTogglePDF() {
		const toggleValue = !showPdf
		setShowPdf(toggleValue)
		setSelectedChunkId(null)
		if (onChangePDFVisibility) {
			onChangePDFVisibility(toggleValue)
		}
	}

	const documentType = doc.type

	const isClaimHighlighted = useMemo(() => {
		if (doc.type !== DocumentType.PATENT) {
			return false
		}
		return effectiveClaims?.some((claim) => {
			const claimText = Object.values(claim)[0] as string
			return claimText?.slice(0, 75) === citationText?.slice(0, 75)
		})
	}, [doc.type, effectiveClaims, citationText])

	// Memoize the PDF URL as well
	const pdfUrl = useMemo(() => {
		return (
			(documentType === DocumentType.PATENT && document?.document?.patent?.pdfUrl) ||
			document?.url ||
			undefined
		)
	}, [documentType, document])

	useEffect(() => {
		const checkPdfAccess = async () => {
			if (pdfUrl) {
				try {
					const response = await fetch(pdfUrl)
					if (!response.ok) {
						setShowPdf(false)
						setLeftPanelSize(100)
					}
				} catch (error) {
					console.error("Error checking PDF access:", error)
					setShowPdf(false)
					setLeftPanelSize(100)
				}
			}
		}

		checkPdfAccess()
	}, [pdfUrl])

	const handleChunkSelect = (chunkId: string | null) => {
		setSelectedChunkId(chunkId)
	}

	const handleEdit = () => {
		setIsEditing(!isEditing)
	}

	const handleSave = () => {
		setIsEditing(false)
	}

	const renderLeftPanel = () => (
		<div className="custom-scrollbar px-3 py-2 h-full overflow-y-auto overflow-x-hidden">
			<div className="flex justify-between mb-2 w-full">
				{doc?.title ? <H4 className="mb-4 break-words">{doc.title}</H4> : null}
				{doc?.patent?.number && <PatentNumberDisplay finalIdString={doc.patent.number} />}
			</div>

			{doc.type === DocumentType.PATENT && <PatentAbstract patent={doc.patent} />}
			<Figures figureUrls={figureUrls} showTitle={true} />

			{isPatent && (
				<DocumentBody
					body={doc.body}
					figureUrls={figureUrls}
					citationText={citationText}
					selectedChunkId={selectedChunkId}
					onChunkSelect={handleChunkSelect}
					editMode={isEditing}
					documentId={doc.id}
				/>
			)}
		</div>
	)

	const renderRightPanel = () => (
		<div>
			{!showPdf && (
				<div className="flex justify-end p-2 gap-2">
					<Button variant="outline" size="sm" onClick={isEditing ? handleSave : handleEdit}>
						{isEditing ? "Done" : "Edit"}
					</Button>
					<Button variant="outline" size="sm" onClick={handleTogglePDF}>
						Show PDF
					</Button>
				</div>
			)}

			{showPdf ? (
				<PdfViewer
					url={pdfUrl}
					documentChunks={doc.body}
					onHidePdf={handleTogglePDF}
					onChunkSelect={handleChunkSelect}
					selectedChunkId={selectedChunkId}
				/>
			) : (
				<div className="custom-scrollbar px-3 py-2 h-full overflow-y-auto">
					<PatentMetadata patent={doc.patent} />
					<DocumentDates
						publicationDate={doc.patent.publicationDate}
						priorityDate={doc.patent.priorityDate}
						enableEditPriorityDate={true}
						documentType={documentType}
					/>
					{isPatent && effectiveClaims.length > 0 && (
						<ClaimList list={effectiveClaims} startCollapsed={false} editingBool={true} />
					)}
				</div>
			)}
		</div>
	)

	const renderSinglePanel = () => (
		<div className="custom-scrollbar px-3 py-2 h-full overflow-y-auto overflow-x-hidden">
			<div className="flex justify-between mb-2 w-full flex-wrap">
				{doc?.title ? <H4 className="break-words">{doc.title}</H4> : null}
				{doc?.patent?.number && <PatentNumberDisplay finalIdString={doc.patent.number} />}

				<div className="flex justify-end p-2 gap-2">
					<Button variant="outline" size="sm" onClick={isEditing ? handleSave : handleEdit}>
						{isEditing ? "Done" : "Edit"}
					</Button>
					<Button variant="outline" size="sm" onClick={handleTogglePDF}>
						Show PDF
					</Button>
				</div>
			</div>

			<div className="mb-4">
				<>
					<DocumentDates
						publicationDate={doc.patent.publicationDate}
						priorityDate={doc.patent.priorityDate}
						enableEditPriorityDate={true}
						documentType={documentType}
					/>

					{doc.type === DocumentType.PATENT ? (
						<>
							<PatentMetadata patent={doc.patent} />
							<PatentAbstract patent={doc.patent} />
							<Figures figureUrls={figureUrls} showTitle={true} />
							<ClaimList
								list={effectiveClaims}
								startCollapsed={false}
								editingBool={true}
								highlightedRef={
									isClaimHighlighted
										? (highlightedRef as React.RefObject<HTMLTableRowElement>)
										: undefined
								}
							/>
						</>
					) : (
						<Figures figureUrls={figureUrls} showTitle={true} />
					)}
				</>
			</div>
			<div className="mt-4">
				<DocumentBody
					body={doc.body}
					figureUrls={figureUrls}
					citationText={citationText}
					selectedChunkId={selectedChunkId}
					onChunkSelect={handleChunkSelect}
					editMode={isEditing}
					documentId={doc.id}
				/>
			</div>
		</div>
	)

	return (
		<div className="h-full w-full overflow-hidden">
			{/* Large screen layout */}
			<div className="hidden lg:block h-[calc(100vh-55px)] overflow-hidden">
				<ResizablePanelGroup direction="horizontal" className="h-full">
					<ResizablePanel defaultSize={leftPanelSize} onResize={setLeftPanelSize}>
						{showPdf ? renderSinglePanel() : renderLeftPanel()}
					</ResizablePanel>

					<ResizableHandle withHandle />

					<ResizablePanel defaultSize={100 - leftPanelSize}>
						{renderRightPanel()}
					</ResizablePanel>
				</ResizablePanelGroup>
			</div>

			{/* Small screen layout */}
			<div className="lg:hidden h-[calc(100vh-55px)] overflow-hidden">
				{showPdf ? (
					<PdfViewer
						url={pdfUrl}
						documentChunks={doc.body}
						onHidePdf={handleTogglePDF}
						onChunkSelect={handleChunkSelect}
						selectedChunkId={selectedChunkId}
					/>
				) : (
					<>{renderSinglePanel()}</>
				)}
			</div>
		</div>
	)
}

export default DocumentDetailLayout
