import { DocumentViewer, Figure, ImageContainer } from "@/components"
import { Button } from "@/components/ui/button"
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { CondensedParagraph } from "@/components/ui/typography"
import { DeleteCitationFromAllElementsModal } from "@/features/charts/components"
import { ColorPopover } from "@/features/charts/components/ColorPopover"
import { useInvalidityChart } from "@/features/charts/hooks"
import { useDocumentChunk } from "@/hooks"
import { cn } from "@/lib/utils"
import {
	BlockType,
	type ChunkLocation,
	type DocumentChunk,
	DocumentChunkMode,
} from "@/types"
import { parseHtml } from "@/utils/parseHtml"
import { formatDocumentChunkLocation } from "@/utils/projectUtils"
import { Pencil, Plus, Trash2, X } from "lucide-react"
import type React from "react"
import { useMemo, useState } from "react"
import { EditDocumentChunkDialog } from "./EditDocumentChunkDialog"

interface DocumentChunkProps {
	chunk: DocumentChunk
	citationText?: string
	highlightedRef?: React.RefObject<HTMLDivElement>
	isTextHighlighted?: boolean
	selectedChunkId?: string
	selectedLimitationId?: string
	onChunkSelect?: (id: string) => void
	onChunkUpdate?: (updatedChunk: DocumentChunk) => void
	figureUrls?: string[]
	editMode?: boolean
	onDeleteChunk?: (id: string) => void
	chartMode?: boolean
	chartColor?: string
	onAddCitation?: (chunk: DocumentChunk) => void
	onRemoveCitation?: (chunk: DocumentChunk) => void
	onUpdateCitationColor?: (chunk: DocumentChunk, newColor: string) => void
	showFigures?: boolean
	mode: DocumentChunkMode
}

export const DocumentChunkComponent: React.FC<DocumentChunkProps> = ({
	chunk,
	citationText,
	highlightedRef,
	isTextHighlighted,
	selectedChunkId,
	selectedLimitationId,
	onChunkSelect,
	onChunkUpdate,
	figureUrls,
	editMode,
	onDeleteChunk,

	// Chart mode
	chartColor,
	onAddCitation,
	onRemoveCitation,
	onUpdateCitationColor,
	showFigures = false,
	mode,
}) => {
	const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
	const [showDeleteCitationFromAllModal, setShowDeleteCitationFromAllModal] =
		useState(false)
	const [showDocumentViewer, setShowDocumentViewer] = useState(false)
	const { updateDocumentChunk } = useDocumentChunk()
	const { toggleRemoveAllDocumentChunkCitations } = useInvalidityChart()

	const isCited = useMemo(() => {
		if (!chartColor) return false
		return chartColor !== null
	}, [chartColor])

	const handleColorChange = (newColor: string) => {
		onUpdateCitationColor?.(chunk, newColor)
	}

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text)
	}

	const handleDeleteCitationFromAll = async () => {
		await toggleRemoveAllDocumentChunkCitations(chunk.id)
	}

	const renderContent = () => {
		switch (chunk.type) {
			case BlockType.TITLE:
				return null
			// case BlockType.FIGURE:
			// 	return <Figures figureUrls={chunk.figureUrls} />
			case BlockType.LIST_ITEM:
				return <li>{chunk.text}</li>
			case BlockType.TABLE:
				return (
					<div className="overflow-x-auto">{chunk.html ? parseHtml(chunk.html) : null}</div>
				)
			case BlockType.HEADER:
				return <h4 className="text-lg font-semibold">{chunk.text}</h4>
			case BlockType.SECTION_HEADER:
				return <h3 className="text-lg font-semibold">{chunk.text}</h3>
			default:
				return (
					<>
						{mode === DocumentChunkMode.CITATION && (
							<div className="text-sm text-gray-500 mb-1">
								{formatDocumentChunkLocation(chunk.location)}
							</div>
						)}
						<div className="flex gap-2">
							{mode !== DocumentChunkMode.CITATION && (
								<div className="w-[75px] shrink-0 text-sm text-gray-500">
									{formatDocumentChunkLocation(chunk.location)}
								</div>
							)}

							<div
								className={cn("flex-1")}
								// style={{ backgroundColor: isCited ? chartColor : undefined }}
							>
								{chunk?.html ? (
									<div className="overflow-x-auto">
										{parseHtml(chunk.html, {}, citationText)}
									</div>
								) : (
									<div>
										{citationText
											? chunk.text
													.split(
														new RegExp(citationText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i"),
													)
													.map((part, i) =>
														part.toLowerCase() === citationText?.toLowerCase() ? (
															<span key={i} className="bg-blue-100">
																{part}
															</span>
														) : (
															part
														),
													)
											: chunk.text}
									</div>
								)}
							</div>
						</div>
					</>
				)
		}
	}

	const textWithCitation = `${chunk.text}\n\n${formatDocumentChunkLocation(chunk.location)}`

	return (
		<>
			<ContextMenu>
				<ContextMenuTrigger>
					<div
						ref={isTextHighlighted || selectedChunkId === chunk.id ? highlightedRef : null}
						data-chunk-id={chunk.id}
						className={`${selectedChunkId === chunk.id ? "bg-blue-50" : ""} group flex items-center hover:cursor-pointer hover:bg-gray-50 mb-2`}
						onClick={!editMode ? () => onChunkSelect?.(chunk.id) : undefined}
					>
						<CondensedParagraph className={"flex-1"}>{renderContent()}</CondensedParagraph>
						{editMode && (
							<>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												variant="ghost"
												size="icon"
												onClick={() => setIsEditDialogOpen(true)}
											>
												<Pencil className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent>Edit section</TooltipContent>
									</Tooltip>
								</TooltipProvider>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												variant="ghost"
												size="icon"
												onClick={() => onDeleteChunk?.(chunk.id)}
											>
												<Trash2 className="h-4 w-4" />
											</Button>
										</TooltipTrigger>
										<TooltipContent>Delete section</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</>
						)}

						{mode === DocumentChunkMode.CHART &&
							(isCited ? (
								<div className="flex flex-col gap-1 items-center mr-2">
									<ColorPopover
										currentColor={chartColor}
										onColorChange={handleColorChange}
										buttonStyle={{ marginBottom: "0.5rem" }}
									/>
									<Button variant="ghost" size="icon" onClick={() => onRemoveCitation?.(chunk)}>
										<X className="h-4 w-4" />
									</Button>
								</div>
							) : (
								<Button variant="ghost" size="icon" onClick={() => onAddCitation?.(chunk)}>
									<Plus className="h-4 w-4" />
								</Button>
							))}
					</div>

					{showFigures &&
						chunk.figureUrls?.map((imageUrl) => (
							<ImageContainer key={imageUrl}>
								<Figure imageUrl={imageUrl} imageName={imageUrl.split("/").pop() || ""} />
							</ImageContainer>
						))}
				</ContextMenuTrigger>
				<ContextMenuContent>
					<ContextMenuItem onClick={() => copyToClipboard(chunk.text)}>Copy</ContextMenuItem>
					<ContextMenuItem onClick={() => copyToClipboard(textWithCitation)}>
						Copy with Citation
					</ContextMenuItem>
					<ContextMenuItem onClick={() => setIsEditDialogOpen(true)}>Edit</ContextMenuItem>
					<ContextMenuItem onClick={() => setShowDeleteCitationFromAllModal(true)}>
						Remove all instances
					</ContextMenuItem>
					<ContextMenuItem onClick={() => setShowDocumentViewer(true)}>
						View in document
					</ContextMenuItem>
				</ContextMenuContent>
			</ContextMenu>

			<EditDocumentChunkDialog
				chunk={chunk}
				open={isEditDialogOpen}
				onOpenChange={setIsEditDialogOpen}
				figureUrls={figureUrls}
				onSave={async (updatedChunk) => {
					const updates: {
						documentId: string
						documentChunkId: string
						text?: string
						location?: ChunkLocation
						figureUrls?: string[]
					} = {
						documentChunkId: updatedChunk.id,
						documentId: updatedChunk.documentId,
					}

					if (updatedChunk.text !== chunk.text) {
						updates.text = updatedChunk.text
					}
					if (JSON.stringify(updatedChunk.location) !== JSON.stringify(chunk.location)) {
						updates.location = updatedChunk.location
					}
					if (updatedChunk.figureUrls !== chunk.figureUrls) {
						updates.figureUrls = updatedChunk.figureUrls
					}

					await updateDocumentChunk(updates)
					onChunkUpdate?.(updatedChunk)
					setIsEditDialogOpen(false)
				}}
			/>

			<DeleteCitationFromAllElementsModal
				open={showDeleteCitationFromAllModal}
				handleClose={() => setShowDeleteCitationFromAllModal(false)}
				handleDelete={handleDeleteCitationFromAll}
				chunk={chunk}
			/>

			{showDocumentViewer && (
				<DocumentViewer
					open={showDocumentViewer}
					handleClose={() => setShowDocumentViewer(false)}
					documentId={chunk.documentId}
					selectedDocumentChunkId={chunk.id}
					selectedLimitationId={selectedLimitationId}
					startInChartMode={true}
				/>
			)}
		</>
	)
}
