import { DocumentViewer, Loader } from "@/components"
import { LoadingButton } from "@/components/ui/loading-button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { usePortfolio } from "@/hooks"
import { ParentType } from "@/types"
import { PageName } from "@/types/pages"
import type React from "react"
import { useState } from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import useCoverPleading from "./hooks/useCoverPleading"

const PortfolioCoverPleadingPage: React.FC = () => {
	const {
		portfolioId,
		portfolioName,
		portfolio,
		portfolioReferenceDocuments,
		isLoading,
	} = usePortfolio()
	const { analyzeSection112, getPortfolioSummaryExport, generateSummaries } =
		useCoverPleading()
	const [downloadIsLoading, setDownloadIsLoading] = useState(false)
	const [selectedView, _setSelectedView] = useState<"references" | "invalidity">(
		"references",
	)
	const [isGeneratingSummaries, setIsGeneratingSummaries] = useState(false)
	const [isAnalyzingSection112, setIsAnalyzingSection112] = useState(false)

	// Page name
	const pageName = portfolioName ? `${portfolioName} - Summary` : "Summary"

	const handleDownloadCoverPleading = async () => {
		setDownloadIsLoading(true)
		await getPortfolioSummaryExport(portfolioId)
		setDownloadIsLoading(false)
	}

	// Generate for all subjects and references
	const handleGenerateSummaries = async () => {
		setIsGeneratingSummaries(true)
		const allIds = [
			...portfolio.projects.map((p) => p.subjectId),
			// ...portfolioReferences.map((r) => r.documentId),
		].filter(Boolean) as string[]
		await generateSummaries({
			documentIds: allIds,
			id: portfolioId,
			parent: ParentType.PORTFOLIO,
			temperature: 0.0,
		})

		setIsGeneratingSummaries(false)
	}

	const [showDocumentViewer, setShowDocumentViewer] = useState<boolean>(false)
	const [documentViewerDocument, setDocumentViewerDocument] = useState<any>(null)
	const [documentViewerCitationText, setDocumentViewerCitationText] = useState<
		string | null
	>(null)

	const handleAnalyzeSection112 = async () => {
		setIsAnalyzingSection112(true)
		await analyzeSection112(portfolioId)
		setIsAnalyzingSection112(false)
	}

	// const section112Types = [
	//   { type: "indefiniteness", claims: portfolio.indefiniteness },
	//   { type: "enablement", claims: portfolio.enablement },
	//   {
	//     type: "written_description",
	//     claims: portfolio.writtenDescription,
	//   },
	// ] as const

	// const allSection112TablesEmpty = section112Types.every(
	//   ({ claims }) => !claims || claims.length === 0,
	// )

	return (
		<ProjectPage pageName={PageName.PortfolioCover}>
			{isLoading.portfolio || isLoading.portfolioDocuments ? (
				<div className="mt-10">
					<Loader />
				</div>
			) : (
				<div className="flex flex-col">
					{/* {portfolioReferences && portfolioReferences.length > 0 ? ( */}
					<>
						<div className="border-b p-2 flex justify-between items-center">
							<div className="flex gap-2">
								{selectedView === "invalidity" && (
									<LoadingButton
										className="h-9"
										variant="outline"
										onClick={handleAnalyzeSection112}
										loading={isAnalyzingSection112}
									>
										{isAnalyzingSection112 ? "Analyzing..." : "Analyze"}
									</LoadingButton>
								)}
								{selectedView === "references" && (
									<LoadingButton
										className="h-9"
										variant="outline"
										onClick={handleGenerateSummaries}
										loading={isGeneratingSummaries}
									>
										{isGeneratingSummaries ? "Generating..." : "Generate Summaries"}
									</LoadingButton>
								)}
								{/* {portfolio.summaries && (
                    <LoadingButton
                      className="h-9"
                      onClick={handleDownloadCoverPleading}
                      loading={downloadIsLoading}
                    >
                      {downloadIsLoading ? "Downloading..." : "Download"}
                    </LoadingButton>
                  )} */}
							</div>
						</div>

						<div className="flex-1">
							<ScrollArea className="h-[calc(100vh-105px)]">
								{selectedView === "references" ? (
									isGeneratingSummaries ? (
										<div className="flex justify-center items-center h-full">
											<Loader />
										</div>
									) : (
										<div className="grid grid-cols-1 gap-2 p-2">
											{/* {Object.entries(portfolio.summaries || {}).map(
                          ([id, summary]) => (
                            <DocumentSummary
                              key={id}
                              documentId={id}
                              summary={summary}
                              parentType={ParentType.PORTFOLIO}
                            />
                          ),
                        )} */}
										</div>
									)
								) : (
									<div className="grid grid-cols-1 lg:grid-cols-3 gap-4 p-2 items-start">
										{/* {!allSection112TablesEmpty &&
                        section112Types.map(({ type, claims }) => (
                          <Section112Table key={type} type={type} claims={claims} />
                        ))} */}
									</div>
								)}
							</ScrollArea>
						</div>
					</>
					{/* ) : ( */}
					{/* <div className="flex-1">
              <ScrollArea className="h-[calc(100vh-105px)]">
                <div className="grid grid-cols-1 gap-2 p-2">
                  {/* {Object.entries(portfolio.summaries || {}).map(([id, summary]) => (
                    <DocumentSummary
                      key={id}
                      documentId={id}
                      summary={summary}
                      parentType={ParentType.PORTFOLIO}
                    />
                  ))} */}

					{/* </ScrollArea>
            </div>
          )} */}
				</div>
			)}
			<DocumentViewer
				open={showDocumentViewer}
				handleClose={() => {
					setShowDocumentViewer(false)
					setDocumentViewerDocument(null)
					setDocumentViewerCitationText(null)
				}}
				documentId={documentViewerDocument?.id}
				startInChartMode={false}
			/>
		</ProjectPage>
	)
}

export default PortfolioCoverPleadingPage
