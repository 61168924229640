import { apiClient } from "./client"

export const coverApi = {
	async getInvaliditySummaryExport(projectId: string) {
		const payload = {
			project_id: projectId,
		}
		return await apiClient.get("get_invalidity_summary_export", payload)
	},

	async getPortfolioSummaryExport(portfolioId: string) {
		const payload = {
			portfolio_id: portfolioId,
		}
		return await apiClient.get("get_portfolio_summary_export", payload)
	},
}
