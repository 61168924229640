/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */
import { Loader } from "@/components"
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { cn } from "@/lib/utils"
import { ChartColor, type InvaliditySummaryRow } from "@/types"
import React, { useEffect, useMemo, useState } from "react"

/**
 * The props interface, so we can pass data in from the parent
 */
export interface SummaryTableProps {
	data: InvaliditySummaryRow[]
	summaryChartHeaders: string[]
	summaryChartRowHeightCollapsed: number
	selectedReferences: any[]
	handleSelect: (claimLimitationId: string | null, documentId: string) => void
	handleRemoveReferenceFromProject: (referenceId: string) => void
	isExpanded: boolean
	hideEmptyRows: boolean
	hideNonAsserted: boolean
}

interface SummaryTableHeaderProps {
	isExpanded: boolean
	summaryChartHeaders: string[]
	selectedReferences: any[]
	handleSelect: (claimLimitationId: string | null, documentId: string) => void
	handleRemoveReferenceFromProject: (referenceId: string) => void
}

interface SummaryTableRowProps {
	row: InvaliditySummaryRow
	originalIndex: number
	isExpanded: boolean
	summaryChartHeaders: string[]
	summaryChartRowNames: string[] // array of claim numbers
	summaryChartRowHeightCollapsed: number
	heightMultiplier: number
	handleSelect: (claimLimitationId: string | null, documentId: string) => void
}

/**
 * Main SummaryTable component
 */
export const SummaryTable: React.FC<SummaryTableProps> = ({
	data,
	summaryChartHeaders,
	summaryChartRowHeightCollapsed,
	selectedReferences,
	handleSelect,
	handleRemoveReferenceFromProject,
	isExpanded,
	hideEmptyRows,
	hideNonAsserted,
}) => {
	const [isLoading, setIsLoading] = useState(true)
	const { getDocumentName } = useDocumentNaming()

	const summaryChartRowNames = useMemo(
		() => data.map((row) => row.limitationNumber),
		[data],
	)

	const claimNumberToIndexMap = useMemo(() => {
		const map = new Map<string, number>()
		data.forEach((row, index) => {
			map.set(row.limitationNumber, index)
		})
		return map
	}, [data])

	useEffect(() => {
		if (data.length > 0 && summaryChartHeaders.length > 0) {
			setIsLoading(false)
		} else {
			setIsLoading(true)
		}
	}, [data, summaryChartHeaders])

	//  Filter columns that actually exist in getDocumentName
	const filteredHeaders = useMemo(() => {
		return summaryChartHeaders.filter((key) => Boolean(getDocumentName(key)))
	}, [summaryChartHeaders, getDocumentName])

	// Filter out non-asserted claims or empty rows
	const memoizedSummaryChartData = useMemo(() => {
		const initialData = data

		const filteredData = hideEmptyRows
			? initialData.filter((row) =>
					filteredHeaders.some(
						(key) => key !== "claimNumber" && row.documentColors?.[key] !== ChartColor.GRAY,
					),
				)
			: initialData

		const filteredWithIndices = filteredData.map((row) => ({
			row,
			originalIndex: claimNumberToIndexMap.get(row.limitationNumber) ?? -1,
		}))

		const totalOriginalRows = data.length
		const totalVisibleRows = filteredWithIndices.length
		const heightMultiplier =
			totalVisibleRows > 0 ? totalOriginalRows / totalVisibleRows : 1

		return filteredWithIndices.map(({ row, originalIndex }) => (
			<SummaryTableRow
				key={originalIndex}
				row={row}
				originalIndex={originalIndex}
				isExpanded={isExpanded}
				summaryChartHeaders={filteredHeaders}
				summaryChartRowNames={summaryChartRowNames}
				summaryChartRowHeightCollapsed={summaryChartRowHeightCollapsed}
				heightMultiplier={heightMultiplier}
				handleSelect={handleSelect}
			/>
		))
	}, [
		data,
		filteredHeaders,
		hideEmptyRows,
		isExpanded,
		summaryChartRowHeightCollapsed,
		claimNumberToIndexMap,
		summaryChartRowNames,
		handleSelect,
	])

	if (isLoading) {
		return <Loader />
	}

	return (
		<div className="flex flex-col relative">
			<div
				className={cn(
					"overflow-x-auto w-[calc(100vw-130px)]",
					isExpanded ? "h-[50vh] overflow-y-auto" : "h-[220px] overflow-y-hidden",
				)}
			>
				<div>
					<div
						className="grid gap-x-0 bg-background w-full"
						style={{
							gridTemplateColumns: isExpanded
								? `75px repeat(${filteredHeaders.length}, minmax(75px, 1fr))`
								: `repeat(${filteredHeaders.length}, minmax(75px, 1fr))`,
							columnGap: "4px",
						}}
					>
						{/* Header row */}
						<SummaryTableHeader
							isExpanded={isExpanded}
							summaryChartHeaders={filteredHeaders}
							selectedReferences={selectedReferences}
							handleSelect={handleSelect}
							handleRemoveReferenceFromProject={handleRemoveReferenceFromProject}
							getDocumentName={getDocumentName}
						/>

						{/* Data rows */}
						{memoizedSummaryChartData}
					</div>
				</div>
			</div>
		</div>
	)
}

const SummaryTableHeader = React.memo(
	({
		isExpanded,
		summaryChartHeaders,
		selectedReferences,
		handleSelect,
		handleRemoveReferenceFromProject,
		getDocumentName,
	}: SummaryTableHeaderProps & { getDocumentName: (key: string) => string }) => {
		return (
			<>
				{isExpanded && (
					<div className="sticky bg-background left-0 top-0 z-50 w-[75px] pt-2" />
				)}
				{summaryChartHeaders.map((key, idx) => (
					<div
						key={idx}
						className="sticky top-0 z-10 bg-background"
						style={{ minWidth: "75px", flex: "1", padding: "0 2px" }}
					>
						<div className="flex flex-col items-center">
							<ContextMenu>
								<ContextMenuTrigger className="w-full">
									<Tooltip>
										<TooltipTrigger className="w-full focus:outline-none focus:ring-0">
											<div
												className={cn(
													"w-full overflow-hidden bg-background",
													isExpanded ? "m-1" : "m-0.75",
													"cursor-pointer rounded-md mb-2 px-2 py-1 font-medium flex items-center justify-between",
													selectedReferences?.some((detail) => detail.id === key) &&
														"border-blue-500 border-2",
												)}
												onClick={() => handleSelect(null, key)}
											>
												<span className="text-xs truncate mr-2 flex-grow">
													{getDocumentName(key)}
												</span>
											</div>
										</TooltipTrigger>
										<TooltipContent>
											<p>{getDocumentName(key)}</p>
										</TooltipContent>
									</Tooltip>
								</ContextMenuTrigger>
								<ContextMenuContent>
									<ContextMenuItem
										className="text-destructive focus:text-destructive cursor-pointer"
										onClick={() => handleRemoveReferenceFromProject(key)}
									>
										Remove {getDocumentName(key)} from Project
									</ContextMenuItem>
								</ContextMenuContent>
							</ContextMenu>
						</div>
					</div>
				))}
			</>
		)
	},
)

const SummaryTableRow = React.memo(
	({
		row,
		originalIndex,
		isExpanded,
		summaryChartHeaders,
		summaryChartRowNames,
		summaryChartRowHeightCollapsed,
		heightMultiplier,
		handleSelect,
	}: SummaryTableRowProps) => {
		return (
			<div className={`contents m-1 ${isExpanded ? "mb-2" : ""}`}>
				{isExpanded && (
					<div className="sticky left-0 z-50 w-[75px] bg-background align-right truncate">
						{summaryChartRowNames[originalIndex]?.match(/^[\d.]+(?=\s|$)/)?.[0] ||
							summaryChartRowNames[originalIndex]}
					</div>
				)}
				{summaryChartHeaders.map((key, colIndex) => (
					<div
						key={`${colIndex}-${key}`}
						className="px-0.75 bg-background"
						style={{
							minWidth: "75px",
							flex: "1",
							margin: "0",
							padding: "0 2px",
						}}
					>
						<div
							className={`w-full h-full flex items-center justify-center cursor-pointer ${
								isExpanded ? "rounded m-1" : ""
							} transition-colors duration-200`}
							style={{
								backgroundColor: row.documentColors?.[key] || "lightgray",
								height: isExpanded
									? "25px"
									: `${summaryChartRowHeightCollapsed * heightMultiplier}px`,
							}}
							onClick={() =>
								handleSelect(isExpanded ? (row.claimLimitationId as string) : null, key)
							}
						/>
					</div>
				))}
			</div>
		)
	},
)

export default SummaryTable
