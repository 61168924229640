import type {
	ClaimAmendmentResponse,
	ClaimArgumentResponse,
	CommitChatResponseToSessionRequest,
	GenerateClaimAmendmentRequest,
	GenerateClaimArgumentRequest,
	GeneratePatentContextRequest,
	GeneratePriorArtSummariesRequest,
	GetProjectContextRequest,
	ProjectContext,
} from "@/types"
import type { ClaimStatusReason } from "@/types"
import { ParentType } from "@/types"
import type { UpdateProjectContextRequest } from "@/types/request"
import { toSnakeCase } from "@/utils/dataUtils"
import { apiClient } from "./client"

/**
 * API functions for LLM-related operations
 */
export const llmApi = {
	/**
	 * Generate claim amendments
	 */
	generateClaimAmendment: async (
		claimText: string,
		reason: ClaimStatusReason,
		id: string,
	): Promise<ClaimAmendmentResponse> => {
		const payload: GenerateClaimAmendmentRequest = {
			claim_text: claimText,
			reason: {
				type: reason.type,
				examiner_commentary: reason.examinerCommentary,
				reference_citations: toSnakeCase(reason.referenceCitations), // Include citations for argument generation
			},
			application_id: id,
		}
		return await apiClient.post("post_generate_claim_amendment", payload)
	},

	/**
	 * Generate claim arguments
	 */
	generateClaimArgument: async (
		claimText: string,
		reason: ClaimStatusReason,
		id: string,
	): Promise<ClaimArgumentResponse> => {
		const payload: GenerateClaimArgumentRequest = {
			claim_text: claimText,
			reason: {
				type: reason.type,
				examiner_commentary: reason.examinerCommentary,
				reference_citations: toSnakeCase(reason.referenceCitations), // Include citations for argument generation
			},
			application_id: id,
		}
		return await apiClient.post("post_generate_claim_argument", payload)
	},

	commitChatResponseToSession: async (sessionId: string, response: string) => {
		const payload: CommitChatResponseToSessionRequest = {
			session_id: sessionId,
			response: response,
		}
		const res = await apiClient.post("post_commit_response_to_session", payload)
		return res
	},

	/**
	 * Generate reference summaries
	 */
	generatePriorArtSummaries: async (
		documentIds: string[],
		id: string,
		parent: ParentType,
		temperature: number,
	) => {
		const payload: GeneratePriorArtSummariesRequest = {
			document_ids: documentIds,
			temperature,
		}
		if (parent === ParentType.PROJECT) {
			payload.project_id = id
		} else {
			payload.portfolio_id = id
		}
		const response = await apiClient.post("post_generate_prior_art_summaries", payload)
		return response
	},

	/**
	 * Generate patent context from document ID
	 */
	generatePatentContextFromId: async (projectId: string, documentId: string) => {
		const payload: GeneratePatentContextRequest = {
			project_id: projectId,
			document_id: documentId,
		}
		const response = await apiClient.post("post_generate_patent_context", payload)
		return response
	},

	/**
	 * Generate patent context from text
	 */
	generatePatentContextFromText: async (projectId: string, text: string) => {
		const payload: GeneratePatentContextRequest = {
			project_id: projectId,
			text: text,
		}
		const response = await apiClient.post("post_generate_patent_context", payload)
		return response
	},

	/**
	 * Save patent context
	 */
	updateProjectContext: async (projectId: string, context: ProjectContext) => {
		const payload: UpdateProjectContextRequest = {
			project_id: projectId,
			context: context,
		}
		return await apiClient.post("post_update_project_context", payload)
	},

	getProjectContext: async (projectId: string): Promise<ProjectContext> => {
		const payload: GetProjectContextRequest = {
			project_id: projectId,
		}
		return await apiClient.get("get_project_context", payload)
	},
}
