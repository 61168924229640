/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import { Button } from "@/components/ui/button"
import { UnprocessedDocumentType } from "@/types"
import { PencilIcon, PlusIcon, Upload } from "lucide-react"
import { useCreateProjectContext } from "../CreateProjectContext"

/**
 * @description Document upload card component
 * @param {Object} props
 * @param {string} props.documentRole - The role of the document (e.g., SUBJECT, REFERENCE, CONTEXT)
 * @param {string} props.label - The label to display on the card
 */
const DocumentTypeButton = ({ selectedType }) => {
	const { setSelectedDocumentType } = useCreateProjectContext()
	const getButtonContent = () => {
		switch (selectedType) {
			case UnprocessedDocumentType.REFERENCE:
				return {
					label: "Upload Other Documents",
					icon: <Upload className="h-4 w-4 mr-2" />,
				}
			case UnprocessedDocumentType.STANDARD:
				return {
					label: "Upload Standards",
					icon: <Upload className="h-4 w-4 mr-2" />,
				}
			case UnprocessedDocumentType.PUBLISHED_PATENT:
				return {
					label: "Add Published Patent or Application",
					icon: <PlusIcon className="h-4 w-4 mr-2" />,
				}
			case UnprocessedDocumentType.UNPUBLISHED_PATENT:
				return {
					label: "Add Unpublished Patent Application",
					icon: <PencilIcon className="h-4 w-4 mr-2" />,
				}
			default:
				return {
					label: "",
					icon: null,
				}
		}
	}

	const { label, icon } = getButtonContent()

	return (
		<Button
			variant="outline"
			onClick={() => setSelectedDocumentType(selectedType)}
			className="flex-1 min-w-[250px]"
		>
			{icon}
			{label}
		</Button>
	)
}
export default DocumentTypeButton
