import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu"
import { Switch } from "@/components/ui/switch"
import { TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { usePortfolio } from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import {} from "@radix-ui/react-icons"
import type React from "react"
import {} from "react"

interface PortfolioMatrixHeaderProps {
	hideEmptyRows: boolean
	setHideEmptyRows: (hide: boolean) => void
	columnWidths: Record<string, number>
	handleDeleteDocument: (id: string) => void
}

const PortfolioMatrixHeader: React.FC<PortfolioMatrixHeaderProps> = ({
	hideEmptyRows,
	setHideEmptyRows,
	columnWidths,
	handleDeleteDocument,
}) => {
	const { getDocumentPortfolioName } = useDocumentNaming()
	const { portfolioMatrix } = usePortfolio()
	const patentIds = Array.from(
		new Set(portfolioMatrix.flatMap((col) => col.priorArts.map((art) => art.id))),
	)

	return (
		<TableHeader className="sticky top-0 z-10 bg-background">
			<TableRow>
				<TableHead className="sticky left-0 z-20 bg-background w-[100px]">
					<div className="flex items-center gap-2 m-4">
						<Switch
							id="hide-empty-rows"
							checked={hideEmptyRows}
							onCheckedChange={setHideEmptyRows}
						/>
						<span className="text-sm text-muted-foreground whitespace-nowrap">
							Hide empty
						</span>
					</div>
				</TableHead>
				{patentIds.map((patentId) => {
					const priorArt = portfolioMatrix
						.flatMap((col) => col.priorArts)
						.find((art) => art.id === patentId)
					let headerText = priorArt?.nickname || ""
					const width = columnWidths[patentId] || 0
					const needsRotation = headerText.length * 8 > width

					if (needsRotation && headerText) {
						const endsWithNumbers = /'[0-9]+$/.test(headerText)
						if (endsWithNumbers) {
							const [mainText, numberPart] = headerText.split(/'(?=[0-9]+$)/)
							if (mainText.length > 12) {
								headerText = `${mainText.slice(0, 9)}... '${numberPart}`
							}
						} else if (headerText.length > 12) {
							headerText = `${headerText.slice(0, 9)}...`
						}
					}

					return (
						<TableHead
							key={patentId}
							data-patent-id={patentId}
							className="relative h-auto min-w-[45px] pr-4"
						>
							<ContextMenu>
								<ContextMenuTrigger>
									<div
										className="absolute"
										style={{
											transformOrigin: "center left",
											transform: needsRotation ? "rotate(-25deg)" : "none",
											width: needsRotation ? "120px" : "100%",
											whiteSpace: "nowrap",
											top: needsRotation ? "65%" : "auto",
											left: needsRotation ? "50%" : "0",
											textAlign: needsRotation ? "left" : "center",
											padding: needsRotation ? "0" : "8px",
										}}
									>
										{headerText}
									</div>
								</ContextMenuTrigger>
								<ContextMenuContent>
									<ContextMenuItem
										className="text-destructive"
										onClick={() => handleDeleteDocument(patentId)}
									>
										Delete {getDocumentPortfolioName(patentId)} from portfolio
									</ContextMenuItem>
								</ContextMenuContent>
							</ContextMenu>
						</TableHead>
					)
				})}
			</TableRow>
		</TableHeader>
	)
}

export default PortfolioMatrixHeader
