/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { cn } from "@/lib/utils"
import type { CreateProjectStep } from "@/types"
import React from "react"

interface CreateStepBreadcrumbProps {
	steps: Array<{
		key: CreateProjectStep
		label: string
	}>
	currentStep: CreateProjectStep
	onStepClick: (step: CreateProjectStep) => void
	isStepComplete: (step: CreateProjectStep) => boolean
}

/**
 * @description Component for displaying the steps of the create project flow
 * @param {Object} props
 * @param {Array<{key: CreateProjectStep, label: string}>} props.steps - The steps to display in the breadcrumb
 * @param {CreateProjectStep} props.currentStep - The current step
 * @param {Function} props.onStepClick - The function to call when a step is clicked
 * @param {Function} props.isStepComplete - The function to call to check if a step is complete
 */
export const CreateStepBreadcrumb: React.FC<CreateStepBreadcrumbProps> = ({
	steps,
	currentStep,
	onStepClick,
	isStepComplete,
}) => {
	return (
		<div className="flex items-center">
			{steps.map((step, index) => {
				const isActive = currentStep === step.key
				// TODO: use is completed to show the completed step
				const _isCompleted = isStepComplete(step.key)

				return (
					<React.Fragment key={step.key}>
						<Button
							variant="outline"
							size="sm"
							onClick={() => onStepClick(step.key)}
							className={cn(
								"rounded-full text-sm border cursor-pointer transition-colors",
								isActive
									? "border-primary text-primary"
									: "border-muted-foreground text-muted-foreground hover:text-primary hover:border-primary",
							)}
						>
							<span className=" text-xs mr-2">{index + 1}</span>
							{step.label}
						</Button>
						{index < steps.length - 1 && (
							<Separator orientation="horizontal" className="w-6 mx-2" />
						)}
					</React.Fragment>
				)
			})}
		</div>
	)
}
