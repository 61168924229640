import DOMPurify from "dompurify"

export const sanitizeHtml = (html: string | undefined): string => {
	if (!html) return ""

	return DOMPurify.sanitize(html, {
		ALLOWED_TAGS: ["b", "i", "em", "strong", "p", "br", "div", "span"],
		ALLOWED_ATTR: ["class", "style"],
		KEEP_CONTENT: true,
	})
}
